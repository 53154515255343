import { useState } from 'react';
import {
  Grid,
  Paper,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';

type Props = {
  isLoading: boolean;
  getClient: (qry: string) => void;
};

export default function ClientSearch({ isLoading, getClient }: Props) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('dk');
  const handleCountryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedCountry(event.target.value as string);
  };
  const handleSearch = () => {
    const queryStr = `?search=${searchTerm}&countryCode=${selectedCountry}`;
    getClient(queryStr);
  };
  return (
    <Paper variant="outlined" style={{ padding: '10px 10px 20px 10px' }}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12}>
          <Typography variant="subtitle2" color="primary">
            Search client information
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <TextField fullWidth label="Search term" onChange={(e) => setSearchTerm(e.target.value)} value={searchTerm} />
        </Grid>
        <Grid item xs={5}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Country</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              fullWidth
              value={selectedCountry}
              onChange={handleCountryChange}
            >
              <MenuItem value="dk">Denmark</MenuItem>
              <MenuItem value="no">Norway</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <Button variant="contained" disabled={isLoading || !searchTerm} color="primary" onClick={handleSearch}>
            Search
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}
