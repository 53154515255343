import { Column } from '@material-table/core';
import { Data } from './types';
import { green, red } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';

export const columns: Array<Column<Data>> = [
  { title: 'ID', field: 'accountUuid', cellStyle: { display: 'none' }, headerStyle: { display: 'none' } },
  { title: 'Name', field: 'accountName' },
  { title: 'Client', field: 'client.clientCompanyName' },
  { title: 'Type', field: 'accountType.accountTypeName' },
  { title: 'Commission', field: 'accountCommission', render: (row) => `${row.accountCommission} %` },
  {
    title: 'On Hold %',
    field: 'accountConversionOnHoldPercentage',
    render: (row) => `${row.accountConversionOnHoldPercentage} %`,
  },
  {
    title: 'Active',
    field: 'accountStatus',
    render: (row) =>
      row.accountStatus ? (
        <Icon style={{ color: green[400] }}>check_circle</Icon>
      ) : (
        <Icon style={{ color: red[400] }}>remove_circle</Icon>
      ),
  },
];

export const createDefaultValues = {
  accountName: '',
  accountTypeUuid: null,
  accountClientUuid: null,
  accountCommission: 0,
  accountConversionOnHoldPercentage: 0,
};
