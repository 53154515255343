import { useState } from 'react';
import { Paper, Grid, Typography } from '@material-ui/core/';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Buttons from './Buttons';
import Skeleton from '@material-ui/lab/Skeleton';
import Lines from './Lines';
import { Graph } from '../../../../types';

type PropsType = {
  isLoading: boolean;
  data: Graph[] | undefined;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
  paperContainer: {
    padding: theme.spacing(3),
  },
  section: {
    marginTop: theme.spacing(2),
  },
}));

export default function LineContainer({ data, isLoading }: PropsType) {
  const classes = useStyles();
  const [filters, setFilter] = useState<string[]>(['partnerCommission', 'heylinkCommission']);

  const handleFilterClick = (key: string) => {
    if (filters.includes(key)) {
      const newFilters = filters.filter((val) => val !== key);
      setFilter(newFilters);
    } else {
      const newArr = [...filters, key];
      setFilter(newArr);
    }
  };

  return (
    <Grid className={classes.root} container>
      <Grid item xs={12}>
        <Paper className={classes.paperContainer} variant="outlined">
          <Grid justify="space-between" alignItems="center" container>
            <div>
              <Typography variant="subtitle2" color="primary">
                Performance graph
              </Typography>
            </div>
            <Buttons filters={filters} handleFilterClick={handleFilterClick} />
          </Grid>
          <Grid justify="space-between" alignItems="center" container className={classes.section}>
            {isLoading ? (
              <Skeleton variant="rect" width="100%" height={370} animation="wave" />
            ) : (
              <Lines filters={filters} data={data} />
            )}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
