import { useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Divider,
  Chip,
  Typography,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { makeCall } from 'utils/utils';
import { UpdateFormData as FormData, UpdateFormPropsType as PropsType } from '../types';
import EnhancedTextField from 'components/EnhancedTextField';
import EnhancedSwitch from 'components/EnhancedSwitch';
import Clipboard from 'components/ClipboardV2';

export default function Update({ open, handleClose, refresh, record }: PropsType) {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
  } = useForm<FormData>();

  useEffect(() => {
    if (record) {
      reset({
        commissionRuleValue: record.commissionRuleValue,
        commissionRuleName: record.commissionRuleName,
        commissionRuleStatus: record.commissionRuleStatus,
      });
    }
  }, [record, reset]);

  const onSubmit = async (data: FormData) => {
    await makeCall(`commissionRules/${record?.commissionRuleUuid}`, 'PUT', data);
    close();
    refresh();
  };

  const close = () => {
    handleClose();
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        disableBackdropClick={true}
        onClose={close}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Update commission rule</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Clipboard id={record?.commissionRuleUuid ?? ''} />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Chip
                  variant="outlined"
                  label={
                    <Typography variant="subtitle2" color="primary">
                      Account: {record?.account.accountName ?? '-'}
                    </Typography>
                  }
                  color="primary"
                  style={{ marginRight: '10px' }}
                />
                <Chip
                  variant="outlined"
                  label={
                    <Typography variant="subtitle2" color="primary">
                      Media: {record?.media.mediaUrl ?? '-'}
                    </Typography>
                  }
                  color="primary"
                  style={{ marginRight: '10px' }}
                />
                <Chip
                  variant="outlined"
                  label={
                    <Typography variant="subtitle2" color="primary">
                      Tracker: {record?.tracker.trackerName ?? '-'}
                    </Typography>
                  }
                  color="primary"
                  style={{ marginRight: '10px' }}
                />
                <Chip
                  variant="outlined"
                  label={
                    <Typography variant="subtitle2" color="primary">
                      Program: {record?.program.programTitle ?? '-'}
                    </Typography>
                  }
                  color="primary"
                  style={{ marginRight: '10px' }}
                />
                <Chip
                  variant="outlined"
                  label={
                    <Typography variant="subtitle2" color="primary">
                      Merchant: {record?.merchant.merchantUrl ?? '-'}
                    </Typography>
                  }
                  color="primary"
                  style={{ marginRight: '10px' }}
                />
              </Grid>
            </Grid>
            <Divider style={{ margin: '20px 0' }} />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <EnhancedTextField
                  control={control}
                  fieldName="commissionRuleName"
                  label="Rule name"
                  hasError={errors.commissionRuleName ? true : false}
                  errorTxt="Rule name is required!"
                />
              </Grid>
              <Grid item xs={12}>
                <EnhancedTextField
                  control={control}
                  fieldName="commissionRuleValue"
                  label="Rule value %"
                  hasError={errors.commissionRuleValue ? true : false}
                  errorTxt="Rule value is required!"
                />
              </Grid>
              <Grid item xs={4}>
                <EnhancedSwitch
                  isChecked={!!record?.commissionRuleStatus}
                  control={control}
                  fieldName="commissionRuleStatus"
                  label="Status"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={close} color="primary">
              Cancel
            </Button>
            <Button disabled={isSubmitting} type="submit" color="primary">
              {isSubmitting ? 'Updating...' : 'Update'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
