import { useForm, Controller } from 'react-hook-form';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import { makeCall } from 'utils/utils';
import { CreateFormData as FormData, CreateFormPropsType as PropsType } from './types';
import { createDefaultValues as defaultValues } from './initialData';
import EnhancedTextField from 'components/EnhancedTextField';
import EnhancedSwitch from 'components/EnhancedSwitch';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ClientSearch from './ClientSearch';

export default function Create({ open, handleClose, refresh, countries }: PropsType) {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
  } = useForm<FormData>({ defaultValues });

  const resetForm = () => {
    reset(defaultValues);
    handleClose();
  };

  const onSubmit = async (data: FormData) => {
    const payload = {
      ...data,
      clientCountryCode: data.clientCountryCode?.countryCode,
    };
    await makeCall('clients', 'POST', payload);
    reset(defaultValues);
    handleClose();
    refresh();
  };

  const getClient = async (qry: string) => {
    const response = await makeCall(`clients/information/search${qry}`, 'GET');
    console.log(response?.data.data);
    if (response) {
      const client = response.data.data;
      const country = countries.filter((row) => row.countryCode.toLowerCase() === client.clientCountryCode);

      reset({
        clientFirstname: client.clientFirstname,
        clientLastname: client.clientLastname,
        clientCompanyName: client.clientCompanyName,
        clientUrl: client.clientUrl,
        clientEmail: client.clientEmail,
        clientBillingEmail: client.clientBillingEmail,
        clientPhone: client.clientPhone,
        clientVatNumber: client.clientVatNumber.toString(),
        clientAddress: client.clientAddress,
        clientZipcode: client.clientZipcode,
        clientCity: client.clientCity,
        clientCountryCode: country.length ? country[0] : null,
        clientBankRegistration: client.clientBankRegistration,
        clientBankAccount: client.clientBankAccount,
        clientBankIban: client.clientBankIban,
        clientStatus: client.clientStatus,
        clientPaymentTypeKey: client.clientPaymentTypeKey,
      });
    }
  };
  return (
    <Dialog
      open={open}
      disableBackdropClick={true}
      onEscapeKeyDown={resetForm}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="form-dialog-title">Create Client</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <ClientSearch isLoading={false} getClient={getClient} />
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <EnhancedTextField
                control={control}
                required={true}
                fieldName="clientFirstname"
                label="First name"
                hasError={errors.clientFirstname ? true : false}
                errorTxt="First name is required!"
                autoFocus
              />
            </Grid>
            <Grid item xs={4}>
              <EnhancedTextField
                control={control}
                required={true}
                fieldName="clientLastname"
                label="Last name"
                hasError={errors.clientLastname ? true : false}
                errorTxt="Last name is required!"
              />
            </Grid>
            <Grid item xs={4}>
              <EnhancedTextField
                control={control}
                required={true}
                fieldName="clientCompanyName"
                label="Company name"
                hasError={errors.clientCompanyName ? true : false}
                errorTxt="Company name is required!"
              />
            </Grid>
            <Grid item xs={4}>
              <EnhancedTextField
                control={control}
                required={true}
                fieldName="clientUrl"
                label="Website url"
                hasError={errors.clientUrl ? true : false}
                errorTxt="Website url is required!"
              />
            </Grid>
            <Grid item xs={4}>
              <EnhancedTextField
                control={control}
                required={true}
                fieldName="clientEmail"
                label="Email"
                hasError={errors.clientEmail ? true : false}
                errorTxt="Valid Email is required!"
                isEmail
              />
            </Grid>
            <Grid item xs={4}>
              <EnhancedTextField
                control={control}
                required={true}
                fieldName="clientBillingEmail"
                label="Billing Email"
                hasError={errors.clientBillingEmail ? true : false}
                errorTxt="Valid Billing Email is required!"
                isEmail
              />
            </Grid>
            <Grid item xs={4}>
              <EnhancedTextField
                control={control}
                required={true}
                fieldName="clientPhone"
                label="Phone"
                hasError={errors.clientPhone ? true : false}
                errorTxt="Phone is required!"
              />
            </Grid>
            <Grid item xs={4}>
              <EnhancedTextField
                control={control}
                required={true}
                fieldName="clientVatNumber"
                label="VAT number"
                hasError={errors.clientVatNumber ? true : false}
                errorTxt="VAT number is required!"
              />
            </Grid>
            <Grid item xs={4}>
              <EnhancedTextField
                control={control}
                required={true}
                fieldName="clientAddress"
                label="Address"
                hasError={errors.clientAddress ? true : false}
                errorTxt="Address is required!"
              />
            </Grid>
            <Grid item xs={4}>
              <EnhancedTextField
                control={control}
                required={true}
                fieldName="clientZipcode"
                label="Zipcode"
                hasError={errors.clientZipcode ? true : false}
                errorTxt="Zipcode is required!"
              />
            </Grid>
            <Grid item xs={4}>
              <EnhancedTextField
                control={control}
                required={true}
                fieldName="clientCity"
                label="City"
                hasError={errors.clientCity ? true : false}
                errorTxt="City is required!"
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="clientCountryCode"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    id="currency-select"
                    options={countries}
                    onChange={(_, data) => field.onChange(data)}
                    getOptionLabel={(option) => option.countryName}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        error={errors.clientCountryCode ? true : false}
                        helperText={errors.clientCountryCode ? 'Country is required!' : null}
                        {...params}
                        label="Country"
                        margin="dense"
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <EnhancedTextField
                control={control}
                fieldName="clientBankRegistration"
                label="Bank registration number"
                hasError={errors.clientBankRegistration ? true : false}
                errorTxt="Bank registration number is required!"
              />
            </Grid>
            <Grid item xs={4}>
              <EnhancedTextField
                control={control}
                fieldName="clientBankAccount"
                label="Bank account number"
                hasError={errors.clientBankAccount ? true : false}
                errorTxt="Bank account number is required!"
              />
            </Grid>
            <Grid item xs={4}>
              <EnhancedTextField control={control} required={false} fieldName="clientBankIban" label="Bank IBAN" />
            </Grid>
            <Grid item xs={4}>
              <EnhancedTextField
                control={control}
                required={false}
                fieldName="clientPaymentTypeKey"
                label="Payment type"
              />
            </Grid>
            <Grid item xs={4}>
              <EnhancedSwitch control={control} fieldName="clientStatus" label="Active" />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={resetForm} color="primary">
            Cancel
          </Button>
          <Button disabled={isSubmitting} type="submit" color="primary">
            {isSubmitting ? 'Creating...' : 'Create'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
