import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Typography,
  Divider,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import RefreshIcon from '@material-ui/icons/Refresh';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Clipboard from 'components/ClipboardV2';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ViewFormPropsType as PropsType } from './types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperRoot: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
    chipsRoot: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    dividerSpace: {
      marginBottom: theme.spacing(1),
    },
  })
);

const formatter = new Intl.NumberFormat('da-DK');

export default function View({ open, handleClose, refresh, record, handleDownloadClick }: PropsType) {
  const classes = useStyles();

  const close = () => {
    handleClose();
  };

  const handleStatusClick = () => {
    if (record && record.paymentNote.paymentNoteStatus === 'COMPLETED') return false;
    refresh(record?.paymentNote.paymentNoteUuid ?? '', 'view');
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      disableBackdropClick={true}
      onClose={close}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Payment note overview
        <IconButton aria-label="close" className={classes.closeButton} onClick={close}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Paper variant="outlined" className={classes.paperRoot}>
              <Grid container spacing={3}>
                <Grid item xs={9}>
                  <div style={{ paddingLeft: '20px' }}>
                    <Clipboard id={record?.paymentNote.paymentNoteUuid ?? ''} />
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    disabled={record?.paymentNote.paymentNoteStatus !== 'COMPLETED'}
                    onClick={() => handleDownloadClick(record?.paymentNote.paymentNoteUuid ?? '')}
                  >
                    Download as PDF
                  </Button>
                </Grid>
              </Grid>
              <Divider className={classes.dividerSpace} />
              <div className={classes.chipsRoot}>
                <Chip
                  variant="outlined"
                  label={
                    <Typography variant="subtitle2" color="primary">
                      Account: {record?.account.accountName ?? '-'}
                    </Typography>
                  }
                  color="primary"
                />
                <Chip
                  variant="outlined"
                  label={
                    <Typography variant="subtitle2" color="primary">
                      Date: {record?.paymentNote.paymentNoteDate ?? '-'}
                    </Typography>
                  }
                  color="primary"
                />
                <Chip
                  variant="outlined"
                  label={
                    <Typography variant="subtitle2" color="primary">
                      Period: {record?.paymentNote.paymentNotePeriodStartDate ?? '-'} -{' '}
                      {record?.paymentNote.paymentNotePeriodEndDate ?? '-'}
                    </Typography>
                  }
                  color="primary"
                />
              </div>
              <div className={classes.chipsRoot}>
                <Chip
                  variant="outlined"
                  label={
                    <Typography variant="subtitle2" color="primary">
                      Subtotal: {formatter.format(record?.paymentNote.paymentNoteSubTotal ?? 0)}
                    </Typography>
                  }
                  color="primary"
                />
                <Chip
                  variant="outlined"
                  label={
                    <Typography variant="subtitle2" color="primary">
                      VAT: {record?.paymentNote.paymentNoteVat ?? '-'}
                    </Typography>
                  }
                  color="primary"
                />
                <Chip
                  variant="outlined"
                  label={
                    <Typography variant="subtitle2" color="primary">
                      Currency: {record?.paymentNote.paymentNoteCurrencyCode ?? '-'}
                    </Typography>
                  }
                  color="primary"
                />
              </div>
              <div className={classes.chipsRoot}>
                <Chip
                  variant="default"
                  label={
                    <Typography variant="subtitle2" style={{ color: '#fff' }}>
                      Sum: {formatter.format(record?.paymentNote.paymentNoteTotal ?? 0)}{' '}
                      {record?.paymentNote.paymentNoteCurrencyCode ?? '-'}
                    </Typography>
                  }
                  color="primary"
                />
                <Chip
                  variant="default"
                  label={
                    <Typography variant="subtitle2" style={{ color: '#fff' }}>
                      Status: {record?.paymentNote.paymentNoteStatus ?? '-'}
                    </Typography>
                  }
                  icon={record?.paymentNote.paymentNoteStatus === 'COMPLETED' ? <CheckCircleIcon /> : <RefreshIcon />}
                  onClick={() => handleStatusClick()}
                  color="primary"
                />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper variant="outlined" className={classes.paperRoot}>
              <Typography color="primary" variant="h6" style={{ marginBottom: '10px' }}>
                Metadata
              </Typography>
              <Divider className={classes.dividerSpace} />
              <div className={classes.chipsRoot}>
                <Chip
                  variant="outlined"
                  label={
                    <Typography variant="subtitle2" color="primary">
                      Admin: {record?.createdBy.adminName ?? '-'}
                    </Typography>
                  }
                  color="primary"
                />
                <Chip
                  variant="outlined"
                  label={
                    <Typography variant="subtitle2" color="primary">
                      Created on: {record?.createdBy.datetime ?? '-'}
                    </Typography>
                  }
                  color="primary"
                />
                <Chip
                  variant="outlined"
                  label={
                    <Typography variant="subtitle2" color="primary">
                      Committed on: {record?.committedBy.datetime ?? '-'}
                    </Typography>
                  }
                  color="primary"
                />
                <Chip
                  variant="outlined"
                  label={
                    <Typography variant="subtitle2" color="primary">
                      Completed on: {record?.paymentNote.paymentNoteCompletedDatetime ?? '-'}
                    </Typography>
                  }
                  color="primary"
                />
              </div>
            </Paper>
          </Grid>
        </Grid>
        <Divider />
        <Grid container style={{ margin: '20px 0' }}>
          <Typography variant="h6" color="primary" style={{ margin: '0 18px' }}>
            Payment Note Items
          </Typography>
          <TableContainer>
            <Table size="small" aria-label="details-table">
              <TableHead>
                <TableRow>
                  <TableCell>Advertiser</TableCell>
                  <TableCell align="right">Quantity</TableCell>
                  <TableCell align="right">Commission</TableCell>
                  <TableCell align="right">Currency</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {record?.paymentNoteItems.map((row, idx) => (
                  <TableRow key={idx}>
                    <TableCell component="th" scope="row">
                      {row.advertiser.advertiserName}
                    </TableCell>
                    <TableCell align="right">{formatter.format(row.paymentNoteItem.paymentNoteItemQuantity)}</TableCell>
                    <TableCell align="right">
                      {formatter.format(row.paymentNoteItem.paymentNoteItemCommission)}
                    </TableCell>
                    <TableCell align="right">{row.paymentNoteItem.paymentNoteItemCurrencyCode}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid container style={{ margin: '20px 0' }}>
          <Typography variant="h6" color="primary" style={{ margin: '0 18px' }}>
            Transactions
          </Typography>
          <TableContainer>
            <Table size="small" aria-label="details-table">
              <TableHead>
                <TableRow>
                  <TableCell>Text</TableCell>
                  <TableCell align="right">Amount</TableCell>
                  <TableCell align="right">Currency</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {record?.paymentNoteTransactions.map((row, idx) => (
                  <TableRow key={idx}>
                    <TableCell component="th" scope="row">
                      {row.transactionText}
                    </TableCell>
                    <TableCell align="right">{formatter.format(row.transactionAmount)}</TableCell>
                    <TableCell align="right">{row.transactionCurrencyCode}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
