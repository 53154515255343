import React, { useState } from 'react';
import { LineChart, Line, Tooltip, YAxis, XAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import { Card, CardContent, Typography, Divider, Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Graph, CurrencyType } from '../../../../types';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    '.recharts-cartesian-axis-tick': {
      fontSize: '14px',
      fontFamily: 'Rubik, sans-serif',
    },
  },
}));

type Props = {
  filters: string[];
  data: Graph[] | undefined;
};

const fillOpacity = 0;
const strokeOpacity = 0.6;
const strokeOpacityActive = 0.9;
const strokeWidth = 4;

export default function Lines({ filters, data }: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const [activeColor, setActiveColor] = useState('');
  const [activeArea, setActiveArea] = useState('');
  const [cx, setCX] = useState(0);
  const [cy, setCY] = useState(0);

  const handleDotEnter = (e: any) => {
    setActiveArea(e.dataKey);
    setActiveColor(e.fill);
    setCX(e.cx);
    setCY(e.cy);
  };
  const handleDotLeave = () => {
    setActiveArea('');
    setCX(0);
    setCY(0);
  };
  const handleAreaEnter = (e: string) => {
    setActiveArea(e);
  };
  const handleAreaLeave = () => {
    setActiveArea('');
  };

  const renderTargetLine = () => {
    if (cy && cx) {
      return (
        <svg>
          <line id="HorizontalLine" y1={cy} y2={cy} x1={0} x2={cx} stroke={activeColor} strokeWidth={2} />
          <line id="VerticalLine" y1={320} y2={cy} x1={cx} x2={cx} stroke={activeColor} strokeWidth={2} />
        </svg>
      );
    }
  };

  const renderTooltip = (props: any) => {
    if (typeof props.payload != 'undefined' && props.payload != null) {
      if (typeof props.payload[0] != 'undefined') {
        const formatter = new Intl.NumberFormat('da-DK');
        return (
          <Card style={{ zIndex: 1000 }}>
            <CardContent>
              <Typography variant="subtitle2">
                <b>{props.payload[0].payload.grpName}</b>
              </Typography>
              <Divider />
              <Typography style={{ color: theme.pageviews.primary }}>
                Pageviews: {formatter.format(props.payload[0].payload.counts.pageviewsCount)}
              </Typography>
              <Typography style={{ color: theme.clicks.primary }}>
                Clicks: {formatter.format(props.payload[0].payload.counts.clicksCount)}
              </Typography>
              <Typography style={{ color: theme.conversions.primary }}>
                Conversions: {formatter.format(props.payload[0].payload.counts.conversionsCount)}
              </Typography>
              <Divider />
              {props.payload[0].payload.commissions.length > 1 ? (
                <div>
                  {props.payload[0].payload.commissions.map((currency: CurrencyType) => {
                    return (
                      <Typography key={`currency-${currency.currencyCode}`}>
                        {currency.currencyCode} : {formatter.format(currency.value)}
                      </Typography>
                    );
                  })}
                  <Divider />
                </div>
              ) : null}
              <Typography style={{ color: theme.partnerCommission.primary }}>
                Partner Commission: DKK {formatter.format(props.payload[0].payload.sums.partnerCommissionSum)}
              </Typography>
              <Typography style={{ color: theme.heylinkCommission.primary }}>
                Heylink Commission: DKK {formatter.format(props.payload[0].payload.sums.heylinkCommissionSum)}
              </Typography>
              <Typography style={{ color: theme.commission.primary }}>
                Total Commission: DKK {formatter.format(props.payload[0].payload.sums.totalCommissionSum)}
              </Typography>
            </CardContent>
          </Card>
        );
      }
    }
  };
  return (
    <Grid container className={classes.root}>
      <ResponsiveContainer width="100%" height={350}>
        <LineChart
          data={data}
          margin={{
            top: 7,
            right: 7,
            left: 7,
            bottom: 7,
          }}
        >
          <Tooltip content={(props: any) => renderTooltip(props)} wrapperStyle={{ zIndex: 10000 }} />
          <YAxis yAxisId="pageviews" hide allowDataOverflow={true} />
          <YAxis yAxisId="clicks" hide allowDataOverflow={true} />
          <YAxis yAxisId="conversions" hide allowDataOverflow={true} />
          <YAxis yAxisId="partnerCommission" hide allowDataOverflow={true} />
          <YAxis yAxisId="heylinkCommission" hide allowDataOverflow={true} />
          <YAxis yAxisId="commission" hide allowDataOverflow={true} />
          <XAxis
            tickFormatter={(label: any) => {
              const chunks = label.split(' ');
              if (chunks.length > 1) {
                return `${chunks[1]}:00`;
              }
              return label;
            }}
            dataKey="grpName"
            allowDataOverflow={true}
            interval="preserveStartEnd"
            axisLine={false}
            tickLine={false}
            scale="point"
            tickMargin={10}
            minTickGap={55}
          />
          <CartesianGrid vertical={false} strokeDasharray="5 3" />
          {renderTargetLine()}
          {filters.includes('pageviews') ? null : (
            <Line
              type="monotone"
              yAxisId="pageviews"
              dataKey={'counts.pageviewsCount'}
              stroke={theme.pageviews.primary}
              strokeOpacity={activeArea === 'pageviews' ? strokeOpacityActive : strokeOpacity}
              fillOpacity={fillOpacity}
              fill={theme.pageviews.primary}
              activeDot={{
                stroke: '#fff',
                strokeWidth: 3,
                r: 7,
                fill: theme.pageviews.primary,
                onMouseEnter: (e: any) => handleDotEnter(e),
                onMouseLeave: () => handleDotLeave(),
              }}
              strokeWidth={strokeWidth}
              dot={false}
              onMouseEnter={() => handleAreaEnter('pageviews')}
              onMouseLeave={() => handleAreaLeave()}
            />
          )}
          {filters.includes('clicks') ? null : (
            <Line
              type="monotone"
              yAxisId="clicks"
              dataKey={'counts.clicksCount'}
              stroke={theme.clicks.primary}
              strokeOpacity={activeArea === 'clicks' ? strokeOpacityActive : strokeOpacity}
              fillOpacity={fillOpacity}
              fill={theme.clicks.primary}
              activeDot={{
                stroke: '#fff',
                strokeWidth: 3,
                r: 7,
                fill: theme.clicks.primary,
                onMouseEnter: (e: any) => handleDotEnter(e),
                onMouseLeave: () => handleDotLeave(),
              }}
              strokeWidth={strokeWidth}
              dot={false}
              onMouseEnter={() => handleAreaEnter('clicks')}
              onMouseLeave={() => handleAreaLeave()}
            />
          )}
          {filters.includes('conversions') ? null : (
            <Line
              type="monotone"
              yAxisId="conversions"
              dataKey={'counts.conversionsCount'}
              stroke={theme.conversions.primary}
              strokeOpacity={activeArea === 'conversions' ? strokeOpacityActive : strokeOpacity}
              fillOpacity={fillOpacity}
              fill={theme.conversions.primary}
              activeDot={{
                stroke: '#fff',
                strokeWidth: 3,
                r: 7,
                fill: theme.conversions.primary,
                onMouseEnter: (e: any) => handleDotEnter(e),
                onMouseLeave: () => handleDotLeave(),
              }}
              strokeWidth={strokeWidth}
              dot={false}
              onMouseEnter={() => handleAreaEnter('conversions')}
              onMouseLeave={() => handleAreaLeave()}
            />
          )}
          {filters.includes('partnerCommission') ? null : (
            <Line
              type="monotone"
              yAxisId="partnerCommission"
              dataKey={'sums.partnerCommissionSum'}
              stroke={theme.partnerCommission.primary}
              strokeOpacity={activeArea === 'partnerCommission' ? strokeOpacityActive : strokeOpacity}
              fillOpacity={fillOpacity}
              fill={theme.partnerCommission.primary}
              activeDot={{
                stroke: '#fff',
                strokeWidth: 3,
                r: 7,
                fill: theme.partnerCommission.primary,
                onMouseEnter: (e: any) => handleDotEnter(e),
                onMouseLeave: () => handleDotLeave(),
              }}
              strokeWidth={strokeWidth}
              dot={false}
              onMouseEnter={() => handleAreaEnter('partnerCommission')}
              onMouseLeave={() => handleAreaLeave()}
            />
          )}
          {filters.includes('heylinkCommission') ? null : (
            <Line
              type="monotone"
              yAxisId="heylinkCommission"
              dataKey={'sums.heylinkCommissionSum'}
              stroke={theme.heylinkCommission.primary}
              strokeOpacity={activeArea === 'heylinkCommission' ? strokeOpacityActive : strokeOpacity}
              fillOpacity={fillOpacity}
              fill={theme.heylinkCommission.primary}
              activeDot={{
                stroke: '#fff',
                strokeWidth: 3,
                r: 7,
                fill: theme.heylinkCommission.primary,
                onMouseEnter: (e: any) => handleDotEnter(e),
                onMouseLeave: () => handleDotLeave(),
              }}
              strokeWidth={strokeWidth}
              dot={false}
              onMouseEnter={() => handleAreaEnter('heylinkCommission')}
              onMouseLeave={() => handleAreaLeave()}
            />
          )}
          {filters.includes('commission') ? null : (
            <Line
              type="monotone"
              yAxisId="commission"
              dataKey={'sums.totalCommissionSum'}
              stroke={theme.commission.primary}
              strokeOpacity={activeArea === 'commission' ? strokeOpacityActive : strokeOpacity}
              fillOpacity={fillOpacity}
              fill={theme.commission.primary}
              activeDot={{
                stroke: '#fff',
                strokeWidth: 3,
                r: 7,
                fill: theme.commission.primary,
                onMouseEnter: (e: any) => handleDotEnter(e),
                onMouseLeave: () => handleDotLeave(),
              }}
              strokeWidth={strokeWidth}
              dot={false}
              onMouseEnter={() => handleAreaEnter('commission')}
              onMouseLeave={() => handleAreaLeave()}
            />
          )}
        </LineChart>
      </ResponsiveContainer>
    </Grid>
  );
}
