import Autocomplete from '@material-ui/lab/Autocomplete';
import { Grid, Paper, TextField, Button } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { FilterFormData as FormData, FilterFormProps as PropsType } from './types';
import { filterDefaultValues as defaultValues } from './initialData';
import Switch from 'components/EnhancedSwitch';

export default function FiltersForm({ networks, merchants, categories, handleFiltersSubmit }: PropsType) {
  const { handleSubmit, control } = useForm<FormData>({
    defaultValues,
  });

  const onSubmit = (data: FormData) => {
    const payload: any = {};
    if (data.programImported) {
      payload.programImported = true;
    }
    if (data.programDisabled) {
      payload.programDisabled = true;
    }
    if (data.programNetworkUuid) {
      payload.programNetworkUuid = data.programNetworkUuid.networkUuid;
    }
    if (data.programMerchantUuid) {
      payload.programMerchantUuid = data.programMerchantUuid.merchantUuid;
    }
    if (data.programCategoryKey) {
      payload.programCategoryKey = data.programCategoryKey.categoryKey;
    }
    handleFiltersSubmit(payload);
  };

  return (
    <Paper variant="outlined" style={{ padding: '10px 20px' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3} alignItems="center" justify="center">
          <Grid item xs={4}>
            <Controller
              name="programNetworkUuid"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  id="network-select"
                  options={networks}
                  onChange={(_, data) => field.onChange(data)}
                  getOptionLabel={(option) => option.networkName}
                  fullWidth
                  renderInput={(params) => <TextField {...params} label="Network" margin="dense" variant="outlined" />}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="programMerchantUuid"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  id="advertiser-select"
                  options={merchants}
                  onChange={(_, data) => field.onChange(data)}
                  getOptionLabel={(option) => option.merchantUrl}
                  fullWidth
                  renderInput={(params) => (
                    <TextField {...params} label="Advertiser" margin="dense" variant="outlined" />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="programCategoryKey"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  id="category-select"
                  options={categories}
                  onChange={(_, data) => field.onChange(data)}
                  getOptionLabel={(option) => option.categoryName}
                  fullWidth
                  renderInput={(params) => <TextField {...params} label="Category" margin="dense" variant="outlined" />}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: '20px' }}>
          <Grid item xs={4}>
            <Switch control={control} isChecked={false} fieldName="programImported" label="Imported" />
          </Grid>
          <Grid item xs={6}>
            <Switch control={control} isChecked={false} fieldName="programDisabled" label="Disabled" />
          </Grid>
          <Grid item xs={2}>
            <Button variant="contained" fullWidth type="submit" color="primary">
              Search
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}
