import { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { makeCall } from 'utils/utils';
import { UpdateFormData as FormData, UpdateFormPropsType as PropsType } from './types';
import EnhancedTextField from 'components/EnhancedTextField';
import EnhancedSwitch from 'components/EnhancedSwitch';

export default function Update({ open, handleClose, record, refresh }: PropsType) {
  const [isCopied, setIsCopied] = useState(false);
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
  } = useForm<FormData>();

  useEffect(() => {
    if (record) {
      reset({
        accountName: record.accountName,
        accountCommission: record.accountCommission,
        accountStatus: record.accountStatus,
        accountConversionOnHoldPercentage: record.accountConversionOnHoldPercentage,
      });
    }
  }, [record, reset]);

  const onSubmit = async (data: FormData) => {
    const payload = {
      ...data,
    };
    await makeCall(`accounts/${record?.accountUuid}`, 'PUT', payload);
    close();
    refresh();
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(record?.accountUuid ?? '');
    setIsCopied(true);
  };

  const close = () => {
    setIsCopied(false);
    handleClose();
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        disableBackdropClick={true}
        onClose={close}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Update Account</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <DialogContentText>
              ID: {record?.accountUuid}{' '}
              <Button disabled={isCopied} color="primary" onClick={handleCopy}>
                {isCopied ? 'Copied' : 'Copy'}
              </Button>
            </DialogContentText>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <EnhancedTextField
                  control={control}
                  required={false}
                  fieldName="accountName"
                  label="Account name"
                  hasError={errors.accountName ? true : false}
                  errorTxt="Account name is required!"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <EnhancedTextField
                  control={control}
                  fieldName="accountCommission"
                  label="Commission %"
                  hasError={errors.accountCommission ? true : false}
                  errorTxt="Commission is required!"
                />
              </Grid>
              <Grid item xs={12}>
                <EnhancedTextField
                  control={control}
                  fieldName="accountConversionOnHoldPercentage"
                  label="Conversion on hold %"
                  hasError={errors.accountConversionOnHoldPercentage ? true : false}
                  errorTxt="Conversion on hold is required!"
                />
              </Grid>
              <Grid item xs={4}>
                <EnhancedSwitch
                  control={control}
                  fieldName="accountStatus"
                  label="Active"
                  isChecked={!!record?.accountStatus}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={close} color="primary">
              Cancel
            </Button>
            <Button disabled={isSubmitting} type="submit" color="primary">
              {isSubmitting ? 'Updating...' : 'Update'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
