import { Column } from '@material-table/core';
import { green, red } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import { Data, CreateFormData } from './types';
import moment from 'moment';

export const columns: Array<Column<Data>> = [
  {
    title: 'ID',
    field: 'connectionUuid',
    cellStyle: { display: 'none' },
    headerStyle: { display: 'none' },
  },
  { title: 'Account', field: 'account.accountName' },
  { title: 'Website ID', field: 'connectionWebsiteId' },
  {
    title: 'Status',
    field: 'connectionStatus',
    render: (row) =>
      row.connectionStatus ? (
        <Icon style={{ color: green[400] }}>check_circle</Icon>
      ) : (
        <Icon style={{ color: red[400] }}>remove_circle</Icon>
      ),
  },
  { title: 'Minutes between Import', field: 'connectionMinutesBetweenImport' },
  {
    title: 'Last Import',
    field: 'connectionLastImportDatetime',
    render: (rowData) => moment(rowData.connectionLastImportDatetime).format('DD MMM, HH:mm'),
  },
];

export const createDefaultValues: CreateFormData = {
  connectionMinutesBetweenImport: 10,
  connectionAccountUuid: null,
  connectionWebsiteId: '',
  connectionApiToken: '',
};

export const texts = {
  title: 'Commission Junction',
  apiKey: 'commissionjunction',
};
