import { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import { tableContainerStyles as useStyles } from 'styles/common';
import { Columns } from './initialData';
import { makeCall, generateFile } from 'utils/utils';
import WithMotion from 'components/withMotion';
import Filters from './Filters';
import { Account, Currency, Network, FiltersType, DataType } from './types';
import CardsContainer from './CardsContainer';
import ChartContainer from './ChartContainer';

function Container() {
  const classes = useStyles();
  const [filters, setFilters] = useState<FiltersType | null>(null);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [networks, setNetworks] = useState<Network[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<DataType | null>(null);

  useEffect(() => {
    const getData = () => {
      makeCall('accounts', 'GET').then((res) => setAccounts(res?.data.data));
      makeCall('currencies', 'GET').then((res) => setCurrencies(res?.data.data));
      makeCall('networks', 'GET').then((res) => setNetworks(res?.data.data));
    };
    getData();
  }, []);

  const generateReport = async (payload: any) => {
    setFilters(payload);
    setIsLoading(true);
    const response = await makeCall('finance/reports/status', 'POST', payload);
    setData(response?.data.data);
    setIsLoading(false);
  };

  const openAsCSV = async () => {
    if (data?.rows && data.rows.length) {
      const response = await makeCall('finance/reports/status', 'POST', filters, null, null, 'text', true);
      if (response) {
        generateFile(response.data, `finance-status-report.csv`);
      }
    }
  };

  return (
    <Grid>
      <Typography variant="h6" color="primary" style={{ marginBottom: 10 }}>
        Finance status report
      </Typography>
      <Filters accounts={accounts} networks={networks} currency={currencies} generate={generateReport} />
      <CardsContainer isLoading={isLoading} data={data} filters={filters} />
      <ChartContainer isLoading={isLoading} data={data} filters={filters} />
      <Grid container style={{ marginTop: 20 }}>
        <Grid item xs={12}>
          <MaterialTable
            title=""
            components={{
              Toolbar: (props) => (
                <div className={classes.toolbarWrapper}>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={{
              search: true,
              pageSize: 10,
            }}
            actions={[
              {
                icon: 'download',
                tooltip: 'Download CSV',
                isFreeAction: true,
                onClick: (event, rowData: any) => openAsCSV(),
              },
            ]}
            isLoading={isLoading}
            columns={Columns}
            data={data?.rows ?? []}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default WithMotion(Container);
