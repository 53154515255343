import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';

type PropsType = {
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
};

export default function ConfirmImportV2({ open, handleClose, handleConfirm }: PropsType) {
  return (
    <Dialog
      open={open}
      disableBackdropClick={true}
      onEscapeKeyDown={handleClose}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title">Confirm program import</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="subtitle2">Are you sure you want to Import programs for this connection?</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button type="button" onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button type="button" color="primary" onClick={handleConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
