import { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { makeCall } from 'utils/utils';
import { tableContainerStyles as useStyles } from 'styles/common';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import WithMotion from 'components/withMotion';
import { ModalType, Country } from 'types/global';
import { DataType, RecordType } from './types';
import { columns } from './initialData';
import Create from './Create';
import Update from './Update';
import EnhancedSnackbar from 'components/EnhancedSnackbar';
import { Account } from 'types/global';
import { Filters } from './Filters';

function Advertisers() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<DataType[]>([]);
  const [modalType, setModalType] = useState<ModalType>(null);
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [record, setRecord] = useState<RecordType>(null);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [countries, setCountries] = useState<Country[]>([]);
  const [accounts, setAccounts] = useState<Account[]>([]);

  useEffect(() => {
    makeCall('countries', 'GET').then((res) => setCountries(res?.data.data));
    makeCall('accounts', 'GET').then((res) => setAccounts(res?.data.data));
  }, []);

  useEffect(() => {
    const getData = async () => {
      const response = await makeCall('merchants', 'GET');
      setData(response?.data.data);
      setIsLoading(false);
      setShouldRefresh(false);
    };
    if (shouldRefresh) {
      getData();
    }
  }, [shouldRefresh]);

  const getRecord = async (clickedRow: any) => {
    const response = await makeCall(`merchants/${clickedRow.merchantUuid}`, 'GET');
    setRecord(response?.data.data);
    setModalType('update');
  };

  const refresh = (key: string) => {
    const message = `Advertiser ${key} successfully!`;
    setToastMessage(message);
    setToastOpen(true);
    setIsLoading(true);
    setShouldRefresh(true);
  };

  const handleFilterSubmit = async (payload: any) => {
    setIsLoading(true);
    const response = await makeCall('merchants', 'GET', payload);
    setData(response?.data.data);
    setIsLoading(false);
  };

  return (
    <>
      <Create open={modalType === 'create'} handleClose={() => setModalType(null)} refresh={() => refresh('created')} />
      <Update
        open={modalType === 'update'}
        handleClose={() => setModalType(null)}
        refresh={() => refresh('updated')}
        record={record}
        countries={countries}
      />
      <EnhancedSnackbar open={toastOpen} message={toastMessage} onClose={() => setToastOpen(false)} />
      <Filters handleFilterSubmit={handleFilterSubmit} isLoading={isLoading} accounts={accounts} />
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <MaterialTable
            title="Advertisers"
            components={{
              Toolbar: (props) => (
                <div className={classes.toolbarWrapper}>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={{
              search: true,
              pageSize: 10,
              actionsColumnIndex: 9,
              actionsCellStyle: { color: '#666' },
            }}
            actions={[
              {
                icon: 'add',
                tooltip: 'Create Advertiser',
                isFreeAction: true,
                onClick: () => setModalType('create'),
              },
              {
                icon: 'edit',
                tooltip: 'Update Advertiser',
                isFreeAction: false,
                onClick: (event, rowData: any) => getRecord(rowData),
              },
            ]}
            isLoading={isLoading}
            columns={columns}
            data={data}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default WithMotion(Advertisers);
