import { useState, useEffect } from 'react';
import { makeCall } from 'utils/utils';
import WithMotion from 'components/withMotion';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import { MediaTypesColumns as columns } from './initialData';
import { MediaTypesData as Data } from './types';
import { Grid } from '@material-ui/core';
import { tableContainerStyles as useStyles } from 'styles/common';

function Container() {
  const classes = useStyles();
  const [shouldFetch, setShouldFetch] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<Data[]>([]);

  useEffect(() => {
    const getData = async () => {
      const response = await makeCall('medias/types', 'GET');
      setIsLoading(false);
      setData(response?.data.data);
      setShouldFetch(false);
    };
    if (shouldFetch) {
      getData();
    }
  }, [shouldFetch]);

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <MaterialTable
          title="Media types"
          components={{
            Toolbar: (props) => (
              <div className={classes.toolbarWrapper}>
                <MTableToolbar {...props} />
              </div>
            ),
          }}
          options={{
            search: true,
            pageSize: 5,
          }}
          isLoading={isLoading}
          columns={columns}
          data={data}
        />
      </Grid>
    </Grid>
  );
}

export default WithMotion(Container);
