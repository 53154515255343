import { useState } from 'react';
import Datepicker from 'components/DatepickerV2';
import { Grid, Button, Paper, makeStyles, TextField } from '@material-ui/core';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { Autocomplete } from '@material-ui/lab';
import { Network } from './types';

type PropsType = {
  handleFiltersSubmit: (filters: any) => void;
  isLoading: boolean;
  networks: Network[];
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

export default function Filters({ handleFiltersSubmit, isLoading, networks }: PropsType) {
  const classes = useStyles();
  const [selectedNetwork, setSelectedNetwork] = useState<Network | null>(null);
  const [dateObj, setDateObj] = useState({
    fromDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
    toDate: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
  });

  const setDate = (start: Date, end: Date) => {
    setDateObj({
      fromDate: format(start, 'yyyy-MM-dd'),
      toDate: format(end, 'yyyy-MM-dd'),
    });
  };

  const handleClick = () => {
    const payload: { fromDate?: string; toDate?: string; networkUuid?: string } = {};
    if (selectedNetwork) {
      payload.networkUuid = selectedNetwork.networkUuid;
    }
    payload.fromDate = dateObj.fromDate;
    payload.toDate = dateObj.toDate;
    handleFiltersSubmit(payload);
  };

  return (
    <Paper variant="outlined" className={classes.root}>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={5}>
          <Autocomplete
            id="network-select"
            options={networks}
            getOptionLabel={(option) => option.networkName}
            onChange={(_, value) => setSelectedNetwork(value)}
            renderInput={(params) => {
              return <TextField {...params} label="Network" variant="outlined" margin="dense" />;
            }}
          />
        </Grid>
        <Grid item xs={5}>
          <div style={{ border: '1px solid rgba(0, 0, 0, 0.2)', padding: '0px', borderRadius: '5px' }}>
            <Datepicker change={setDate} startDate={startOfMonth(new Date())} endDate={endOfMonth(new Date())} />
          </div>
        </Grid>
        <Grid item xs={2}>
          <Button onClick={handleClick} disabled={isLoading} variant="contained" color="primary" fullWidth>
            {isLoading ? 'Searching...' : 'Search'}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}
