import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Icon from '@material-ui/core/Icon';
import moment from 'moment';

const styles = (theme) => ({
  applyButton: {
    width: '100%',
    backgroundColor: '#282E67',
    '&:hover': {
      background: '#323981',
    },
  },
  applyButtonMargin: {
    margin: '10px',
  },
  applyButtonWrapper: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    backgroundColor: '#fff',
  },
  dateRangeWrapper: {
    position: 'absolute',
    top: '50px',
    right: 0,
    zIndex: 99999,
    paddingBottom: '45px',
    boxShadow: '0px 0px 25px 1px rgba(0, 0, 0,0.20) !important',
    fontFamily: 'Rubik',
    fontSize: '12px',
  },
  inputBox: {
    height: '25px',
    backgroundColor: '#fff',
    border: '#f4f4f4',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    padding: '22px',
    cursor: 'pointer',
    fontFamily: 'Rubik',
  },
  calendarIcon: {
    marginRight: '10px',
  },
  arrowIcon: {
    fontSize: '13px',
    marginLeft: '7px',
    marginRight: '7px',
  },
  dropdownIcon: {
    position: 'absolute',
    top: '9px',
    right: '3px',
    cursor: 'pointer',
  },
});

class renderInput extends Component {
  state = {
    startDate: this.props.startDate,
    endDate: this.props.endDate,
    selectedDate: this.props.date,
    activePreset: '',
    open: false,
    appliedStartDate: this.props.startDate,
    appliedEndDate: this.props.endDate,
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutSide, false);
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutSide, false);
  }
  handleClickOutSide = (e) => {
    if (this.node !== 'undefined') {
      if (typeof e.target != 'undefined') {
        if (this.node.contains(e.target)) {
          return;
        }
      }
    }
    this.setState({ open: false });
  };

  toggle = () => {
    this.setState((prev) => ({ open: !prev.open }));
  };

  handleSelect = (ranges, props) => {
    this.setState({ selectedDate: ranges, open: false });
    this.props.setDate(ranges);
  };
  render() {
    const { selectedDate, open } = this.state;
    const { classes } = this.props;

    return (
      <div style={{ position: 'relative', width: '100%' }} ref={(node) => (this.node = node)}>
        <div onClick={this.toggle} className={classes.inputBox}>
          <Icon
            classes={{ root: classes.calendarIcon }}
            color={open ? 'green' : 'inherit'}
            style={{ transform: 'translateY(-2px)' }}
          >
            calendar_today
          </Icon>
          {moment(selectedDate).format('DD-MM-YYYY')}
        </div>
        <Icon onClick={this.handleToggle} classes={{ root: classes.dropdownIcon }} color={open ? '#282e67' : 'inherit'}>
          arrow_drop_down
        </Icon>

        {open && (
          <div className={classes.dateRangeWrapper}>
            <Calendar startOfWeek={1} color="#282E67" onChange={this.handleSelect} />
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(renderInput);
