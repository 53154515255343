import Autocomplete from '@material-ui/lab/Autocomplete';
import { Grid, TextField } from '@material-ui/core';
import Datepicker from 'components/DatepickerV2';
import { PropsType } from './types';
import { useStyles } from './styles';

export default function Filters({
  channels,
  activeChannel,
  handleChannelChange,
  accounts,
  activeAccount,
  handleAccountChange,
  setDate,
}: PropsType) {
  const classes = useStyles();
  return (
    <Grid container spacing={3} justify="space-between" alignItems="center">
      <Grid item xs={4}>
        <Autocomplete
          id="channel-select"
          options={channels}
          getOptionLabel={(option) => option.channelName}
          value={activeChannel}
          onChange={handleChannelChange}
          fullWidth
          size="small"
          renderInput={(params) => <TextField {...params} className={classes.input} label="Channel" variant="filled" />}
        />
      </Grid>
      <Grid item xs={4}>
        <Autocomplete
          id="account-select"
          options={accounts}
          getOptionLabel={(option) => option.accountName}
          value={activeAccount}
          onChange={handleAccountChange}
          fullWidth
          size="small"
          renderInput={(params) => <TextField className={classes.input} {...params} label="Account" variant="filled" />}
        />
      </Grid>
      <Grid item xs={4}>
        <Datepicker change={setDate} startDate={new Date()} endDate={new Date()} />
      </Grid>
    </Grid>
  );
}
