import { Column } from '@material-table/core';
import { RowsType } from './types';
import StatusBar from './StatusBar';

export const formatter = new Intl.NumberFormat('da-DK');

export const Columns: Array<Column<RowsType>> = [
  { title: 'Time group', field: 'timeGrp' },
  { title: 'Data group', field: 'grpName' },
  {
    title: 'Pending no payment received',
    field: 'pendingNoPaymentReceived',
    render: (row) => formatter.format(row.pendingNoPaymentReceived),
  },
  {
    title: 'Pending payment received',
    field: 'pendingPaymentReceived',
    render: (row) => formatter.format(row.pendingPaymentReceived),
  },
  { title: 'Paid', field: 'paid', render: (row) => formatter.format(row.paid) },
  { title: 'Rejected', field: 'rejected', render: (row) => formatter.format(row.rejected) },
  { title: 'Provision', field: 'provision', render: (row) => formatter.format(row.provision) },
  { title: 'Currency', field: 'currencyCode' },
  { title: 'Status', field: '', render: (row) => <StatusBar data={row.percent} /> },
];

export const TimeGroup = [
  {
    key: 'month',
    value: 'Month',
  },
  {
    key: 'year',
    value: 'Year',
  },
];

export const DataGroup = [
  {
    key: 'account',
    value: 'Account',
  },
  {
    key: 'network',
    value: 'Network',
  },
];

export const DataSource = [
  {
    key: 'count',
    value: 'Conversions',
  },
  {
    key: 'commission',
    value: 'Partner Commission',
  },
  {
    key: 'cut',
    value: 'Heylink Commission',
  },
  {
    key: 'amount',
    value: 'Total Commission',
  },
];

function generateArrayOfYears() {
  var max = new Date().getFullYear();
  var min = max - 4;
  var years = [];

  for (var i = max; i >= min; i--) {
    years.push({
      key: i.toString(),
      value: i.toString(),
    });
  }
  return years;
}

export const Years = generateArrayOfYears();
