import { useState, useEffect } from 'react';
import { makeCall } from 'utils/utils';
import WithMotion from 'components/withMotion';
import { Data, Record, Country } from './types';
import { Grid } from '@material-ui/core';
import { tableContainerStyles as useStyles } from 'styles/common';
import { columns } from './initialData';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import Create from './Create';
import Update from './Update';

function Container() {
  const classes = useStyles();
  const [shouldFetch, setShouldFetch] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isCreate, setIsCreate] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [data, setData] = useState<Data[]>([]);
  const [record, setRecord] = useState<Record | null>(null);
  const [countries, setCountries] = useState<Country[]>([]);

  useEffect(() => {
    const getData = async () => {
      const response = await makeCall('countries', 'GET');
      setIsLoading(false);
      setCountries(response?.data.data);
    };
    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      const response = await makeCall('clients', 'GET');
      setIsLoading(false);
      setData(response?.data.data);
      setShouldFetch(false);
    };
    if (shouldFetch) {
      getData();
    }
  }, [shouldFetch]);

  const getRecord = async (clickedRow: Data) => {
    const response = await makeCall(`clients/${clickedRow.clientUuid}`, 'GET');
    setRecord(response?.data.data);
    setIsUpdate(true);
  };

  return (
    <>
      <Create
        open={isCreate}
        handleClose={() => setIsCreate(!isCreate)}
        refresh={() => {
          setIsLoading(true);
          setShouldFetch(true);
        }}
        countries={countries}
      />
      <Update
        record={record}
        open={isUpdate}
        handleClose={() => setIsUpdate(!isUpdate)}
        refresh={() => {
          setIsLoading(true);
          setShouldFetch(true);
        }}
        countries={countries}
      />
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <MaterialTable
            title="Clients"
            components={{
              Toolbar: (props) => (
                <div className={classes.toolbarWrapper}>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={{
              search: true,
              pageSize: 10,
              actionsColumnIndex: 9,
              actionsCellStyle: { color: '#666' },
            }}
            actions={[
              {
                icon: 'add',
                tooltip: 'Create Client',
                isFreeAction: true,
                onClick: () => setIsCreate(!isCreate),
              },
              {
                icon: 'edit',
                tooltip: 'Update Client',
                isFreeAction: false,
                onClick: (event, rowData: any) => getRecord(rowData),
              },
            ]}
            isLoading={isLoading}
            columns={columns}
            data={data}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default WithMotion(Container);
