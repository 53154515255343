import { useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Divider,
  Chip,
  Typography,
  Paper,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { makeCall } from 'utils/utils';
import { UpdateFormData as FormData, UpdateFormPropsType as PropsType } from './types';
import EnhancedTextField from 'components/EnhancedTextField';
import EnhancedSwitch from 'components/EnhancedSwitch';
import Clipboard from 'components/ClipboardV2';
import moment from 'moment';
import { texts } from './initialData';
import ConnectionChannelTable from 'components/ConnectionChannel';

export default function Update({ open, handleClose, refresh, record }: PropsType) {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
  } = useForm<FormData>();

  useEffect(() => {
    if (record) {
      reset({
        connectionMinutesBetweenImport: record.connectionMinutesBetweenImport,
        connectionStatus: record.connectionStatus,
      });
    }
  }, [record, reset]);

  const onSubmit = async (data: FormData) => {
    await makeCall(`${texts.apiKey}/connections/${record?.connectionUuid}`, 'PUT', data);
    close();
    refresh();
  };

  const close = () => {
    handleClose();
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        disableBackdropClick={true}
        onClose={close}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Update {texts.title} Connection</DialogTitle>

        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={7}>
              <Paper variant="outlined" style={{ padding: '18px' }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Clipboard id={record?.connectionUuid ?? ''} />
                    <Chip
                      variant="outlined"
                      label={
                        <Typography variant="subtitle2" color="primary">
                          Account: {record?.account.accountName ?? '-'}
                        </Typography>
                      }
                      color="primary"
                      style={{ marginRight: '10px' }}
                    />
                    <Chip
                      variant="outlined"
                      label={
                        <Typography variant="subtitle2" color="primary">
                          API Account ID: {record?.connectionApiAccountId ?? '-'}
                        </Typography>
                      }
                      color="primary"
                      style={{ marginRight: '10px' }}
                    />
                    <Chip
                      variant="outlined"
                      label={
                        <Typography variant="subtitle2" color="primary">
                          Last import: {moment(record?.connectionLastImportDatetime).format('DD MMM, HH:mm')}
                        </Typography>
                      }
                      color="primary"
                      style={{ marginRight: '10px' }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={5}>
              <Paper variant="outlined" style={{ padding: '10px' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container>
                    <Grid item xs={12}>
                      <EnhancedTextField
                        control={control}
                        fieldName="connectionMinutesBetweenImport"
                        label="Minutes between import"
                        hasError={errors.connectionMinutesBetweenImport ? true : false}
                        errorTxt="Minutes between import is required!"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <EnhancedSwitch
                        isChecked={!!record?.connectionStatus}
                        control={control}
                        fieldName="connectionStatus"
                        label="Status"
                      />
                    </Grid>
                  </Grid>
                </form>
              </Paper>
            </Grid>
          </Grid>
          <Divider style={{ marginBottom: '0px', marginTop: '40px' }} />

          <ConnectionChannelTable apiKey={texts.apiKey} connectionUuid={record?.connectionUuid} />
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary">
            Cancel
          </Button>
          <Button disabled={isSubmitting} type="submit" color="primary" onClick={handleSubmit(onSubmit)}>
            {isSubmitting ? 'Updating...' : 'Update'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
