import { useForm, Controller } from 'react-hook-form';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import { makeCall } from 'utils/utils';
import { CreateFormData as FormData, CreateFormPropsType as PropsType } from './types';
import { createDefaultValues as defaultValues } from './initialData';
import EnhancedTextField from 'components/EnhancedTextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function Create({ open, handleClose, refresh, clients, accountTypes }: PropsType) {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
  } = useForm<FormData>({ defaultValues });

  const resetForm = () => {
    reset(defaultValues, { keepIsSubmitted: false });
    handleClose();
  };

  const onSubmit = async (data: FormData) => {
    const payload = {
      ...data,
      accountTypeUuid: data.accountTypeUuid?.accountTypeUuid,
      accountClientUuid: data.accountClientUuid?.clientUuid,
    };
    await makeCall('accounts', 'POST', payload);
    reset(defaultValues);
    handleClose();
    refresh();
  };
  return (
    <Dialog
      open={open}
      disableBackdropClick={true}
      onEscapeKeyDown={resetForm}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title">Create Account</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <EnhancedTextField
                control={control}
                fieldName="accountName"
                label="Account name"
                hasError={errors.accountName ? true : false}
                errorTxt="Account name is required!"
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="accountTypeUuid"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    id="currency-select"
                    options={accountTypes}
                    onChange={(_, data) => field.onChange(data)}
                    getOptionLabel={(option) => option.accountTypeName}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        error={errors.accountTypeUuid ? true : false}
                        helperText={errors.accountTypeUuid ? 'Account type is required!' : null}
                        {...params}
                        label="Account Type"
                        margin="dense"
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="accountClientUuid"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    id="currency-select"
                    options={clients}
                    onChange={(_, data) => field.onChange(data)}
                    getOptionLabel={(option) => option.clientCompanyName}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        error={errors.accountClientUuid ? true : false}
                        helperText={errors.accountClientUuid ? 'Client is required!' : null}
                        {...params}
                        label="Client"
                        margin="dense"
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <EnhancedTextField
                control={control}
                required={false}
                fieldName="accountCommission"
                label="Commission %"
                hasError={errors.accountCommission ? true : false}
                errorTxt="Commission is required!"
              />
            </Grid>
            <Grid item xs={12}>
              <EnhancedTextField
                control={control}
                required={false}
                fieldName="accountConversionOnHoldPercentage"
                label="Conversion on hold %"
                hasError={errors.accountConversionOnHoldPercentage ? true : false}
                errorTxt="Conversion on hold is required!"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={resetForm} color="primary">
            Cancel
          </Button>
          <Button disabled={isSubmitting} type="submit" color="primary">
            {isSubmitting ? 'Creating...' : 'Create'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
