import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useForm, Controller } from 'react-hook-form';
import { makeCall } from 'utils/utils';
import Switch from 'components/EnhancedSwitch';
import EnhancedTextField from 'components/EnhancedTextField';
import { CreateFormData as FormData, CreateFormPropsType as PropsType } from './types';
import { createDefaultValues as defaultValues } from './initialData';

export default function Create({
  open,
  handleClose,
  refresh,
  networks,
  merchants,
  categories,
  countries,
  currencies,
}: PropsType) {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
  } = useForm<FormData>({ defaultValues });

  const resetForm = () => {
    reset(defaultValues);
    handleClose();
  };

  const onSubmit = async (data: FormData) => {
    const payload = {
      ...data,
      programNetworkUuid: data.programNetworkUuid?.networkUuid ?? '',
      programMerchantUuid: data.programMerchantUuid?.merchantUuid ?? '',
      programCategoryKey: data.programCategoryKey?.categoryKey ?? '',
      programCountryCode: data.programCountryCode?.countryCode ?? '',
      programCurrencyCode: data.programCurrencyCode?.currencyCode ?? '',
    };
    await makeCall('programs', 'POST', payload);
    reset(defaultValues);
    handleClose();
    refresh();
  };

  return (
    <>
      <Dialog
        open={open}
        disableBackdropClick={true}
        onEscapeKeyDown={resetForm}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">Create Program</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="programTitle"
                  label="Title"
                  hasError={errors.programTitle ? true : false}
                  errorTxt="Title is required!"
                  autoFocus
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="programDescription"
                  label="Description"
                  hasError={errors.programDescription ? true : false}
                  errorTxt="Description is required!"
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="programTerms"
                  label="Terms"
                  hasError={errors.programTerms ? true : false}
                  errorTxt="Terms is required!"
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="programContactPerson"
                  label="Contact person"
                  hasError={errors.programContactPerson ? true : false}
                  errorTxt="Contact person is required!"
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="programContactEmail"
                  label="Contact email"
                  hasError={errors.programContactEmail ? true : false}
                  errorTxt="Contact email is required!"
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="programNetworkUuid"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="network-select"
                      options={networks}
                      onChange={(_, data) => field.onChange(data)}
                      getOptionLabel={(option) => option.networkName}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          error={errors.programNetworkUuid ? true : false}
                          helperText={errors.programNetworkUuid ? 'Network is required!' : null}
                          {...params}
                          label="Network"
                          margin="dense"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="programMerchantUuid"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="merchant-select"
                      options={merchants}
                      onChange={(_, data) => field.onChange(data)}
                      getOptionLabel={(option) => option.merchantUrl}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          error={errors.programMerchantUuid ? true : false}
                          helperText={errors.programMerchantUuid ? 'Advertiser is required!' : null}
                          {...params}
                          label="Advertiser"
                          margin="dense"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="programCategoryKey"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="category-select"
                      options={categories}
                      onChange={(_, data) => field.onChange(data)}
                      getOptionLabel={(option) => option.categoryName}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          error={errors.programCategoryKey ? true : false}
                          helperText={errors.programCategoryKey ? 'Category is required!' : null}
                          {...params}
                          label="Category"
                          margin="dense"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="programCountryCode"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="country-select"
                      options={countries}
                      onChange={(_, data) => field.onChange(data)}
                      getOptionLabel={(option) => option.countryName}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          error={errors.programCountryCode ? true : false}
                          helperText={errors.programCountryCode ? 'Country is required!' : null}
                          {...params}
                          label="Country"
                          margin="dense"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="programCurrencyCode"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="currency-select"
                      options={currencies}
                      onChange={(_, data) => field.onChange(data)}
                      getOptionLabel={(option) => option.currencyCode}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          error={errors.programCurrencyCode ? true : false}
                          helperText={errors.programCurrencyCode ? 'Currency is required!' : null}
                          {...params}
                          label="Currency"
                          margin="dense"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Switch isChecked={false} control={control} fieldName="programCashbackAllowed" label="Cashback" />
              </Grid>
              <Grid item xs={3}>
                <Switch isChecked={false} control={control} fieldName="programCouponSiteAllowed" label="Coupon Site" />
              </Grid>
              <Grid item xs={3}>
                <Switch isChecked={false} control={control} fieldName="programSocialAllowed" label="Social" />
              </Grid>
              <Grid item xs={3}>
                <Switch isChecked={false} control={control} fieldName="programPpcAllowed" label="PPC" />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={resetForm} color="primary">
              Cancel
            </Button>
            <Button disabled={isSubmitting} type="submit" color="primary">
              {isSubmitting ? 'Creating...' : 'Create'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
