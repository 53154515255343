import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import moment from 'moment';
import { defaultInputRanges, defaultStaticRanges } from './ranges';

const styles = (theme) => ({
  applyButton: {
    width: '100%',
    backgroundColor: '#282E67',
    '&:hover': {
      background: '#323981',
    },
  },
  applyButtonMargin: {
    margin: '10px',
  },
  applyButtonWrapper: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    backgroundColor: '#fff',
  },
  dateRangeWrapper: {
    position: 'absolute',
    top: '50px',
    right: 0,
    zIndex: 99999,
    paddingBottom: '45px',
    boxShadow: '0px 0px 25px 1px rgba(0, 0, 0,0.20) !important',
    fontFamily: 'Rubik',
    fontSize: '12px',
  },
  inputBox: {
    height: '25px',
    backgroundColor: '#fff',
    border: '#f4f4f4',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    padding: '22px',
    cursor: 'pointer',
    fontFamily: 'Rubik',
  },
  calendarIcon: {
    marginRight: '10px',
  },
  arrowIcon: {
    fontSize: '13px',
    marginLeft: '7px',
    marginRight: '7px',
  },
  dropdownIcon: {
    position: 'absolute',
    top: '9px',
    right: '3px',
    cursor: 'pointer',
  },
});

class renderInput extends Component {
  state = {
    startDate: this.props.startDate,
    endDate: this.props.endDate,
    activePreset: '',
    open: false,
    appliedStartDate: this.props.startDate,
    appliedEndDate: this.props.endDate,
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutSide, false);
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutSide, false);
  }
  handleClickOutSide = (e) => {
    if (this.node !== 'undefined') {
      if (typeof e.target != 'undefined') {
        if (this.node.contains(e.target)) {
          return;
        }
      }
    }
    this.setState({ open: false });
  };
  handleApply = () => {
    const { startDate, endDate, appliedStartDate, appliedEndDate } = this.state;

    //only trigger if a date has changed from the last applied date
    if (!moment(startDate).isSame(appliedStartDate) || !moment(endDate).isSame(appliedEndDate)) {
      this.props.change(startDate, endDate);

      this.setState({
        appliedStartDate: startDate,
        appliedEndDate: endDate,
      });
    }
    this.setState({ open: false });
  };

  handleToggle = () => {
    this.setState((prevState) => ({
      open: !prevState.open,
    }));
  };
  handleSelect = (ranges, props) => {
    const startDate = ranges.selection.startDate;
    const endDate = ranges.selection.endDate;

    this.setState(
      {
        startDate: startDate,
        endDate: endDate,
      },
      () => {
        if (ranges.selection.key === 'predefined') {
          this.props.change(startDate, endDate);
          this.handleApply();
        }
      }
    );
  };
  renderText() {
    var { appliedEndDate, appliedStartDate } = this.state;

    var text;

    appliedStartDate = moment(appliedStartDate).format('YYYY-MM-DD');
    appliedEndDate = moment(appliedEndDate).format('YYYY-MM-DD');

    if (
      moment(appliedStartDate).isSame(moment().format('YYYY-MM-DD')) &&
      moment(appliedEndDate).isSame(moment().format('YYYY-MM-DD'))
    ) {
      text = <div>Today</div>; //ok
    } else if (
      moment(appliedStartDate).isSame(moment().add(-1, 'days').format('YYYY-MM-DD')) &&
      moment(appliedEndDate).isSame(moment().add(-1, 'days').format('YYYY-MM-DD'))
    ) {
      text = <div>Yesterday</div>; //ok
    } else if (
      moment(appliedStartDate).isSame(moment().startOf('isoWeek').format('YYYY-MM-DD')) &&
      moment(appliedEndDate).isSame(moment().endOf('isoWeek').format('YYYY-MM-DD'))
    ) {
      text = <div>This week</div>; //ok
    } else if (
      moment(appliedStartDate).isSame(moment().startOf('isoWeek').add(-1, 'weeks').format('YYYY-MM-DD')) &&
      moment(appliedEndDate).isSame(moment().endOf('isoWeek').add(-1, 'weeks').format('YYYY-MM-DD'))
    ) {
      text = <div>Last week</div>; //ok
    } else if (
      moment(appliedStartDate).isSame(moment().startOf('month').format('YYYY-MM-DD')) &&
      moment(appliedEndDate).isSame(moment().endOf('month').format('YYYY-MM-DD'))
    ) {
      text = <div>This month</div>; //ok
    } else if (
      moment(appliedStartDate).isSame(moment().startOf('month').add(-1, 'months').format('YYYY-MM-DD')) &&
      moment(appliedEndDate).isSame(moment().add(-1, 'months').endOf('month').format('YYYY-MM-DD'))
    ) {
      text = <div>Last month</div>; //ok
    } else if (
      moment(appliedStartDate).isSame(moment().startOf('year').format('YYYY-MM-DD')) &&
      moment(appliedEndDate).isSame(moment().format('YYYY-MM-DD'))
    ) {
      text = <div>Year to date</div>; //ok
    } else {
      text = (
        <div>
          {moment(appliedStartDate).format('DD MMM YYYY')}
          <Icon classes={{ root: this.props.classes.arrowIcon }}>arrow_forward_ios</Icon>
          {moment(appliedEndDate).format('DD MMM YYYY')}
        </div>
      );
    }

    return text;
  }
  render() {
    const { startDate, endDate, open } = this.state;
    const { classes } = this.props;

    const minDate = new Date('2018-01-01');
    const maxDate = new Date();

    const selectionRange = {
      startDate: startDate,
      endDate: endDate,
      key: 'selection',
    };

    return (
      <div style={{ position: 'relative', width: '100%' }} ref={(node) => (this.node = node)}>
        <div onClick={this.handleToggle} className={classes.inputBox}>
          <Icon
            classes={{ root: classes.calendarIcon }}
            color={open ? 'green' : 'inherit'}
            style={{ transform: 'translateY(-2px)' }}
          >
            calendar_today
          </Icon>
          {this.renderText()}
        </div>
        <Icon onClick={this.handleToggle} classes={{ root: classes.dropdownIcon }} color={open ? '#282e67' : 'inherit'}>
          arrow_drop_down
        </Icon>

        {open && (
          <div className={classes.dateRangeWrapper}>
            <div className={classes.applyButtonWrapper}>
              <div className={classes.applyButtonMargin}>
                <Button
                  color="secondary"
                  variant="contained"
                  classes={{ root: classes.applyButton }}
                  onClick={this.handleApply}
                >
                  Apply date
                </Button>
              </div>
            </div>
            <DateRangePicker
              startOfWeek={1}
              showDateDisplay={false}
              ranges={[selectionRange]}
              onChange={this.handleSelect}
              showPreview={true}
              minDate={minDate}
              maxDate={maxDate}
              rangeColors={['#282E67']}
              inputRanges={defaultInputRanges}
              staticRanges={defaultStaticRanges}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(renderInput);
