import { Paper, Grid, Typography, Card, CardContent, Divider, makeStyles, useTheme, Theme } from '@material-ui/core/';
import { BarChart, Bar, Tooltip, YAxis, XAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import { GoErrorGraph as GoErrorGraphType } from '../../../../types';
import Skeleton from '@material-ui/lab/Skeleton';

type PropsType = {
  isLoading: boolean;
  data: GoErrorGraphType[] | undefined;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
  paperContainer: {
    padding: theme.spacing(3),
  },
  section: {
    marginTop: theme.spacing(2),
  },
}));

export default function GoErrorGraph({ isLoading, data }: PropsType) {
  const classes = useStyles();
  const theme = useTheme();

  const renderTooltip = (props: any) => {
    if (typeof props.payload != 'undefined' && props.payload != null) {
      if (typeof props.payload[0] != 'undefined') {
        const formatter = new Intl.NumberFormat('da-DK');
        return (
          <Card style={{ zIndex: 1000 }}>
            <CardContent>
              <Typography variant="subtitle1" color="primary">
                <b>{props.payload[0].payload.key}</b>
              </Typography>
              <Divider />
              <Typography style={{ color: theme.palette.secondary.dark, marginTop: theme.spacing(2) }}>
                Errors: {formatter.format(props.payload[0].payload.count)}
              </Typography>
            </CardContent>
          </Card>
        );
      }
    }
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paperContainer} variant="outlined">
          <Grid container>
            <Typography variant="subtitle2" color="primary">
              Go Errors graph
            </Typography>
          </Grid>
          <Grid container className={classes.section}>
            {isLoading ? (
              <Skeleton variant="rect" width="100%" height={370} animation="wave" />
            ) : (
              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  data={data}
                  margin={{
                    top: 7,
                    right: 7,
                    left: 7,
                    bottom: 7,
                  }}
                >
                  <CartesianGrid vertical={false} strokeDasharray="5 3" />
                  <XAxis dataKey="key" interval={0} hide axisLine={false} tickLine={false} />
                  <YAxis hide allowDataOverflow={true} />
                  <Tooltip cursor={{ fill: 'rgba(0, 0, 150, 0.024)' }} content={(props: any) => renderTooltip(props)} />
                  <Bar
                    dataKey="count"
                    fill={theme.palette.primary.main}
                    stroke={theme.palette.primary.main}
                    strokeWidth={0.9}
                    fillOpacity={0}
                    strokeOpacity={1}
                  />
                </BarChart>
              </ResponsiveContainer>
            )}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
