import { Route, Switch, BrowserRouter as Router, Redirect } from 'react-router-dom';
import Navigation from './components/Navigation';
import Login from './pages/Login/index';
import './styles/app.css';
import { getRoutes } from './utils/routesUtils';
import { adminNavItems, studentNavItems } from './Routes';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from 'styles/theme';

export default function App() {
  const isAuth = localStorage.getItem('token') ? true : false;
  const adminType = localStorage.getItem('adminType');
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Route path="/login" component={Login} />
        {window.location.pathname === '/dashboard' && console.log(adminType)}
        {window.location.pathname === '/' && (
          <Redirect to={`/${adminType === 'student' ? 'advertisers' : 'dashboard'}`} />
        )}
        {isAuth ? (
          <>
            <Navigation />
            <Switch>
              <main
                style={{
                  margin: '30px 20px 30px 320px',
                }}
              >
                {getRoutes(adminType === 'student' ? studentNavItems : adminNavItems)}
              </main>
            </Switch>
          </>
        ) : (
          <Redirect to="/login" />
        )}
      </Router>
    </ThemeProvider>
  );
}
