import { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { makeCall } from 'utils/utils';
import { Record, UpdateFormData as FormData } from './types';

type PropsType = {
  open: boolean;
  handleClose: () => void;
  record: Record | null;
  refresh: () => void;
};

export default function Update({ open, handleClose, record, refresh }: PropsType) {
  const [isCopied, setIsCopied] = useState(false);
  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
    reset,
  } = useForm<FormData>();

  useEffect(() => {
    if (record) {
      reset({
        countryStatus: record.countryStatus,
      });
    }
  }, [record, reset]);

  const onSubmit = async (data: FormData) => {
    const payload = {
      ...data,
    };
    await makeCall(`countries/${record?.countryCode}`, 'PUT', payload);
    close();
    refresh();
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(record?.countryCode ?? '');
    setIsCopied(true);
  };

  const close = () => {
    setIsCopied(false);
    handleClose();
  };

  return (
    <div>
      <Dialog open={open} disableBackdropClick={true} onClose={close} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Update Country</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <DialogContentText>
              ID: {record?.countryCode}{' '}
              <Button disabled={isCopied} color="primary" onClick={handleCopy}>
                {isCopied ? 'Copied' : 'Copy'}
              </Button>
            </DialogContentText>
            <Controller
              name="countryStatus"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Switch {...field} defaultChecked={record?.countryStatus} color="primary" />}
                  label="Active"
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={close} color="primary">
              Cancel
            </Button>
            <Button disabled={isSubmitting} type="submit" color="primary">
              {isSubmitting ? 'Updating...' : 'Update'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
