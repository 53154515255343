import { useState } from 'react';
import { DialogContentText, Button } from '@material-ui/core';

type PropsType = {
  id: string;
};

export default function ClipboardV2({ id }: PropsType) {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(id);
    setIsCopied(true);
  };
  return (
    <DialogContentText>
      ID: {id}{' '}
      <Button disabled={isCopied} color="primary" onClick={handleCopy}>
        {isCopied ? 'Copied' : 'Copy'}
      </Button>
    </DialogContentText>
  );
}
