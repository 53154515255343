import React from 'react';
import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar';
import Alert, { Color } from '@material-ui/lab/Alert';

type PropsType = {
  open: boolean;
  onClose: () => void;
  message: string;
  duration?: number;
  direction?: SnackbarOrigin;
  severity?: Color | undefined;
  variant?: 'filled' | 'outlined' | 'standard' | undefined;
};

export default function EnhancedSnackbar({
  open,
  onClose,
  message,
  duration = 3000,
  severity = 'success',
  variant = 'filled',
  direction = { vertical: 'bottom', horizontal: 'right' },
}: PropsType) {
  return (
    <Snackbar anchorOrigin={direction} open={open} autoHideDuration={duration} onClose={onClose}>
      <Alert variant={variant} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
}
