import { useState, useEffect } from 'react';
import { makeCall } from 'utils/utils';
import WithMotion from 'components/withMotion';
import { Data, Record, Client, AccountType } from './types';
import { Grid } from '@material-ui/core';
import { tableContainerStyles as useStyles } from 'styles/common';
import { columns } from './initialData';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import Create from './Create';
import Update from './Update';
import Upload from './Upload';

function Container() {
  const classes = useStyles();
  const [shouldFetch, setShouldFetch] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isCreate, setIsCreate] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [selectedRow, setSelectedRow] = useState<Data | null>(null);
  const [data, setData] = useState<Data[]>([]);
  const [record, setRecord] = useState<Record | null>(null);
  const [clients, setClients] = useState<Client[]>([]);
  const [accountTypes, setAccountTypes] = useState<AccountType[]>([]);

  useEffect(() => {
    const getData = async () => {
      const responseClients = await makeCall('clients', 'GET');
      const responseAccountTypes = await makeCall('accounts/types', 'GET');
      setClients(responseClients?.data.data);
      setAccountTypes(responseAccountTypes?.data.data);
    };
    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      const response = await makeCall('accounts', 'GET');
      setIsLoading(false);
      setData(response?.data.data);
      setShouldFetch(false);
    };
    if (shouldFetch) {
      getData();
    }
  }, [shouldFetch]);

  const getRecord = async (clickedRow: Data) => {
    const response = await makeCall(`accounts/${clickedRow.accountUuid}`, 'GET');
    setRecord(response?.data.data);
    setIsUpdate(true);
  };

  const showImageUpload = (clickedRow: Data) => {
    setIsUpload(true);
    setSelectedRow(clickedRow);
  };

  return (
    <>
      <Create
        open={isCreate}
        handleClose={() => setIsCreate(!isCreate)}
        refresh={() => {
          setIsLoading(true);
          setShouldFetch(true);
        }}
        clients={clients}
        accountTypes={accountTypes}
      />
      <Update
        record={record}
        open={isUpdate}
        handleClose={() => setIsUpdate(!isUpdate)}
        refresh={() => {
          setIsLoading(true);
          setShouldFetch(true);
        }}
      />
      <Upload
        open={isUpload}
        handleClose={() => {
          setIsUpload(!isUpload);
          setSelectedRow(null);
        }}
        row={selectedRow}
      />
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <MaterialTable
            title="Accounts"
            components={{
              Toolbar: (props) => (
                <div className={classes.toolbarWrapper}>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={{
              search: true,
              pageSize: 10,
              actionsColumnIndex: 9,
              actionsCellStyle: { color: '#666' },
            }}
            actions={[
              {
                icon: 'add',
                tooltip: 'Create Account',
                isFreeAction: true,
                onClick: () => setIsCreate(!isCreate),
              },
              {
                icon: 'edit',
                tooltip: 'Update Account',
                isFreeAction: false,
                onClick: (event, rowData: any) => getRecord(rowData),
              },
              {
                icon: 'add_a_photo',
                tooltip: 'Upload Logo',
                isFreeAction: false,
                onClick: (event, rowData: any) => showImageUpload(rowData),
              },
            ]}
            isLoading={isLoading}
            columns={columns}
            data={data}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default WithMotion(Container);
