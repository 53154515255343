import React, { useState } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { studentNavItems, adminNavItems } from '../Routes';
import Logo from './Logo';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Drawer, ListItemText, ListItem, Grid } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import Icon from '@material-ui/core/Icon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    drawer: {
      width: 300,
      paddingTop: theme.spacing(4),
    },
    fullList: {
      width: 'auto',
    },
    ico: {
      color: '#666',
      paddingRight: theme.spacing(6),
      fontSize: '20px',
    },
    spacing: {
      paddingLeft: theme.spacing(9),
    },
    logo: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2.3),
    },
    activeLink: {
      color: theme.palette.primary.main,
      background: 'rgba(0, 0, 150, 0.025)',
    },
  })
);

export function hasChildren(item: any) {
  const { nested: children } = item;

  if (children === undefined) {
    return false;
  }

  if (children.constructor !== Array) {
    return false;
  }

  if (children.length === 0) {
    return false;
  }

  return true;
}

const SingleLevel = ({ item, isChild }: any) => {
  const classes = useStyles();
  return (
    <ListItem
      button
      component={NavLink}
      activeClassName={classes.activeLink}
      to={item.to}
      className={isChild ? classes.spacing : ''}
    >
      {item.icon ? <Icon className={classes.ico}>{item.icon}</Icon> : null}

      <ListItemText primary={item.label} />
    </ListItem>
  );
};

const MultiLevel = ({ item, isChild }: any) => {
  const classes = useStyles();
  const { nested: children } = item;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };
  return (
    <React.Fragment>
      <ListItem button onClick={handleClick} className={isChild ? classes.spacing : ''}>
        {item.icon ? <Icon className={classes.ico}>{item.icon}</Icon> : null}
        <ListItemText primary={item.label} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children.map((child: any, key: any) =>
            child.nested.length ? (
              <MultiLevel key={key} item={child} isChild />
            ) : (
              <SingleLevel key={key} item={child} isChild />
            )
          )}
        </List>
      </Collapse>
    </React.Fragment>
  );
};

const MenuItem = ({ item }: any) => {
  const Component = hasChildren(item) ? MultiLevel : SingleLevel;
  return <Component item={item} />;
};

function Navigation(props: any) {
  // const activeRoute = (routeName: any) => {
  //   return props.location.pathname === routeName ? true : false;
  // };
  const adminType = localStorage.getItem('adminType');
  const routes = adminType === 'student' ? studentNavItems : adminNavItems;
  const classes = useStyles();
  return (
    <>
      <Drawer classes={{ paper: classes.drawer }} variant="permanent">
        <Grid item className={classes.logo}>
          <Logo />
        </Grid>
        <div className={classes.fullList} role="presentation">
          {routes.map((item: any, key: Number) => (
            <MenuItem key={key} item={item} />
          ))}
        </div>
      </Drawer>
    </>
  );
}

export default withRouter(Navigation);
