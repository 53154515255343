import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  input: {
    '& .MuiFilledInput-root': {
      background: 'rgb(255, 255, 255)',
    },
    '& .MuiFilledInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiSvgIcon-root': {
      fill: 'rgb(0,0,0)',
    },
  },
}));
