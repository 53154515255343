import { Grid } from '@material-ui/core';
import ClicksTable from './ClicksTable';
import { useContainerStyles } from './styles';
import { ContainerPropsType } from './types';
import ConversionsTable from './ConversionsTable';

export default function LatestContainer({ isLoading, clicks, conversions }: ContainerPropsType) {
  const classes = useContainerStyles();
  return (
    <Grid container className={classes.root} spacing={3}>
      <Grid item xs={6}>
        <ConversionsTable isLoading={isLoading} data={conversions?.conversions} />
      </Grid>
      <Grid item xs={6}>
        <ClicksTable isLoading={isLoading} data={clicks?.clicks} />
      </Grid>
    </Grid>
  );
}
