import { Controller, Control } from 'react-hook-form';
import { TextField } from '@material-ui/core';

type PropsType = {
  control: Control<any>;
  required?: boolean;
  fieldName: string;
  hasError?: boolean;
  label: string;
  errorTxt?: string;
  autoFocus?: boolean;
  isEmail?: boolean;
  disabled?: boolean;
  multiline?: boolean;
  isURL?: boolean;
};

export default function EnhancedTextField({
  control,
  required = true,
  fieldName,
  hasError,
  label,
  errorTxt,
  autoFocus = false,
  isEmail = false,
  disabled = false,
  multiline = false,
  isURL = false,
}: PropsType) {
  const emailPattern = isEmail
    ? { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: 'Valid email is required!' }
    : undefined;
  const urlPattern = isURL
    ? {
        value: /^((http(s?)?):\/\/)([wW]{3}\.)?[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/g,
        message: 'Valid URL is required!',
      }
    : undefined;
  const pattern = emailPattern ? emailPattern : urlPattern ? urlPattern : undefined;
  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{ required, pattern }}
      render={({ field }) => (
        <TextField
          {...field}
          error={hasError}
          helperText={hasError && errorTxt}
          autoFocus={autoFocus}
          margin="dense"
          label={label}
          disabled={disabled}
          multiline={multiline}
          fullWidth
        />
      )}
    />
  );
}
