import { Column } from '@material-table/core';
import { green, red } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import { Data } from './types';
import moment from 'moment';

export const columns: Array<Column<Data>> = [
  { title: 'ID', field: 'callbackUuid', cellStyle: { display: 'none' }, headerStyle: { display: 'none' } },
  { title: 'Payout', field: 'callbackPayout' },
  { title: 'Sale', field: 'callbackSale' },
  { title: 'Order Id', field: 'callbackSaleOrderId' },
  { title: 'Currency', field: 'callbackCurrencyCode' },
  {
    title: 'Status',
    field: 'callbackStatus',
    render: (row) =>
      row.callbackStatus ? (
        <Icon style={{ color: green[400] }}>check_circle</Icon>
      ) : (
        <Icon style={{ color: red[400] }}>remove_circle</Icon>
      ),
  },
  { title: 'User Agent', field: 'callbackUserAgent' },
  { title: 'IP', field: 'callbackIp' },
  { title: 'Host', field: 'callbackHost' },
  { title: 'URL', field: 'callbackUrl' },
  { title: 'Type', field: 'callbackType' },
  {
    title: 'Created On',
    field: 'callbackCreatedDatetime',
    render: (rowData) => moment(rowData.callbackCreatedDatetime).format('DD MMM, HH:mm'),
  },
  {
    title: 'Updated On',
    field: 'callbackUpdatedDatetime',
    render: (rowData) => moment(rowData.callbackUpdatedDatetime).format('DD MMM, HH:mm'),
  },
];
