import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useForm, Controller } from 'react-hook-form';
import { makeCall } from 'utils/utils';
import EnhancedTextField from 'components/EnhancedTextField';
import { createDefaultValues as defaultValues, texts } from './initialData';
import { CreateFormData as FormData, CreateFormPropsType as PropsType } from './types';

export default function Create({ open, handleClose, refresh, accounts }: PropsType) {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
  } = useForm<FormData>({ defaultValues });

  const resetForm = () => {
    reset(defaultValues);
    handleClose();
  };

  const onSubmit = async (data: FormData) => {
    const payload = {
      ...data,
      connectionAccountUuid: data.connectionAccountUuid?.accountUuid ?? '',
    };

    await makeCall(`${texts.apiKey}/connections`, 'POST', payload);
    reset(defaultValues);
    handleClose();
    refresh();
  };

  return (
    <>
      <Dialog
        open={open}
        disableBackdropClick={true}
        onEscapeKeyDown={resetForm}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">Create New Connection For {texts.title}</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  name="connectionAccountUuid"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="account-select"
                      options={accounts}
                      onChange={(_, data) => field.onChange(data)}
                      getOptionLabel={(option) => option.accountName}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          error={errors.connectionAccountUuid ? true : false}
                          helperText={errors.connectionAccountUuid ? 'Account is required!' : null}
                          {...params}
                          label="Account"
                          margin="dense"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <EnhancedTextField
                  control={control}
                  fieldName="connectionApiPublisherId"
                  label="API publisher id"
                  hasError={errors.connectionApiPublisherId ? true : false}
                  errorTxt="API publisher id is required!"
                />
              </Grid>
              <Grid item xs={12}>
                <EnhancedTextField
                  control={control}
                  fieldName="connectionApiUsername"
                  label="API username"
                  hasError={errors.connectionApiUsername ? true : false}
                  errorTxt="API username is required!"
                />
              </Grid>
              <Grid item xs={12}>
                <EnhancedTextField
                  control={control}
                  fieldName="connectionApiPassword"
                  label="API password"
                  hasError={errors.connectionApiPassword ? true : false}
                  errorTxt="API password is required!"
                />
              </Grid>
              <Grid item xs={12}>
                <EnhancedTextField
                  control={control}
                  fieldName="connectionMinutesBetweenImport"
                  label="Minutes between import"
                  hasError={errors.connectionMinutesBetweenImport ? true : false}
                  errorTxt="Minutes between import is required!"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={resetForm} color="primary">
              Cancel
            </Button>
            <Button disabled={isSubmitting} type="submit" color="primary">
              {isSubmitting ? 'Creating...' : 'Create'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
