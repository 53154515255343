import { useState } from 'react';
import { Grid, Button, Paper, makeStyles, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { filterSelectOptions } from './initialData';
import { FilterSelectType, Country, MerchantType } from './types';

type Props = {
  handleFilterSubmit: (data: any) => void;
  isLoading: boolean;
  countries: Country[];
  advertisers: MerchantType[];
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

export function Filters({ handleFilterSubmit, isLoading, countries, advertisers }: Props) {
  const classes = useStyles();
  const [expireValue, setExpireValue] = useState<FilterSelectType | undefined>({ label: 'All', value: null });
  const [statusValue, setStatusValue] = useState<FilterSelectType | undefined>({ label: 'All', value: null });
  const [countryValue, setCountryValue] = useState<Country | undefined | null>();
  const [advertiserValue, setAdvertiserValue] = useState<MerchantType | undefined | null>();

  const handleSearchClick = () => {
    const payload: {
      dealIsExpired?: boolean;
      dealActiveStatus?: boolean;
      dealCountryCode?: string;
      dealAdvertiserUuid?: string;
    } = {};
    if (typeof expireValue?.value === 'boolean') {
      payload.dealIsExpired = expireValue?.value;
    }
    if (typeof statusValue?.value === 'boolean') {
      payload.dealActiveStatus = statusValue.value;
    }
    if (countryValue) {
      payload.dealCountryCode = countryValue.countryCode;
    }
    if (advertiserValue) {
      payload.dealAdvertiserUuid = advertiserValue.merchantUuid;
    }

    handleFilterSubmit(payload);
  };
  return (
    <Paper variant="outlined" className={classes.root}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={2}>
          <Autocomplete
            id="expire-select"
            options={filterSelectOptions}
            getOptionLabel={(option) => option.label}
            onChange={(_, value) => setExpireValue(value)}
            disableClearable={true}
            value={expireValue}
            renderInput={(params) => {
              return <TextField {...params} label="Expire" variant="outlined" margin="dense" />;
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Autocomplete
            id="status-select"
            options={filterSelectOptions}
            getOptionLabel={(option) => option.label}
            onChange={(_, value) => setStatusValue(value)}
            disableClearable={true}
            value={statusValue}
            renderInput={(params) => {
              return <TextField {...params} label="Status" variant="outlined" margin="dense" />;
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            id="country-select"
            options={countries}
            getOptionLabel={(option) => option.countryName}
            onChange={(_, value) => setCountryValue(value)}
            value={countryValue}
            renderInput={(params) => {
              return <TextField {...params} label="Country" variant="outlined" margin="dense" />;
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            id="advertiser-select"
            options={advertisers}
            getOptionLabel={(option) => option.merchantName}
            onChange={(_, value) => setAdvertiserValue(value)}
            value={advertiserValue}
            renderInput={(params) => {
              return <TextField {...params} label="Advertiser" variant="outlined" margin="dense" />;
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Button variant="contained" color="primary" fullWidth onClick={handleSearchClick} disabled={isLoading}>
            Search
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}
