import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  TextField,
  Switch,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { makeCall } from 'utils/utils';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CreateFormData as FormData, CreateFormPropsType as PropsType } from './types';
import { createDefaultValues as defaultValues } from './initialData';

export default function Create({ open, handleClose, refresh, accounts, currencies }: PropsType) {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
  } = useForm<FormData>({ defaultValues });

  const resetForm = () => {
    reset(defaultValues);
    handleClose();
  };

  const onSubmit = async (data: FormData) => {
    const payload = {
      ...data,
      userAccountUuid: data.userAccountUuid?.accountUuid,
      userCurrencyCode: data.userCurrencyCode?.currencyCode,
    };
    await makeCall('users', 'POST', payload);
    reset(defaultValues);
    handleClose();
    refresh();
  };
  return (
    <Dialog
      open={open}
      disableBackdropClick={true}
      onEscapeKeyDown={resetForm}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Create User</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={errors.userFirstname ? true : false}
                helperText={errors.userFirstname ? 'User first name is required!' : null}
                autoFocus
                margin="dense"
                label="First Name"
                fullWidth
              />
            )}
            name="userFirstname"
            control={control}
            rules={{ required: true }}
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={errors.userLastname ? true : false}
                helperText={errors.userLastname ? 'User last name is required!' : null}
                margin="dense"
                label="Last Name"
                fullWidth
              />
            )}
            name="userLastname"
            control={control}
            rules={{ required: true }}
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={errors.userEmail ? true : false}
                helperText={errors.userEmail ? 'Valid email is required!' : null}
                margin="dense"
                label="Email Address"
                fullWidth
              />
            )}
            name="userEmail"
            control={control}
            rules={{
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Valid email is required!',
              },
            }}
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={errors.userPhone ? true : false}
                helperText={errors.userPhone ? 'User phone is required!' : null}
                margin="dense"
                label="Phone"
                fullWidth
              />
            )}
            name="userPhone"
            control={control}
            rules={{ required: true }}
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={errors.userPassword ? true : false}
                helperText={errors.userPassword ? 'Password is required and must be 8 characters long!' : null}
                margin="dense"
                label="Password"
                fullWidth
              />
            )}
            name="userPassword"
            control={control}
            rules={{ required: true, minLength: 8 }}
          />
          <Controller
            name="userAccountUuid"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Autocomplete
                {...field}
                id="account-select"
                options={accounts}
                onChange={(_, data) => field.onChange(data)}
                getOptionLabel={(option) => option.accountName}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    error={errors.userAccountUuid ? true : false}
                    helperText={errors.userAccountUuid ? 'Account name is required!' : null}
                    {...params}
                    label="Account"
                    margin="dense"
                  />
                )}
              />
            )}
          />
          <Controller
            name="userCurrencyCode"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Autocomplete
                {...field}
                id="currency-select"
                options={currencies}
                onChange={(_, data) => field.onChange(data)}
                getOptionLabel={(option) => option.currencyCode}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    error={errors.userCurrencyCode ? true : false}
                    helperText={errors.userCurrencyCode ? 'Currency code is required!' : null}
                    {...params}
                    label="Currency"
                    margin="dense"
                  />
                )}
              />
            )}
          />
          <Controller
            name="userActive"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <FormControlLabel control={<Switch defaultChecked {...field} color="primary" />} label="Active" />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={resetForm} color="primary">
            Cancel
          </Button>
          <Button disabled={isSubmitting} type="submit" color="primary">
            {isSubmitting ? 'Creating...' : 'Create'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
