import { useForm } from 'react-hook-form';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { makeCall } from 'utils/utils';
import { CreateFormData as FormData, CreateFormPropsType as PropsType } from './types';
import { createDefaultValues as defaultValues } from './initialData';
import EnhancedTextField from 'components/EnhancedTextField';

export default function Create({ open, handleClose, refresh }: PropsType) {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
  } = useForm<FormData>({ defaultValues });

  const resetForm = () => {
    reset(defaultValues);
    handleClose();
  };

  const onSubmit = async (data: FormData) => {
    const payload = {
      ...data,
    };
    await makeCall('accounts/types', 'POST', payload);
    reset(defaultValues);
    handleClose();
    refresh();
  };
  return (
    <Dialog
      open={open}
      disableBackdropClick={true}
      onEscapeKeyDown={resetForm}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Create New Create Account Type</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <EnhancedTextField
            control={control}
            required={true}
            fieldName="accountTypeName"
            label="Account type name"
            hasError={errors.accountTypeName ? true : false}
            errorTxt="Account type name is required!"
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={resetForm} color="primary">
            Cancel
          </Button>
          <Button disabled={isSubmitting} type="submit" color="primary">
            {isSubmitting ? 'Creating...' : 'Create'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
