import { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { makeCall } from 'utils/utils';
import { Record, UpdateFormData as FormData } from './types';
import EnhancedTextField from 'components/EnhancedTextField';

type PropsType = {
  open: boolean;
  handleClose: () => void;
  record: Record | null;
  refresh: () => void;
};

export default function Update({ open, handleClose, record, refresh }: PropsType) {
  const [isCopied, setIsCopied] = useState(false);
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
  } = useForm<FormData>();

  useEffect(() => {
    if (record) {
      reset({
        currencyDecimalPlace: record.currencyDecimalPlace,
        currencyDecimalSeparator: record.currencyDecimalSeparator,
        currencyLeft: record.currencyLeft,
        currencyRight: record.currencyRight,
        currencyThousandSeparator: record.currencyThousandSeparator,
      });
    }
  }, [record, reset]);

  const onSubmit = async (data: FormData) => {
    const payload = {
      ...data,
    };
    await makeCall(`currencies/${record?.currencyCode}`, 'PUT', payload);
    close();
    refresh();
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(record?.currencyCode ?? '');
    setIsCopied(true);
  };

  const close = () => {
    setIsCopied(false);
    handleClose();
  };

  return (
    <div>
      <Dialog open={open} disableBackdropClick={true} onClose={close} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Update Currency</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <DialogContentText>
              ID: {record?.currencyCode}{' '}
              <Button disabled={isCopied} color="primary" onClick={handleCopy}>
                {isCopied ? 'Copied' : 'Copy'}
              </Button>
            </DialogContentText>
            <EnhancedTextField
              control={control}
              required={false}
              fieldName="currencyLeft"
              label="Currency left"
              autoFocus
            />
            <EnhancedTextField control={control} required={false} fieldName="currencyRight" label="Currency right" />
            <EnhancedTextField
              control={control}
              fieldName="currencyDecimalPlace"
              hasError={errors.currencyDecimalPlace ? true : false}
              errorTxt="Decimal place is required!"
              label="Currency decimal place"
            />
            <EnhancedTextField
              control={control}
              fieldName="currencyDecimalSeparator"
              hasError={errors.currencyDecimalSeparator ? true : false}
              errorTxt="Decimal separator is required!"
              label="Currency decimal separator"
            />
            <EnhancedTextField
              control={control}
              fieldName="currencyThousandSeparator"
              hasError={errors.currencyThousandSeparator ? true : false}
              errorTxt="Thousand separator is required!"
              label="Currency thousand separator"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={close} color="primary">
              Cancel
            </Button>
            <Button disabled={isSubmitting} type="submit" color="primary">
              {isSubmitting ? 'Updating...' : 'Update'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
