import { createMuiTheme } from '@material-ui/core/styles';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    pageviews: {
      primary: string;
      secondary: string;
    };
    clicks: {
      primary: string;
      secondary: string;
    };
    conversions: {
      primary: string;
      secondary: string;
    };
    commission: {
      primary: string;
      secondary: string;
    };
    heylinkCommission: {
      primary: string;
      secondary: string;
    };
    partnerCommission: {
      primary: string;
      secondary: string;
    };
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    pageviews?: {
      primary?: string;
      secondary?: string;
    };
    clicks?: {
      primary?: string;
      secondary?: string;
    };
    conversions?: {
      primary?: string;
      secondary?: string;
    };
    commission?: {
      primary?: string;
      secondary?: string;
    };
    heylinkCommission?: {
      primary?: string;
      secondary?: string;
    };
    partnerCommission?: {
      primary?: string;
      secondary?: string;
    };
  }
}

export const theme = createMuiTheme({
  typography: {
    fontFamily: '"Rubik", "Helvetica", "Arial", sans-serif;',
    fontSize: 14,
  },
  palette: {
    primary: {
      main: '#282E67',
    },
    secondary: {
      main: '#ff4081',
    },
  },
  pageviews: {
    primary: '#ff8656',
    secondary: 'rgba(255,134,86,0.1)',
  },
  clicks: {
    primary: '#ffb000',
    secondary: 'rgba(255,176,0,0.1)',
  },
  conversions: {
    primary: '#6a98fd',
    secondary: 'rgba(106,152,253,0.1)',
  },
  commission: {
    primary: '#0ccfaf',
    secondary: 'rgba(79,220,197,0.1)',
  },
  heylinkCommission: {
    primary: '#282E67',
    secondary: 'rgba(40, 46, 103, 0.1)',
  },
  partnerCommission: {
    primary: '#ff4081',
    secondary: 'rgba(255, 64, 128, 0.1)',
  },
  overrides: {
    MuiTableCell: {
      footer: {
        left: 0,
        bottom: 0, // <-- KEY
        zIndex: 2,
        position: 'sticky',
        backgroundColor: '#fff',
      },
    },
  },
});
