import { useState, useEffect } from 'react';
import WithMotion from 'components/withMotion';
import Filters from './Filters';
import { makeCall, generateFile } from 'utils/utils';
import { tableContainerStyles as useStyles } from 'styles/common';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import { Data, Record, Currency, Network } from './types';
import { columns } from './initialData';
import { Grid } from '@material-ui/core';
import View from './View';
import Create from './Create';
import { endOfMonth, format, startOfMonth } from 'date-fns';

function Container() {
  const classes = useStyles();
  const [data, setData] = useState<Data[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [isView, setIsView] = useState(false);
  const [record, setRecord] = useState<Record | null>(null);
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [networks, setNetworks] = useState<Network[]>([]);
  const [filters, setFilters] = useState<any>({
    networkUuid: '',
    fromDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
    toDate: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
  });

  useEffect(() => {
    const getData = async () => {
      makeCall('networks?networkSync=false', 'GET').then((res) => setNetworks(res?.data.data));
      makeCall('currencies', 'GET').then((res) => setCurrencies(res?.data.data));
    };
    getData();
  }, []);

  const handleFiltersSubmit = async (filters: any) => {
    const payload = {
      ...filters,
      limit: 1000,
      offset: 0,
    };
    setIsLoading(true);
    setFilters(filters);
    const response = await makeCall('finance/networks/paymentnotes', 'GET', payload);
    setData(response?.data.data);
    setIsLoading(false);
  };

  const getRecord = async (clickedRowUuid: string) => {
    const response = await makeCall(`finance/networks/paymentnotes/${clickedRowUuid}`, 'GET');
    setRecord(response?.data.data);
    setIsView(true);
  };

  const refreshView = (id: string) => {
    getRecord(id);
  };
  const handleRefresh = (id: string) => {
    getRecord(id);
    handleFiltersSubmit(filters);
  };

  const openAsCSV = async () => {
    if (data.length) {
      const payload = {
        ...filters,
        limit: 1000,
        offset: 0,
      };
      const response = await makeCall('finance/networks/paymentnotes', 'GET', payload, null, null, 'text', true);
      if (response) {
        generateFile(response.data, `network-payment-notes.csv`);
      }
    }
  };

  return (
    <div>
      <Create
        open={isCreate}
        handleClose={() => setIsCreate(!isCreate)}
        refresh={handleRefresh}
        currencies={currencies}
        networks={networks}
      />
      <View open={isView} handleClose={() => setIsView(!isView)} refresh={refreshView} record={record} />
      <Filters handleFiltersSubmit={handleFiltersSubmit} isLoading={isLoading} networks={networks} />
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <MaterialTable
            title="Network payment notes"
            components={{
              Toolbar: (props) => (
                <div className={classes.toolbarWrapper}>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={{
              search: true,
              pageSize: 10,
              actionsColumnIndex: 9,
              actionsCellStyle: { color: '#666' },
            }}
            actions={[
              {
                icon: 'add',
                tooltip: 'Create Payment note',
                isFreeAction: true,
                onClick: () => setIsCreate(!isCreate),
              },
              {
                icon: 'download',
                tooltip: 'Download CSV',
                isFreeAction: true,
                onClick: (event, rowData: any) => openAsCSV(),
              },
              {
                icon: 'visibility',
                tooltip: 'View payment note',
                isFreeAction: false,
                onClick: (event, rowData: any) => getRecord(rowData.paymentNote.paymentNoteUuid),
              },
            ]}
            isLoading={isLoading}
            columns={columns}
            data={data}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default WithMotion(Container);
