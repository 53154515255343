import { useForm } from 'react-hook-form';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { makeCall } from 'utils/utils';
import EnhancedTextField from 'components/EnhancedTextField';
import EnhancedSwitch from 'components/EnhancedSwitch';
import { BaseFormPropType as PropTypes } from 'types/global';
import { CreateFormData as FormData } from './types';
import { createDefaultValues as defaultValues } from './initialData';

export default function Create({ open, handleClose, refresh }: PropTypes) {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
  } = useForm<FormData>({ defaultValues });

  const resetForm = () => {
    reset(defaultValues);
    handleClose();
  };

  const onSubmit = async (data: FormData) => {
    const payload = {
      ...data,
    };
    await makeCall('networks', 'POST', payload);
    resetForm();
    refresh();
  };

  return (
    <>
      <Dialog
        open={open}
        disableBackdropClick={true}
        onEscapeKeyDown={resetForm}
        onClose={resetForm}
        aria-labelledby="create-form"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="create-form">Create Network</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="networkName"
                  label="Name"
                  hasError={errors.networkName ? true : false}
                  errorTxt="Name is required!"
                  autoFocus
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="networkLegalName"
                  label="Legal Name"
                  hasError={errors.networkLegalName ? true : false}
                  errorTxt="Legal Name is required!"
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="networkTrackingUrl"
                  label="Tracking URL"
                  hasError={errors.networkTrackingUrl ? true : false}
                  errorTxt="Tracking URL is required!"
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="networkTagDeeplink"
                  label="Tag deeplink"
                  required={false}
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="networkUrlDeeplinkTags"
                  label="Url deeplink static tags"
                  required={false}
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="networkTagClickId"
                  label="Global click id tag"
                  required={false}
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="networkTagChannelUrl"
                  label="Global channel url tag"
                  required={false}
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="networkTagChannelId"
                  label="Tag channel id"
                  required={false}
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="networkTagAccountId"
                  label="Tag account id"
                  required={false}
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="networkTagCampaignId"
                  label="Tag campaign id"
                  required={false}
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField control={control} fieldName="networkTagUrlId" label="Tag url id" required={false} />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="networkTagTrackingUrl"
                  label="Tag tracking url"
                  required={false}
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="networkTagUrlShortCode"
                  label="Global url short code"
                  hasError={errors.networkTagUrlShortCode ? true : false}
                  errorTxt="Global url short code is required!"
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="networkTagCustom1"
                  label="Custom Tag 1"
                  required={false}
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="networkTagCustom2"
                  label="Custom Tag 2"
                  required={false}
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="networkTagCustom3"
                  label="Custom Tag 3"
                  required={false}
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="networkTagCustom4"
                  label="Custom Tag 4"
                  required={false}
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="networkTagCustom5"
                  label="Custom Tag 5"
                  required={false}
                />
              </Grid>
              <Grid item xs={2}>
                <EnhancedSwitch
                  isChecked={false}
                  control={control}
                  fieldName="networkImport"
                  label="Import"
                  required={false}
                />
              </Grid>
              <Grid item xs={3}>
                <EnhancedSwitch
                  isChecked={false}
                  control={control}
                  fieldName="networkDeeplinkPathOnly"
                  label="Deeplink Path Only"
                  required={false}
                />
              </Grid>
              <Grid item xs={3}>
                <EnhancedSwitch
                  isChecked={false}
                  control={control}
                  fieldName="networkUrlEncodeDeeplink"
                  label="URL Encode Deeplink"
                  required={false}
                />
              </Grid>
              <Grid item xs={3}>
                <EnhancedSwitch
                  isChecked={false}
                  control={control}
                  fieldName="networkUrlAllowEmptyTagsStatus"
                  label="Allow Empty Tags"
                  required={false}
                />
              </Grid>
              <Grid item xs={1}>
                <EnhancedSwitch
                  isChecked={false}
                  control={control}
                  fieldName="networkSync"
                  label="Sync"
                  required={false}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={resetForm} color="primary">
              Cancel
            </Button>
            <Button disabled={isSubmitting} type="submit" color="primary">
              {isSubmitting ? 'Creating...' : 'Create'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
