import { useState, useEffect } from 'react';
import { makeCall } from 'utils/utils';
import WithMotion from 'components/withMotion';
import { Data, Record, Country, Account, MediaType } from './types';
import { Grid } from '@material-ui/core';
import { tableContainerStyles as useStyles } from 'styles/common';
import { columns } from './initialData';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import EnhancedSnackbar from 'components/EnhancedSnackbar';
import Create from './Create';
import Update from './Update';

function Container() {
  const classes = useStyles();
  const [shouldFetch, setShouldFetch] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isCreate, setIsCreate] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [data, setData] = useState<Data[]>([]);
  const [record, setRecord] = useState<Record | null>(null);
  const [countries, setCountries] = useState<Country[]>([]);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [mediaTypes, setMediaTypes] = useState<MediaType[]>([]);
  const [syncedRows, setSyncedRows] = useState<string[]>([]);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  useEffect(() => {
    const getData = async () => {
      const countryRes = await makeCall('countries', 'GET');
      const mediaTypeRes = await makeCall('medias/types', 'GET');
      const accountRes = await makeCall('accounts', 'GET');
      setCountries(countryRes?.data.data);
      setMediaTypes(mediaTypeRes?.data.data);
      setAccounts(accountRes?.data.data);
    };
    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      const response = await makeCall('medias', 'GET');
      setIsLoading(false);
      setData(response?.data.data);
      setShouldFetch(false);
    };
    if (shouldFetch) {
      getData();
    }
  }, [shouldFetch]);

  const getRecord = async (clickedRow: Data) => {
    const response = await makeCall(`medias/${clickedRow.mediaUuid}`, 'GET');
    setRecord(response?.data.data);
    setIsUpdate(true);
  };

  const syncMedia = async (clickedRow: Data) => {
    await makeCall(`medias/${clickedRow.mediaUuid}/sync`, 'GET');
    const newSyncedRows = [...syncedRows, clickedRow.mediaUuid];
    setSyncedRows(newSyncedRows);
    setToastMessage('Media synced successfully!');
    setToastOpen(true);
  };

  const refresh = (key: string) => {
    const message = `Media ${key} successfully!`;
    setToastMessage(message);
    setToastOpen(true);
    setIsLoading(true);
    setShouldFetch(true);
  };

  return (
    <>
      <Create
        open={isCreate}
        handleClose={() => setIsCreate(!isCreate)}
        refresh={() => refresh('created')}
        countries={countries}
        accounts={accounts}
        mediaTypes={mediaTypes}
      />
      <Update
        record={record}
        open={isUpdate}
        handleClose={() => setIsUpdate(!isUpdate)}
        refresh={() => refresh('updated')}
        countries={countries}
        mediaTypes={mediaTypes}
      />
      <EnhancedSnackbar open={toastOpen} message={toastMessage} onClose={() => setToastOpen(false)} />
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <MaterialTable
            title="Medias"
            components={{
              Toolbar: (props) => (
                <div className={classes.toolbarWrapper}>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={{
              search: true,
              pageSize: 10,
              actionsColumnIndex: 9,
              actionsCellStyle: { color: '#666' },
            }}
            actions={[
              {
                icon: 'add',
                tooltip: 'Create Media',
                isFreeAction: true,
                onClick: () => setIsCreate(!isCreate),
              },
              {
                icon: 'edit',
                tooltip: 'Update Media',
                isFreeAction: false,
                onClick: (event, rowData: any) => getRecord(rowData),
              },
              (rowData) => ({
                disabled: syncedRows.includes(rowData.mediaUuid),
                icon: 'sync',
                tooltip: 'Sync Media Images',
                isFreeAction: false,
                onClick: (event, rowData: any) => syncMedia(rowData),
              }),
            ]}
            isLoading={isLoading}
            columns={columns}
            data={data}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default WithMotion(Container);
