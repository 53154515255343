import Skeleton from '@material-ui/lab/Skeleton';
import { TableCell, TableRow, makeStyles } from '@material-ui/core';
import { range } from 'lodash';

type PropsType = {
  rows: number;
  cells: number;
};

const useStyles = makeStyles((theme) => ({
  tableCell: {
    borderBottom: 'none',
  },
}));

export default function LoadingTableRow({ rows, cells }: PropsType) {
  const classes = useStyles();
  return (
    <>
      {range(rows).map((row) => (
        <TableRow key={row}>
          {range(cells).map((cell) => (
            <TableCell key={cell} className={classes.tableCell}>
              <Skeleton animation="wave" />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
}
