import { useState, useEffect } from 'react';
import { makeCall } from 'utils/utils';
import { Grid } from '@material-ui/core';
import { tableContainerStyles as useStyles } from 'styles/common';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import EnhancedSnackbar from 'components/EnhancedSnackbar';
import WithMotion from 'components/withMotion';
import { Data, Network, Merchant, Media, Account, Country, Currency, FiltersFormData, Program } from './types';
import FiltersForm from './FiltersForm';
import { columns } from './initial';
import Create from './Create';
import Update from './Update';

function Container() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [data, setData] = useState<Data[]>([]);
  const [record, setRecord] = useState<any>(null);
  const [networks, setNetworks] = useState<Network[]>([]);
  const [merchants, setMerchants] = useState<Merchant[]>([]);
  const [medias, setMedias] = useState<Media[]>([]);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [countries, setCountries] = useState<Country[]>([]);
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [programs, setPrograms] = useState<Program[]>([]);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [filters, setFilters] = useState<any>({});

  useEffect(() => {
    function getData() {
      makeCall('currencies', 'GET').then((res) => setCurrencies(res?.data.data));
      makeCall('countries', 'GET').then((res) => setCountries(res?.data.data));
      makeCall('networks', 'GET').then((res) => setNetworks(res?.data.data));
      makeCall('merchants', 'GET').then((res) => setMerchants(res?.data.data));
      makeCall('medias', 'GET').then((res) => setMedias(res?.data.data));
      makeCall('accounts', 'GET').then((res) => setAccounts(res?.data.data));
      makeCall('programs', 'GET').then((res) => setPrograms(res?.data.data));
    }
    getData();
  }, []);

  const fetchData = async (payload: any) => {
    const response = await makeCall('trackers', 'GET', payload);
    setData(response?.data.data);
    setIsLoading(false);
  };

  const getRecord = async (clickedRow: Data) => {
    const response = await makeCall(`trackers/${clickedRow.trackerUuid}`, 'GET');
    setRecord(response?.data.data);
    setIsUpdate(true);
  };

  const handleFiltersSubmit = (filtersData: FiltersFormData) => {
    setIsLoading(true);
    fetchData(filtersData);
    setFilters(filtersData);
  };

  const refresh = (key: string) => {
    const message = `Tracker ${key} successfully!`;
    setToastMessage(message);
    setToastOpen(true);
    setIsLoading(true);
    fetchData(filters);
  };

  return (
    <>
      <Create
        open={isCreate}
        handleClose={() => setIsCreate(!isCreate)}
        refresh={() => refresh('created')}
        countries={countries}
        accounts={accounts}
        currencies={currencies}
        programs={programs}
      />
      <Update
        open={isUpdate}
        handleClose={() => setIsUpdate(!isUpdate)}
        refresh={() => refresh('updated')}
        countries={countries}
        currencies={currencies}
        record={record}
      />
      <EnhancedSnackbar open={toastOpen} message={toastMessage} onClose={() => setToastOpen(false)} />
      <FiltersForm
        networks={networks}
        merchants={merchants}
        medias={medias}
        accounts={accounts}
        countries={countries}
        currencies={currencies}
        handleFiltersSubmit={handleFiltersSubmit}
      />
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <MaterialTable
            title="Trackers"
            components={{
              Toolbar: (props) => (
                <div className={classes.toolbarWrapper}>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={{
              search: true,
              pageSize: 10,
              actionsColumnIndex: 1,
              actionsCellStyle: { color: '#666' },
            }}
            actions={[
              {
                icon: 'add',
                tooltip: 'Create Tracker',
                isFreeAction: true,
                onClick: () => setIsCreate(!isCreate),
              },
              {
                icon: 'edit',
                tooltip: 'Update Tracker',
                isFreeAction: false,
                onClick: (event, rowData: any) => getRecord(rowData),
              },
            ]}
            isLoading={isLoading}
            columns={columns}
            data={data}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default WithMotion(Container);
