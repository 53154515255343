import { Column } from '@material-table/core';
import { green, red } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import { ConnectionChannel } from 'types/global';

export const columns: Array<Column<ConnectionChannel>> = [
  {
    title: 'ID',
    field: 'connectionChannelUuid',
    cellStyle: { display: 'none' },
    headerStyle: { display: 'none' },
  },
  { title: 'API Id', field: 'connectionChannelApiId' },
  { title: 'API Name', field: 'connectionChannelApiName' },
  { title: 'Account', field: 'account.accountName' },
  { title: 'Media', field: 'media.mediaUrl' },
  {
    title: 'Status',
    field: 'connectionChannelStatus',
    render: (row) =>
      row.connectionChannelStatus ? (
        <Icon style={{ color: green[400] }}>check_circle</Icon>
      ) : (
        <Icon style={{ color: red[400] }}>remove_circle</Icon>
      ),
  },
];
