import { Column } from '@material-table/core';
import { green, red } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import { DataType, FilterSelectType, CreateFormData } from './types';

export const columns: Array<Column<DataType>> = [
  {
    title: 'ID',
    field: 'deal.dealUuid',
    cellStyle: { display: 'none' },
    headerStyle: { display: 'none' },
  },
  { title: 'Deal name', field: 'deal.dealName' },
  { title: 'Advertiser', field: 'advertiser.advertiserName' },
  { title: 'Country', field: 'deal.dealCountryCode' },
  { title: 'Start on', field: 'deal.dealStartDatetime' },
  { title: 'Expires on', field: 'deal.dealExpireDatetime' },
  {
    title: 'Status',
    field: 'deal.dealActiveStatus',
    render: (row) =>
      row.deal.dealActiveStatus ? (
        <Icon style={{ color: green[400] }}>check_circle</Icon>
      ) : (
        <Icon style={{ color: red[400] }}>remove_circle</Icon>
      ),
  },
  {
    title: 'Global',
    field: 'deal.dealGlobalStatus',
    render: (row) =>
      row.deal.dealGlobalStatus ? (
        <Icon style={{ color: green[400] }}>check_circle</Icon>
      ) : (
        <Icon style={{ color: red[400] }}>remove_circle</Icon>
      ),
  },
];

export const filterSelectOptions: FilterSelectType[] = [
  {
    label: 'All',
    value: null,
  },
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export const createDefaultValues: CreateFormData = {
  dealActiveStatus: false,
  dealCountryCode: null,
  dealCouponCode: '',
  dealDescription: '',
  dealDiscountText: '',
  dealDiscountValue: 0,
  dealStartDatetime: new Date(),
  dealExpireDatetime: new Date(),
  dealName: '',
  dealGlobalTrackerUuid: null,
  dealAdvertiserUuid: null,
  dealTargetUrl: '',
  dealTermsConditions: '',
  dealManagerAdminUuid: null,
  dealDiscountType: '',
  dealDiscountCurrencyCode: null,
};
