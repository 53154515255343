import React from 'react';
import {
  // Button,
  Dialog,
  // DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Typography,
  Divider,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import RefreshIcon from '@material-ui/icons/Refresh';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Clipboard from 'components/ClipboardV2';
import { ViewFormPropsType as PropsType } from './types';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperRoot: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
    chipsRoot: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    dividerSpace: {
      marginBottom: theme.spacing(1),
    },
  })
);

const formatter = new Intl.NumberFormat('da-DK');

export default function View({ open, handleClose, refresh, record }: PropsType) {
  const classes = useStyles();
  const close = () => {
    handleClose();
  };

  const handleStatusClick = () => {
    if (record?.paymentNote.paymentNoteCompletedStatus) return false;
    refresh(record?.paymentNote.paymentNoteUuid ?? '');
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      disableBackdropClick={true}
      onClose={close}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Payment note overview
        <IconButton aria-label="close" className={classes.closeButton} onClick={close}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Paper variant="outlined" className={classes.paperRoot}>
              <div style={{ paddingLeft: '20px' }}>
                <Clipboard id={record?.paymentNote.paymentNoteUuid ?? ''} />
              </div>
              <Divider className={classes.dividerSpace} />
              <div className={classes.chipsRoot}>
                <Chip
                  variant="outlined"
                  label={
                    <Typography variant="subtitle2" color="primary">
                      Network: {record?.network.networkName ?? '-'}
                    </Typography>
                  }
                  color="primary"
                />
                <Chip
                  variant="outlined"
                  label={
                    <Typography variant="subtitle2" color="primary">
                      Identifer: {record?.paymentNote.paymentNoteIdentifier ?? '-'}
                    </Typography>
                  }
                  color="primary"
                />
                <Chip
                  variant="outlined"
                  label={
                    <Typography variant="subtitle2" color="primary">
                      Date: {record?.paymentNote.paymentNoteDate ?? '-'}
                    </Typography>
                  }
                  color="primary"
                />
                <Chip
                  variant="outlined"
                  label={
                    <Typography variant="subtitle2" color="primary">
                      Period: {record?.paymentNote.paymentNotePeriodStartDate ?? '-'} -{' '}
                      {record?.paymentNote.paymentNotePeriodEndDate ?? '-'}
                    </Typography>
                  }
                  color="primary"
                />
              </div>
              <div className={classes.chipsRoot}>
                <Chip
                  variant="outlined"
                  label={
                    <Typography variant="subtitle2" color="primary">
                      Subtotal: {formatter.format(record?.paymentNote.paymentNoteSubTotal ?? 0)}
                    </Typography>
                  }
                  color="primary"
                />
                <Chip
                  variant="outlined"
                  label={
                    <Typography variant="subtitle2" color="primary">
                      VAT: {record?.paymentNote.paymentNoteVat ?? '-'}
                    </Typography>
                  }
                  color="primary"
                />
                <Chip
                  variant="outlined"
                  label={
                    <Typography variant="subtitle2" color="primary">
                      Currency: {record?.paymentNote.paymentNoteCurrencyCode ?? '-'}
                    </Typography>
                  }
                  color="primary"
                />
              </div>
              <div className={classes.chipsRoot}>
                {record?.paymentNote.paymentNoteCompletedStatus ? null : (
                  <Chip
                    variant="default"
                    label={
                      <Typography variant="subtitle2" style={{ color: '#fff' }}>
                        Sum: {formatter.format(record?.paymentNoteSums.amountSum ?? 0)}{' '}
                        {record?.paymentNoteSums.currencyCode ?? '-'}
                      </Typography>
                    }
                    color="primary"
                  />
                )}
                <Chip
                  variant="default"
                  label={
                    <Typography variant="subtitle2" style={{ color: '#fff' }}>
                      Status: {record?.paymentNote.paymentNoteCompletedStatus ? 'Completed' : 'Running' ?? '-'}
                    </Typography>
                  }
                  icon={!record?.paymentNote.paymentNoteCompletedStatus ? <RefreshIcon /> : <CheckCircleIcon />}
                  onClick={() => handleStatusClick()}
                  color="primary"
                />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper variant="outlined" className={classes.paperRoot}>
              <Typography color="primary" variant="h6" style={{ marginBottom: '10px' }}>
                Metadata
              </Typography>
              <Divider className={classes.dividerSpace} />
              <div className={classes.chipsRoot}>
                <Chip
                  variant="outlined"
                  label={
                    <Typography variant="subtitle2" color="primary">
                      Admin: {record?.admin.adminName ?? '-'}
                    </Typography>
                  }
                  color="primary"
                />
                <Chip
                  variant="outlined"
                  label={
                    <Typography variant="subtitle2" color="primary">
                      Created on: {record?.paymentNote.paymentNoteCreatedDatetime ?? '-'}
                    </Typography>
                  }
                  color="primary"
                />
                <Chip
                  variant="outlined"
                  label={
                    <Typography variant="subtitle2" color="primary">
                      Completed on: {record?.paymentNote.paymentNoteCompletedDatetime ?? '-'}
                    </Typography>
                  }
                  color="primary"
                />
              </div>
            </Paper>
          </Grid>
        </Grid>
        <Divider />
        <Grid container style={{ margin: '20px 0' }}>
          <Typography variant="h6" color="primary" style={{ margin: '0 18px' }}>
            Details
          </Typography>
          <TableContainer>
            <Table size="small" aria-label="details-table">
              <TableHead>
                <TableRow>
                  <TableCell>Currency</TableCell>
                  <TableCell align="right">Conversions</TableCell>
                  <TableCell align="right">Amount</TableCell>
                  <TableCell align="right">Commission</TableCell>
                  <TableCell align="right">Cut</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {record?.paymentNoteDetails.map((row, idx) => (
                  <TableRow key={idx}>
                    <TableCell component="th" scope="row">
                      {row.currencyCode}
                    </TableCell>
                    <TableCell align="right">{formatter.format(row.conversionsCount)}</TableCell>
                    <TableCell align="right">{formatter.format(row.amountSum)}</TableCell>
                    <TableCell align="right">{formatter.format(row.commissionSum)}</TableCell>
                    <TableCell align="right">{formatter.format(row.cutSum)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
