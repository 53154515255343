import { Column } from '@material-table/core';
import { Data, FiltersFormData, CreateFormData } from './types';
import { green, red } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';

export const columns: Array<Column<Data>> = [
  { title: 'ID', field: 'trackerUuid', cellStyle: { display: 'none' }, headerStyle: { display: 'none' } },
  { title: 'Name', field: 'trackerName', defaultSort: 'asc' },
  { title: 'Program', field: 'program.programTitle' },
  { title: 'Url Match', field: 'trackerUrlMatch' },
  { title: 'Url Alias', field: 'trackerUrlAlias' },
  {
    title: 'Url Alias Exact',
    field: 'trackerUrlAliasExact',
    render: (row) =>
      row.trackerUrlAliasExact ? (
        <Icon style={{ color: green[400] }}>check_circle</Icon>
      ) : (
        <Icon style={{ color: red[400] }}>remove_circle</Icon>
      ),
  },
  { title: 'API Identifier', field: 'trackerApiIdentifier' },
  { title: 'Network', field: 'network.networkName' },
  { title: 'Account', field: 'account.accountName' },
  { title: 'Media', field: 'media.mediaUrl' },
  { title: 'Merchant', field: 'merchant.merchantUrl' },
  { title: 'Country', field: 'trackerCountryCode' },
  { title: 'Currency', field: 'trackerCurrencyCode' },
  { title: 'Fixed', field: 'trackerFixed' },
  { title: 'Percentage', field: 'trackerPercentage' },
  {
    title: 'Imported',
    field: 'trackerImported',
    render: (row) =>
      row.trackerImported ? (
        <Icon style={{ color: green[400] }}>check_circle</Icon>
      ) : (
        <Icon style={{ color: red[400] }}>remove_circle</Icon>
      ),
  },
  {
    title: 'Omit Deeplink',
    field: 'trackerOmitDeeplink',
    render: (row) =>
      row.trackerOmitDeeplink ? (
        <Icon style={{ color: green[400] }}>check_circle</Icon>
      ) : (
        <Icon style={{ color: red[400] }}>remove_circle</Icon>
      ),
  },
  {
    title: 'Deeplink path',
    field: 'trackerDeeplinkPathOnly',
    render: (row) =>
      row.trackerDeeplinkPathOnly ? (
        <Icon style={{ color: green[400] }}>check_circle</Icon>
      ) : (
        <Icon style={{ color: red[400] }}>remove_circle</Icon>
      ),
  },
  {
    title: 'Status',
    field: 'trackerStatus',
    render: (row) =>
      row.trackerStatus ? (
        <Icon style={{ color: green[400] }}>check_circle</Icon>
      ) : (
        <Icon style={{ color: red[400] }}>remove_circle</Icon>
      ),
  },
  {
    title: 'Disabled',
    field: 'trackerDisabled',
    render: (row) =>
      row.trackerDisabled ? (
        <Icon style={{ color: green[400] }}>check_circle</Icon>
      ) : (
        <Icon style={{ color: red[400] }}>remove_circle</Icon>
      ),
  },
  {
    title: 'Deal Status',
    field: 'trackerIsDealStatus',
    render: (row) =>
      row.trackerIsDealStatus ? (
        <Icon style={{ color: green[400] }}>check_circle</Icon>
      ) : (
        <Icon style={{ color: red[400] }}>remove_circle</Icon>
      ),
  },
];

export const filterDefaultValues: FiltersFormData = {
  trackerNetworkUuid: null,
  trackerMerchantUuid: null,
  trackerMediaUuid: null,
  trackerAccountUuid: null,
  trackerCountryCode: null,
  trackerCurrencyCode: null,
  trackerStatus: true,
  trackerDisabled: false,
  trackerIsDealStatus: false,
};

export const createDefaultValues: CreateFormData = {
  trackerName: '',
  trackerUrlMatch: '',
  trackerUrlAlias: '',
  trackerTagDeeplink: '',
  trackerTagChannelId: '',
  trackerTagAccountId: '',
  trackerTagCampaignId: '',
  trackerTagTrackingUrl: '',
  trackerTagUrlId: '',
  trackerTagCustom1: '',
  trackerTagCustom2: '',
  trackerTagCustom3: '',
  trackerTagCustom4: '',
  trackerTagCustom5: '',
  trackerFixed: 0,
  trackerPercentage: 0,
  trackerSplitDeeplinkPercentage: 0,
  trackerCountryCode: null,
  trackerCurrencyCode: null,
  trackerUrlAliasExact: false,
  trackerOmitDeeplink: false,
  trackerProgramUuid: null,
  trackerAccountUuid: null,
  trackerMediaUuid: null,
  trackerIsDealStatus: false,
  trackerDeeplinkPathOnly: false,
};
