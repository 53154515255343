import { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, Grid, Button, DialogActions } from '@material-ui/core';
import ImageUploader from 'react-images-upload';
import { Data } from './types';
import { makeCall } from 'utils/utils';
import axios from 'axios';

const ROOT_URL = process.env.REACT_APP_ROOT_URL;

type PropsType = {
  open: boolean;
  handleClose: () => void;
  row: Data | null;
};

export default function Upload({ open, handleClose, row }: PropsType) {
  const [imageUrl, setImageUrl] = useState('');
  const [imageFile, setImageFile] = useState<any>(null);
  const [isDisable, setIsDisable] = useState(true);
  useEffect(() => {
    if (row) {
      const getData = async () => {
        const response = await makeCall(`accounts/${row.accountUuid}`, 'GET');
        console.log(response?.data.data);
        setImageUrl(response?.data.data.accountLogoUrl);
      };
      getData();
    }
  }, [row]);

  const uploadImage = async () => {
    const data = new FormData();
    data.append('file', imageFile[0]);
    setIsDisable(true);
    try {
      await axios({
        method: 'PUT',
        url: `${ROOT_URL}accounts/${row?.accountUuid}/logo`,
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        data: data,
      });
      // this.props.getAccount({ accountId: this.props.accountId });
      resetForm();
    } catch (error) {
      console.log(error);
      setIsDisable(false);
    }
  };

  const onDrop = (pictureFiles: any) => {
    setImageFile(pictureFiles);
    setIsDisable(false);
  };

  const resetForm = () => {
    setImageUrl('');
    setImageFile(null);
    setIsDisable(true);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      disableBackdropClick={true}
      onEscapeKeyDown={resetForm}
      onClose={resetForm}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title">Upload Account Logo</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid xs={12} item>
            {imageUrl && (
              <div
                style={{
                  width: '200px',
                  margin: '1rem auto',
                }}
              >
                <img style={{ width: '100%' }} src={imageUrl} alt="account logo" />
              </div>
            )}
          </Grid>
          <Grid xs={12} item>
            <ImageUploader
              withIcon={true}
              buttonText="Choose image"
              onChange={onDrop}
              imgExtension={['.png']}
              maxFileSize={5242880}
              singleImage
              withPreview
              fileContainerStyle={{
                boxShadow: 'none',
                borderRadius: 'none',
              }}
              buttonStyles={{
                backgroundColor: '#3f51b5',
                color: '#fff',
                fontWeight: 'normal',
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={resetForm} color="primary">
          Cancel
        </Button>
        <Button disabled={isDisable} type="button" onClick={uploadImage} color="primary">
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
}
