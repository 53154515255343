import { useState } from 'react';
import { Grid, Button, Paper, makeStyles, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { TimeGroup, DataGroup, DataSource, Years } from './initialData';
import {
  Account,
  Currency,
  Network,
  AccountValue,
  CurrencyValue,
  NetworkValue,
  GenericValue,
  FiltersType,
} from './types';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

type PropsType = {
  accounts: Account[];
  networks: Network[];
  currency: Currency[];
  generate: (payload: any) => void;
};

export default function Filters({ accounts, networks, currency, generate }: PropsType) {
  const currentYear = new Date().getFullYear().toString();
  const classes = useStyles();
  const [selectedAccount, setSelectedAccount] = useState<AccountValue>(null);
  const [selectedCurrency, setSelectedCurrency] = useState<CurrencyValue>(null);
  const [selectedOutputCurrencyCode, setSelectedOutputCurrencyCode] = useState<CurrencyValue>(null);
  const [selectedNetwork, setSelectedNetwork] = useState<NetworkValue>(null);
  const [selectedTimeGroup, setSelectedTimeGroup] = useState<GenericValue>({ key: 'month', value: 'Month' });
  const [selectedDataGroup, setSelectedDataGroup] = useState<GenericValue>({ key: 'account', value: 'Account' });
  const [selectedDataSource, setSelectedDataSource] = useState<GenericValue>({
    key: 'commission',
    value: 'Partner commission',
  });
  const [selectedYear, setSelectedYear] = useState<GenericValue>({ key: currentYear, value: currentYear });

  const handleGenerateClick = () => {
    const payload: FiltersType = {
      timeGroupBy: selectedTimeGroup?.key ?? 'month',
      groupBy: selectedDataGroup?.key ?? 'account',
      dataSource: selectedDataSource?.key ?? 'commission',
      year: selectedYear?.key ?? currentYear,
      outputCurrencyCode: '',
      filters: {},
    };
    if (selectedAccount) {
      payload.filters.accountUuid = selectedAccount.accountUuid;
    }
    if (selectedCurrency) {
      payload.filters.currencyCode = selectedCurrency.currencyCode;
    }
    if (selectedNetwork) {
      payload.filters.networkUuid = selectedNetwork.networkUuid;
    }
    if (selectedOutputCurrencyCode) {
      payload.outputCurrencyCode = selectedOutputCurrencyCode.currencyCode;
    }
    generate(payload);
  };

  return (
    <Paper variant="outlined" className={classes.root}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={3}>
          <Autocomplete
            id="timeGroup-select"
            options={TimeGroup}
            getOptionLabel={(option) => option.value}
            onChange={(_, value) => setSelectedTimeGroup(value)}
            defaultValue={selectedTimeGroup}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label="Time group"
                  defaultValue={selectedTimeGroup?.value}
                  variant="outlined"
                  margin="dense"
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            id="dataGroup-select"
            options={DataGroup}
            getOptionLabel={(option) => option.value}
            onChange={(_, value) => setSelectedDataGroup(value)}
            defaultValue={selectedDataGroup}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  defaultValue={selectedDataGroup?.value}
                  label="Data group"
                  variant="outlined"
                  margin="dense"
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            id="dataSource-select"
            options={DataSource}
            getOptionLabel={(option) => option.value}
            onChange={(_, value) => setSelectedDataSource(value)}
            defaultValue={selectedDataSource}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label="Data source"
                  defaultValue={selectedDataSource?.value}
                  variant="outlined"
                  margin="dense"
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            id="account-select"
            options={accounts}
            getOptionLabel={(option) => option.accountName}
            onChange={(_, value) => setSelectedAccount(value)}
            renderInput={(params) => {
              return <TextField {...params} label="Account" variant="outlined" margin="dense" />;
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            id="network-select"
            options={networks}
            getOptionLabel={(option) => option.networkName}
            onChange={(_, value) => setSelectedNetwork(value)}
            renderInput={(params) => {
              return <TextField {...params} label="Network" variant="outlined" margin="dense" />;
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Autocomplete
            id="currency-select"
            options={currency}
            getOptionLabel={(option) => option.currencyCode}
            onChange={(_, value) => setSelectedCurrency(value)}
            renderInput={(params) => {
              return <TextField {...params} label="Filter currency" variant="outlined" margin="dense" />;
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Autocomplete
            id="output-currency-select"
            options={currency}
            getOptionLabel={(option) => option.currencyCode}
            onChange={(_, value) => setSelectedOutputCurrencyCode(value)}
            renderInput={(params) => {
              return <TextField {...params} label="Output currency" variant="outlined" margin="dense" />;
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Autocomplete
            id="year-select"
            options={Years}
            getOptionLabel={(option) => option.value}
            onChange={(_, value) => setSelectedYear(value)}
            defaultValue={selectedYear}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  defaultValue={selectedYear?.value}
                  label="Year"
                  variant="outlined"
                  margin="dense"
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={2}>
          <Button onClick={handleGenerateClick} variant="contained" color="primary" fullWidth>
            Generate
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}
