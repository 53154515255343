import { useForm, Controller } from 'react-hook-form';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import { makeCall } from 'utils/utils';
import { CreateFormData as FormData, CreateFormPropsType as PropsType } from './types';
import { createDefaultValues as defaultValues } from './initialData';
import EnhancedTextField from 'components/EnhancedTextField';
import EnhancedSwitch from 'components/EnhancedSwitch';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function Create({ open, handleClose, refresh, countries, accounts, mediaTypes }: PropsType) {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
  } = useForm<FormData>({ defaultValues });

  const resetForm = () => {
    reset(defaultValues);
    handleClose();
  };

  const onSubmit = async (data: FormData) => {
    const payload = {
      ...data,
      mediaCountryCode: data.mediaCountryCode?.countryCode,
      mediaTypeUuid: data.mediaTypeUuid?.mediaTypeUuid,
      mediaAccountUuid: data.mediaAccountUuid?.accountUuid,
    };
    await makeCall('medias', 'POST', payload);
    reset(defaultValues);
    handleClose();
    refresh();
  };
  return (
    <>
      <Dialog
        open={open}
        disableBackdropClick={true}
        onEscapeKeyDown={resetForm}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">Create Media</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="mediaName"
                  label="Media name"
                  hasError={errors.mediaName ? true : false}
                  errorTxt="Media name is required!"
                  autoFocus
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  required={false}
                  fieldName="mediaDescription"
                  label="Media description"
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="mediaUrl"
                  label="Media URL"
                  hasError={errors.mediaUrl ? true : false}
                  errorTxt="Media URL is required!"
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="mediaAccountUuid"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="account-select"
                      options={accounts}
                      onChange={(_, data) => field.onChange(data)}
                      getOptionLabel={(option) => option.accountName}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          error={errors.mediaAccountUuid ? true : false}
                          helperText={errors.mediaAccountUuid ? 'Account is required!' : null}
                          {...params}
                          label="Account"
                          margin="dense"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="mediaTypeUuid"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="media-type-select"
                      options={mediaTypes}
                      onChange={(_, data) => field.onChange(data)}
                      getOptionLabel={(option) => option.mediaTypeName}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          error={errors.mediaTypeUuid ? true : false}
                          helperText={errors.mediaTypeUuid ? 'Media type is required!' : null}
                          {...params}
                          label="Media type"
                          margin="dense"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="mediaCountryCode"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="country-select"
                      options={countries}
                      onChange={(_, data) => field.onChange(data)}
                      getOptionLabel={(option) => option.countryName}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          error={errors.mediaCountryCode ? true : false}
                          helperText={errors.mediaCountryCode ? 'Country is required!' : null}
                          {...params}
                          label="Country"
                          margin="dense"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <EnhancedSwitch control={control} fieldName="mediaDiscoverMode" label="Discover Mode" />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={resetForm} color="primary">
              Cancel
            </Button>
            <Button disabled={isSubmitting} type="submit" color="primary">
              {isSubmitting ? 'Creating...' : 'Create'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
