import React, { ReactFragment, ReactNode } from 'react';
import { Grid, Card, CardHeader, CardContent, Typography, Tooltip } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(5),
  },
  cardInnerBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'default',
  },
  title: {
    fontSize: '0.875rem',
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    marginBottom: 8,
    fontWeight: 400,
  },
  avatar: {
    color: 'green',
    backgroundColor: 'black',
    marginLeft: '16px',
  },
  value: {
    fontSize: 28,
    fontWeight: 500,
    marginBottom: 5,
    textAlign: 'center',
  },

  subTxt: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '14px',
    display: 'inline-block',
  },
  subValue: {
    fontSize: '14px',
    display: 'inline-block',
    color: '#000',
    marginLeft: '5px',
    borderBottom: '1px dashed #000',
  },
}));

const useStylesBootstrap = makeStyles((theme: Theme) => ({
  arrow: {
    color: theme.palette.primary.dark,
  },
  tooltip: {
    backgroundColor: theme.palette.primary.dark,
    color: 'rgba(255, 255, 255, 0.87)',
    maxWidth: 420,
    fontSize: theme.typography.pxToRem(16),
    border: '1px solid #dadde9',
    padding: 10,
    textAlign: 'center',
    marginTop: -12,
  },
}));

type Props = {
  title: string;
  value: string;
  isLoading: boolean;
  subText?: string;
  subValue?: string;
  avatar: ReactNode;
  tooltip?: ReactFragment;
};

export default function SingleCard({ title, value, subText, subValue, avatar, tooltip, isLoading }: Props) {
  const classes = useStyles();
  const tooltipClasses = useStylesBootstrap();
  return (
    <Grid item xs={3}>
      <Tooltip placement="bottom" classes={tooltipClasses} title={tooltip && !isLoading ? tooltip : ''} arrow>
        <Card variant="outlined">
          <div className={classes.cardInnerBox}>
            <CardHeader avatar={avatar} />
            <CardContent>
              <div style={{ textAlign: 'center' }}>
                <Typography variant="h3" className={classes.title}>
                  {title}
                </Typography>
              </div>
              {isLoading ? (
                <Skeleton variant="rect" width={210} height={60} animation="wave" />
              ) : (
                <>
                  <Typography variant="h3" className={classes.value}>
                    {value}
                  </Typography>
                  <div style={{ textAlign: 'center' }}>
                    <Typography variant="body1" className={classes.subTxt}>
                      {subText ? `${subText}: ` : ''}
                    </Typography>
                    <Typography variant="body1" className={classes.subValue}>
                      {subText ? `${subValue}` : ''}
                    </Typography>
                  </div>
                </>
              )}
            </CardContent>
          </div>
        </Card>
      </Tooltip>
    </Grid>
  );
}
