import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from 'App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
//extra
import WebFontLoader from 'webfontloader';

if (process.env.REACT_APP_ENABLE_LOGGING === true) {
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_URL });
  LogRocket.init(process.env.REACT_APP_LOG_ROCKET_URL);
  setupLogRocketReact(LogRocket);
}

WebFontLoader.load({
  google: {
    families: ['Roboto:300,400,500,700', 'Material Icons'],
  },
});

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);
serviceWorker.unregister();
