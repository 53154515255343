import { ColumnType } from './types';

export const columns: ColumnType = {
  medias: [
    { field: 'name', title: 'Name' },
    { field: 'count', title: 'Count' },
    { field: 'mediaUuid', title: 'Media Uuid' },
  ],
  accounts: [
    { field: 'name', title: 'Name' },
    { field: 'count', title: 'Count' },
    { field: 'accountUuid', title: 'Account Uuid' },
  ],
  dates: [
    { field: 'date', title: 'Date' },
    { field: 'count', title: 'Count' },
  ],
  targets: [
    { field: 'target', title: 'Target' },
    { field: 'count', title: 'Count' },
  ],
  pageUrls: [
    { field: 'pageUrl', title: 'Page Urls' },
    { field: 'count', title: 'Count' },
  ],
  opportunities: [
    { title: 'Target', field: 'target' },
    { title: 'Tracker Name', field: 'tracker.trackerName' },
    { title: 'Program Name', field: 'tracker.programTitle' },
    { title: 'Network Name', field: 'tracker.networkName' },
    { field: 'count', title: 'Count' },
  ],
  domains: [
    { field: 'domain', title: 'Domain' },
    { field: 'count', title: 'Count' },
  ],
};

export const discoverTabs = [
  {
    label: 'Medias',
    icon: 'save',
    key: 'medias',
  },
  {
    label: 'Accounts',
    icon: 'account_circle',
    key: 'accounts',
  },
  {
    label: 'Dates',
    icon: 'date_range',
    key: 'dates',
  },
  {
    label: 'Target Domain',
    icon: 'web',
    key: 'domains',
  },
  {
    label: 'Target Path',
    icon: 'highlight_alt',
    key: 'targets',
  },
  {
    label: 'Page URLS',
    icon: 'pageview',
    key: 'pageUrls',
  },
  {
    label: 'Opportunities',
    icon: 'local_offer',
    key: 'opportunities',
  },
];
