import { Column } from '@material-table/core';
import { green, red } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import { Data, CreateFormData, FilterFormData } from './types';
import moment from 'moment';

export const columns: Array<Column<Data>> = [
  {
    title: 'ID',
    field: 'trackerMediaExcludeUuid',
    cellStyle: { display: 'none' },
    headerStyle: { display: 'none' },
  },
  { title: 'Domain', field: 'trackerMediaExcludeDomain' },
  { title: 'URL', field: 'trackerMediaExcludeUrl' },
  {
    title: 'Status',
    field: 'trackerMediaExcludeStatus',
    render: (row) =>
      row.trackerMediaExcludeStatus ? (
        <Icon style={{ color: green[400] }}>check_circle</Icon>
      ) : (
        <Icon style={{ color: red[400] }}>remove_circle</Icon>
      ),
  },
  {
    title: 'Created On',
    field: 'trackerMediaExcludeCreatedDatetime',
    render: (rowData) => moment(rowData.trackerMediaExcludeCreatedDatetime).format('DD MMM, HH:mm'),
  },
  {
    title: 'Updated On',
    field: 'trackerMediaExcludeUpdatedDatetime',
    render: (rowData) => moment(rowData.trackerMediaExcludeUpdatedDatetime).format('DD MMM, HH:mm'),
  },
];

export const createDefaultValues: CreateFormData = {
  trackerMediaExcludeDomain: '',
  trackerMediaExcludeUrl: '*',
  trackerMediaExcludeAccountUuid: null,
  trackerMediaExcludeMediaUuid: null,
  trackerMediaExcludeStatus: false,
};

export const filterDefaultValues: FilterFormData = {
  trackerMediaExcludeAccountUuid: null,
  trackerMediaExcludeMediaUuid: null,
};
