import { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { makeCall } from 'utils/utils';
import { UpdateFormData as FormData, UpdateFormPropsType as PropsType } from './types';
import EnhancedTextField from 'components/EnhancedTextField';
import EnhancedSwitch from 'components/EnhancedSwitch';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Clipboard from 'components/ClipboardV2';

export default function Update({ open, handleClose, record, refresh, countries, mediaTypes }: PropsType) {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
  } = useForm<FormData>();

  useEffect(() => {
    if (record) {
      const selectedCountry = countries.filter((row) => row.countryCode === record.mediaCountryCode);
      const selectedMediaType = mediaTypes.filter((row) => row.mediaTypeUuid === record.mediaTypeUuid);

      reset({
        mediaName: record.mediaName,
        mediaDescription: record.mediaDescription,
        mediaUrl: record.mediaUrl,
        mediaTypeUuid: selectedMediaType.length ? selectedMediaType[0] : null,
        mediaCountryCode: selectedCountry.length ? selectedCountry[0] : null,
        mediaDiscoverMode: !!record.mediaDiscoverMode,
      });
    }
  }, [record, reset, countries, mediaTypes]);

  const onSubmit = async (data: FormData) => {
    const payload = {
      ...data,
      mediaCountryCode: data.mediaCountryCode?.countryCode,
      mediaTypeUuid: data.mediaTypeUuid?.mediaTypeUuid,
    };
    await makeCall(`medias/${record?.mediaUuid}`, 'PUT', payload);
    close();
    refresh();
  };

  const close = () => {
    handleClose();
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        disableBackdropClick={true}
        onClose={close}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Update Media</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Clipboard id={record?.mediaUuid ?? ''} />
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="mediaName"
                  label="Media name"
                  hasError={errors.mediaName ? true : false}
                  errorTxt="Media name is required!"
                  autoFocus
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  required={false}
                  fieldName="mediaDescription"
                  label="Media description"
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="mediaUrl"
                  label="Media URL"
                  hasError={errors.mediaUrl ? true : false}
                  errorTxt="Media URL is required!"
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="mediaTypeUuid"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="media-type-select"
                      options={mediaTypes}
                      onChange={(_, data) => field.onChange(data)}
                      getOptionLabel={(option) => option.mediaTypeName}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          error={errors.mediaTypeUuid ? true : false}
                          helperText={errors.mediaTypeUuid ? 'Media type is required!' : null}
                          {...params}
                          label="Media type"
                          margin="dense"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="mediaCountryCode"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="country-select"
                      options={countries}
                      onChange={(_, data) => field.onChange(data)}
                      getOptionLabel={(option) => option.countryName}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          error={errors.mediaCountryCode ? true : false}
                          helperText={errors.mediaCountryCode ? 'Country is required!' : null}
                          {...params}
                          label="Country"
                          margin="dense"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <EnhancedSwitch
                  isChecked={!!record?.mediaDiscoverMode}
                  control={control}
                  fieldName="mediaDiscoverMode"
                  label="Discover Mode"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={close} color="primary">
              Cancel
            </Button>
            <Button disabled={isSubmitting} type="submit" color="primary">
              {isSubmitting ? 'Updating...' : 'Update'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
