import { makeStyles } from '@material-ui/core';

type Props = {
  data: {
    paid: number;
    pendingNoPaymentReceived: number;
    pendingPaymentReceived: number;
    rejected: number;
  };
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
    cursor: 'pointer',
    '& > div': {
      height: 12,
    },
    '&:hover > span': {
      display: 'block',
    },
  },
  pendingNo: {
    backgroundColor: theme.clicks.primary,
  },
  pending: {
    backgroundColor: theme.conversions.primary,
  },
  reject: {
    backgroundColor: theme.partnerCommission.primary,
  },
  paid: {
    backgroundColor: theme.commission.primary,
  },
  tooltip: {
    display: 'none',
    border: '1px solid #ccc',
    borderRadius: '5px',
    position: 'absolute',
    zIndex: 99999999999,
    top: -100,
    left: -260,
    width: '250px',
    background: 'white',
    padding: 10,
    '& > span': {
      display: 'block',
      fontWeight: 'bold',
      paddingTop: 3,
      paddingBottom: 3,
    },
    '& > span:nth-child(1)': {
      color: theme.clicks.primary,
    },
    '& > span:nth-child(2)': {
      color: theme.conversions.primary,
    },
    '& > span:nth-child(3)': {
      color: theme.partnerCommission.primary,
    },
    '& > span:nth-child(4)': {
      color: theme.commission.primary,
    },
  },
}));

export default function StatusBar({ data }: Props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.pendingNo} style={{ width: `${data.pendingNoPaymentReceived}%` }}>
        &nbsp;
      </div>
      <div className={classes.pending} style={{ width: `${data.pendingPaymentReceived}%` }}>
        &nbsp;
      </div>
      <div className={classes.reject} style={{ width: `${data.rejected}%` }}>
        &nbsp;
      </div>
      <div className={classes.paid} style={{ width: `${data.paid}%` }}>
        &nbsp;
      </div>
      <span className={classes.tooltip}>
        <span>Pending no payment received: {data.pendingNoPaymentReceived}%</span>
        <span>Pending payment received: {data.pendingPaymentReceived}%</span>
        <span>Rejected: {data.rejected}%</span>
        <span>Paid: {data.paid}%</span>
      </span>
    </div>
  );
}
