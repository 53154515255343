import React from 'react';
import { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { makeCall } from 'utils/utils';
import { tableContainerStyles as useStyles } from 'styles/common';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import WithMotion from 'components/withMotion';
import { ModalType, Account, Media } from 'types/global';
import { Data, Record, FilterFormData } from './types';
import { columns } from './initialData';
import FiltersForm from './Filters';
import Create from './Create';
import Update from './Update';
import EnhancedSnackbar from 'components/EnhancedSnackbar';

function TrackersMediaExclude() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Data[]>([]);
  const [modalType, setModalType] = useState<ModalType>(null);
  const [record, setRecord] = useState<Record>(null);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [medias, setMedias] = useState<Media[]>([]);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [filters, setFilters] = useState<any>({});

  useEffect(() => {
    makeCall('medias', 'GET').then((res) => setMedias(res?.data.data));
    makeCall('accounts', 'GET').then((res) => setAccounts(res?.data.data));
  }, []);

  const fetchData = async (payload: any) => {
    const response = await makeCall('trackers/trackerMediaExcludes', 'GET', payload);
    setData(response?.data.data);
    setIsLoading(false);
  };

  const getRecord = async (clickedRow: Data) => {
    const response = await makeCall(`trackers/trackerMediaExcludes/${clickedRow.trackerMediaExcludeUuid}`, 'GET');
    setRecord(response?.data.data);
    setModalType('update');
  };

  const handleFiltersSubmit = (filtersData: FilterFormData) => {
    setIsLoading(true);
    fetchData(filtersData);
    setFilters(filtersData);
  };

  const refresh = (key: string) => {
    const message = `Tracker media exclude ${key} successfully!`;
    setToastMessage(message);
    setToastOpen(true);
    setIsLoading(true);
    fetchData(filters);
  };

  return (
    <>
      <Create
        open={modalType === 'create'}
        handleClose={() => setModalType(null)}
        refresh={() => refresh('created')}
        medias={medias}
        accounts={accounts}
      />
      <Update
        open={modalType === 'update'}
        handleClose={() => setModalType(null)}
        refresh={() => refresh('updated')}
        record={record}
      />
      <FiltersForm accounts={accounts} medias={medias} handleFiltersSubmit={handleFiltersSubmit} />
      <EnhancedSnackbar open={toastOpen} message={toastMessage} onClose={() => setToastOpen(false)} />
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <MaterialTable
            title="Tracker media excludes"
            components={{
              Toolbar: (props) => (
                <div className={classes.toolbarWrapper}>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={{
              search: true,
              pageSize: 10,
              actionsColumnIndex: 9,
              actionsCellStyle: { color: '#666' },
            }}
            actions={[
              {
                icon: 'add',
                tooltip: 'Create tracker media exclude',
                isFreeAction: true,
                onClick: () => setModalType('create'),
              },
              {
                icon: 'edit',
                tooltip: 'Update tracker media exclude',
                isFreeAction: false,
                onClick: (event, rowData: any) => getRecord(rowData),
              },
            ]}
            isLoading={isLoading}
            columns={columns}
            data={data}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default WithMotion(TrackersMediaExclude);
