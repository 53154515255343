import React, { useState, useEffect } from 'react';
import Datepicker from 'components/DatepickerV2';
import { endOfMonth, format, startOfWeek, endOfWeek } from 'date-fns';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import WithMotion from 'components/withMotion';
import TabsContainer from './components/Tabs';
import FiltersContainer from './components/Filters';
import ChartContainer from './components/Chart';
import Table from './components/Table';
import { getDateOfWeek, fetchFunc, sortData } from './utils';
import { PayloadType, GroupByType, GraphDataType, InsightsDataType } from './types';
import { initialData } from './initialData';

const useStyles = makeStyles((theme) => ({
  tabsContainer: {
    marginTop: theme.spacing(4),
  },
}));

function Insights() {
  const classes = useStyles();
  const [data, setData] = useState(initialData);
  const [dataToRender, setDataToRender] = useState<InsightsDataType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [group, setGroup] = useState<GroupByType>('date');
  const [dateObj, setDateObj] = useState({
    fromDate: format(new Date(), 'yyyy-MM-dd'),
    toDate: format(new Date(), 'yyyy-MM-dd'),
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [sortBy, setSortBy] = useState('grp');
  const [sortDirection, setSortDirection] = useState<'desc' | 'asc'>('asc');
  const [groupFilters, setGroupFilters] = useState({
    date: '',
    week: '',
    month: '',
    year: '',
    advertiserUuid: '',
    advertiserLabel: '',
    channelLabel: '',
    channelUuid: '',
    accountUuid: '',
    accountLabel: '',
    networkUuid: '',
    networkLabel: '',
    device: '',
    pageUrl: '',
    referer: '',
    status: '',
    currency: '',
  });
  const [graphData, setGraphData] = useState<GraphDataType>({
    lines: [],
    bars: [],
  });

  const [searchValue, setSearchValue] = React.useState('');

  const PER_PAGE = 30;

  useEffect(() => {
    async function getData() {
      const payload: PayloadType = {
        fromDate: dateObj.fromDate,
        toDate: dateObj.toDate,
        groupBy: group,
      };
      if (groupFilters.year) {
        const startDate = format(new Date(parseInt(groupFilters.year), 0, 1), 'yyy-MM-dd');
        const endDate = format(new Date(parseInt(groupFilters.year), 11, 31), 'yyy-MM-dd');
        payload.fromDate = startDate;
        payload.toDate = endDate;
      }
      if (groupFilters.month) {
        const monthArr = groupFilters.month.split('-');
        const startDate = format(new Date(parseInt(monthArr[0]), parseInt(monthArr[1]) - 1, 1), 'yyy-MM-dd');
        const endDate = format(endOfMonth(new Date(parseInt(monthArr[0]), parseInt(monthArr[1]) - 1, 1)), 'yyy-MM-dd');
        payload.fromDate = startDate;
        payload.toDate = endDate;
      }
      if (groupFilters.week) {
        const weekArr = groupFilters.week.split('-');
        const weekDate = getDateOfWeek(parseInt(weekArr[1]), parseInt(weekArr[0]));
        const startDate = format(startOfWeek(weekDate), 'yyy-MM-dd');
        const endDate = format(endOfWeek(weekDate), 'yyy-MM-dd');
        payload.fromDate = startDate;
        payload.toDate = endDate;
      }
      if (groupFilters.advertiserUuid) {
        payload.advertiserUuid = groupFilters.advertiserUuid;
      }
      if (groupFilters.channelUuid) {
        payload.channelUuid = groupFilters.channelUuid;
      }
      if (groupFilters.device) {
        payload.device = groupFilters.device;
      }
      if (groupFilters.pageUrl) {
        payload.pageUrl = groupFilters.pageUrl;
      }
      if (groupFilters.referer) {
        payload.referer = groupFilters.referer;
      }
      if (groupFilters.networkUuid) {
        payload.networkUuid = groupFilters.networkUuid;
      }
      if (groupFilters.accountUuid) {
        payload.accountUuid = groupFilters.accountUuid;
      }
      if (groupFilters.status) {
        payload.status = groupFilters.status;
      }
      if (groupFilters.currency) {
        payload.currencyCode = groupFilters.currency;
      }

      const data = await fetchFunc({ url: `insights`, payload });
      const dataLength = data.insights.length;
      const currentPage = 1;
      const pageCount = Math.ceil(dataLength / PER_PAGE);
      const dataToRender = data.insights.slice(0, PER_PAGE);
      setData(data);
      setCurrentPage(currentPage);
      setPageCount(pageCount);
      setDataToRender(dataToRender);
      setGraphData(data.graphs);
      setSortBy('grp');
      setSortDirection('asc');
      setIsLoading(false);
      setSearchValue('');
    }
    getData();
  }, [dateObj, group, groupFilters]);

  const handlePageChange = (page: number) => {
    if (page === currentPage) return false;
    const startIndex = (page - 1) * PER_PAGE;
    const dataToRender = data.insights.slice(startIndex, startIndex + PER_PAGE);
    setCurrentPage(page);
    setDataToRender(dataToRender);
  };

  const handleSort = (newSort: string) => {
    let newSortDirection: 'asc' | 'desc' = 'asc';
    if (sortBy === newSort) {
      newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    }
    const sortedData = sortData(data.insights, newSort, newSortDirection);
    const filteredData = sortedData.filter((row) => row.grpName.includes(searchValue));
    setData({ ...data, insights: sortedData });
    setSortBy(newSort);
    setSortDirection(newSortDirection);
    setCurrentPage(1);
    setDataToRender(filteredData.slice(0, PER_PAGE));
  };

  const handleFilterClick = (grpFilter: string, grpName: string) => {
    const newFilters = { ...groupFilters };
    setIsLoading(true);
    switch (group) {
      case 'date':
        newFilters.date = grpFilter;
        break;
      case 'week':
        newFilters.week = grpFilter;
        break;
      case 'month':
        newFilters.month = grpFilter;
        break;
      case 'year':
        newFilters.year = grpFilter;
        break;
      case 'advertiser':
        newFilters.advertiserUuid = grpFilter;
        newFilters.advertiserLabel = grpName;
        break;
      case 'channel':
        newFilters.channelUuid = grpFilter;
        newFilters.channelLabel = grpName;
        break;
      case 'page':
        newFilters.pageUrl = grpFilter;
        break;
      case 'device':
        newFilters.device = grpFilter;
        break;
      case 'referer':
        newFilters.referer = grpFilter;
        break;
      case 'network':
        newFilters.networkUuid = grpFilter;
        newFilters.networkLabel = grpName;
        break;
      case 'account':
        newFilters.accountUuid = grpFilter;
        newFilters.accountLabel = grpName;
        break;
      case 'status':
        newFilters.status = grpFilter;
        break;
      case 'currency':
        newFilters.currency = grpFilter;
        break;
      default:
        break;
    }

    setGroupFilters(newFilters);
  };

  const handleSearch = (val: string) => {
    const searchedData = [...data.insights].filter((row) => row.grpName.toLowerCase().includes(val.toLowerCase()));
    const dataLength = searchedData.length;
    const currentPage = 1;
    const pageCount = Math.ceil(dataLength / PER_PAGE);
    const dataToRender = searchedData.slice(0, PER_PAGE);
    setCurrentPage(currentPage);
    setPageCount(pageCount);
    setDataToRender(dataToRender);
    setSearchValue(val);
  };

  const handleGroupChange = (newGroup: GroupByType) => {
    if (group !== newGroup) {
      setIsLoading(true);
      setGroup(newGroup);
    }
  };

  const setDate = (start: Date, end: Date) => {
    setDateObj({
      fromDate: format(start, 'yyyy-MM-dd'),
      toDate: format(end, 'yyyy-MM-dd'),
    });
    !isLoading && setIsLoading(true);
  };

  const removeFilter = (filterId: string) => {
    const str = filterId as keyof typeof groupFilters;
    setIsLoading(true);
    const newFilters = { ...groupFilters };
    newFilters[str] = '';
    if (filterId === 'advertiserUuid') {
      newFilters.advertiserLabel = '';
    }
    if (filterId === 'channelUuid') {
      newFilters.channelLabel = '';
    }
    if (filterId === 'networkUuid') {
      newFilters.networkLabel = '';
    }
    if (filterId === 'accountUuid') {
      newFilters.accountLabel = '';
    }
    setGroupFilters(newFilters);
  };

  return (
    <>
      <Grid container spacing={0} justify="space-between" alignItems="center">
        <Grid item xs={9}>
          <Typography variant="h5">Insights</Typography>
          <Typography gutterBottom color="textSecondary">
            Dive into your performance metrics
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Datepicker change={setDate} startDate={new Date()} endDate={new Date()} />
        </Grid>
      </Grid>
      <Grid container className={classes.tabsContainer}>
        <TabsContainer handleGroupChange={handleGroupChange} group={group} />
      </Grid>
      <ChartContainer data={graphData} isLoading={isLoading} group={group} />
      <Grid container className={classes.tabsContainer}>
        <FiltersContainer data={groupFilters} handleFilterClick={removeFilter} />
      </Grid>
      <Grid container className={classes.tabsContainer}>
        <Table
          sortBy={sortBy}
          sortDirection={sortDirection}
          handleSort={handleSort}
          isLoading={isLoading}
          currentPage={currentPage}
          pageCount={pageCount}
          handlePageChange={handlePageChange}
          data={dataToRender}
          totals={data.totals}
          handleFilterClick={handleFilterClick}
          handleSearch={handleSearch}
        />
      </Grid>
    </>
  );
}

export default WithMotion(Insights);
