import { Column } from '@material-table/core';
import { green, red } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import { Data } from './types';

export const columns: Array<Column<Data>> = [
  { title: 'ID', field: 'apiKeyUuid', cellStyle: { display: 'none' }, headerStyle: { display: 'none' } },
  { title: 'Name', field: 'apiKeyName' },
  { title: 'Description', field: 'apiKeyDescription' },
  { title: 'Public key', field: 'apiKeyPublicKey' },
  { title: 'Scope', field: 'apiKeyScope' },
  {
    title: 'Active',
    field: 'adminStatus',
    render: (row) =>
      row.apiKeyActiveStatus ? (
        <Icon style={{ color: green[400] }}>check_circle</Icon>
      ) : (
        <Icon style={{ color: red[400] }}>remove_circle</Icon>
      ),
  },
];

export const createDefaultValues = {
  apiKeyScope: 'connect',
  apiKeyName: '',
  apiKeyDescription: '',
  apiKeyActiveStatus: true,
};
