import React from 'react';
import { UpdateFormData as FormData, UpdateFormPropsType as PropsType } from './types';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import {
  DialogContent,
  Grid,
  TextField,
  Paper,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Divider,
  Chip,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useForm, Controller } from 'react-hook-form';
import { makeCall } from 'utils/utils';
import Switch from 'components/EnhancedSwitch';
import EnhancedTextField from 'components/EnhancedTextField';
import Clipboard from 'components/ClipboardV2';
import TrackerTable from './TrackerTable/index';
import CommissionRulesTable from 'pages/CommissionRule/Table';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
      marginBottom: theme.spacing(3),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
      color: '#fff',
    },
    chipsRoot: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginTop: theme.spacing(2),

      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Update({
  open,
  handleClose,
  refresh,
  record,
  categories,
  countries,
  currencies,
  accounts,
  medias,
}: PropsType) {
  const classes = useStyles();

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
  } = useForm<FormData>();

  React.useEffect(() => {
    if (record) {
      const selectedCountry = countries.filter((row) => row.countryCode === record.programCountryCode);
      const selectedCurrency = currencies.filter((row) => row.currencyCode === record.programCurrencyCode);
      const selectedCategory = categories.filter((row) => row.categoryKey === record.programCategoryKey);

      reset({
        programTitle: record.programTitle,
        programDescription: record.programDescription,
        programTerms: record.programTerms,
        programContactPerson: record.programContactPerson,
        programContactEmail: record.programContactEmail,
        programCategoryKey: selectedCategory.length ? selectedCategory[0] : null,
        programCountryCode: selectedCountry.length ? selectedCountry[0] : null,
        programCurrencyCode: selectedCurrency.length ? selectedCurrency[0] : null,
        programCashbackAllowed: record.programCashbackAllowed,
        programCouponSiteAllowed: record.programCouponSiteAllowed,
        programSocialAllowed: record.programSocialAllowed,
        programPpcAllowed: record.programPpcAllowed,
        programDisabled: record.programDisabled,
      });
    }
  }, [reset, record, countries, currencies, categories]);

  const onSubmit = async (data: FormData) => {
    const payload = {
      ...data,
      programCategoryKey: data.programCategoryKey?.categoryKey ?? '',
      programCountryCode: data.programCountryCode?.countryCode ?? '',
      programCurrencyCode: data.programCurrencyCode?.currencyCode ?? '',
    };
    await makeCall(`programs/${record?.programUuid}`, 'PUT', payload);
    handleClose();
    refresh();
  };

  return (
    <>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Update Program
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
              <Grid item xs={9}>
                <Paper variant="outlined" style={{ padding: '20px' }}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <EnhancedTextField
                        control={control}
                        fieldName="programTitle"
                        label="Title"
                        hasError={errors.programTitle ? true : false}
                        errorTxt="Title is required!"
                        disabled={record?.programImported}
                        autoFocus
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <EnhancedTextField
                        control={control}
                        fieldName="programDescription"
                        label="Description"
                        hasError={errors.programDescription ? true : false}
                        errorTxt="Description is required!"
                        disabled={record?.programImported}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <EnhancedTextField
                        control={control}
                        fieldName="programTerms"
                        label="Terms"
                        hasError={errors.programTerms ? true : false}
                        errorTxt="Terms is required!"
                        disabled={record?.programImported}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <EnhancedTextField
                        control={control}
                        fieldName="programContactPerson"
                        label="Contact person"
                        hasError={errors.programContactPerson ? true : false}
                        errorTxt="Contact person is required!"
                        disabled={record?.programImported}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <EnhancedTextField
                        control={control}
                        fieldName="programContactEmail"
                        label="Contact email"
                        hasError={errors.programContactEmail ? true : false}
                        errorTxt="Contact email is required!"
                        disabled={record?.programImported}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="programCategoryKey"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            id="category-select"
                            disabled={record?.programImported}
                            options={categories}
                            onChange={(_, data) => field.onChange(data)}
                            getOptionLabel={(option) => option.categoryName}
                            fullWidth
                            renderInput={(params) => (
                              <TextField
                                error={errors.programCategoryKey ? true : false}
                                helperText={errors.programCategoryKey ? 'Category is required!' : null}
                                {...params}
                                label="Category"
                                margin="dense"
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="programCountryCode"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            id="country-select"
                            disabled={record?.programImported}
                            options={countries}
                            onChange={(_, data) => field.onChange(data)}
                            getOptionLabel={(option) => option.countryName}
                            fullWidth
                            renderInput={(params) => (
                              <TextField
                                error={errors.programCountryCode ? true : false}
                                helperText={errors.programCountryCode ? 'Country is required!' : null}
                                {...params}
                                label="Country"
                                margin="dense"
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="programCurrencyCode"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            id="currency-select"
                            disabled={record?.programImported}
                            options={currencies}
                            onChange={(_, data) => field.onChange(data)}
                            getOptionLabel={(option) => option.currencyCode}
                            fullWidth
                            renderInput={(params) => (
                              <TextField
                                error={errors.programCurrencyCode ? true : false}
                                helperText={errors.programCurrencyCode ? 'Currency is required!' : null}
                                {...params}
                                label="Currency"
                                margin="dense"
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Switch
                        isChecked={!!record?.programCashbackAllowed}
                        control={control}
                        fieldName="programCashbackAllowed"
                        label="Cashback"
                        disabled={record?.programImported}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Switch
                        isChecked={!!record?.programCouponSiteAllowed}
                        control={control}
                        fieldName="programCouponSiteAllowed"
                        label="Coupon Site"
                        disabled={record?.programImported}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Switch
                        isChecked={!!record?.programSocialAllowed}
                        control={control}
                        fieldName="programSocialAllowed"
                        label="Social"
                        disabled={record?.programImported}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Switch
                        isChecked={!!record?.programPpcAllowed}
                        control={control}
                        fieldName="programPpcAllowed"
                        label="PPC"
                        disabled={record?.programImported}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Switch
                        isChecked={!!record?.programDisabled}
                        control={control}
                        fieldName="programDisabled"
                        label="Disable"
                      />
                    </Grid>
                    <Grid item xs={10}></Grid>
                    <Grid item xs={2}>
                      <Button disabled={isSubmitting} type="submit" color="primary" variant="contained" fullWidth>
                        {isSubmitting ? 'Updating...' : 'Update'}
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper variant="outlined" style={{ padding: '20px' }}>
                  <Typography variant="h6" color="primary">
                    Program information
                  </Typography>
                  <Divider />
                  <div className={classes.chipsRoot}>
                    <Clipboard id={record?.programUuid ?? ''} />
                    <Chip
                      variant="outlined"
                      label={
                        <Typography variant="subtitle2" color="primary">
                          Advertiser: {record?.merchant?.merchantUrl ?? '-'}
                        </Typography>
                      }
                      color="primary"
                    />
                    <Chip
                      variant="outlined"
                      label={
                        <Typography variant="subtitle2" color="primary">
                          network: {record?.network?.networkName ?? '-'}
                        </Typography>
                      }
                      color="primary"
                    />
                    <Chip
                      variant="outlined"
                      label={
                        <Typography variant="subtitle2" color="primary">
                          API Identifier: {record?.programApiIdentifier ?? '-'}
                        </Typography>
                      }
                      color="primary"
                    />
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </form>
          {record && (
            <>
              <Grid container style={{ marginTop: '10px' }}>
                <Grid item xs={12}>
                  <TrackerTable
                    programUuid={record.programUuid}
                    programTitle={record.programTitle}
                    accounts={accounts}
                    countries={countries}
                    currencies={currencies}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: '10px' }}>
                <Grid item xs={12}>
                  <CommissionRulesTable
                    searchOptions={{ commissionRuleProgramUuid: record.programUuid }}
                    shouldCreateDisabled={false}
                    options={{
                      program: { programTitle: record.programTitle, programUuid: record.programUuid },
                      merchant: {
                        merchantUrl: record.merchant.merchantUrl,
                        merchantUuid: record.merchant.merchantUuid,
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
