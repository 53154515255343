import { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useForm, Controller } from 'react-hook-form';
import { makeCall } from 'utils/utils';
import EnhancedTextField from 'components/EnhancedTextField';
import EnhancedSwitch from 'components/EnhancedSwitch';
import { createDefaultValues as defaultValues } from './initialData';
import { CreateFormData as FormData, CreateFormPropsType as PropsType, Tracker, Program } from '../types';
import { Account, Media, Merchant } from 'types/global';
import { debounce } from 'lodash';

export default function Create({ open, handleClose, refresh, options }: PropsType) {
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [medias, setMedias] = useState<Media[]>([]);
  const [merchants, setMerchants] = useState<Merchant[]>([]);
  const [trackers, setTrackers] = useState<Tracker[]>([]);
  const [programs, setPrograms] = useState<Program[]>([]);

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    setValue,
    reset,
    getValues,
  } = useForm<FormData>({ defaultValues });

  useEffect(() => {
    reset({
      ...defaultValues,
      commissionRuleAccountUuid: options?.account ?? null,
      commissionRuleMediaUuid: options?.media ?? null,
      commissionRuleMerchantUuid: options?.merchant ?? null,
      commissionRuleProgramUuid: options?.program ?? null,
      commissionRuleTrackerUuid: options?.tracker ?? null,
    });
  }, [options, reset]);

  const resetForm = () => {
    reset({
      ...defaultValues,
      commissionRuleAccountUuid: options?.account ?? null,
      commissionRuleMediaUuid: options?.media ?? null,
      commissionRuleMerchantUuid: options?.merchant ?? null,
      commissionRuleProgramUuid: options?.program ?? null,
      commissionRuleTrackerUuid: options?.tracker ?? null,
    });
    handleClose();
  };

  const onSubmit = async (data: FormData) => {
    const payload = {
      ...data,
      commissionRuleAccountUuid: data.commissionRuleAccountUuid?.accountUuid ?? '',
      commissionRuleMediaUuid: data.commissionRuleMediaUuid?.mediaUuid ?? '',
      commissionRuleMerchantUuid: data.commissionRuleMerchantUuid?.merchantUuid ?? '',
      commissionRuleProgramUuid: data.commissionRuleProgramUuid?.programUuid ?? '',
      commissionRuleTrackerUuid: data.commissionRuleTrackerUuid?.trackerUuid ?? '',
    };
    await makeCall(`commissionRules`, 'POST', payload);
    reset(defaultValues);
    handleClose();
    refresh();
  };

  const getAccounts = (val: string) => {
    if (val.length >= 3) {
      debounce(async () => {
        const payload = { search: val };
        const response = await makeCall(`accounts`, 'GET', payload);
        if (response?.data.data.length) {
          setAccounts(response?.data.data);
        }
      }, 300)();
    }
  };

  const getMedias = (val: string) => {
    if (val.length >= 3 && getValues('commissionRuleAccountUuid')) {
      debounce(async () => {
        const payload = { search: val, mediaAccountUuid: getValues('commissionRuleAccountUuid')?.accountUuid };
        const response = await makeCall(`medias`, 'GET', payload);
        if (response?.data.data.length) {
          setMedias(response?.data.data);
        }
      }, 300)();
    }
  };

  const getMerchants = (val: string) => {
    if (val.length >= 3) {
      debounce(async () => {
        const payload = { search: val };
        const response = await makeCall(`merchants`, 'GET', payload);
        if (response?.data.data.length) {
          setMerchants(response?.data.data);
        }
      }, 300)();
    }
  };

  const getPrograms = (val: string) => {
    if (val.length >= 3 && getValues('commissionRuleMerchantUuid')) {
      debounce(async () => {
        const payload = { search: val, programMerchantUuid: getValues('commissionRuleMerchantUuid')?.merchantUuid };
        const response = await makeCall(`programs`, 'GET', payload);
        if (response?.data.data.length) {
          setPrograms(response?.data.data);
        }
      }, 300)();
    }
  };

  const getTrackers = (val: string) => {
    if (val.length >= 3 && getValues('commissionRuleMerchantUuid') && getValues('commissionRuleProgramUuid')) {
      debounce(async () => {
        const payload = {
          search: val,
          trackerMerchantUuid: getValues('commissionRuleMerchantUuid')?.merchantUuid,
          trackerProgramUuid: getValues('commissionRuleProgramUuid')?.programUuid,
        };
        const response = await makeCall(`trackers`, 'GET', payload);
        if (response?.data.data.length) {
          setTrackers(response?.data.data);
        }
      }, 300)();
    }
  };

  return (
    <>
      <Dialog
        open={open}
        disableBackdropClick={true}
        onEscapeKeyDown={resetForm}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">Create New commission rule</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={8}>
                <EnhancedTextField
                  control={control}
                  fieldName="commissionRuleName"
                  label="Rule name"
                  hasError={errors.commissionRuleName ? true : false}
                  errorTxt="Rule name is required!"
                />
              </Grid>
              <Grid item xs={4}>
                <EnhancedTextField
                  control={control}
                  fieldName="commissionRuleValue"
                  label="Commission %"
                  hasError={errors.commissionRuleValue ? true : false}
                  errorTxt="Commission % is required!"
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="commissionRuleAccountUuid"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="account-select"
                      options={accounts}
                      onChange={(_, data) => {
                        field.onChange(data);
                        setValue('commissionRuleMediaUuid', null);
                        setMedias([]);
                      }}
                      getOptionLabel={(option) => option.accountName}
                      disabled={!!options?.account}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          error={errors.commissionRuleAccountUuid ? true : false}
                          helperText={errors.commissionRuleAccountUuid ? 'Account is required!' : null}
                          {...params}
                          onChange={(e) => getAccounts(e.target.value)}
                          label="Account"
                          margin="dense"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="commissionRuleMediaUuid"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="media-select"
                      options={medias}
                      onChange={(_, data) => field.onChange(data)}
                      getOptionLabel={(option) => option.mediaUrl}
                      disabled={!!options?.media}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={(e) => getMedias(e.target.value)}
                          label="Media"
                          margin="dense"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="commissionRuleMerchantUuid"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="merchant-select"
                      options={merchants}
                      onChange={(_, data) => {
                        field.onChange(data);
                        setValue('commissionRuleProgramUuid', null);
                        setValue('commissionRuleTrackerUuid', null);
                        setPrograms([]);
                        setTrackers([]);
                      }}
                      getOptionLabel={(option) => option.merchantUrl}
                      disabled={!!options?.merchant}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={(e) => getMerchants(e.target.value)}
                          label="Merchant"
                          margin="dense"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="commissionRuleProgramUuid"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="program-select"
                      options={programs}
                      onChange={(_, data) => {
                        field.onChange(data);
                        setValue('commissionRuleTrackerUuid', null);
                        setTrackers([]);
                      }}
                      getOptionLabel={(option) => option.programTitle}
                      disabled={!!options?.program}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={(e) => getPrograms(e.target.value)}
                          label="Program"
                          margin="dense"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="commissionRuleTrackerUuid"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="tracker-select"
                      options={trackers}
                      onChange={(_, data) => field.onChange(data)}
                      getOptionLabel={(option) => option.trackerName}
                      disabled={!!options?.tracker}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={(e) => getTrackers(e.target.value)}
                          label="Tracker"
                          margin="dense"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <EnhancedSwitch isChecked={true} control={control} fieldName="commissionRuleStatus" label="Status" />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={resetForm} color="primary">
              Cancel
            </Button>
            <Button disabled={isSubmitting} type="submit" color="primary">
              {isSubmitting ? 'Creating...' : 'Create'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
