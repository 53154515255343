import {
  Typography,
  Paper,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { Partner, Advertiser } from '../../../types';
import DotDot from 'react-dotdotdot';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  tableContainer: {
    maxHeight: '310px',
    minHeight: '310px',
  },
  noData: {
    display: 'block',
    width: '100%',
    fontSize: '14px',
    margin: '100px 0',
    textAlign: 'center',
    color: '#555',
  },
  tableCell: {
    borderBottom: 'none',
  },
  text: {
    width: 90,
    fontSize: 12,
  },
}));

type PropsType = {
  title: string;
  isLoading: boolean;
  data: Partner[] | Advertiser[] | undefined;
};

export default function BestPerformingTable({ title, isLoading, data }: PropsType) {
  const classes = useStyles();
  const getValue = (val: number) => {
    const formatter = new Intl.NumberFormat('da-DK');
    return `DKK ${formatter.format(val)}`;
  };
  return (
    <Paper variant="outlined" className={classes.root}>
      <Typography variant="subtitle2" color="primary" className={classes.title}>
        Best Performing {title}
      </Typography>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>{title}</TableCell>
              <TableCell>Heylink</TableCell>
              <TableCell>Partner</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              [1, 2, 3, 4, 5].map((row) => (
                <TableRow key={row}>
                  {[10, 20, 30, 40].map((cell) => (
                    <TableCell key={cell} className={classes.tableCell}>
                      <Skeleton animation="wave" />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : data && data.length ? (
              data.map((row, idx) => (
                <TableRow
                  key={row.uuid}
                  style={idx % 2 ? { background: 'rgba(0, 0, 150, 0.025)' } : { background: 'white' }}
                >
                  <TableCell className={classes.tableCell}>
                    <div className={classes.text}>
                      <DotDot clamp={1}>{row.name}</DotDot>
                    </div>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <div className={classes.text}>{getValue(row.heylinkCommissionSum)}</div>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <div className={classes.text}>{getValue(row.partnerCommissionSum)}</div>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <div className={classes.text}>{getValue(row.totalCommissionSum)}</div>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4}>
                  <Typography variant="subtitle1" className={classes.noData}>
                    No {title} Found
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
