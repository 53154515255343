import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Grid, Paper, TextField, Button } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { Network, Merchant, Media, Account, Country, Currency, FiltersFormData as FormData } from './types';
import { filterDefaultValues as defaultValues } from './initial';
import Switch from 'components/EnhancedSwitch';

interface PropsType {
  networks: Network[];
  merchants: Merchant[];
  medias: Media[];
  accounts: Account[];
  countries: Country[];
  currencies: Currency[];
  handleFiltersSubmit: (payload: any) => void;
}

export default function FiltersForm({
  networks,
  merchants,
  medias,
  accounts,
  countries,
  currencies,
  handleFiltersSubmit,
}: PropsType) {
  const { handleSubmit, control } = useForm<FormData>({
    defaultValues,
  });

  const onSubmit = (data: FormData) => {
    const payload: any = {
      trackerStatus: data.trackerStatus,
      trackerDisabled: data.trackerDisabled,
      trackerIsDealStatus: data.trackerIsDealStatus,
    };
    if (data.trackerCountryCode) {
      payload.trackerCountryCode = data.trackerCountryCode.countryCode;
    }
    if (data.trackerCurrencyCode) {
      payload.trackerCurrencyCode = data.trackerCurrencyCode.currencyCode;
    }
    if (data.trackerNetworkUuid) {
      payload.trackerNetworkUuid = data.trackerNetworkUuid.networkUuid;
    }
    if (data.trackerMerchantUuid) {
      payload.trackerMerchantUuid = data.trackerMerchantUuid.merchantUuid;
    }
    if (data.trackerMediaUuid) {
      payload.trackerMediaUuid = data.trackerMediaUuid.mediaUuid;
    }
    if (data.trackerAccountUuid) {
      payload.trackerAccountUuid = data.trackerAccountUuid.accountUuid;
    }
    handleFiltersSubmit(payload);
  };

  return (
    <Paper variant="outlined" style={{ padding: '10px 20px' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3} alignItems="center" justify="center">
          <Grid item xs={3}>
            <Controller
              name="trackerNetworkUuid"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  id="network-select"
                  options={networks}
                  onChange={(_, data) => field.onChange(data)}
                  getOptionLabel={(option) => option.networkName}
                  fullWidth
                  renderInput={(params) => <TextField {...params} label="Network" margin="dense" variant="outlined" />}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name="trackerMerchantUuid"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  id="merchant-select"
                  options={merchants}
                  onChange={(_, data) => field.onChange(data)}
                  getOptionLabel={(option) => option.merchantUrl}
                  fullWidth
                  renderInput={(params) => <TextField {...params} label="Merchant" margin="dense" variant="outlined" />}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name="trackerMediaUuid"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  id="media-select"
                  options={medias}
                  onChange={(_, data) => field.onChange(data)}
                  getOptionLabel={(option) => option.mediaUrl}
                  fullWidth
                  renderInput={(params) => <TextField {...params} label="Media" margin="dense" variant="outlined" />}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name="trackerAccountUuid"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  id="account-select"
                  options={accounts}
                  onChange={(_, data) => field.onChange(data)}
                  getOptionLabel={(option) => option.accountName}
                  fullWidth
                  renderInput={(params) => <TextField {...params} label="Account" margin="dense" variant="outlined" />}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name="trackerCountryCode"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  id="country-select"
                  options={countries}
                  onChange={(_, data) => field.onChange(data)}
                  getOptionLabel={(option) => option.countryName}
                  fullWidth
                  renderInput={(params) => <TextField {...params} label="Country" margin="dense" variant="outlined" />}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name="trackerCurrencyCode"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  id="currency-select"
                  options={currencies}
                  onChange={(_, data) => field.onChange(data)}
                  getOptionLabel={(option) => option.currencyCode}
                  fullWidth
                  renderInput={(params) => <TextField {...params} label="Currency" margin="dense" variant="outlined" />}
                />
              )}
            />
          </Grid>
          <Grid item xs={4} container>
            <Grid item xs={4}>
              <Switch control={control} isChecked={true} fieldName="trackerStatus" label="Status" />
            </Grid>
            <Grid item xs={4}>
              <Switch control={control} isChecked={false} fieldName="trackerDisabled" label="Disabled" />
            </Grid>
            <Grid item xs={4}>
              <Switch control={control} isChecked={false} fieldName="trackerIsDealStatus" label="Deal Status" />
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Button variant="contained" fullWidth type="submit" color="primary">
              Search
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}
