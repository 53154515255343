import { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { makeCall } from 'utils/utils';
import { UpdateFormData as FormData, UpdateFormPropsType as PropsType } from './types';
import EnhancedTextField from 'components/EnhancedTextField';
import EnhancedSwitch from 'components/EnhancedSwitch';
import Clipboard from 'components/ClipboardV2';

export default function Update({ open, handleClose, refresh, record }: PropsType) {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
  } = useForm<FormData>();

  useEffect(() => {
    if (record) {
      reset({
        trackerMediaExcludeDomain: record.trackerMediaExcludeDomain,
        trackerMediaExcludeUrl: record.trackerMediaExcludeUrl,
        trackerMediaExcludeStatus: record.trackerMediaExcludeStatus,
      });
    }
  }, [reset, record]);

  const onSubmit = async (data: FormData) => {
    await makeCall(`trackers/trackerMediaExcludes/${record?.trackerMediaExcludeUuid}`, 'PUT', data);
    close();
    refresh();
  };
  const close = () => {
    handleClose();
  };
  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        disableBackdropClick={true}
        onClose={close}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Update Tracker Media Exclude</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Clipboard id={record?.trackerMediaExcludeUuid ?? ''} />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <EnhancedTextField
                  control={control}
                  fieldName="trackerMediaExcludeDomain"
                  label="Domain"
                  hasError={errors.trackerMediaExcludeDomain ? true : false}
                  errorTxt="Domain is required!"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <EnhancedTextField
                  control={control}
                  fieldName="trackerMediaExcludeUrl"
                  label="URL"
                  hasError={errors.trackerMediaExcludeUrl ? true : false}
                  errorTxt="URL is required!"
                />
              </Grid>
              <Grid item xs={4}>
                <EnhancedSwitch
                  isChecked={!!record?.trackerMediaExcludeStatus}
                  control={control}
                  fieldName="trackerMediaExcludeStatus"
                  label="Status"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={close} color="primary">
              Cancel
            </Button>
            <Button disabled={isSubmitting} type="submit" color="primary">
              {isSubmitting ? 'Updating...' : 'Update'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
