import React from 'react';
import { Grid, Avatar, Icon } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Totals } from '../../../types';
import SingleCard from './Card';

type Props = {
  totals: Totals | undefined;
  isLoading: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  baseAvatar: {
    marginLeft: '16px',
  },
  pageviews: {
    color: theme.pageviews.primary,
    backgroundColor: theme.pageviews.secondary,
  },
  clicks: {
    color: theme.clicks.primary,
    backgroundColor: theme.clicks.secondary,
  },
  conversions: {
    color: theme.conversions.primary,
    backgroundColor: theme.conversions.secondary,
  },
  commission: {
    color: theme.commission.primary,
    backgroundColor: theme.commission.secondary,
  },
  customAvatar: {
    color: theme.palette.primary.light,
    backgroundColor: 'rgba(0,0,0,0.03)',
  },
}));

export default function CardsContainer({ totals, isLoading }: Props) {
  const classes = useStyles();
  const formatter = new Intl.NumberFormat('da-DK');
  return (
    <>
      <Grid className={classes.root} container spacing={3} justify="space-between" alignItems="center">
        <SingleCard
          isLoading={isLoading}
          title="Pageviews"
          value={formatter.format(totals?.counts.pageviewsCount ?? 0)}
          subText="EPM"
          subValue={`DKK ${formatter.format(totals?.performanceIndex.epm ?? 0)}`}
          avatar={
            <Avatar className={[classes.baseAvatar, classes.customAvatar].join(' ')}>
              <Icon>pageviews</Icon>
            </Avatar>
          }
          tooltip={
            <span>
              <div>Earnings Per Mille (Thousand)</div>
              <span>{formatter.format(totals?.sums.totalCommissionSum ?? 0)}</span> /
              <span>{formatter.format(totals?.counts.pageviewsCount ?? 0)}</span> ={' '}
              {`DKK ${formatter.format(totals?.performanceIndex.epm ?? 0)}`}
            </span>
          }
        />
        <SingleCard
          isLoading={isLoading}
          title="Discoveries"
          value={formatter.format(totals?.counts.discoversCount ?? 0)}
          avatar={
            <Avatar className={[classes.baseAvatar, classes.customAvatar].join(' ')}>
              <Icon>bookmarks</Icon>
            </Avatar>
          }
        />
        <SingleCard
          isLoading={isLoading}
          title="Clicks"
          value={formatter.format(totals?.counts.clicksCount ?? 0)}
          subText="CTR"
          subValue={`${formatter.format(totals?.performanceIndex.ctr ?? 0)}%`}
          avatar={
            <Avatar className={[classes.baseAvatar, classes.customAvatar].join(' ')}>
              <Icon>mouse</Icon>
            </Avatar>
          }
          tooltip={
            <span>
              <div>Click Through Rate</div>
              <span>{formatter.format(totals?.counts.clicksCount ?? 0)}</span> /
              <span>{formatter.format(totals?.counts.pageviewsCount ?? 0)}</span> ={' '}
              {`${formatter.format(totals?.performanceIndex.ctr ?? 0)}%`}
            </span>
          }
        />
        <SingleCard
          isLoading={isLoading}
          title="Conversions"
          value={formatter.format(totals?.counts.conversionsCount ?? 0)}
          subText="CR"
          subValue={`${formatter.format(totals?.performanceIndex.cr ?? 0)}%`}
          avatar={
            <Avatar className={[classes.baseAvatar, classes.customAvatar].join(' ')}>
              <Icon>compare_arrows</Icon>
            </Avatar>
          }
          tooltip={
            <span>
              <div>Conversion Rate</div>
              <span>{formatter.format(totals?.counts.conversionsCount ?? 0)}</span> /
              <span>{formatter.format(totals?.counts.clicksCount ?? 0)}</span> ={' '}
              {`${formatter.format(totals?.performanceIndex.cr ?? 0)}%`}
            </span>
          }
        />
      </Grid>
      <Grid className={classes.root} container spacing={3} justify="space-between" alignItems="center">
        <SingleCard
          isLoading={isLoading}
          title="Sales Amount"
          value={`DKK ${formatter.format(totals?.sums.saleSum ?? 0)}`}
          avatar={
            <Avatar className={[classes.baseAvatar, classes.customAvatar].join(' ')}>
              <Icon>receipt</Icon>
            </Avatar>
          }
        />
        <SingleCard
          isLoading={isLoading}
          title="Partner Commission"
          value={`DKK ${formatter.format(totals?.sums.partnerCommissionSum ?? 0)}`}
          avatar={
            <Avatar className={[classes.baseAvatar, classes.customAvatar].join(' ')}>
              <Icon>account_balance</Icon>
            </Avatar>
          }
        />
        <SingleCard
          isLoading={isLoading}
          title="Heylink Commission"
          value={`DKK ${formatter.format(totals?.sums.heylinkCommissionSum ?? 0)}`}
          avatar={
            <Avatar className={[classes.baseAvatar, classes.customAvatar].join(' ')}>
              <Icon>monetization_on</Icon>
            </Avatar>
          }
        />
        <SingleCard
          isLoading={isLoading}
          title="Total Commission"
          value={`DKK ${formatter.format(totals?.sums.totalCommissionSum ?? 0)}`}
          subText="EPC"
          subValue={`DKK ${formatter.format(totals?.performanceIndex.epc ?? 0)}`}
          avatar={
            <Avatar className={[classes.baseAvatar, classes.customAvatar].join(' ')}>
              <Icon>attach_money</Icon>
            </Avatar>
          }
          tooltip={
            <span>
              <div>Earnings Per Click</div>
              <span>{formatter.format(totals?.sums.totalCommissionSum ?? 0)}</span> /
              <span>{formatter.format(totals?.counts.clicksCount ?? 0)}</span> ={' '}
              {`DKK ${formatter.format(totals?.performanceIndex.epc ?? 0)}`}
            </span>
          }
        />
      </Grid>
    </>
  );
}
