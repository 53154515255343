import { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { Grid, FormControlLabel, Switch } from '@material-ui/core';
import { makeCall } from 'utils/utils';
import { tableContainerStyles as useStyles } from 'styles/common';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import WithMotion from 'components/withMotion';
import { Data } from './types';
import { columns } from './initialData';
import Datepicker from 'components/DatepickerV2';

function Callbacks() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Data[]>([]);
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [onlyErrors, setOnlyErrors] = useState(false);
  const [dateObj, setDateObj] = useState({
    fromDate: format(new Date(), 'yyyy-MM-dd'),
    toDate: format(new Date(), 'yyyy-MM-dd'),
  });

  useEffect(() => {
    if (shouldRefresh) {
      makeCall(
        `callbacks?startDate=${dateObj.fromDate}&endDate=${dateObj.toDate}&callbackError=${onlyErrors}`,
        'GET'
      ).then((res) => {
        console.log(res?.data.data);
        setData(res?.data.data);
        setShouldRefresh(false);
        setIsLoading(false);
      });
    }
  }, [shouldRefresh, dateObj, onlyErrors]);

  const setDate = (start: Date, end: Date) => {
    setDateObj({
      fromDate: format(start, 'yyyy-MM-dd'),
      toDate: format(end, 'yyyy-MM-dd'),
    });

    setIsLoading(true);
    setShouldRefresh(true);
  };

  return (
    <>
      <Grid container spacing={3} justify="flex-end">
        <Grid item xs={2}>
          <FormControlLabel
            control={
              <Switch
                checked={onlyErrors}
                onChange={(e) => {
                  setOnlyErrors(e.target.checked);
                  setShouldRefresh(true);
                  setIsLoading(true);
                }}
                color="primary"
              />
            }
            label="Show Only Errors"
          />
        </Grid>
        <Grid item xs={3}>
          <Datepicker change={setDate} startDate={new Date()} endDate={new Date()} />
        </Grid>
      </Grid>
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <MaterialTable
            title="Callbacks"
            components={{
              Toolbar: (props) => (
                <div className={classes.toolbarWrapper}>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={{
              search: true,
              pageSize: 10,
              actionsColumnIndex: 9,
              actionsCellStyle: { color: '#666' },
            }}
            isLoading={isLoading}
            columns={columns}
            data={data}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default WithMotion(Callbacks);
