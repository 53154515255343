import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Partner, Advertiser } from '../../../types';
import TableContainer from './Table';

type PropsType = {
  isLoading: boolean;
  partners: Partner[] | undefined;
  advertisers: Advertiser[] | undefined;
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
}));

export default function TopPerformers({ isLoading, partners, advertisers }: PropsType) {
  const classes = useStyles();

  return (
    <Grid spacing={3} container className={classes.root}>
      <Grid item xs={6}>
        <TableContainer title="Partners" isLoading={isLoading} data={partners} />
      </Grid>
      <Grid item xs={6}>
        <TableContainer title="Advertisers" isLoading={isLoading} data={advertisers} />
      </Grid>
    </Grid>
  );
}
