import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  Divider,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { makeCall } from 'utils/utils';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createDefaultValues as defaultValues } from './initialData';
import { CreateFormData as FormData, CreateFormPropsType as PropsType } from './types';
import { useForm, Controller } from 'react-hook-form';
import EnhancedTextField from 'components/EnhancedTextField';
import Datepicker from 'components/DatepickerV2';
import Calendar from 'components/Calendar';
import { endOfMonth, format, startOfMonth } from 'date-fns';

const formatter = new Intl.NumberFormat('da-DK');

type paymentNoteDetails = {
  currencyCode: string;
  conversionsCount: number;
  amountSum: number;
  commissionSum: number;
  cutSum: number;
};
type ReportType = {
  paymentNoteSums: {
    amountSum: number;
    commissionSum: number;
    saleSum: number;
    cutSum: number;
    currencyCode: string;
  };
  paymentNoteDetails: paymentNoteDetails[];
};

export default function Create({ open, handleClose, refresh, networks, currencies }: PropsType) {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
    getValues,
  } = useForm<FormData>({ defaultValues });

  const [paymentNoteDate, setPaymentNoteDate] = React.useState(format(new Date(), 'yyyy-MM-dd'));
  const [dateObj, setDateObj] = React.useState({
    fromDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
    toDate: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
  });
  const [report, setReport] = React.useState<ReportType | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);

  const setDate = (start: Date, end: Date) => {
    setDateObj({
      fromDate: format(start, 'yyyy-MM-dd'),
      toDate: format(end, 'yyyy-MM-dd'),
    });
    handleFetchReport();
  };

  const handlePaymentNoteChange = (date: Date) => {
    setPaymentNoteDate(format(date, 'yyyy-MM-dd'));
  };

  const handleFetchReport = () => {
    const networkUuid = getValues('paymentNoteNetworkUuid')?.networkUuid;
    const currencyCode = getValues('paymentNoteCurrencyCode')?.currencyCode;
    const { fromDate, toDate } = dateObj;

    if (networkUuid && currencyCode && fromDate && toDate) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const fetchReport = async () => {
    const payload = {
      paymentNoteCurrencyCode: getValues('paymentNoteCurrencyCode')?.currencyCode,
      paymentNoteNetworkUuid: getValues('paymentNoteNetworkUuid')?.networkUuid,
      paymentNotePeriodStartDate: dateObj.fromDate,
      paymentNotePeriodEndDate: dateObj.toDate,
    };
    setIsLoading(true);

    const response = await makeCall('finance/networks/paymentnotes/report', 'POST', payload);
    setReport(response?.data.data);
    setIsLoading(false);
  };

  const resetForm = () => {
    reset(defaultValues);
    setDateObj({
      fromDate: format(new Date(), 'yyyy-MM-dd'),
      toDate: format(new Date(), 'yyyy-MM-dd'),
    });
    setPaymentNoteDate(format(new Date(), 'yyyy-MM-dd'));
    setReport(null);
    setIsLoading(false);
    setIsValid(false);
    handleClose();
  };

  const onSubmit = async (data: FormData) => {
    const payload = {
      ...data,
      paymentNoteDate,
      paymentNotePeriodStartDate: dateObj.fromDate,
      paymentNotePeriodEndDate: dateObj.toDate,
      paymentNoteCurrencyCode: data.paymentNoteCurrencyCode?.currencyCode,
      paymentNoteNetworkUuid: data.paymentNoteNetworkUuid?.networkUuid,
    };
    const response = await makeCall('finance/networks/paymentnotes', 'POST', payload);
    resetForm();
    refresh(response?.data.data);
  };

  return (
    <>
      <Dialog
        open={open}
        disableBackdropClick={true}
        onEscapeKeyDown={resetForm}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">Create Payment note</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent style={{ minHeight: 500 }}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Typography variant="body1" style={{ color: 'rgba(0, 0, 0, 0.54)', paddingLeft: 5, paddingBottom: 5 }}>
                  Payment note date
                </Typography>
                <div style={{ border: '1px solid rgba(0, 0, 0, 0.2)', padding: '4px', borderRadius: '5px' }}>
                  <Calendar setDate={handlePaymentNoteChange} />
                </div>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" style={{ color: 'rgba(0, 0, 0, 0.54)', paddingLeft: 5, paddingBottom: 5 }}>
                  Period range selection
                </Typography>
                <div style={{ border: '1px solid rgba(0, 0, 0, 0.2)', padding: '4px', borderRadius: '5px' }}>
                  <Datepicker change={setDate} startDate={startOfMonth(new Date())} endDate={endOfMonth(new Date())} />
                </div>
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="paymentNoteNetworkUuid"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="network-select"
                      options={networks}
                      onChange={(_, data) => {
                        field.onChange(data);
                        handleFetchReport();
                      }}
                      getOptionLabel={(option) => option.networkName}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          error={errors.paymentNoteNetworkUuid ? true : false}
                          helperText={errors.paymentNoteNetworkUuid ? 'Network is required!' : null}
                          {...params}
                          autoFocus
                          label="Network"
                          margin="dense"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="paymentNoteCurrencyCode"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="currency-select"
                      options={currencies}
                      onChange={(_, data) => {
                        field.onChange(data);
                        handleFetchReport();
                      }}
                      getOptionLabel={(option) => option.currencyCode}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          error={errors.paymentNoteCurrencyCode ? true : false}
                          helperText={errors.paymentNoteCurrencyCode ? 'Currency is required!' : null}
                          {...params}
                          label="Currency"
                          margin="dense"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <EnhancedTextField
                  control={control}
                  fieldName="paymentNoteIdentifier"
                  label="Identifier"
                  hasError={errors.paymentNoteIdentifier ? true : false}
                  errorTxt="Identifier is required!"
                />
              </Grid>
              <Grid item xs={4}>
                <EnhancedTextField
                  control={control}
                  fieldName="paymentNoteSubTotal"
                  label="Subtotal"
                  hasError={errors.paymentNoteSubTotal ? true : false}
                  errorTxt="Subtotal is required!"
                />
              </Grid>
              <Grid item xs={4}>
                <EnhancedTextField
                  control={control}
                  fieldName="paymentNoteVat"
                  label="VAT"
                  hasError={errors.paymentNoteVat ? true : false}
                  errorTxt="VAT is required!"
                />
              </Grid>
              <Grid item xs={10}></Grid>
              <Grid item xs={2}>
                <Button
                  onClick={fetchReport}
                  disabled={!isValid || isLoading}
                  fullWidth
                  variant="outlined"
                  color="primary"
                >
                  {isLoading ? 'Getting report...' : 'Get report'}
                </Button>
              </Grid>
            </Grid>
            {report && (
              <>
                <Grid container>
                  <Grid item xs={12}>
                    <Divider style={{ margin: '25px 0', width: '100%' }} />
                  </Grid>
                  <Grid item xs={12}>
                    <Chip
                      variant="default"
                      label={
                        <Typography variant="subtitle2" style={{ color: '#fff' }}>
                          Sum: {formatter.format(report?.paymentNoteSums.amountSum ?? 0)}{' '}
                          {report?.paymentNoteSums.currencyCode ?? '-'}
                        </Typography>
                      }
                      style={{ marginLeft: 10 }}
                      color="primary"
                    />
                  </Grid>
                </Grid>
                <Grid container style={{ margin: '20px 0' }}>
                  <Typography variant="h6" color="primary" style={{ margin: '0 18px' }}>
                    Details
                  </Typography>
                  <TableContainer>
                    <Table size="small" aria-label="details-table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Currency</TableCell>
                          <TableCell align="right">Conversions</TableCell>
                          <TableCell align="right">Amount</TableCell>
                          <TableCell align="right">Commission</TableCell>
                          <TableCell align="right">Cut</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {report?.paymentNoteDetails.map((row, idx) => (
                          <TableRow key={idx}>
                            <TableCell component="th" scope="row">
                              {row.currencyCode}
                            </TableCell>
                            <TableCell align="right">{formatter.format(row.conversionsCount)}</TableCell>
                            <TableCell align="right">{formatter.format(row.amountSum)}</TableCell>
                            <TableCell align="right">{formatter.format(row.commissionSum)}</TableCell>
                            <TableCell align="right">{formatter.format(row.cutSum)}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={resetForm} color="primary">
              Cancel
            </Button>
            <Button disabled={isSubmitting} type="submit" color="primary">
              {isSubmitting ? 'Creating...' : 'Create'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
