import { useState, useEffect } from 'react';
import { Media, MediaValueType } from 'types/global';
import { Grid, Typography, Paper, TextField, Button, Divider } from '@material-ui/core';
import { makeCall } from 'utils/utils';
import WithMotion from 'components/withMotion';
import Autocomplete from '@material-ui/lab/Autocomplete';
import EnhancedTextField from 'components/EnhancedTextField';
import { useForm, Controller } from 'react-hook-form';
import EnhancedSwitch from 'components/EnhancedSwitch';
import { green, red } from '@material-ui/core/colors';
import axios from 'axios';

type FormData = {
  mediaUuid: MediaValueType;
  targetUrl: string;
  pageUrl: string;
  query: string;
  utm: boolean;
};

const defaultValues: FormData = {
  mediaUuid: null,
  targetUrl: '',
  pageUrl: '',
  query: '',
  utm: false,
};

function LinkGenerator() {
  const [link, setLink] = useState('');
  const [error, setError] = useState('');
  const [isCopied, setIsCopied] = useState(false);
  const [medias, setMedias] = useState<Media[]>([]);

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
  } = useForm<FormData>({ defaultValues });

  useEffect(() => {
    makeCall('medias', 'GET').then((res) => setMedias(res?.data.data));
  }, []);

  const onGenerate = async (props: FormData) => {
    setIsCopied(false);
    setError('');
    setLink('');
    const ERROR_MESSAGE = 'No tracking link generated!';
    try {
      const request = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_TARGETS_URL}${props.mediaUuid?.mediaUuid}`,
        data: {
          urls: [props.targetUrl],
          pageUrl: props.pageUrl || '',
        },
      });
      if (request.data.data.length && request.data.data[0].go) {
        let BASE_URL = request.data.data[0].go;
        if (props.pageUrl && props.query) {
          BASE_URL += `&${props.query}`;
        }
        if (props.utm) {
          BASE_URL += '&utm=1';
        }
        setLink(BASE_URL);
      } else {
        setError(ERROR_MESSAGE);
      }
    } catch (error) {
      setError(ERROR_MESSAGE);
    }
  };

  const handleCopy = () => {
    const textField = document.createElement('textarea');
    textField.innerText = link;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    setIsCopied(true);
  };

  return (
    <form onSubmit={handleSubmit(onGenerate)}>
      <Paper variant="outlined" style={{ padding: '20px' }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6" color="primary">
              Link Generator
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="mediaUuid"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  id="media-select"
                  options={medias}
                  onChange={(_, data) => field.onChange(data)}
                  getOptionLabel={(option) => option.mediaUrl}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      error={errors.mediaUuid ? true : false}
                      helperText={errors.mediaUuid ? 'Media is required!' : null}
                      {...params}
                      label="Media"
                      margin="dense"
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <EnhancedTextField
              control={control}
              fieldName="targetUrl"
              label="Target URL"
              hasError={errors.targetUrl ? true : false}
              errorTxt="Target URL is required!"
            />
          </Grid>
          <Grid item xs={6}>
            <EnhancedTextField control={control} fieldName="pageUrl" label="Page URL" required={false} />
          </Grid>
          <Grid item xs={6}>
            <EnhancedTextField control={control} fieldName="query" label="Query" required={false} />
          </Grid>
          <Grid item xs={10}>
            <EnhancedSwitch isChecked={false} control={control} fieldName="utm" label="Keep UTM tags on target URL" />
          </Grid>
          <Grid item xs={2}>
            <Button fullWidth disabled={isSubmitting} variant="contained" type="submit" color="primary">
              {isSubmitting ? 'Generating...' : 'Generate'}
            </Button>
          </Grid>
          {link && (
            <Grid item xs={12}>
              <Typography variant="h6" style={{ color: green[600] }}>
                {link}{' '}
                <Button
                  onClick={handleCopy}
                  type="button"
                  style={{ marginLeft: '10px' }}
                  variant="outlined"
                  color="primary"
                >
                  {isCopied ? 'Link Copied' : 'Copy Link'}
                </Button>
              </Typography>
            </Grid>
          )}
          {error && (
            <Grid item xs={12}>
              <Typography variant="h6" style={{ color: red[400] }}>
                {error}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Paper>
    </form>
  );
}

export default WithMotion(LinkGenerator);
