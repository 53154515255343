import { Column } from '@material-table/core';
import { Data, CreateFormData } from './types';
import { green, red } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';

export const columns: Array<Column<Data>> = [
  {
    title: 'ID',
    field: 'networkUuid',
    cellStyle: { display: 'none' },
    headerStyle: { display: 'none' },
  },
  { title: 'Name', field: 'networkName', defaultSort: 'asc' },
  {
    title: 'Import',
    field: 'networkImport',
    render: (row) =>
      row.networkImport ? (
        <Icon style={{ color: green[400] }}>check_circle</Icon>
      ) : (
        <Icon style={{ color: red[400] }}>remove_circle</Icon>
      ),
  },
];

export const createDefaultValues: CreateFormData = {
  networkName: '',
  networkLegalName: '',
  networkTrackingUrl: '',
  networkTagDeeplink: '',
  networkUrlDeeplinkTags: '',
  networkTagClickId: '',
  networkTagChannelUrl: '',
  networkTagChannelId: '',
  networkTagAccountId: '',
  networkTagCampaignId: '',
  networkTagUrlId: '',
  networkTagTrackingUrl: '',
  networkTagUrlShortCode: '',
  networkTagCustom1: '',
  networkTagCustom2: '',
  networkTagCustom3: '',
  networkTagCustom4: '',
  networkTagCustom5: '',
  networkImport: false,
  networkDeeplinkPathOnly: false,
  networkUrlEncodeDeeplink: false,
  networkUrlAllowEmptyTagsStatus: false,
  networkSync: false,
};
