import React from 'react';
import { Typography, Chip, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
    },
    heading: {
      fontSize: 16,
      color: '#93949f',
      margin: theme.spacing(),
    },
    chip: {
      margin: theme.spacing(),
    },
    chipRoot: {
      borderRadius: 4,
      color: '#fff',
      fontWeight: 500,
      backgroundColor: '#93949f',
    },
    chipDeleteIcon: {
      color: '#fff',
    },
  })
);

type PropsType = {
  data: {
    date: string;
    week: string;
    month: string;
    year: string;
    advertiserUuid: string;
    channelUuid: string;
    device: string;
    pageUrl: string;
    referer: string;
    advertiserLabel: string;
    channelLabel: string;
    accountUuid: string;
    accountLabel: string;
    networkUuid: string;
    networkLabel: string;
    status: string;
    currency: string;
  };
  handleFilterClick: (filterId: string) => void;
};

export default function FiltersContainer({ data, handleFilterClick }: PropsType) {
  const classes = useStyles();
  if (
    !data.date &&
    !data.week &&
    !data.month &&
    !data.year &&
    !data.advertiserUuid &&
    !data.channelUuid &&
    !data.device &&
    !data.pageUrl &&
    !data.referer &&
    !data.accountUuid &&
    !data.networkUuid &&
    !data.status &&
    !data.currency
  ) {
    return null;
  }
  return (
    <div className={classes.root}>
      <Typography variant="subtitle1" className={classes.heading}>
        Filter by:
      </Typography>
      {data.date ? (
        <Chip
          classes={{
            root: classes.chipRoot,
            deleteIcon: classes.chipDeleteIcon,
          }}
          className={classes.chip}
          label={data.date}
          onDelete={() => handleFilterClick('date')}
        />
      ) : null}
      {data.week ? (
        <Chip
          classes={{
            root: classes.chipRoot,
            deleteIcon: classes.chipDeleteIcon,
          }}
          className={classes.chip}
          label={data.week}
          onDelete={() => handleFilterClick('week')}
        />
      ) : null}
      {data.month ? (
        <Chip
          classes={{
            root: classes.chipRoot,
            deleteIcon: classes.chipDeleteIcon,
          }}
          className={classes.chip}
          label={data.month}
          onDelete={() => handleFilterClick('month')}
        />
      ) : null}
      {data.year ? (
        <Chip
          classes={{
            root: classes.chipRoot,
            deleteIcon: classes.chipDeleteIcon,
          }}
          className={classes.chip}
          label={data.year}
          onDelete={() => handleFilterClick('year')}
        />
      ) : null}
      {data.advertiserUuid ? (
        <Chip
          label={data.advertiserLabel}
          onDelete={() => handleFilterClick('advertiserUuid')}
          className={classes.chip}
          classes={{
            root: classes.chipRoot,
            deleteIcon: classes.chipDeleteIcon,
          }}
        />
      ) : null}
      {data.channelUuid ? (
        <Chip
          label={data.channelLabel}
          onDelete={() => handleFilterClick('channelUuid')}
          classes={{
            root: classes.chipRoot,
            deleteIcon: classes.chipDeleteIcon,
          }}
          className={classes.chip}
        />
      ) : null}
      {data.networkUuid ? (
        <Chip
          label={data.networkLabel}
          onDelete={() => handleFilterClick('networkUuid')}
          classes={{
            root: classes.chipRoot,
            deleteIcon: classes.chipDeleteIcon,
          }}
          className={classes.chip}
        />
      ) : null}
      {data.accountUuid ? (
        <Chip
          label={data.accountLabel}
          onDelete={() => handleFilterClick('accountUuid')}
          classes={{
            root: classes.chipRoot,
            deleteIcon: classes.chipDeleteIcon,
          }}
          className={classes.chip}
        />
      ) : null}
      {data.device ? (
        <Chip
          label={data.device}
          onDelete={() => handleFilterClick('device')}
          classes={{
            root: classes.chipRoot,
            deleteIcon: classes.chipDeleteIcon,
          }}
          className={classes.chip}
        />
      ) : null}
      {data.pageUrl ? (
        <Chip
          label={data.pageUrl}
          onDelete={() => handleFilterClick('pageUrl')}
          classes={{
            root: classes.chipRoot,
            deleteIcon: classes.chipDeleteIcon,
          }}
          className={classes.chip}
        />
      ) : null}
      {data.referer ? (
        <Chip
          label={data.referer}
          onDelete={() => handleFilterClick('referer')}
          classes={{
            root: classes.chipRoot,
            deleteIcon: classes.chipDeleteIcon,
          }}
          className={classes.chip}
        />
      ) : null}
      {data.status ? (
        <Chip
          label={data.status}
          onDelete={() => handleFilterClick('status')}
          classes={{
            root: classes.chipRoot,
            deleteIcon: classes.chipDeleteIcon,
          }}
          className={classes.chip}
        />
      ) : null}
      {data.currency ? (
        <Chip
          label={data.currency}
          onDelete={() => handleFilterClick('currency')}
          classes={{
            root: classes.chipRoot,
            deleteIcon: classes.chipDeleteIcon,
          }}
          className={classes.chip}
        />
      ) : null}
    </div>
  );
}
