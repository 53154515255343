import { useEffect, useState } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { makeCall } from 'utils/utils';
import { Data, UpdateFormData as FormData } from './types';

type PropsType = {
  open: boolean;
  handleClose: () => void;
  record: Data | null;
  refresh: () => void;
};

export function Update({ open, handleClose, record, refresh }: PropsType) {
  const [isCopied, setIsCopied] = useState(false);
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
  } = useForm<FormData>();

  useEffect(() => {
    if (record) {
      reset({
        apiKeyName: record.apiKeyName,
        apiKeyDescription: record.apiKeyDescription,
        apiKeyActiveStatus: record.apiKeyActiveStatus,
      });
    }
  }, [record, reset]);

  const onSubmit = async (data: FormData) => {
    const payload = {
      ...data,
    };
    await makeCall(`apikeys/${record?.apiKeyUuid}`, 'PUT', payload);
    close();
    refresh();
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(record?.apiKeyUuid ?? '');
    setIsCopied(true);
  };

  const close = () => {
    setIsCopied(false);
    handleClose();
  };

  return (
    <div>
      <Dialog open={open} disableBackdropClick={true} onClose={close} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Update API key</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <DialogContentText>
              ID: {record?.apiKeyUuid}{' '}
              <Button disabled={isCopied} color="primary" onClick={handleCopy}>
                {isCopied ? 'Copied' : 'Copy'}
              </Button>
            </DialogContentText>
            <Controller
              name="apiKeyName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors.apiKeyName ? true : false}
                  helperText={errors.apiKeyName ? 'API key name is required!' : null}
                  autoFocus
                  margin="dense"
                  label="API key name"
                  fullWidth
                  style={{ marginTop: 20 }}
                />
              )}
            />
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors.apiKeyDescription ? true : false}
                  helperText={errors.apiKeyDescription ? 'API key description is required!' : null}
                  margin="dense"
                  label="API key description"
                  fullWidth
                  style={{ marginTop: 20 }}
                />
              )}
              name="apiKeyDescription"
              control={control}
              rules={{ required: true }}
            />
            <Controller
              name="apiKeyActiveStatus"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Switch {...field} defaultChecked={record?.apiKeyActiveStatus} color="primary" />}
                  label="Active"
                  style={{ marginTop: 20 }}
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={close} color="primary">
              Cancel
            </Button>
            <Button disabled={isSubmitting} type="submit" color="primary">
              {isSubmitting ? 'Updating...' : 'Update'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
