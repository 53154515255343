import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { v2Endpoints } from './v2Endpoints';

const ROOT_URL = process.env.REACT_APP_ROOT_URL;

export const getToken = () => {
  const token = localStorage.getItem('token');
  const { exp } = jwtDecode(token);
  const expirationTime = exp * 1000 - 10000;
  if (Date.now() >= expirationTime) {
    localStorage.removeItem('token');
    window.location.assign('/');
  }
  return token;
};

export const generateFile = (data, fileName) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  link.click();
};

export const makeCall = async (destination, method, data, type, dispatch, responseType = 'json', isCSV = false) => {
  try {
    const root = v2Endpoints.includes(destination.split('/')[0])
      ? `${ROOT_URL.substring(0, ROOT_URL.length - 3)}v2/`
      : ROOT_URL;
    if (method === 'GET' && data !== null && data !== undefined) {
      const params = Object.keys(data)
        .map(function (key) {
          return key + '=' + data[key];
        })
        .join('&');
      destination += `?${params}`;
    }

    const request = await axios({
      method,
      url: `${root}${destination}`,
      headers: { Authorization: `Bearer ${getToken()}`, ...(isCSV && { accept: 'text/csv' }) },
      ...(method !== 'GET' && { data: data }),
      responseType,
    });
    if (dispatch) {
      dispatch({
        type,
        payload: request,
      });
    }
    return request;
  } catch (error) {
    const status = error?.response?.status ?? 0;
    if (status === 401) {
      if (dispatch) {
        dispatch({ type: 'LOGOUT_ADMIN', payload: null });
      } else {
        localStorage.removeItem('token');
        window.location.assign('/');
      }
    }
    if (status === 400 || status === 500) {
      if (dispatch) {
        dispatch({ type: 'SHOW_API_ERROR_RESPONSE', payload: error.response });
      }
    }
  }
};

export function formatValue(val, isCurrency) {
  if (val) {
    if (isCurrency) {
      return val.toLocaleString('da-DK', {
        style: 'currency',
        currency: 'dkk',
      });
    }
    return val.toLocaleString('da-DK');
  }
  return 0;
}

function calcValue(val1, val2, sign) {
  let value = 0;
  if (!sign) {
    if (val1 && val2) {
      value = (val1 / val2) * 100;
    }
    return value;
  } else {
    if (val1 && val2) {
      value = ((val1 / val2) * 100).toFixed(2);
    }
    return `${value} ${sign}`;
  }
}

const calCommission = (commissionObj) => {
  const rates = {
    USD: 0.158,
    EUR: 0.134378,
    GBP: 0.122549,
    NOK: 1.47396,
    PLN: 0.602143,
    SEK: 1.41247,
    DKK: 1,
  };
  let keys = Object.keys(commissionObj);
  return keys.map((key) => commissionObj[key] / rates[key]).reduce((init, current) => init + current, 0);
};

export const formatInsightsData = (data) => {
  return data.map((row) => {
    let calculatedCommission = calCommission(row.commission);
    let calculatedCut = calCommission(row.cut);
    let calculatedAmount = calCommission(row.amount);

    return {
      group: row.grp,
      filter: row.filter,
      pageviews: row.pageviews,
      clicks: row.clicks,
      ctr: calcValue(row.clicks, row.pageviews),
      conversions: row.conversions,
      cr: calcValue(row.conversions, row.clicks),
      commission: calculatedCommission,
      cut: calculatedCut,
      amount: calculatedAmount,
      epc: calcValue(calculatedCommission, row.clicks),
      epm: calcValue(calculatedCommission, row.pageviews),
    };
  });
};
