import { useState } from 'react';
import {
  Grid,
  Typography,
  Paper,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Theme,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { GoErrorRow } from '../../../../types';
import Skeleton from '@material-ui/lab/Skeleton';

type PropsType = {
  isLoading: boolean;
  data: GoErrorRow[] | undefined;
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
  paperRoot: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(2),
  },
  header: {
    marginBottom: theme.spacing(2.5),
  },
  tableContainer: {
    maxHeight: '350px',
    minHeight: '350px',
  },
  noData: {
    display: 'block',
    width: '100%',
    fontSize: '14px',
    margin: '100px 0',
    textAlign: 'center',
    color: '#555',
  },
  tableCell: {
    borderBottom: 'none',
  },
  text: {
    fontSize: 11,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'default',
  },
}));

const useStylesTooltip = makeStyles((theme: Theme) => ({
  arrow: {
    color: theme.palette.primary.dark,
  },
  tooltip: {
    backgroundColor: theme.palette.primary.dark,
    color: 'rgba(255, 255, 255, 0.87)',
    maxWidth: 350,
    fontSize: theme.typography.pxToRem(16),
    border: '1px solid #dadde9',
    padding: 10,
    textAlign: 'center',
    marginTop: '10px',
  },
}));

export default function GoErrorsTable({ isLoading, data }: PropsType) {
  const classes = useStyles();
  const tooltipClasses = useStylesTooltip();
  const [searchValue, setSearchValue] = useState('');
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };
  const dataToRender = data?.filter((row) => row.pageUrl.includes(searchValue) || row.targetUrl.includes(searchValue));
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Paper variant="outlined" className={classes.paperRoot}>
          <Grid container alignItems="center" justify="space-between">
            <Grid item xs={9}>
              <Typography variant="subtitle2" color="primary">
                Latest Go Errors
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <TextField
                size="small"
                fullWidth
                variant="outlined"
                type="search"
                id="go-error-search-field"
                margin="dense"
                className={classes.header}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Error</TableCell>
                  <TableCell>Page Url</TableCell>
                  <TableCell>Target</TableCell>
                  <TableCell>Date time</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {isLoading ? (
                  [1, 2, 3, 4, 5, 6].map((row) => (
                    <TableRow key={row}>
                      {[10, 20, 30, 40].map((cell) => (
                        <TableCell key={cell} className={classes.tableCell}>
                          <Skeleton variant="text" animation="wave" />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                ) : dataToRender && dataToRender.length ? (
                  dataToRender.map((row, idx) => (
                    <TableRow
                      key={idx}
                      style={idx % 2 ? { background: 'rgba(0, 0, 150, 0.025)' } : { background: 'white' }}
                    >
                      <TableCell className={classes.tableCell}>
                        <Tooltip title={row.errorMessage} classes={tooltipClasses} arrow>
                          <div className={classes.text} style={{ width: '120px' }}>
                            {row.errorMessage}
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Tooltip title={row.pageUrl} classes={tooltipClasses} arrow>
                          <div className={classes.text} style={{ width: '430px' }}>
                            {row.pageUrl}
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Tooltip title={row.targetUrl} classes={tooltipClasses} arrow>
                          <div className={classes.text} style={{ width: '430px' }}>
                            {row.targetUrl}
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Tooltip title={row.datetime} classes={tooltipClasses} arrow>
                          <div className={classes.text} style={{ width: '120px' }}>
                            {row.datetime}
                          </div>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Typography variant="subtitle1" className={classes.noData}>
                        No Errors Found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </Grid>
  );
}
