import { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { makeCall } from 'utils/utils';
import { Record, UpdateFormData as FormData, Country } from './types';
import EnhancedTextField from 'components/EnhancedTextField';
import EnhancedSwitch from 'components/EnhancedSwitch';
import Autocomplete from '@material-ui/lab/Autocomplete';

type PropsType = {
  open: boolean;
  handleClose: () => void;
  record: Record | null;
  refresh: () => void;
  countries: Country[];
};

export default function Update({ open, handleClose, record, refresh, countries }: PropsType) {
  const [isCopied, setIsCopied] = useState(false);
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
  } = useForm<FormData>();

  useEffect(() => {
    if (record) {
      const selectedCountry = countries.filter((row) => row.countryCode === record.clientCountryCode);
      reset({
        clientFirstname: record.clientFirstname,
        clientLastname: record.clientLastname,
        clientCompanyName: record.clientCompanyName,
        clientUrl: record.clientUrl,
        clientEmail: record.clientEmail,
        clientBillingEmail: record.clientBillingEmail,
        clientPhone: record.clientPhone,
        clientVatNumber: record.clientVatNumber,
        clientAddress: record.clientAddress,
        clientZipcode: record.clientZipcode,
        clientCity: record.clientCity,
        clientCountryCode: selectedCountry.length ? selectedCountry[0] : null,
        clientBankRegistration: record.clientBankRegistration,
        clientBankAccount: record.clientBankAccount,
        clientBankIban: record.clientBankIban,
        clientPaymentTypeKey: record.clientPaymentTypeKey,
        clientStatus: record.clientStatus,
      });
    }
  }, [record, reset, countries]);

  const onSubmit = async (data: FormData) => {
    const payload = {
      ...data,
      clientCountryCode: data.clientCountryCode?.countryCode,
    };
    await makeCall(`clients/${record?.clientUuid}`, 'PUT', payload);
    close();
    refresh();
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(record?.clientUuid ?? '');
    setIsCopied(true);
  };

  const close = () => {
    setIsCopied(false);
    handleClose();
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        disableBackdropClick={true}
        onClose={close}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Update Client</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <DialogContentText>
              ID: {record?.clientUuid}{' '}
              <Button disabled={isCopied} color="primary" onClick={handleCopy}>
                {isCopied ? 'Copied' : 'Copy'}
              </Button>
            </DialogContentText>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <EnhancedTextField
                  control={control}
                  required={true}
                  fieldName="clientFirstname"
                  label="First name"
                  hasError={errors.clientFirstname ? true : false}
                  errorTxt="First name is required!"
                  autoFocus
                />
              </Grid>
              <Grid item xs={4}>
                <EnhancedTextField
                  control={control}
                  required={true}
                  fieldName="clientLastname"
                  label="Last name"
                  hasError={errors.clientLastname ? true : false}
                  errorTxt="Last name is required!"
                />
              </Grid>
              <Grid item xs={4}>
                <EnhancedTextField
                  control={control}
                  required={true}
                  fieldName="clientCompanyName"
                  label="Company name"
                  hasError={errors.clientCompanyName ? true : false}
                  errorTxt="Company name is required!"
                />
              </Grid>
              <Grid item xs={4}>
                <EnhancedTextField
                  control={control}
                  required={true}
                  fieldName="clientUrl"
                  label="Website url"
                  hasError={errors.clientUrl ? true : false}
                  errorTxt="Website url is required!"
                />
              </Grid>
              <Grid item xs={4}>
                <EnhancedTextField
                  control={control}
                  required={true}
                  fieldName="clientEmail"
                  label="Email"
                  hasError={errors.clientEmail ? true : false}
                  errorTxt="Valid Email is required!"
                  isEmail
                />
              </Grid>
              <Grid item xs={4}>
                <EnhancedTextField
                  control={control}
                  required={true}
                  fieldName="clientBillingEmail"
                  label="Billing Email"
                  hasError={errors.clientBillingEmail ? true : false}
                  errorTxt="Valid Billing Email is required!"
                  isEmail
                />
              </Grid>
              <Grid item xs={4}>
                <EnhancedTextField
                  control={control}
                  required={true}
                  fieldName="clientPhone"
                  label="Phone"
                  hasError={errors.clientPhone ? true : false}
                  errorTxt="Phone is required!"
                />
              </Grid>
              <Grid item xs={4}>
                <EnhancedTextField
                  control={control}
                  required={true}
                  fieldName="clientVatNumber"
                  label="VAT number"
                  hasError={errors.clientVatNumber ? true : false}
                  errorTxt="VAT number is required!"
                />
              </Grid>
              <Grid item xs={4}>
                <EnhancedTextField
                  control={control}
                  required={true}
                  fieldName="clientAddress"
                  label="Address"
                  hasError={errors.clientAddress ? true : false}
                  errorTxt="Address is required!"
                />
              </Grid>
              <Grid item xs={4}>
                <EnhancedTextField
                  control={control}
                  required={true}
                  fieldName="clientZipcode"
                  label="Zipcode"
                  hasError={errors.clientZipcode ? true : false}
                  errorTxt="Zipcode is required!"
                />
              </Grid>
              <Grid item xs={4}>
                <EnhancedTextField
                  control={control}
                  required={true}
                  fieldName="clientCity"
                  label="City"
                  hasError={errors.clientCity ? true : false}
                  errorTxt="City is required!"
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="clientCountryCode"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="currency-select"
                      options={countries}
                      onChange={(_, data) => field.onChange(data)}
                      getOptionLabel={(option) => option.countryName}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          error={errors.clientCountryCode ? true : false}
                          helperText={errors.clientCountryCode ? 'Country is required!' : null}
                          {...params}
                          label="Country"
                          margin="dense"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <EnhancedTextField
                  control={control}
                  fieldName="clientBankRegistration"
                  label="Bank registration number"
                  hasError={errors.clientBankRegistration ? true : false}
                  errorTxt="Bank registration number is required!"
                />
              </Grid>
              <Grid item xs={4}>
                <EnhancedTextField
                  control={control}
                  fieldName="clientBankAccount"
                  label="Bank account number"
                  hasError={errors.clientBankAccount ? true : false}
                  errorTxt="Bank account number is required!"
                />
              </Grid>
              <Grid item xs={4}>
                <EnhancedTextField control={control} required={false} fieldName="clientBankIban" label="Bank IBAN" />
              </Grid>
              <Grid item xs={4}>
                <EnhancedTextField
                  control={control}
                  required={false}
                  fieldName="clientPaymentTypeKey"
                  label="Payment type"
                />
              </Grid>
              <Grid item xs={4}>
                <EnhancedSwitch
                  control={control}
                  fieldName="clientStatus"
                  label="Active"
                  isChecked={record?.clientStatus}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={close} color="primary">
              Cancel
            </Button>
            <Button disabled={isSubmitting} type="submit" color="primary">
              {isSubmitting ? 'Updating...' : 'Update'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
