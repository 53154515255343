import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  Divider,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { makeCall } from 'utils/utils';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useForm, Controller } from 'react-hook-form';
import Datepicker from 'components/DatepickerV2';
import Calendar from 'components/Calendar';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { Account, AccountValueType, OverviewRecord } from './types';

type PropsType = {
  open: boolean;
  handleClose: () => void;
  accounts: Account[];
  record: OverviewRecord | null;
  refresh: () => void;
  refreshView: (id: string, type: string) => void;
};

type FormData = {
  paymentNoteAccountUuid: AccountValueType;
  paymentNotePeriodStartDate: string;
  paymentNotePeriodEndDate: string;
  paymentNoteDate: string;
};

type Group = {
  grp: string;
  conversionsCount: number;
  sums: {
    amountSum: number;
    commissionSum: number;
    saleSum: number;
    cutSum: number;
  };
};
type ReportType = {
  conversionsCount: number;
  groups: Group[];
};

const formatter = new Intl.NumberFormat('da-DK');

export default function Update({ open, handleClose, accounts, record, refresh, refreshView }: PropsType) {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
    getValues,
  } = useForm<FormData>();

  const [dateObj, setDateObj] = React.useState({
    fromDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
    toDate: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
  });

  const [paymentNoteDate, setPaymentNoteDate] = React.useState(format(new Date(), 'yyyy-MM-dd'));

  const [report, setReport] = React.useState<ReportType | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);
  const [transactionVisible, setTransactionVisible] = React.useState(false);
  const [transactionAmount, setTransactionAmount] = React.useState('');
  const [transactionText, setTransactionText] = React.useState('');
  const [transactionStatus, setTransactionStatus] = React.useState(false);
  const [transactionType, setTransactionType] = React.useState('POST');
  const [transactionUuid, setTransactionUuid] = React.useState('');
  const [disableCommit, setDisableCommit] = React.useState(false);

  const setDate = (start: Date, end: Date) => {
    setDateObj({
      fromDate: format(start, 'yyyy-MM-dd'),
      toDate: format(end, 'yyyy-MM-dd'),
    });
    handleFetchReport();
  };

  const handlePaymentNoteChange = (date: Date) => {
    setPaymentNoteDate(format(date, 'yyyy-MM-dd'));
    handleFetchReport();
  };

  React.useEffect(() => {
    if (record) {
      const getData = async () => {
        const payload = {
          paymentNoteCurrencyCode: record?.paymentNote.paymentNoteCurrencyCode,
          paymentNoteAccountUuid: record.account.accountUuid,
          paymentNotePeriodStartDate: record.paymentNote.paymentNotePeriodStartDate,
          paymentNotePeriodEndDate: record.paymentNote.paymentNotePeriodEndDate,
        };

        const response = await makeCall('finance/paymentnotes/report', 'POST', payload);
        setReport(response?.data.data);
        setIsValid(true);
      };

      const selectedAccount = accounts.filter((row) => row.accountUuid === record.account.accountUuid)[0];
      reset({
        paymentNoteAccountUuid: selectedAccount ? selectedAccount : null,
      });
      setDateObj({
        fromDate: record.paymentNote.paymentNotePeriodStartDate,
        toDate: record.paymentNote.paymentNotePeriodEndDate,
      });
      setPaymentNoteDate(record.paymentNote.paymentNoteDate);
      getData();
    }
  }, [reset, record, accounts]);

  const handleFetchReport = () => {
    const accountUuid = getValues('paymentNoteAccountUuid')?.accountUuid;
    const { fromDate, toDate } = dateObj;
    setDisableCommit(true);
    if (accountUuid && fromDate && toDate) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const fetchReport = async () => {
    const payload = {
      paymentNoteCurrencyCode: record?.paymentNote.paymentNoteCurrencyCode,
      paymentNoteAccountUuid: getValues('paymentNoteAccountUuid')?.accountUuid,
      paymentNotePeriodStartDate: dateObj.fromDate,
      paymentNotePeriodEndDate: dateObj.toDate,
    };
    setIsLoading(true);

    const response = await makeCall('finance/paymentnotes/report', 'POST', payload);
    setReport(response?.data.data);
    setIsLoading(false);
  };

  const resetForm = () => {
    reset({
      paymentNoteAccountUuid: null,
      paymentNotePeriodEndDate: '',
      paymentNotePeriodStartDate: '',
      paymentNoteDate: '',
    });
    setDateObj({
      fromDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
      toDate: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
    });
    setPaymentNoteDate(format(new Date(), 'yyyy-MM-dd'));
    setReport(null);
    setIsLoading(false);
    setIsValid(false);
    setTransactionVisible(false);
    setTransactionAmount('');
    setTransactionText('');
    setTransactionStatus(false);
    setTransactionType('POST');
    setTransactionUuid('');
    setDisableCommit(false);
    handleClose();
  };

  const onSubmit = async (data: FormData) => {
    const payload = {
      paymentNoteDate,
      paymentNotePeriodStartDate: dateObj.fromDate,
      paymentNotePeriodEndDate: dateObj.toDate,
      paymentNoteAccountUuid: data.paymentNoteAccountUuid?.accountUuid,
    };
    await makeCall(`finance/paymentnotes/${record?.paymentNote.paymentNoteUuid}`, 'PUT', payload);
    // resetForm();
    setDisableCommit(false);
    refresh();
  };

  const handleTransactionSubmit = async () => {
    if (!transactionAmount || !transactionText) {
      return false;
    }
    if (!parseInt(transactionAmount)) {
      return false;
    }
    setTransactionStatus(true);
    const payload = {
      transactionAmount,
      transactionText,
    };
    if (transactionType === 'POST') {
      await makeCall(
        `finance/paymentnotes/${record?.paymentNote.paymentNoteUuid}/transactions`,
        transactionType,
        payload
      );
    } else {
      await makeCall(
        `finance/paymentnotes/${record?.paymentNote.paymentNoteUuid}/transactions/${transactionUuid}`,
        transactionType,
        payload
      );
    }
    refreshView(record?.paymentNote.paymentNoteUuid ?? '', 'edit');
    setTransactionVisible(false);
    setTransactionAmount('');
    setTransactionText('');
    setTransactionStatus(false);
    setTransactionType('POST');
    setTransactionUuid('');
  };

  const handleTransactionUpdateClick = (clickedObj: any) => {
    setTransactionVisible(true);
    setTransactionAmount(clickedObj.transactionAmount);
    setTransactionText(clickedObj.transactionText);
    setTransactionType('PUT');
    setTransactionUuid(clickedObj.transactionUuid);
    setTransactionStatus(false);
  };

  const handleTransactionDeleteClick = async (id: string) => {
    await makeCall(`finance/paymentnotes/${record?.paymentNote.paymentNoteUuid}/transactions/${id}`, 'DELETE');
    refreshView(record?.paymentNote.paymentNoteUuid ?? '', 'edit');
    setTransactionVisible(false);
    setTransactionAmount('');
    setTransactionText('');
    setTransactionStatus(false);
    setTransactionType('POST');
    setTransactionUuid('');
  };

  const handleCommitClick = async () => {
    await makeCall(`finance/paymentnotes/${record?.paymentNote.paymentNoteUuid}/commit`, 'POST');
    resetForm();
    refreshView(record?.paymentNote.paymentNoteUuid ?? '', 'view');
    refresh();
  };

  return (
    <>
      <Dialog
        open={open}
        disableBackdropClick={true}
        onEscapeKeyDown={resetForm}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">Update Payment note</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent style={{ minHeight: 500 }}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Controller
                  name="paymentNoteAccountUuid"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="network-select"
                      options={accounts}
                      onChange={(_, data) => {
                        field.onChange(data);
                        handleFetchReport();
                      }}
                      getOptionLabel={(option) => option.accountName}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          error={errors.paymentNoteAccountUuid ? true : false}
                          helperText={errors.paymentNoteAccountUuid ? 'Account is required!' : null}
                          {...params}
                          autoFocus
                          label="Account"
                          margin="dense"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" style={{ color: 'rgba(0, 0, 0, 0.54)', paddingLeft: 5, paddingBottom: 5 }}>
                  Payment note date
                </Typography>
                <div style={{ border: '1px solid rgba(0, 0, 0, 0.2)', padding: '0px', borderRadius: '5px' }}>
                  <Calendar date={paymentNoteDate} setDate={handlePaymentNoteChange} />
                </div>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" style={{ color: 'rgba(0, 0, 0, 0.54)', paddingLeft: 5, paddingBottom: 5 }}>
                  Period range selection
                </Typography>
                <div style={{ border: '1px solid rgba(0, 0, 0, 0.2)', padding: '0px', borderRadius: '5px' }}>
                  <Datepicker change={setDate} startDate={dateObj.fromDate} endDate={dateObj.toDate} />
                </div>
              </Grid>
              <Grid item xs={8}>
                <Chip
                  variant="default"
                  label={
                    <Typography variant="subtitle2" style={{ color: '#fff' }}>
                      Currency: {record?.paymentNote.paymentNoteCurrencyCode ?? '-'}{' '}
                    </Typography>
                  }
                  style={{ marginLeft: 10 }}
                  color="primary"
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  disabled={disableCommit}
                  onClick={handleCommitClick}
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Commit Note
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  onClick={fetchReport}
                  disabled={!isValid || isLoading}
                  fullWidth
                  variant="outlined"
                  color="primary"
                >
                  {isLoading ? 'Getting report...' : 'Get report'}
                </Button>
              </Grid>
            </Grid>
            {report && (
              <>
                <Grid container>
                  <Grid item xs={12}>
                    <Divider style={{ margin: '25px 0', width: '100%' }} />
                  </Grid>
                  <Grid item xs={12}>
                    <Chip
                      variant="default"
                      label={
                        <Typography variant="subtitle2" style={{ color: '#fff' }}>
                          Conversions: {formatter.format(report.conversionsCount)}{' '}
                        </Typography>
                      }
                      style={{ marginLeft: 10 }}
                      color="primary"
                    />
                  </Grid>
                </Grid>
                <Grid container style={{ margin: '20px 0' }}>
                  <Typography variant="h6" color="primary" style={{ margin: '0 18px' }}>
                    Details
                  </Typography>
                  <TableContainer>
                    <Table size="small" aria-label="details-table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Currency</TableCell>
                          <TableCell align="right">Conversions</TableCell>
                          <TableCell align="right">Amount</TableCell>
                          <TableCell align="right">Commission</TableCell>
                          <TableCell align="right">Cut</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {report?.groups.map((row, idx) => (
                          <TableRow key={idx}>
                            <TableCell component="th" scope="row">
                              {row.grp}
                            </TableCell>
                            <TableCell align="right">{formatter.format(row.conversionsCount)}</TableCell>
                            <TableCell align="right">{formatter.format(row.sums.amountSum)}</TableCell>
                            <TableCell align="right">{formatter.format(row.sums.commissionSum)}</TableCell>
                            <TableCell align="right">{formatter.format(row.sums.cutSum)}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Divider />
                <Grid container style={{ margin: '20px 0' }}>
                  <Typography variant="h6" color="primary" style={{ margin: '0 18px' }}>
                    Payment Note Items
                  </Typography>
                  <TableContainer>
                    <Table size="small" aria-label="details-table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Advertiser</TableCell>
                          <TableCell align="right">Currency</TableCell>
                          <TableCell align="right">Quantity</TableCell>
                          <TableCell align="right">Commission</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {record?.paymentNoteItems.map((row, idx) => (
                          <TableRow key={idx}>
                            <TableCell component="th" scope="row">
                              {row.advertiser.advertiserName}
                            </TableCell>
                            <TableCell align="right">{row.paymentNoteItem.paymentNoteItemCurrencyCode}</TableCell>
                            <TableCell align="right">
                              {formatter.format(row.paymentNoteItem.paymentNoteItemQuantity)}
                            </TableCell>
                            <TableCell align="right">
                              {formatter.format(row.paymentNoteItem.paymentNoteItemCommission)}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid container style={{ margin: '20px 0' }}>
                  <Grid item xs={12}>
                    <Typography variant="h6" color="primary" style={{ margin: '0 18px' }}>
                      Transactions
                      <IconButton
                        onClick={() => setTransactionVisible((prev) => !prev)}
                        color="primary"
                        aria-label="add transaction"
                        component="span"
                      >
                        <AddIcon />
                      </IconButton>
                    </Typography>
                  </Grid>
                  {transactionVisible ? (
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={4}
                        alignItems="center"
                        style={{ transform: 'translate(16px, -6px)', marginBottom: '2px' }}
                      >
                        <Grid item xs={3}>
                          <TextField
                            name="transactionAmount"
                            label="Amount"
                            value={transactionAmount}
                            onChange={(e) => setTransactionAmount(e.target.value)}
                            fullWidth
                            autoFocus
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            name="transactionText"
                            label="Text"
                            value={transactionText}
                            onChange={(e) => setTransactionText(e.target.value)}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={1} style={{ paddingBottom: '0px' }}>
                          <Button
                            disabled={transactionStatus}
                            color="primary"
                            variant="contained"
                            type="button"
                            onClick={handleTransactionSubmit}
                          >
                            {transactionStatus ? 'Saving...' : 'Save'}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : null}
                  <TableContainer>
                    <Table size="small" aria-label="details-table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Text</TableCell>
                          <TableCell align="right">Amount</TableCell>
                          <TableCell align="right">Currency</TableCell>
                          <TableCell align="right">Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {record?.paymentNoteTransactions.map((row, idx) => (
                          <TableRow key={idx}>
                            <TableCell component="th" scope="row">
                              {row.transactionText}
                            </TableCell>
                            <TableCell align="right">{formatter.format(row.transactionAmount)}</TableCell>
                            <TableCell align="right">{row.transactionCurrencyCode}</TableCell>
                            <TableCell align="right">
                              <IconButton
                                onClick={() => handleTransactionUpdateClick(row)}
                                color="primary"
                                aria-label="update transaction"
                                component="span"
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => handleTransactionDeleteClick(row.transactionUuid)}
                                color="primary"
                                aria-label="delete transaction"
                                component="span"
                              >
                                <DeleteForeverIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={resetForm} color="primary">
              Cancel
            </Button>
            <Button disabled={isSubmitting} type="submit" color="primary">
              {isSubmitting ? 'Updating...' : 'Update'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
