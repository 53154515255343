import React, { useState } from 'react';
import { Typography, Paper, Button, Container, makeStyles, TextField } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import Logo from 'components/Logo';
import WithMotion from 'components/withMotion';
import { Redirect } from 'react-router-dom';
import axios from 'axios';

const ROOT_URL = process.env.REACT_APP_ROOT_URL;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(5),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function PageContainer() {
  const classes = useStyles();
  const [hasError, setHasError] = useState(false);

  const { control, handleSubmit, formState } = useForm({
    defaultValues: {
      adminEmail: process.env.REACT_APP_USER,
      adminPassword: process.env.REACT_APP_PASS,
    },
  });

  const { isSubmitting, errors } = formState;

  const handleLoginSubmit = async (props: any) => {
    const destination = 'admins/login';
    if (hasError) {
      setHasError(false);
    }
    try {
      const request = await axios.post(`${ROOT_URL}${destination}`, props);
      if (request.data.data) {
        localStorage.setItem('token', request.data.data.jwt);
        localStorage.setItem('adminType', request.data.data.adminType);
        window.location.assign(`/${request.data.data.adminType === 'student' ? 'advertisers' : 'dashboard'}`);
      }
    } catch (error) {
      setHasError(true);
    }
  };

  const isAuth = localStorage.getItem('token');
  const adminType = localStorage.getItem('adminType');

  if (isAuth) {
    <Redirect to={`/${adminType === 'student' ? 'advertisers' : 'dashboard'}`} />;
  }

  return (
    <Container maxWidth="sm">
      <Paper className={classes.paper} variant="outlined">
        <div style={{ display: 'flex', margin: '1rem auto', width: '100%', justifyContent: 'center' }}>
          <Logo />
          <span
            style={{
              marginLeft: '18px',
              display: 'inline-block',
              height: '20px',
              width: '2px',
              backgroundColor: '#ff6801',
              transform: 'translateY(-1px)',
            }}
          >
            &nbsp;
          </span>
          <Typography
            variant="subtitle1"
            style={{
              marginLeft: '18px',
              transform: 'translateY(-11px)',
              color: '#75934e',
              fontWeight: 700,
              fontSize: '1.6rem',
            }}
          >
            Yoda
          </Typography>
        </div>
        <form className={classes.form} onSubmit={handleSubmit(handleLoginSubmit)}>
          <Controller
            name="adminEmail"
            control={control}
            rules={{
              required: 'Email is required!',
              validate: (value) => {
                if (value && !value.trim()) return 'Email is required!';
                return undefined;
              },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Enter a valid email address!',
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                error={errors.adminEmail ? true : false}
                helperText={errors.adminEmail ? 'E-mail is required!' : null}
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label="Email Address"
                autoFocus
                name="adminEmail"
                placeholder="E-mail"
              />
            )}
          />
          <Controller
            name="adminPassword"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                error={errors.adminPassword ? true : false}
                helperText={errors.adminPassword ? 'Password is required!' : null}
                variant="outlined"
                margin="normal"
                fullWidth
                label="Password"
                type="password"
                autoComplete="current-password"
              />
            )}
          />
          {hasError && (
            <Typography style={{ color: 'red' }} variant="subtitle1">
              Valid email and password required!
            </Typography>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Signing in...' : 'Sign In'}
          </Button>
        </form>
      </Paper>
    </Container>
  );
}

export default WithMotion(PageContainer);
