import { Column } from '@material-table/core';
import { green, red } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import { Data, CreateFormData } from '../types';

export const columns: Array<Column<Data>> = [
  {
    title: 'ID',
    field: 'commissionRuleUuid',
    cellStyle: { display: 'none' },
    headerStyle: { display: 'none' },
  },
  { title: 'Name', field: 'commissionRuleName' },
  { title: 'Commission', field: 'commissionRuleValue', render: (row) => `${row.commissionRuleValue}%` },
  { title: 'Account', field: 'account.accountName' },
  { title: 'Media', field: 'media.mediaUrl' },
  { title: 'Tracker', field: 'tracker.trackerName' },
  { title: 'Program', field: 'program.programTitle' },
  { title: 'Merchant', field: 'merchant.merchantUrl' },
  {
    title: 'Status',
    field: 'connectionStatus',
    render: (row) =>
      row.commissionRuleStatus ? (
        <Icon style={{ color: green[400] }}>check_circle</Icon>
      ) : (
        <Icon style={{ color: red[400] }}>remove_circle</Icon>
      ),
  },
];

export const createDefaultValues: CreateFormData = {
  commissionRuleValue: 0,
  commissionRuleName: '',
  commissionRuleStatus: true,
  commissionRuleAccountUuid: null,
  commissionRuleMediaUuid: null,
  commissionRuleMerchantUuid: null,
  commissionRuleProgramUuid: null,
  commissionRuleTrackerUuid: null,
};
