import { Column } from '@material-table/core';
import { green, yellow } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import { HelperData, OverviewData } from './types';

export const formatter = new Intl.NumberFormat('da-DK');

export const helperColumns: Array<Column<HelperData>> = [
  { title: 'Account', field: 'accountName' },
  {
    title: 'Min - Max Dates',
    field: '',
    render: (row) => (
      <>
        {row.minDate}
        <br />
        {row.maxDate}
      </>
    ),
  },
  { title: 'Commission', field: 'commissionSum', render: (row) => formatter.format(row.commissionSum) },
  { title: 'currencyCode', field: 'currencyCode' },
];

const getStatusSymbol = (key: string) => {
  let symbol;
  if (key === 'COMPLETED') {
    symbol = <Icon style={{ color: green[400] }}>check_circle</Icon>;
  }
  if (key === 'COMMITTED') {
    symbol = <Icon style={{ color: green[800] }}>timer</Icon>;
  }
  if (key === 'CREATED') {
    symbol = <Icon style={{ color: yellow[800] }}>hourglass_full</Icon>;
  }
  return symbol;
};

export const overviewColumns: Array<Column<OverviewData>> = [
  {
    title: 'ID',
    field: 'paymentNote.paymentNoteUuid',
    cellStyle: { display: 'none' },
    headerStyle: { display: 'none' },
  },
  { title: 'Account', field: 'account.accountName' },
  { title: 'Date', field: 'paymentNote.paymentNoteDate' },
  {
    title: 'Period',
    field: '',
    render: (row) => (
      <>
        {row.paymentNote.paymentNotePeriodStartDate} <br /> {row.paymentNote.paymentNotePeriodEndDate}
      </>
    ),
  },
  {
    title: 'Subtotal',
    field: 'paymentNote.paymentNoteSubTotal',
    render: (row) => formatter.format(row.paymentNote.paymentNoteSubTotal),
  },
  { title: 'VAT', field: 'paymentNote.paymentNoteVat' },
  { title: 'Currency', field: 'paymentNote.paymentNoteCurrencyCode' },
  { title: 'Status', field: '', render: (row) => getStatusSymbol(row.paymentNote.paymentNoteStatus) },
];
