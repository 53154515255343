import { useState, useEffect, useRef } from 'react';
import format from 'date-fns/format';
import { makeCall } from 'utils/utils';
import useInterval from 'hooks/useInterval';
import WithMotion from 'components/withMotion';
import { formatAccounts, formatChannels, fetchFunc } from './utils';
import {
  AccountValueType,
  ChannelValueType,
  Channel,
  Account,
  StatsDataType,
  ClicksDataType,
  ConversionsDataType,
  PayloadType,
} from './types';
import {
  CardsContainer,
  Filters,
  LineChartContainer,
  TopPerformers,
  LatestContainer,
  GoErrorsGraph,
  GoErrorsTable,
} from './components/V2';

function Home() {
  const [isLoadingStats, setIsLoadingStats] = useState(true);
  const [stats, setStats] = useState<StatsDataType>();
  const [channels, setChannels] = useState<Channel[]>([]);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [activeAccount, setActiveAccount] = useState<AccountValueType>({ accountName: 'All', accountUuid: 'All' });
  const [activeChannel, setActiveChannel] = useState<ChannelValueType>({ channelName: 'All', channelUuid: 'All' });
  const [dateObj, setDateObj] = useState({
    fromDate: format(new Date(), 'yyyy-MM-dd'),
    toDate: format(new Date(), 'yyyy-MM-dd'),
  });
  const [isLoadingLatest, setIsLoadingLatest] = useState(true);
  const [clicks, setClicks] = useState<ClicksDataType>();
  const [conversions, setConversions] = useState<ConversionsDataType>();
  const [pollCount, setPollCount] = useState(0);
  const timeStamp = useRef('');

  useEffect(() => {
    const getData = async () => {
      const payload: PayloadType = {
        fromDate: dateObj.fromDate,
        toDate: dateObj.toDate,
      };
      if (activeAccount && activeAccount.accountUuid !== 'All') {
        payload.accountUuid = activeAccount.accountUuid;
      }
      if (activeChannel && activeChannel.channelUuid !== 'All') {
        payload.channelUuid = activeChannel.channelUuid;
      }
      const data = await fetchFunc({ url: `dashboards/stats`, payload });
      setStats(data);
      setIsLoadingStats(false);
    };
    getData();
  }, [dateObj, activeAccount, activeChannel]);

  useEffect(() => {
    const getData = async () => {
      let payload: PayloadType = {};
      if (activeAccount && activeAccount.accountUuid !== 'All') {
        payload.accountUuid = activeAccount.accountUuid;
        timeStamp.current = '';
      }
      if (activeChannel && activeChannel.channelUuid !== 'All') {
        payload.channelUuid = activeChannel.channelUuid;
        timeStamp.current = '';
      }
      if (timeStamp.current) {
        payload.fromDatetime = timeStamp.current;
      }
      const clicksData = await fetchFunc({ url: `dashboards/latest/clicks`, payload });
      const conversionsData = await fetchFunc({ url: `dashboards/latest/conversions`, payload });
      timeStamp.current = clicksData?.timestamp || '';

      const newClicks = [...clicksData.clicks, ...(clicks?.clicks ?? [])].slice(0, 20);
      const newConversions = [...conversionsData.conversions, ...(conversions?.conversions ?? [])].slice(0, 20);
      setClicks({ timestamp: clicksData.timestamp, clicks: newClicks });
      setConversions({ timestamp: conversionsData.timestamp, conversions: newConversions });
      setIsLoadingLatest(false);
    };
    getData();
    //eslint-disable-next-line
  }, [pollCount, activeAccount, activeChannel]);

  useEffect(() => {
    const getData = async () => {
      const url = `accounts`;
      const response = await makeCall(url, 'GET');
      const data = formatAccounts(response?.data.data);
      setAccounts(data);
    };
    getData();
  }, []);

  useInterval(() => {
    if (activeAccount && activeAccount.accountUuid !== 'All') {
      return;
    }
    if (activeChannel && activeChannel.channelUuid !== 'All') {
      return;
    }
    setPollCount(pollCount + 1);
  }, 60000);

  useEffect(() => {
    const getData = async () => {
      const url = `medias`;
      const response = await makeCall(url, 'GET');
      const data = formatChannels(response?.data.data);
      setChannels(data);
    };
    getData();
  }, []);

  const setDate = (start: Date, end: Date) => {
    setDateObj({
      fromDate: format(start, 'yyyy-MM-dd'),
      toDate: format(end, 'yyyy-MM-dd'),
    });
    setIsLoadingStats(true);
  };

  const handleChannelChange = (event: React.ChangeEvent<{}>, value: ChannelValueType) => {
    setActiveChannel(value);
    setIsLoadingStats(true);
    setIsLoadingLatest(true);
  };

  const handleAccountChange = (event: React.ChangeEvent<{}>, value: AccountValueType) => {
    setActiveAccount(value);
    setIsLoadingStats(true);
    setIsLoadingLatest(true);
  };

  return (
    <>
      <Filters
        channels={channels}
        activeChannel={activeChannel}
        handleChannelChange={handleChannelChange}
        accounts={accounts}
        activeAccount={activeAccount}
        handleAccountChange={handleAccountChange}
        setDate={setDate}
      />
      <CardsContainer totals={stats?.totals} isLoading={isLoadingStats} />
      <LineChartContainer isLoading={isLoadingStats} data={stats?.groups} />
      <TopPerformers isLoading={isLoadingStats} partners={stats?.partners} advertisers={stats?.advertisers} />
      <LatestContainer isLoading={isLoadingLatest} clicks={clicks} conversions={conversions} />
      <GoErrorsGraph isLoading={isLoadingStats} data={stats?.goErrors.graphBars} />
      <GoErrorsTable isLoading={isLoadingStats} data={stats?.goErrors.rows} />
    </>
  );
}

export default WithMotion(Home);
