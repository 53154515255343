import { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { makeCall } from 'utils/utils';
import { tableContainerStyles as useStyles } from 'styles/common';
import WithMotion from 'components/withMotion';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import { HelperData, Account, Currency, OverviewRecord, FiltersType } from '../types';
import Filters from '../Filters';
import { helperColumns } from '../initialData';
import Create from '../Create';
import Update from '../Update';

function Container() {
  const classes = useStyles();
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [data, setData] = useState<HelperData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [defaultValues, setDefaultValues] = useState<{}>({
    paymentNotePeriodStartDate: '',
    paymentNotePeriodEndDate: '',
    paymentNoteCurrencyCode: null,
    paymentNoteAccountUuid: null,
  });
  const [record, setRecord] = useState<OverviewRecord | null>(null);
  const [filters, setFilters] = useState<FiltersType>({
    accountUuid: '',
    currencyCode: '',
    helperFromDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
    helperToDate: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
    overviewFromDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
    overviewToDate: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
    filterByPeriodDatetime: false,
  });

  useEffect(() => {
    const getData = () => {
      makeCall('accounts', 'GET').then((res) => setAccounts(res?.data.data));
      makeCall('currencies', 'GET').then((res) => setCurrencies(res?.data.data));
    };
    getData();
  }, []);

  const getHelperData = async (filters: FiltersType) => {
    setIsLoading(true);
    const payload = {
      accountUuid: filters.accountUuid,
      currencyCode: filters.currencyCode,
      minDate: filters.helperFromDate,
      maxDate: filters.helperToDate,
    };
    setFilters(filters);
    const response = await makeCall('finance/paymentnotes/helper', 'GET', payload);
    setData(response?.data.data);
    setIsLoading(false);
  };

  const getRecord = async (clickedRowUuid: string, type: string) => {
    if (type === 'edit') {
      const response = await makeCall(`finance/paymentnotes/${clickedRowUuid}`, 'GET');
      setRecord(response?.data.data);
      setIsUpdate(true);
    }
    if (type === 'view') {
      setRecord(null);
      setIsUpdate(false);
    }
  };

  const handleFiltersSubmit = (filters: FiltersType) => {
    getHelperData(filters);
  };

  const createFromHelper = (row: any) => {
    const selectedAccount = accounts.filter((acc) => acc.accountUuid === row.accountUuid)[0];
    const selectedCurrency = currencies.filter((cur) => cur.currencyCode === row.currencyCode)[0];
    const defaultValues = {
      paymentNotePeriodStartDate: row.minDate,
      paymentNotePeriodEndDate: row.maxDate,
      paymentNoteCurrencyCode: selectedCurrency ? selectedCurrency : null,
      paymentNoteAccountUuid: selectedAccount ? selectedAccount : null,
    };

    setDefaultValues(defaultValues);
    setIsCreate(true);
  };

  const handleCreateClick = () => {
    setDefaultValues({
      paymentNotePeriodStartDate: '',
      paymentNotePeriodEndDate: '',
      paymentNoteCurrencyCode: null,
      paymentNoteAccountUuid: null,
    });
    setIsCreate(true);
  };

  const handleRefresh = (id: string) => {
    getRecord(id, 'edit');
    getHelperData(filters);
  };

  return (
    <>
      <Update
        open={isUpdate}
        handleClose={() => setIsUpdate(!isUpdate)}
        accounts={accounts}
        record={record}
        refresh={() => getHelperData(filters)}
        refreshView={getRecord}
      />
      <Create
        open={isCreate}
        handleClose={() => setIsCreate(!isCreate)}
        refresh={handleRefresh}
        accounts={accounts}
        currencies={currencies}
        defaultValues={defaultValues}
      />
      <Filters
        accounts={accounts}
        currencies={currencies}
        handleFiltersSubmit={handleFiltersSubmit}
        viewMode="helper"
        isLoading={isLoading}
        handleCreateClick={handleCreateClick}
      />
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <MaterialTable
            title="Payment notes helper"
            components={{
              Toolbar: (props) => (
                <div className={classes.toolbarWrapper}>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={{
              search: true,
              pageSize: 10,
              actionsColumnIndex: 9,
              actionsCellStyle: { color: '#666' },
            }}
            actions={[
              {
                icon: 'add',
                tooltip: 'Create',
                isFreeAction: false,
                onClick: (event, rowData: any) => createFromHelper(rowData),
              },
            ]}
            isLoading={isLoading}
            columns={helperColumns}
            data={data}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default WithMotion(Container);
