import { Column } from '@material-table/core';
import { green, yellow } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import { Data, CreateFormData } from './types';

export const formatter = new Intl.NumberFormat('da-DK');

export const columns: Array<Column<Data>> = [
  {
    title: 'ID',
    field: 'paymentNote.paymentNoteUuid',
    cellStyle: { display: 'none' },
    headerStyle: { display: 'none' },
  },
  { title: 'Network', field: 'network.networkName' },
  { title: 'Identifier', field: 'paymentNote.paymentNoteIdentifier' },
  { title: 'Date', field: 'paymentNote.paymentNoteDate' },
  {
    title: 'Period',
    field: '',
    render: (row) => (
      <>
        {row.paymentNote.paymentNotePeriodStartDate} <br /> {row.paymentNote.paymentNotePeriodEndDate}
      </>
    ),
  },
  {
    title: 'Subtotal',
    field: 'paymentNote.paymentNoteSubTotal',
    render: (row) => formatter.format(row.paymentNote.paymentNoteSubTotal),
  },
  { title: 'VAT', field: 'paymentNote.paymentNoteVat' },
  { title: 'Currency', field: 'paymentNote.paymentNoteCurrencyCode' },
  {
    title: 'Status',
    field: '',
    render: (row) =>
      row.paymentNote.paymentNoteCompletedStatus ? (
        <Icon style={{ color: green[400] }}>check_circle</Icon>
      ) : (
        <Icon style={{ color: yellow[800] }}>hourglass_full</Icon>
      ),
  },
];

export const createDefaultValues: CreateFormData = {
  paymentNoteCurrencyCode: null,
  paymentNoteDate: '',
  paymentNoteIdentifier: '',
  paymentNoteNetworkUuid: null,
  paymentNotePeriodStartDate: '',
  paymentNotePeriodEndDate: '',
  paymentNoteSubTotal: 0,
  paymentNoteVat: 0,
};
