import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Avatar,
} from '@material-ui/core';
import LoadingTableRow from 'components/LoadingTableRow';
import { ClicksTablePropsType } from './types';
import { useTableStyles, useStylesTooltip } from './styles';

export default function ClicksTable({ isLoading, data }: ClicksTablePropsType) {
  const classes = useTableStyles();
  const tooltipClasses = useStylesTooltip();

  return (
    <Paper variant="outlined" className={classes.root}>
      <Typography variant="subtitle2" color="primary" className={classes.title}>
        Latest Clicks
      </Typography>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Advertiser</TableCell>
              <TableCell>Source</TableCell>
              <TableCell>Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <LoadingTableRow rows={10} cells={3} />
            ) : data && data.length ? (
              data.map((row, idx) => (
                <TableRow key={row.click.clickUuid} className={idx % 2 ? classes.tableRowAlt : classes.tableRow}>
                  <TableCell className={classes.tableCell}>
                    <div className={classes.avatarContainer}>
                      <Avatar
                        className={classes.avatar}
                        sizes="15"
                        alt={row.advertiser.advertiserUrl}
                        src={row.advertiser.advertiserFavicon}
                      >
                        {row.advertiser.advertiserUrl.charAt(0).toLowerCase()}
                      </Avatar>
                      <Tooltip title={row.advertiser.advertiserUrl} classes={tooltipClasses} arrow>
                        <div className={classes.text} style={{ width: '90px' }}>
                          {row.advertiser.advertiserUrl}
                        </div>
                      </Tooltip>
                    </div>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Tooltip title={row.channel.channelUrl} classes={tooltipClasses} arrow>
                      <div className={classes.text} style={{ width: '90px' }}>
                        {row.channel.channelUrl}
                      </div>
                    </Tooltip>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Tooltip title={row.click.clickDatetime} classes={tooltipClasses} arrow>
                      <div className={classes.text} style={{ width: '90px' }}>
                        {row.click.clickDatetime}
                      </div>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} className={classes.tableCell}>
                  <Typography variant="subtitle1" className={classes.noData}>
                    No Clicks Found
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
