import { Column } from '@material-table/core';
import { Data } from './types';
import { green, red } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';

export const columns: Array<Column<Data>> = [
  { title: 'ID', field: 'clientUuid', cellStyle: { display: 'none' }, headerStyle: { display: 'none' } },
  { title: 'Company', field: 'clientCompanyName' },
  { title: 'URL', field: 'clientUrl' },
  { title: 'First name', field: 'clientFirstname' },
  { title: 'Last name', field: 'clientLastname' },
  { title: 'Email', field: 'clientEmail' },
  { title: 'Phone', field: 'clientPhone' },
  { title: 'Country', field: 'country.countryName' },
  {
    title: 'Active',
    field: 'clientStatus',
    render: (row) =>
      row.clientStatus ? (
        <Icon style={{ color: green[400] }}>check_circle</Icon>
      ) : (
        <Icon style={{ color: red[400] }}>remove_circle</Icon>
      ),
  },
];

export const createDefaultValues = {
  clientFirstname: '',
  clientLastname: '',
  clientCompanyName: '',
  clientUrl: '',
  clientEmail: '',
  clientPhone: '',
  clientVatNumber: '',
  clientAddress: '',
  clientZipcode: '',
  clientCity: '',
  clientCountryCode: null,
  clientBankRegistration: '',
  clientBankAccount: '',
  clientBankIban: '',
  clientStatus: true,
  clientPaymentTypeKey: '',
};
