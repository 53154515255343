import React from 'react';
import { Button } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  legendButton: {
    marginLeft: '8px',
  },
  btnPageview: {
    color: '#fff',
    backgroundColor: theme.pageviews.primary,
    '&:hover': {
      backgroundColor: theme.pageviews.primary,
    },
  },
  btnPageviewAlt: {
    color: theme.pageviews.primary,
  },
  btnClicks: {
    color: '#fff',
    backgroundColor: theme.clicks.primary,
    '&:hover': {
      backgroundColor: theme.clicks.primary,
    },
  },
  btnClicksAlt: {
    color: theme.clicks.primary,
  },
  btnConversions: {
    color: '#fff',
    backgroundColor: theme.conversions.primary,
    '&:hover': {
      backgroundColor: theme.conversions.primary,
    },
  },
  btnConversionsAlt: {
    color: theme.conversions.primary,
  },
  btnPartnerCommission: {
    color: '#fff',
    backgroundColor: theme.partnerCommission.primary,
    '&:hover': {
      backgroundColor: theme.partnerCommission.primary,
    },
  },
  btnPartnerCommissionAlt: {
    color: theme.partnerCommission.primary,
  },
  btnHeylinkCommission: {
    color: '#fff',
    backgroundColor: theme.heylinkCommission.primary,
    '&:hover': {
      backgroundColor: theme.heylinkCommission.primary,
    },
  },
  btnHeylinkCommissionAlt: {
    color: theme.heylinkCommission.primary,
  },
  btnCommission: {
    color: '#fff',
    backgroundColor: theme.commission.primary,
    '&:hover': {
      backgroundColor: theme.commission.primary,
    },
  },
  btnCommissionAlt: {
    color: theme.commission.primary,
  },
}));

type Props = {
  filters: string[];
  handleFilterClick: (key: string) => void;
};

export default function Buttons({ filters, handleFilterClick }: Props) {
  const classes = useStyles();
  return (
    <div>
      <Button
        className={[
          classes.legendButton,
          filters.includes('pageviews') ? classes.btnPageviewAlt : classes.btnPageview,
        ].join(' ')}
        size="small"
        variant={filters.includes('pageviews') ? 'outlined' : 'contained'}
        disableElevation
        disableRipple
        disableFocusRipple
        onClick={() => handleFilterClick('pageviews')}
      >
        Pageviews
      </Button>
      <Button
        className={[classes.legendButton, filters.includes('clicks') ? classes.btnClicksAlt : classes.btnClicks].join(
          ' '
        )}
        size="small"
        variant={filters.includes('clicks') ? 'outlined' : 'contained'}
        disableElevation
        disableRipple
        disableFocusRipple
        onClick={() => handleFilterClick('clicks')}
      >
        Clicks
      </Button>
      <Button
        className={[
          classes.legendButton,
          filters.includes('conversions') ? classes.btnConversionsAlt : classes.btnConversions,
        ].join(' ')}
        size="small"
        variant={filters.includes('conversions') ? 'outlined' : 'contained'}
        disableElevation
        disableRipple
        disableFocusRipple
        onClick={() => handleFilterClick('conversions')}
      >
        Conversions
      </Button>
      <Button
        className={[
          classes.legendButton,
          filters.includes('partnerCommission') ? classes.btnPartnerCommissionAlt : classes.btnPartnerCommission,
        ].join(' ')}
        size="small"
        variant={filters.includes('partnerCommission') ? 'outlined' : 'contained'}
        disableElevation
        disableRipple
        disableFocusRipple
        onClick={() => handleFilterClick('partnerCommission')}
      >
        Partner Commission
      </Button>
      <Button
        className={[
          classes.legendButton,
          filters.includes('heylinkCommission') ? classes.btnHeylinkCommissionAlt : classes.btnHeylinkCommission,
        ].join(' ')}
        size="small"
        variant={filters.includes('heylinkCommission') ? 'outlined' : 'contained'}
        disableElevation
        disableRipple
        disableFocusRipple
        onClick={() => handleFilterClick('heylinkCommission')}
      >
        Heylink Commission
      </Button>
      <Button
        className={[
          classes.legendButton,
          filters.includes('commission') ? classes.btnCommissionAlt : classes.btnCommission,
        ].join(' ')}
        size="small"
        variant={filters.includes('commission') ? 'outlined' : 'contained'}
        disableElevation
        disableRipple
        disableFocusRipple
        onClick={() => handleFilterClick('commission')}
      >
        Commission
      </Button>
    </div>
  );
}
