import { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { makeCall } from 'utils/utils';
import { Record, UpdateFormData as FormData } from './types';
import EnhancedTextField from 'components/EnhancedTextField';

type PropsType = {
  open: boolean;
  handleClose: () => void;
  record: Record | null;
  refresh: () => void;
};

export default function Update({ open, handleClose, record, refresh }: PropsType) {
  const [isCopied, setIsCopied] = useState(false);
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
  } = useForm<FormData>();

  useEffect(() => {
    if (record) {
      reset({
        accountTypeName: record.accountTypeName,
      });
    }
  }, [record, reset]);

  const onSubmit = async (data: FormData) => {
    const payload = {
      ...data,
    };
    await makeCall(`accounts/types/${record?.accountTypeUuid}`, 'PUT', payload);
    close();
    refresh();
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(record?.accountTypeUuid ?? '');
    setIsCopied(true);
  };

  const close = () => {
    setIsCopied(false);
    handleClose();
  };

  return (
    <div>
      <Dialog open={open} disableBackdropClick={true} onClose={close} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Update Account type</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <DialogContentText>
              ID: {record?.accountTypeUuid}{' '}
              <Button disabled={isCopied} color="primary" onClick={handleCopy}>
                {isCopied ? 'Copied' : 'Copy'}
              </Button>
            </DialogContentText>
            <EnhancedTextField
              control={control}
              required={true}
              fieldName="accountTypeName"
              label="Account type name"
              hasError={errors.accountTypeName ? true : false}
              errorTxt="Account type name is required!"
              autoFocus
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={close} color="primary">
              Cancel
            </Button>
            <Button disabled={isSubmitting} type="submit" color="primary">
              {isSubmitting ? 'Updating...' : 'Update'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
