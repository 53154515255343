import React, { useState } from 'react';
import { Paper, Grid, Typography } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import Buttons from './Buttons';
import Lines from './Lines';
import { LinesGraphType } from '../../types';

const useStyles = makeStyles({
  root: {
    marginTop: '22px',
    marginBottom: '22px',
  },
  paperContainer: {
    padding: '16px',
  },
});

type Props = {
  data: LinesGraphType[];
};

export default function Chart({ data }: Props) {
  const classes = useStyles();
  const [filters, setFilter] = useState<string[]>(['partnerCommission', 'heylinkCommission']);

  const handleFilterClick = (key: string) => {
    if (filters.includes(key)) {
      const newFilters = filters.filter((val) => val !== key);
      setFilter(newFilters);
    } else {
      const newArr = [...filters, key];
      setFilter(newArr);
    }
  };
  return (
    <Grid className={classes.root} container>
      <Grid item xs={12}>
        <Paper className={classes.paperContainer} variant="outlined">
          <Grid justify="space-between" alignItems="center" container>
            <div>
              <Typography variant="subtitle2" color="primary">
                Performance graph
              </Typography>
            </div>
            <Buttons filters={filters} handleFilterClick={handleFilterClick} />
          </Grid>
          <Lines filters={filters} data={data} />
        </Paper>
      </Grid>
    </Grid>
  );
}
