import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useForm, Controller } from 'react-hook-form';
import { makeCall } from 'utils/utils';
import Switch from 'components/EnhancedSwitch';
import EnhancedTextField from 'components/EnhancedTextField';
import { createDefaultValues as defaultValues } from './initialData';
import { CreateFormData as FormData, CreateFormPropsType as PropsType } from './types';

export default function Create({ open, handleClose, refresh, accounts, medias }: PropsType) {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
  } = useForm<FormData>({ defaultValues });

  const resetForm = () => {
    reset(defaultValues);
    handleClose();
  };

  const onSubmit = async (data: FormData) => {
    const payload = {
      ...data,
      trackerMediaExcludeAccountUuid: data.trackerMediaExcludeAccountUuid?.accountUuid ?? '',
      trackerMediaExcludeMediaUuid: data.trackerMediaExcludeMediaUuid?.mediaUuid ?? '',
    };

    await makeCall('trackers/trackerMediaExcludes', 'POST', payload);
    reset(defaultValues);
    handleClose();
    refresh();
  };

  return (
    <>
      <Dialog
        open={open}
        disableBackdropClick={true}
        onEscapeKeyDown={resetForm}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">Create New Tracker Media Exclude</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <EnhancedTextField
                  control={control}
                  fieldName="trackerMediaExcludeDomain"
                  label="Domain"
                  hasError={errors.trackerMediaExcludeDomain ? true : false}
                  errorTxt="Domain is required!"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <EnhancedTextField
                  control={control}
                  fieldName="trackerMediaExcludeUrl"
                  label="URL"
                  hasError={errors.trackerMediaExcludeUrl ? true : false}
                  errorTxt="URL is required!"
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="trackerMediaExcludeAccountUuid"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="account-select"
                      options={accounts}
                      onChange={(_, data) => field.onChange(data)}
                      getOptionLabel={(option) => option.accountName}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          error={errors.trackerMediaExcludeAccountUuid ? true : false}
                          helperText={errors.trackerMediaExcludeAccountUuid ? 'Account is required!' : null}
                          {...params}
                          label="Account"
                          margin="dense"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="trackerMediaExcludeMediaUuid"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="media-select"
                      options={medias}
                      onChange={(_, data) => field.onChange(data)}
                      getOptionLabel={(option) => option.mediaName}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          error={errors.trackerMediaExcludeMediaUuid ? true : false}
                          helperText={errors.trackerMediaExcludeMediaUuid ? 'Media is required!' : null}
                          {...params}
                          label="Media"
                          margin="dense"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Switch isChecked={false} control={control} fieldName="trackerMediaExcludeStatus" label="Status" />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={resetForm} color="primary">
              Cancel
            </Button>
            <Button disabled={isSubmitting} type="submit" color="primary">
              {isSubmitting ? 'Creating...' : 'Create'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
