import { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { makeCall } from 'utils/utils';
import { tableContainerStyles as useStyles } from 'styles/common';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import WithMotion from 'components/withMotion';
import { ModalType, Account } from 'types/global';
import { Data, Record } from './types';
import { columns, texts } from './initialData';
import Create from './Create';
import Update from './Update';
import Confirm from 'components/ConfirmImportV2';
import EnhancedSnackbar from 'components/EnhancedSnackbar';

function PageContainer() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<Data[]>([]);
  const [modalType, setModalType] = useState<ModalType>(null);
  const [record, setRecord] = useState<Record>(null);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [disabledRows, setDisabledRows] = useState<string[]>([]);
  const [importId, setImportId] = useState('');

  useEffect(() => {
    makeCall('accounts', 'GET').then((res) => setAccounts(res?.data.data));
  }, []);

  useEffect(() => {
    if (shouldRefresh) {
      makeCall(`${texts.apiKey}/connections`, 'GET').then((res) => {
        setData(res?.data.data);
        setShouldRefresh(false);
        setIsLoading(false);
      });
    }
  }, [shouldRefresh]);

  const getRecord = async (clickedRow: Data) => {
    const response = await makeCall(`${texts.apiKey}/connections/${clickedRow.connectionUuid}`, 'GET');
    setRecord(response?.data.data);
    setModalType('update');
  };

  const refresh = (key: string) => {
    const message = `Connection ${key} successfully!`;
    setToastMessage(message);
    setToastOpen(true);
    setIsLoading(true);
    setShouldRefresh(true);
  };

  const handleImportClick = (clickedRow: Data) => {
    setModalType('confirm');
    setImportId(clickedRow.connectionUuid);
  };

  const importProgram = async () => {
    await makeCall(`${texts.apiKey}/connections/${importId}/import/programs`, 'POST');
    const newDisabledRows = [...disabledRows, importId];
    setModalType(null);
    setDisabledRows(newDisabledRows);
    setImportId('');
  };

  return (
    <>
      <Create
        open={modalType === 'create'}
        handleClose={() => setModalType(null)}
        refresh={() => refresh('created')}
        accounts={accounts}
      />
      <Update
        open={modalType === 'update'}
        handleClose={() => setModalType(null)}
        refresh={() => refresh('updated')}
        record={record}
      />
      <Confirm open={modalType === 'confirm'} handleClose={() => setModalType(null)} handleConfirm={importProgram} />
      <EnhancedSnackbar open={toastOpen} message={toastMessage} onClose={() => setToastOpen(false)} />
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <MaterialTable
            title={`${texts.title} Connection`}
            components={{
              Toolbar: (props) => (
                <div className={classes.toolbarWrapper}>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={{
              search: true,
              pageSize: 10,
              actionsColumnIndex: 9,
              actionsCellStyle: { color: '#666' },
            }}
            actions={[
              {
                icon: 'add',
                tooltip: `Create ${texts.title} connection`,
                isFreeAction: true,
                onClick: () => setModalType('create'),
              },
              (rowData) => ({
                icon: 'system_update_alt_icon',
                tooltip: 'Import programs',
                isFreeAction: false,
                onClick: (event, rowData: any) => handleImportClick(rowData),
                disabled: disabledRows.includes(rowData.connectionUuid),
              }),

              {
                icon: 'edit',
                tooltip: `Update ${texts.title} connection`,
                isFreeAction: false,
                onClick: (event, rowData: any) => getRecord(rowData),
              },
            ]}
            isLoading={isLoading}
            columns={columns}
            data={data}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default WithMotion(PageContainer);
