import { useState, useEffect } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { makeCall } from 'utils/utils';
import { tableContainerStyles as useStyles } from 'styles/common';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import WithMotion from 'components/withMotion';
import { Data } from './types';
import { columns } from './initialData';
import { format } from 'date-fns';
import Datepicker from 'components/DatepickerV2';
import Tabs from './Tabs';
import Filters from './Filters';
import { Record } from 'pages/Programs/Trackers/types';
import UpdateDialog from 'pages/Programs/Trackers/Update';
import { Country, Currency } from 'types/global';

const useTabsContainerStyles = makeStyles((theme) => ({
  tabsContainer: {
    marginTop: theme.spacing(4),
  },
}));

function PageContainer() {
  const classes = useStyles();
  const tabsClasses = useTabsContainerStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<Data | null>(null);
  const [group, setGroup] = useState<keyof Data>('medias');
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [dateObj, setDateObj] = useState({
    startDate: format(new Date(), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd'),
  });
  const [filters, setFilters] = useState<any>({
    startDate: '',
    endDate: '',
  });
  const [chips, setChips] = useState<any>({});
  const [trackerRecord, setTrackerRecord] = useState<Record | null>(null);
  const [isTrackerUpdateVisible, setIsTrackerUpdateVisible] = useState(false);
  const [countries, setCountries] = useState<Country[]>([]);
  const [currencies, setCurrencies] = useState<Currency[]>([]);

  useEffect(() => {
    function getData() {
      makeCall('currencies', 'GET').then((res) => setCurrencies(res?.data.data));
      makeCall('countries', 'GET').then((res) => setCountries(res?.data.data));
    }
    getData();
  }, []);

  useEffect(() => {
    if (shouldRefresh) {
      const payload = { ...filters };
      if (!payload.startDate && !payload.endDate) {
        payload.startDate = dateObj.startDate;
        payload.endDate = dateObj.endDate;
      }
      makeCall(`discovers`, 'GET', payload).then((res) => {
        setData(res?.data.data);
        setIsLoading(false);
        setShouldRefresh(false);
      });
    }
  }, [shouldRefresh, dateObj, filters]);

  const setDate = (start: Date, end: Date) => {
    setDateObj({
      startDate: format(start, 'yyyy-MM-dd'),
      endDate: format(end, 'yyyy-MM-dd'),
    });
    setIsLoading(true);
    setShouldRefresh(true);
  };

  const handleGroupChange = (group: keyof Data) => {
    if (group === 'opportunities') {
      const newFilters = { ...filters };
      newFilters.opportunities = true;
      setFilters(newFilters);
      setIsLoading(true);
      setShouldRefresh(true);
    }
    if (filters.opportunities) {
      const newFilters = { ...filters };
      delete newFilters.opportunities;
      setFilters(newFilters);
    }
    setGroup(group);
  };

  const handleTrackerRowClick = async (row: any) => {
    if (row?.tracker?.trackerUuid) {
      const response = await makeCall(`trackers/${row.tracker.trackerUuid}`, 'GET');
      setTrackerRecord(response?.data.data);
      setIsTrackerUpdateVisible(true);
    }
    return false;
  };

  const handleCloseDialog = () => {
    setIsTrackerUpdateVisible(false);
    setTrackerRecord(null);
  };

  const handleFilterClick = (row: any) => {
    const newFilters = { ...filters };
    const newChips = { ...chips };
    if (group === 'medias') {
      newFilters.mediaUuid = row.mediaUuid;
      newChips.mediaUuid = row.name;
    }
    if (group === 'accounts') {
      newFilters.accountUuid = row.accountUuid;
      newChips.accountUuid = row.name;
    }
    if (group === 'dates') {
      newFilters.startDate = row.date;
      newFilters.endDate = row.date;
      newChips.date = row.date;
    }
    if (group === 'domains') {
      newFilters.targetDomain = row.domain;
      newChips.targetDomain = row.domain;
    }
    if (group === 'targets') {
      newFilters.targetUrl = row.target;
      newChips.targetUrl = row.target;
    }
    if (group === 'pageUrls') {
      newFilters.pageUrl = row.pageUrl;
      newChips.pageUrl = row.pageUrl;
    }
    setFilters(newFilters);
    setChips(newChips);
    setIsLoading(true);
    setShouldRefresh(true);
  };

  const handleFilterRemove = (filterId: string) => {
    const newFilters = { ...filters };
    const newChips = { ...chips };
    if (filterId === 'date') {
      delete newChips[filterId];
      newFilters.startDate = '';
      newFilters.endDate = '';
    } else {
      delete newFilters[filterId];
      delete newChips[filterId];
    }
    setFilters(newFilters);
    setChips(newChips);
    setIsLoading(true);
    setShouldRefresh(true);
  };

  return (
    <>
      <UpdateDialog
        open={isTrackerUpdateVisible}
        handleClose={handleCloseDialog}
        refresh={handleCloseDialog}
        countries={countries}
        currencies={currencies}
        record={trackerRecord}
      />
      <Grid container spacing={0} justify="space-between" alignItems="center">
        <Grid item xs={9}>
          <Typography variant="h5">Discovers</Typography>
          <Typography gutterBottom color="textSecondary">
            View discovers
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Datepicker change={setDate} startDate={new Date()} endDate={new Date()} />
        </Grid>
      </Grid>
      <Grid container className={tabsClasses.tabsContainer}>
        <Tabs handleGroupChange={handleGroupChange} group={group} />
      </Grid>
      <Grid container>
        <Filters filters={chips} handleFilterClick={handleFilterRemove} />
      </Grid>
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <MaterialTable
            title="Discovers"
            components={{
              Toolbar: (props) => (
                <div className={classes.toolbarWrapper}>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={{
              search: true,
              pageSize: 10,
              actionsColumnIndex: 0,
              actionsCellStyle: { color: '#666' },
            }}
            actions={[
              {
                icon: group === 'opportunities' ? 'edit' : 'filter_list',
                tooltip: group === 'opportunities' ? `Update tracker` : 'Filter',
                isFreeAction: false,
                onClick: (event, rowData: any) =>
                  group === 'opportunities' ? handleTrackerRowClick(rowData) : handleFilterClick(rowData),
              },
            ]}
            isLoading={isLoading}
            columns={columns[group]}
            data={data ? data[group] : []}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default WithMotion(PageContainer);
