import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import {
  DialogContent,
  Grid,
  Paper,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Divider,
  TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useForm, Controller } from 'react-hook-form';
import { makeCall } from 'utils/utils';
import EnhancedTextField from 'components/EnhancedTextField';
import EnhancedSwitch from 'components/EnhancedSwitch';
import Clipboard from 'components/ClipboardV2';
import EnhancedSnackbar from 'components/EnhancedSnackbar';
import { UpdateFormPropsType as PropsType, UpdateFormData } from './types';
import CommissionRulesTable from 'pages/CommissionRule/Table';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
      marginBottom: theme.spacing(3),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
      color: '#fff',
    },
    chipsRoot: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginTop: theme.spacing(2),

      '& > *': {
        margin: theme.spacing(1),
      },
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Update({ handleClose, open, refresh, record, countries }: PropsType) {
  const classes = useStyles();
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [advertiserSync, setAdvertiserSync] = useState(false);

  const [logo, setLogo] = useState<{ currentFile: any; previewImage: any }>({
    currentFile: '',
    previewImage: '',
  });
  const [isUploadingLogo, setIsUploadingLogo] = useState(false);

  const onClose = () => {
    handleClose();
    setToastMessage('');
    setToastOpen(false);
    setIsUploadingLogo(false);
    setAdvertiserSync(false);
    setLogo({
      currentFile: '',
      previewImage: '',
    });
  };

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
  } = useForm<UpdateFormData>();

  useEffect(() => {
    if (record) {
      const selectedCountry = countries.filter((row) => row.countryCode === record.merchantContactCountryCode);
      reset({
        merchantName: record.merchantName,
        merchantUrl: record.merchantUrl,
        merchantFavicon: record.merchantFavicon,
        merchantForceHttp: record.merchantForceHttp,
        merchantForceHttps: record.merchantForceHttps,
        merchantForceWww: record.merchantForceWww,
        merchantForceRemoveWww: record.merchantForceRemoveWww,
        merchantContactPhone: record.merchantContactPhone,
        merchantContactAddress: record.merchantContactAddress,
        merchantContactCity: record.merchantContactCity,
        merchantContactCountryCode: selectedCountry.length ? selectedCountry[0] : null,
        merchantContactEmail: record.merchantContactEmail,
        merchantContactName: record.merchantContactName,
        merchantContactZipCode: record.merchantContactZipCode,
      });
    }
  }, [record, reset, countries]);

  const onSubmit = async (data: UpdateFormData) => {
    const payload = {
      ...data,
      merchantContactCountryCode: data.merchantContactCountryCode ? data.merchantContactCountryCode.countryCode : '',
    };
    await makeCall(`merchants/${record?.merchantUuid}`, 'PUT', payload);
    onClose();
    refresh();
  };

  const handleLogoSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    setLogo({
      currentFile: file,
      previewImage: URL.createObjectURL(file),
    });
  };

  const handleLogoUpload = async () => {
    if (logo.currentFile && record) {
      setIsUploadingLogo(true);
      const imageData = new FormData();
      imageData.append('file', logo.currentFile);
      try {
        await makeCall(`merchants/${record.merchantUuid}/logo`, 'PUT', imageData);
        setIsUploadingLogo(false);
        setToastMessage('Advertiser logo uploaded successfully!');
        setToastOpen(true);
      } catch (error) {
        console.log(error);
        setIsUploadingLogo(false);
      }
    }
  };

  const handleAdvertiserSync = async () => {
    if (record) {
      setAdvertiserSync(true);
      await makeCall(`merchants/${record.merchantUuid}/sync`, 'GET');
      setToastMessage('Advertiser synced successfully!');
      setToastOpen(true);
      setAdvertiserSync(false);
    }
  };

  return (
    <>
      <EnhancedSnackbar open={toastOpen} message={toastMessage} onClose={() => setToastOpen(false)} />
      <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Update Advertiser
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ marginBottom: '20px' }}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Paper variant="outlined" style={{ padding: '20px' }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Clipboard id={record?.merchantUuid ?? ''} />
                      <Divider />
                    </Grid>
                    <Grid item xs={6}>
                      <EnhancedTextField
                        control={control}
                        fieldName="merchantName"
                        label="Advertiser name"
                        hasError={errors.merchantName ? true : false}
                        errorTxt="Advertiser name is required!"
                        autoFocus
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <EnhancedTextField
                        control={control}
                        fieldName="merchantUrl"
                        label="Advertiser Url"
                        hasError={errors.merchantUrl ? true : false}
                        errorTxt="Advertiser Url is required!"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <EnhancedTextField
                        control={control}
                        fieldName="merchantFavicon"
                        label="Advertiser Favicon"
                        required={false}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <EnhancedTextField
                        control={control}
                        fieldName="merchantContactName"
                        label="Contact name"
                        required={false}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <EnhancedTextField
                        control={control}
                        fieldName="merchantContactEmail"
                        label="Contact email"
                        required={false}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <EnhancedTextField
                        control={control}
                        fieldName="merchantContactPhone"
                        label="Contact phone"
                        required={false}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <EnhancedTextField
                        control={control}
                        fieldName="merchantContactAddress"
                        label="Contact address"
                        required={false}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <EnhancedTextField
                        control={control}
                        fieldName="merchantContactCity"
                        label="Contact city"
                        required={false}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <EnhancedTextField
                        control={control}
                        fieldName="merchantContactZipCode"
                        label="Contact zipcode"
                        required={false}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="merchantContactCountryCode"
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            id="country-select"
                            options={countries}
                            onChange={(_, data) => field.onChange(data)}
                            getOptionLabel={(option) => option.countryName}
                            fullWidth
                            renderInput={(params) => <TextField {...params} label="Country" margin="dense" />}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <EnhancedSwitch
                        isChecked={!!record?.merchantForceHttps}
                        control={control}
                        fieldName="merchantForceHttps"
                        label="Force Https"
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <EnhancedSwitch
                        isChecked={!!record?.merchantForceHttp}
                        control={control}
                        fieldName="merchantForceHttp"
                        label="Force Http"
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <EnhancedSwitch
                        isChecked={!!record?.merchantForceWww}
                        control={control}
                        fieldName="merchantForceWww"
                        label="Force WWW"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <EnhancedSwitch
                        isChecked={!!record?.merchantForceRemoveWww}
                        control={control}
                        fieldName="merchantForceRemoveWww"
                        label="Force remove WWW"
                      />
                    </Grid>
                    <Grid item xs={7}></Grid>
                    <Grid item xs={3}>
                      <Button
                        disabled={advertiserSync}
                        onClick={handleAdvertiserSync}
                        color="primary"
                        variant="contained"
                        fullWidth
                      >
                        {advertiserSync ? 'Syncing...' : 'sync advertiser'}
                      </Button>
                    </Grid>
                    <Grid item xs={2}>
                      <Button disabled={isSubmitting} type="submit" color="primary" variant="contained" fullWidth>
                        {isSubmitting ? 'Updating...' : 'Update'}
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </form>
            </Grid>
            <Grid item xs={4}>
              <Paper variant="outlined" style={{ padding: '20px', height: '350px' }}>
                <Typography variant="h6" color="primary">
                  Advertiser Logo
                </Typography>
                <Divider />
                <Grid container style={{ marginTop: '30px' }}>
                  <Grid item xs={10}>
                    <label htmlFor="btn-upload-logo">
                      <input
                        id="btn-upload-logo"
                        name="btn-upload-logo"
                        style={{ display: 'none' }}
                        type="file"
                        accept="image/*"
                        onChange={handleLogoSelect}
                      />
                      <Button className="btn-choose" variant="outlined" component="span">
                        Choose Image
                      </Button>
                    </label>
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      disabled={isUploadingLogo || !logo.previewImage}
                      type="button"
                      color="primary"
                      variant="contained"
                      onClick={handleLogoUpload}
                      fullWidth
                    >
                      {isUploadingLogo ? 'Uploading...' : 'Upload'}
                    </Button>
                  </Grid>
                  {logo.previewImage && (
                    <div
                      style={{
                        width: '150px',
                        height: '100px',
                        margin: '35px auto',
                      }}
                    >
                      <img style={{ maxWidth: '100%', maxHeight: '100%' }} src={logo.previewImage} alt="deal cover" />
                    </div>
                  )}
                  {!logo.previewImage && record?.merchantLogoUrl && (
                    <div
                      style={{
                        width: '150px',
                        height: '100px',
                        margin: '35px auto',
                      }}
                    >
                      <img
                        style={{ maxWidth: '100%', maxHeight: '100%' }}
                        src={record?.merchantLogoUrl}
                        alt="advertiser logo"
                      />
                    </div>
                  )}
                </Grid>
              </Paper>
            </Grid>
            {record && (
              <Grid item xs={12}>
                <CommissionRulesTable
                  shouldCreateDisabled={false}
                  searchOptions={{
                    commissionRuleMerchantUuid: record?.merchantUuid,
                  }}
                  options={{
                    merchant: { merchantUrl: record.merchantUrl, merchantUuid: record.merchantUuid },
                  }}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
