import React, { useState, useEffect, useRef } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import {
  DialogContent,
  Grid,
  TextField,
  Paper,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Divider,
  Chip,
  Switch,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { makeCall } from 'utils/utils';
import {
  UpdateFormData,
  UpdateFormPropsType as PropsType,
  DealImage,
  Account,
  Tracker,
  Media,
  MediaValueType,
  AccountValueType,
  TrackerValueType,
  NetworkValueType,
  DealExcludes,
  DealTracker,
} from './types';
import EnhancedTextField from 'components/EnhancedTextField';
import EnhancedSwitch from 'components/EnhancedSwitch';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Clipboard from 'components/ClipboardV2';
import { DateTimePicker } from '@material-ui/pickers';
import EnhancedSnackbar from 'components/EnhancedSnackbar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
      marginBottom: theme.spacing(3),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
      color: '#fff',
    },
    chipsRoot: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginTop: theme.spacing(2),

      '& > *': {
        margin: theme.spacing(1),
      },
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function Update({ open, handleClose, refresh, record, countries, networks, admins, currencies }: PropsType) {
  const classes = useStyles();
  const [coverImage, setCoverImage] = useState<{ currentFile: any; previewImage: any }>({
    currentFile: '',
    previewImage: '',
  });
  const [isUploadingCover, setIsUploadingCover] = useState(false);
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [dealImages, setDealImages] = useState<DealImage[] | null>(null);
  const [shouldFetchDealImages, setShouldFetchDealImages] = useState(false);

  const [dealExcludes, setDealExcludes] = useState<DealExcludes[] | null>(null);
  const [dealTrackers, setDealTrackers] = useState<DealTracker[] | null>(null);

  const [masterChannels, setMasterChannels] = useState<Media[]>([]);
  const [channels, setChannels] = useState<Media[]>([]);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [trackers, setTrackers] = useState<Tracker[]>([]);

  const [excludeChannelValue, setExcludeChannelValue] = useState<MediaValueType>(null);

  const trackerNetworkValue = useRef<NetworkValueType>(null);
  const trackerAccountValue = useRef<AccountValueType>(null);
  const trackerChannelValue = useRef<MediaValueType>(null);
  const trackerTrackerValue = useRef<TrackerValueType>(null);

  const [trackerFormCounter, setTrackerFormCounter] = useState(0);

  const [image, setImage] = useState<{ currentFile: any; previewImage: any }>({
    currentFile: '',
    previewImage: '',
  });

  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const onClose = () => {
    handleClose();
    setCoverImage({ currentFile: '', previewImage: '' });
    setIsUploadingCover(false);
    setIsUploadingImage(false);
    setDealExcludes(null);
    setDealTrackers(null);
    setTrackers([]);
    setChannels([]);
    setExcludeChannelValue(null);
    setTrackerFormCounter(0);
    trackerNetworkValue.current = null;
    trackerAccountValue.current = null;
    trackerChannelValue.current = null;
    trackerTrackerValue.current = null;
    setImage({
      currentFile: '',
      previewImage: '',
    });
  };

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
  } = useForm<UpdateFormData>();

  const handleTrackerAccountChange = () => {
    const filteredChannels = masterChannels.filter(
      (row) => row.mediaAccountUuid === trackerAccountValue.current?.accountUuid
    );
    setChannels(filteredChannels);
    if (trackerChannelValue.current) {
      trackerChannelValue.current = null;
    }
    handleTrackerFetch();
  };

  const handleTrackerFetch = async () => {
    if (record && trackerNetworkValue.current && trackerAccountValue.current && trackerChannelValue.current) {
      const payload = {
        trackerAccountUuid: trackerAccountValue.current?.accountUuid,
        trackerMediaUuid: trackerChannelValue.current?.mediaUuid,
        trackerNetworkUuid: trackerNetworkValue.current?.networkUuid,
        trackerMerchantUuid: record?.advertiser.advertiserUuid,
        trackerStatus: true,
        trackerDisabled: false,
      };
      const response = await makeCall('trackers', 'GET', payload);
      setTrackers(response?.data.data);
    }
    if (trackerTrackerValue.current) {
      trackerTrackerValue.current = null;
    }
    setTrackerFormCounter(trackerFormCounter + 1);
  };

  useEffect(() => {
    const getImages = async () => {
      const response = await makeCall(`deals/${record?.deal.dealUuid}/images`, 'GET');
      if (response?.data.data) {
        setDealImages(response.data.data);
      }
      setShouldFetchDealImages(false);
    };
    if (record && shouldFetchDealImages) {
      getImages();
    }
  }, [shouldFetchDealImages, record]);

  useEffect(() => {
    const getData = async () => {
      makeCall('medias', 'GET').then((res) => setMasterChannels(res?.data.data));
      makeCall('accounts', 'GET').then((res) => setAccounts(res?.data.data));
    };
    if (record && !channels.length && !accounts.length) {
      getData();
    }
  }, [record, channels, accounts]);

  useEffect(() => {
    if (record) {
      const selectedCountry = countries.filter((country) => country.countryCode === record.deal.dealCountryCode);
      const selectedAdmin = admins.filter((admin) => admin.adminUuid === record.deal.dealManagerAdminUuid);
      const selectedCurrency = currencies.filter(
        (currency) => currency.currencyCode === record.deal.dealDiscountCurrencyCode
      );
      reset({
        dealActiveStatus: record.deal.dealActiveStatus,
        dealCountryCode: selectedCountry.length ? selectedCountry[0] : null,
        dealCouponCode: record.deal.dealCouponCode,
        dealDescription: record.deal.dealDescription,
        dealDiscountText: record.deal.dealDiscountText,
        dealDiscountValue: record.deal.dealDiscountValue,
        dealStartDatetime: new Date(record.deal.dealStartDatetime),
        dealExpireDatetime: new Date(record.deal.dealExpireDatetime),
        dealName: record.deal.dealName,
        dealManagerAdminUuid: selectedAdmin.length ? selectedAdmin[0] : null,
        dealTargetUrl: record.deal.dealTargetUrl,
        dealTermsConditions: record.deal.dealTermsConditions,
        dealDiscountCurrencyCode: selectedCurrency.length ? selectedCurrency[0] : null,
        dealDiscountType: record.deal.dealDiscountType,
      });
      setDealImages(record.dealImages);
      setDealExcludes(record.dealExcludes);
      setDealTrackers(record.dealTrackers);
    }
  }, [record, reset, countries, admins, currencies]);

  const onSubmit = async (data: UpdateFormData) => {
    let expireDate = '';
    let startDate = '';
    if (data.dealExpireDatetime) {
      expireDate = new Date(
        data.dealExpireDatetime.getTime() - data.dealExpireDatetime.getTimezoneOffset() * 60000
      ).toISOString();
    }
    if (data.dealStartDatetime) {
      startDate = new Date(
        data.dealStartDatetime.getTime() - data.dealStartDatetime.getTimezoneOffset() * 60000
      ).toISOString();
    }
    const payload = {
      ...data,
      dealCountryCode: data.dealCountryCode?.countryCode ?? '',
      dealManagerAdminUuid: data.dealManagerAdminUuid?.adminUuid,
      dealDiscountCurrencyCode: data.dealDiscountCurrencyCode?.currencyCode ?? '',
      dealStartDatetime: startDate,
      dealExpireDatetime: expireDate,
    };
    await makeCall(`deals/${record?.deal.dealUuid}`, 'PUT', payload);
    onClose();
    refresh();
  };

  const handleCoverImageSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    setCoverImage({
      currentFile: file,
      previewImage: URL.createObjectURL(file),
    });
  };

  const handleCoverImageUpload = async () => {
    if (coverImage.currentFile && record) {
      setIsUploadingCover(true);
      const imageData = new FormData();
      imageData.append('file', coverImage.currentFile);
      try {
        await makeCall(`deals/${record.deal.dealUuid}/image`, 'POST', imageData);
        setIsUploadingCover(false);
      } catch (error) {
        console.log(error);
        setIsUploadingCover(false);
      }
    }
  };

  const handleImageSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    setImage({
      currentFile: file,
      previewImage: URL.createObjectURL(file),
    });
  };

  const handleImageUpload = async () => {
    if (image.currentFile && record) {
      setIsUploadingImage(true);
      const imageData = new FormData();
      imageData.append('file', image.currentFile);
      try {
        await makeCall(`deals/${record.deal.dealUuid}/images`, 'POST', imageData);
        setImage({
          currentFile: '',
          previewImage: '',
        });
        setTimeout(() => {
          setShouldFetchDealImages(true);
          setIsUploadingImage(false);
        }, 1500);
      } catch (error) {
        console.log(error);
        setIsUploadingImage(false);
      }
    }
  };

  const handleDealStatusChange = async (id: string, status: boolean) => {
    if (record) {
      await makeCall(`deals/${record.deal.dealUuid}/images/${id}`, 'PUT', {
        dealImageActiveStatus: status,
      });
    }
  };

  const handleCreateDealExcludeClick = async () => {
    if (record && excludeChannelValue) {
      const payload = {
        dealExcludeChannelUuid: excludeChannelValue.mediaUuid,
      };
      setExcludeChannelValue(null);
      await makeCall(`deals/${record.deal.dealUuid}/excludes`, 'POST', payload);
      await makeCall(`deals/${record.deal.dealUuid}/excludes`, 'GET').then((res) => setDealExcludes(res?.data.data));
    }
  };

  const handleDealExcludeStatus = async (id: string, status: boolean) => {
    if (record) {
      await makeCall(`deals/${record.deal.dealUuid}/excludes/${id}`, 'PUT', {
        dealExcludeActiveStatus: status,
      });
    }
  };

  const handleCreateDealTrackerClick = async () => {
    if (record && trackerAccountValue.current && trackerChannelValue.current && trackerTrackerValue.current) {
      const payload = {
        dealTrackerTrackerUuid: trackerTrackerValue.current?.trackerUuid,
        dealTrackerAccountUuid: trackerAccountValue.current?.accountUuid,
        dealTrackerChannelUuid: trackerChannelValue.current?.mediaUuid,
      };
      trackerNetworkValue.current = null;
      trackerAccountValue.current = null;
      trackerChannelValue.current = null;
      trackerTrackerValue.current = null;
      await makeCall(`deals/${record.deal.dealUuid}/trackers`, 'POST', payload);
      await makeCall(`deals/${record.deal.dealUuid}/trackers`, 'GET').then((res) => {
        setDealTrackers(res?.data.data);
      });
    }
  };

  const handleDealTrackerStatus = async (id: string, status: boolean) => {
    if (record) {
      await makeCall(`deals/${record.deal.dealUuid}/trackers/${id}`, 'PUT', {
        dealTrackerActiveStatus: status,
      });
    }
  };

  const handleTrackerCopyClick = () => {
    if (record && record.deal.dealGlobalTrackerUuid) {
      navigator.clipboard.writeText(record.deal.dealGlobalTrackerUuid);
      setToastMessage('Global tracker ID copied to clipboard!');
      setToastOpen(true);
    }
  };

  return (
    <>
      <EnhancedSnackbar open={toastOpen} message={toastMessage} onClose={() => setToastOpen(false)} />
      <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Update Deal
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ marginBottom: '20px' }}>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Paper variant="outlined" style={{ padding: '20px' }}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <EnhancedTextField
                        control={control}
                        fieldName="dealName"
                        label="Deal name"
                        hasError={errors.dealName ? true : false}
                        errorTxt="Deal name is required!"
                        autoFocus
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <EnhancedTextField
                        control={control}
                        fieldName="dealTargetUrl"
                        label="Target URL"
                        hasError={errors.dealTargetUrl ? true : false}
                        errorTxt="Valid URL is required!"
                        isURL
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="dealDiscountType"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            select
                            label="Discount type"
                            error={errors.dealDiscountType ? true : false}
                            helperText={errors.dealDiscountType ? 'Discount type is required!' : null}
                            margin="dense"
                            fullWidth
                          >
                            <MenuItem value="percentage">Percentage</MenuItem>
                            <MenuItem value="fixed">Fixed</MenuItem>
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <EnhancedTextField
                        control={control}
                        fieldName="dealDiscountValue"
                        label="Discount value"
                        hasError={errors.dealDiscountValue ? true : false}
                        errorTxt="Discount value is required!"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <EnhancedTextField
                        control={control}
                        fieldName="dealDiscountText"
                        label="Discount text"
                        required={false}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <EnhancedTextField
                        control={control}
                        fieldName="dealCouponCode"
                        label="Coupon code"
                        required={false}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <EnhancedTextField
                        control={control}
                        fieldName="dealDescription"
                        label="Description"
                        required={false}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <EnhancedTextField
                        control={control}
                        fieldName="dealTermsConditions"
                        label="Terms & Conditions"
                        required={false}
                        multiline
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Controller
                        name="dealManagerAdminUuid"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            id="admin-select"
                            options={admins}
                            onChange={(_, data) => field.onChange(data)}
                            getOptionLabel={(option) => option.adminFirstname}
                            fullWidth
                            renderInput={(params) => (
                              <TextField
                                error={errors.dealManagerAdminUuid ? true : false}
                                helperText={errors.dealManagerAdminUuid ? 'Admin manager is required!' : null}
                                {...params}
                                label="Admin manager"
                                margin="dense"
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Controller
                        name="dealDiscountCurrencyCode"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            id="currency-select"
                            options={currencies}
                            onChange={(_, data) => field.onChange(data)}
                            getOptionLabel={(option) => option.currencyCode}
                            fullWidth
                            renderInput={(params) => (
                              <TextField
                                error={errors.dealDiscountCurrencyCode ? true : false}
                                helperText={errors.dealDiscountCurrencyCode ? 'Currency is required!' : null}
                                {...params}
                                label="Discount currency"
                                margin="dense"
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Controller
                        name="dealCountryCode"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            id="country-select"
                            options={countries}
                            onChange={(_, data) => field.onChange(data)}
                            getOptionLabel={(option) => option.countryName}
                            fullWidth
                            renderInput={(params) => (
                              <TextField
                                error={errors.dealCountryCode ? true : false}
                                helperText={errors.dealCountryCode ? 'Country is required!' : null}
                                {...params}
                                label="Country"
                                margin="dense"
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="dealStartDatetime"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <DateTimePicker
                            {...field}
                            label="Deal start on"
                            inputVariant="standard"
                            fullWidth
                            error={errors.dealStartDatetime ? true : false}
                            helperText={errors.dealStartDatetime ? 'Deal start date is required!' : null}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="dealExpireDatetime"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <DateTimePicker
                            {...field}
                            label="Deal expires on"
                            inputVariant="standard"
                            fullWidth
                            error={errors.dealExpireDatetime ? true : false}
                            helperText={errors.dealExpireDatetime ? 'Deal expire date is required!' : null}
                            // onChange={(e) => console.log(e)}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={7}></Grid>
                    <Grid item xs={3}>
                      <EnhancedSwitch
                        isChecked={!!record?.deal.dealActiveStatus}
                        control={control}
                        fieldName="dealActiveStatus"
                        label="Status"
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Button disabled={isSubmitting} type="submit" color="primary" variant="contained" fullWidth>
                        {isSubmitting ? 'Updating...' : 'Update'}
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </form>
            </Grid>
            <Grid item xs={4}>
              <Paper variant="outlined" style={{ padding: '20px', height: '386px' }}>
                <Typography variant="h6" color="primary">
                  Deal cover image
                </Typography>
                <Divider />
                <Grid container style={{ marginTop: '20px' }}>
                  <Grid item xs={10}>
                    <label htmlFor="btn-upload">
                      <input
                        id="btn-upload"
                        name="btn-upload"
                        style={{ display: 'none' }}
                        type="file"
                        accept="image/*"
                        onChange={handleCoverImageSelect}
                      />
                      <Button className="btn-choose" variant="outlined" component="span">
                        Choose Image
                      </Button>
                    </label>
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      disabled={isUploadingCover || !coverImage.previewImage}
                      type="button"
                      color="primary"
                      variant="contained"
                      onClick={handleCoverImageUpload}
                      fullWidth
                    >
                      {isUploadingCover ? 'Uploading...' : 'Upload'}
                    </Button>
                  </Grid>
                  {coverImage.previewImage && (
                    <div
                      style={{
                        width: '300px',
                        height: '230px',
                        margin: '25px auto',
                      }}
                    >
                      <img
                        style={{ maxWidth: '100%', maxHeight: '100%' }}
                        src={coverImage.previewImage}
                        alt="deal cover"
                      />
                    </div>
                  )}
                  {!coverImage.previewImage && record?.deal.dealImageUrl && (
                    <div
                      style={{
                        width: '300px',
                        height: '230px',
                        margin: '25px auto',
                      }}
                    >
                      <img
                        style={{ maxWidth: '100%', maxHeight: '100%' }}
                        src={record?.deal.dealImageUrl}
                        alt="deal cover"
                      />
                      <span
                        style={{
                          display: 'inline-block',
                          width: '100%',
                          marginBottom: 0,
                          textAlign: 'center',
                        }}
                      >
                        W: {record?.deal.dealImageWidth}, H: {record?.deal.dealImageHeight}
                      </span>
                    </div>
                  )}
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper variant="outlined" style={{ padding: '25px 10px' }}>
                <Typography variant="h6" color="primary">
                  Deal information
                </Typography>
                <Divider />
                <div className={classes.chipsRoot}>
                  <Clipboard id={record?.deal.dealUuid ?? ''} />
                  <Chip
                    variant="outlined"
                    label={
                      <Typography variant="subtitle2" color="primary">
                        Advertiser: {record?.advertiser.advertiserName ?? '-'}
                      </Typography>
                    }
                    color="primary"
                  />
                  <Chip
                    variant="outlined"
                    label={
                      <Typography variant="subtitle2" color="primary">
                        Global Tracker: {record?.deal.dealGlobalTrackerUuid ? record.globalTracker.trackerName : '-'}
                      </Typography>
                    }
                    onClick={handleTrackerCopyClick}
                    color="primary"
                  />
                  <Chip
                    variant="outlined"
                    label={
                      <Typography variant="subtitle2" color="primary">
                        Created by: {record?.admin.adminName ?? '-'}
                      </Typography>
                    }
                    color="primary"
                  />
                  <Chip
                    variant="outlined"
                    label={
                      <Typography variant="subtitle2" color="primary">
                        Created at: {record?.deal.dealCreatedDatetime ?? '-'}
                      </Typography>
                    }
                    color="primary"
                  />
                  <Chip
                    variant="outlined"
                    label={
                      <Typography variant="subtitle2" color="primary">
                        Updated at: {record?.deal.dealUpdatedDatetime ?? '-'}
                      </Typography>
                    }
                    color="primary"
                  />
                </div>
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Paper variant="outlined" style={{ padding: '20px', height: '386px', marginTop: '20px' }}>
                <Typography variant="h6" color="primary">
                  Upload Deal Image
                </Typography>
                <Divider />
                <Grid container style={{ marginTop: '20px' }}>
                  <Grid item xs={10}>
                    <label htmlFor="btn-upload-single">
                      <input
                        id="btn-upload-single"
                        name="btn-upload-single"
                        style={{ display: 'none' }}
                        type="file"
                        accept="image/*"
                        onChange={handleImageSelect}
                      />
                      <Button className="btn-choose" variant="outlined" component="span">
                        Choose Image
                      </Button>
                    </label>
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      disabled={isUploadingImage}
                      type="button"
                      color="primary"
                      variant="contained"
                      onClick={handleImageUpload}
                      fullWidth
                    >
                      {isUploadingImage ? 'Uploading...' : 'Upload'}
                    </Button>
                  </Grid>
                  {image.previewImage && (
                    <div
                      style={{
                        width: '300px',
                        height: '230px',
                        margin: '25px auto',
                      }}
                    >
                      <img style={{ maxWidth: '100%', maxHeight: '100%' }} src={image.previewImage} alt="deal" />
                    </div>
                  )}
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={8}>
              <Paper
                variant="outlined"
                style={{ padding: '20px', height: '386px', marginTop: '20px', overflow: 'auto' }}
              >
                <Typography variant="h6" color="primary">
                  Deal Images
                </Typography>
                <Divider />
                <Grid container spacing={3} style={{ marginTop: '10px' }}>
                  {dealImages && dealImages.length ? (
                    dealImages.map((image) => (
                      <Grid item xs={2}>
                        <div style={{ width: '100px', height: '100px' }}>
                          <a href={image.dealImageUrl} target="_blank" rel="noreferrer">
                            <img style={{ maxWidth: '100%', maxHeight: '100%' }} src={image.dealImageUrl} alt="deal" />
                          </a>
                        </div>
                        <div>
                          <span style={{ display: 'inline-block', width: '100%', margin: '5px', marginBottom: 0 }}>
                            W: {image.dealImageWidth}, H: {image.dealImageHeight}
                          </span>
                          <FormControlLabel
                            control={
                              <Switch
                                defaultChecked={image.dealImageActiveStatus}
                                onChange={(e) => handleDealStatusChange(image.dealImageUuid, e.target.checked)}
                                name="dealImageStatus"
                                color="primary"
                              />
                            }
                            label="Status"
                          />
                        </div>
                      </Grid>
                    ))
                  ) : (
                    <Grid item xs={12} style={{ marginTop: '150px' }}>
                      <Typography variant="subtitle1" color="primary" align="center">
                        No Deal images found!
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          {record && !record.deal.dealGlobalStatus && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper variant="outlined" style={{ padding: '20px', height: '500px', marginTop: '20px' }}>
                  <Typography variant="h6" color="primary">
                    Deal Trackers
                  </Typography>
                  <Divider />
                  <Grid container spacing={3} style={{ marginTop: '10px' }}>
                    <Grid item xs={2}>
                      <Autocomplete
                        id="tracker-account-select"
                        options={accounts}
                        value={trackerAccountValue.current}
                        onChange={(_, data) => {
                          trackerAccountValue.current = data;
                          handleTrackerAccountChange();
                        }}
                        getOptionLabel={(option) => option.accountName}
                        fullWidth
                        renderInput={(params) => (
                          <TextField {...params} label="Account" margin="dense" variant="outlined" />
                        )}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Autocomplete
                        id="tracker-channel-select"
                        options={channels}
                        value={trackerChannelValue.current}
                        onChange={(_, data) => {
                          trackerChannelValue.current = data;
                          handleTrackerFetch();
                        }}
                        getOptionLabel={(option) => option.mediaName}
                        fullWidth
                        renderInput={(params) => (
                          <TextField {...params} label="Channel" margin="dense" variant="outlined" />
                        )}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Autocomplete
                        id="tracker-network-select"
                        options={networks}
                        value={trackerNetworkValue.current}
                        onChange={(_, data) => {
                          trackerNetworkValue.current = data;
                          handleTrackerFetch();
                        }}
                        getOptionLabel={(option) => option.networkName}
                        fullWidth
                        renderInput={(params) => (
                          <TextField {...params} label="Network" margin="dense" variant="outlined" />
                        )}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Autocomplete
                        id="tracker-tracker-select"
                        options={trackers}
                        value={trackerTrackerValue.current}
                        onChange={(_, data) => {
                          trackerTrackerValue.current = data;
                          setTrackerFormCounter(trackerFormCounter + 1);
                        }}
                        getOptionLabel={(option) => option.trackerName}
                        fullWidth
                        renderInput={(params) => (
                          <TextField {...params} label="Tracker" margin="dense" variant="outlined" />
                        )}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Button
                        disabled={!trackerTrackerValue.current}
                        type="button"
                        color="primary"
                        variant="contained"
                        onClick={handleCreateDealTrackerClick}
                        fullWidth
                        style={{ marginTop: '10px' }}
                      >
                        Add tracker
                      </Button>
                    </Grid>
                  </Grid>
                  <TableContainer style={{ marginTop: '30px' }}>
                    <Table size="small" aria-label="tracker-table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Account</TableCell>
                          <TableCell>Channel</TableCell>
                          <TableCell>Tracker</TableCell>
                          <TableCell>Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dealTrackers && dealTrackers.length ? (
                          dealTrackers.map((row) => (
                            <TableRow key={row.dealTrackerUuid}>
                              <TableCell component="th" scope="row">
                                {row.account.accountName}
                              </TableCell>
                              <TableCell>{row.channel.channelName}</TableCell>
                              <TableCell>{row.tracker.trackerName}</TableCell>
                              <TableCell>
                                <Switch
                                  defaultChecked={row.dealTrackerActiveStatus}
                                  onChange={(e) => handleDealTrackerStatus(row.dealTrackerUuid, e.target.checked)}
                                  color="primary"
                                />
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell component="th" scope="row" colSpan={4} align="center">
                              <Typography variant="subtitle1" color="primary" align="center">
                                No deal trackers found!
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            </Grid>
          )}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper
                variant="outlined"
                style={{ padding: '20px', height: '400px', marginTop: '20px', overflow: 'auto' }}
              >
                <Typography variant="h6" color="primary">
                  Deal Excludes
                </Typography>
                <Divider />
                <Grid container spacing={3} style={{ marginTop: '10px' }}>
                  <Grid item xs={4}>
                    <Autocomplete
                      id="exclude-channel-select"
                      options={channels}
                      value={excludeChannelValue}
                      onChange={(_, data) => {
                        setExcludeChannelValue(data);
                      }}
                      getOptionLabel={(option) => option.mediaName}
                      fullWidth
                      renderInput={(params) => (
                        <TextField {...params} label="Channel" margin="dense" variant="outlined" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      disabled={!excludeChannelValue}
                      type="button"
                      color="primary"
                      variant="contained"
                      onClick={handleCreateDealExcludeClick}
                      fullWidth
                      style={{ marginTop: '10px' }}
                    >
                      Create exclude
                    </Button>
                  </Grid>
                </Grid>
                <TableContainer style={{ marginTop: '30px' }}>
                  <Table size="small" aria-label="excludes-table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Account</TableCell>
                        <TableCell>Channel</TableCell>
                        <TableCell>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dealExcludes && dealExcludes.length ? (
                        dealExcludes.map((row) => (
                          <TableRow key={row.dealExcludeUuid}>
                            <TableCell component="th" scope="row">
                              {row.account.accountName}
                            </TableCell>
                            <TableCell>{row.channel.channelName}</TableCell>
                            <TableCell>
                              <Switch
                                defaultChecked={row.dealExcludeActiveStatus}
                                onChange={(e) => handleDealExcludeStatus(row.dealExcludeUuid, e.target.checked)}
                                color="primary"
                              />
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell component="th" scope="row" colSpan={3} align="center">
                            <Typography variant="subtitle1" color="primary" align="center">
                              No deal excludes found!
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
