import { useEffect } from 'react';
import { Media, ConnectionChannel, MediaValueType } from 'types/global';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { makeCall } from 'utils/utils';
import EnhancedSwitch from 'components/EnhancedSwitch';
import Clipboard from 'components/ClipboardV2';
import Autocomplete from '@material-ui/lab/Autocomplete';

type PropsType = {
  record: ConnectionChannel | null;
  medias: Media[];
  handleClose: () => void;
  refresh: () => void;
  open: boolean;
  apiKey: string;
};

type FormProps = {
  connectionChannelMediaUuid: MediaValueType;
  connectionChannelStatus: boolean;
};

export default function DialogContainer({ record, medias, handleClose, refresh, open, apiKey }: PropsType) {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
  } = useForm<FormProps>();

  useEffect(() => {
    if (record) {
      const selectedMedia = medias.filter((row) => row.mediaUuid === record.connectionChannelMediaUuid);
      reset({
        connectionChannelMediaUuid: selectedMedia.length ? selectedMedia[0] : null,
        connectionChannelStatus: record.connectionChannelStatus,
      });
    }
  }, [record, reset, medias]);

  const onSubmit = async (data: FormProps) => {
    const payload = {
      ...data,
      connectionChannelMediaUuid: data.connectionChannelMediaUuid?.mediaUuid ?? '',
    };
    await makeCall(`${apiKey}/connectionChannels/${record?.connectionChannelUuid}`, 'PUT', payload);
    close();
    refresh();
  };

  const close = () => {
    handleClose();
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        disableBackdropClick={true}
        onClose={close}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Update Connection Channel</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Clipboard id={record?.connectionChannelUuid ?? ''} />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  name="connectionChannelMediaUuid"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="media-select"
                      options={medias}
                      onChange={(_, data) => field.onChange(data)}
                      getOptionLabel={(option) => option.mediaUrl}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          error={errors.connectionChannelMediaUuid ? true : false}
                          helperText={errors.connectionChannelMediaUuid ? 'Media is required!' : null}
                          {...params}
                          label="Media"
                          margin="dense"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <EnhancedSwitch
                  isChecked={!!record?.connectionChannelStatus}
                  control={control}
                  fieldName="connectionChannelStatus"
                  label="Status"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={close} color="primary">
              Cancel
            </Button>
            <Button disabled={isSubmitting} type="submit" color="primary">
              {isSubmitting ? 'Updating...' : 'Update'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
