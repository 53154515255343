import { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { makeCall } from 'utils/utils';
import { tableContainerStyles as useStyles } from 'styles/common';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import WithMotion from 'components/withMotion';
import { ModalType } from 'types/global';
import { Data, Record } from './types';
import { columns } from './initialData';
import Create from './Create';
import Update from './Update';
import EnhancedSnackbar from 'components/EnhancedSnackbar';

function Networks() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<Data[]>([]);
  const [modalType, setModalType] = useState<ModalType>(null);
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [record, setRecord] = useState<Record>(null);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  useEffect(() => {
    const getData = async () => {
      const response = await makeCall('networks', 'GET');
      setData(response?.data.data);
      setIsLoading(false);
      setShouldRefresh(false);
    };
    if (shouldRefresh) {
      getData();
    }
  }, [shouldRefresh]);

  const getRecord = async (clickedRow: any) => {
    const response = await makeCall(`networks/${clickedRow.networkUuid}`, 'GET');
    setRecord(response?.data.data);
    setModalType('update');
  };

  const refresh = (key: string) => {
    const message = `Network ${key} successfully!`;
    setToastMessage(message);
    setToastOpen(true);
    setIsLoading(true);
    setShouldRefresh(true);
  };

  return (
    <>
      <Create open={modalType === 'create'} handleClose={() => setModalType(null)} refresh={() => refresh('created')} />
      <Update
        open={modalType === 'update'}
        handleClose={() => setModalType(null)}
        refresh={() => refresh('updated')}
        record={record}
      />
      <EnhancedSnackbar open={toastOpen} message={toastMessage} onClose={() => setToastOpen(false)} />
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <MaterialTable
            title="Networks"
            components={{
              Toolbar: (props) => (
                <div className={classes.toolbarWrapper}>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={{
              search: true,
              pageSize: 10,
              actionsColumnIndex: 9,
              actionsCellStyle: { color: '#666' },
            }}
            actions={[
              {
                icon: 'add',
                tooltip: 'Create Network',
                isFreeAction: true,
                onClick: () => setModalType('create'),
              },
              {
                icon: 'edit',
                tooltip: 'Update Network',
                isFreeAction: false,
                onClick: (event, rowData: any) => getRecord(rowData),
              },
            ]}
            isLoading={isLoading}
            columns={columns}
            data={data}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default WithMotion(Networks);
