import { Paper, Grid, Typography, Card, CardContent, Divider } from '@material-ui/core/';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { BarsGraphType } from '../../types';
import { BarChart, Bar, Tooltip, YAxis, XAxis, Text, ResponsiveContainer } from 'recharts';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    '& .recharts-cartesian-axis-tick': {
      fontSize: '14px',
      fontFamily: 'Rubik, sans-serif',
    },
  },
  paperContainer: {
    padding: '16px',
  },
}));

type Props = {
  data: BarsGraphType[];
};

export default function BarChartContainer({ data }: Props) {
  const classes = useStyles();
  const theme = useTheme();

  const renderTooltip = (props: any) => {
    if (typeof props.payload != 'undefined' && props.payload != null) {
      if (typeof props.payload[0] != 'undefined') {
        const formatter = new Intl.NumberFormat('da-DK');
        return (
          <Card style={{ zIndex: 1000 }}>
            <CardContent>
              <Typography variant="subtitle2">
                <b>{props.payload[0].payload.grpName}</b>
              </Typography>
              <Divider />
              <Typography style={{ color: theme.pageviews.primary }}>
                Pageviews: {formatter.format(props.payload[0].payload.pageviews)}
              </Typography>
              <Typography style={{ color: theme.clicks.primary }}>
                Clicks: {formatter.format(props.payload[0].payload.clicks)}
              </Typography>
              <Typography style={{ color: theme.conversions.primary }}>
                Conversions: {formatter.format(props.payload[0].payload.conversions)}
              </Typography>
              <Divider />
              <Typography style={{ color: theme.partnerCommission.primary }}>
                Partner Commission: {window.localStorage.getItem('publisherCurrency') || 'DKK'}{' '}
                {formatter.format(props.payload[0].payload.partnerCommissionSum)}
              </Typography>
              <Typography style={{ color: theme.heylinkCommission.primary }}>
                Heylink Commission: {window.localStorage.getItem('publisherCurrency') || 'DKK'}{' '}
                {formatter.format(props.payload[0].payload.heylinkCommissionSum)}
              </Typography>
              <Typography style={{ color: theme.commission.primary }}>
                Total Commission: {window.localStorage.getItem('publisherCurrency') || 'DKK'}{' '}
                {formatter.format(props.payload[0].payload.totalCommissionSum)}
              </Typography>
            </CardContent>
          </Card>
        );
      }
    }
  };

  const renderLabel = (props: any, rows: any) => {
    return (
      <svg>
        <Text {...props}>{props.payload.value}</Text>
        {props.index !== 9 && (
          <image x={props.x - 12} y={props.y + 24} href={rows[props.index].favicon} width="24" height="24" />
        )}
      </svg>
    );
  };
  return (
    <Grid className={classes.root} container>
      <Grid item xs={12}>
        <Paper className={classes.paperContainer} variant="outlined">
          <Grid justify="space-between" alignItems="center" container>
            <div>
              <Typography variant="subtitle2" color="primary">
                Performance graph
              </Typography>
            </div>
          </Grid>
          <div>
            <ResponsiveContainer width="100%" height={350}>
              <BarChart data={data} margin={{ top: 5, right: 0, left: 0, bottom: 26 }}>
                <Tooltip
                  cursor={{ fill: 'rgba(0, 0, 150, 0.024)' }}
                  content={(props: any) => renderTooltip(props)}
                  wrapperStyle={{ zIndex: 10000 }}
                />

                <YAxis yAxisId="clicks" hide allowDataOverflow={true} />
                <YAxis yAxisId="conversions" hide allowDataOverflow={true} />
                <YAxis yAxisId="partnerCommissionSum" hide allowDataOverflow={true} />
                <YAxis yAxisId="heylinkCommissionSum" hide allowDataOverflow={true} />
                <YAxis yAxisId="totalCommissionSum" hide allowDataOverflow={true} />
                <XAxis
                  stroke={theme.palette.primary.main}
                  strokeOpacity={0.3}
                  dataKey="grpName"
                  tick={(props: any) => renderLabel(props, data)}
                />
                <Bar dataKey="clicks" fill={theme.clicks.primary} stackId="a" yAxisId="clicks" />
                <Bar dataKey="conversions" fill={theme.conversions.primary} stackId="a" yAxisId="conversions" />
                <Bar
                  dataKey="partnerCommissionSum"
                  fill={theme.partnerCommission.primary}
                  stackId="a"
                  yAxisId="partnerCommissionSum"
                />
                <Bar
                  dataKey="heylinkCommissionSum"
                  fill={theme.heylinkCommission.primary}
                  stackId="a"
                  yAxisId="heylinkCommissionSum"
                />
                <Bar
                  dataKey="totalCommissionSum"
                  fill={theme.commission.primary}
                  stackId="a"
                  yAxisId="totalCommissionSum"
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
}
