import { makeStyles } from '@material-ui/core/styles';

export const useContainerStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
}));

export const useTableStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  avatar: {
    height: 25,
    width: 25,
    fontSize: '1rem',
    marginRight: 5,
  },
  tableContainer: {
    maxHeight: '550px',
    minHeight: '550px',
  },
  noData: {
    display: 'block',
    width: '100%',
    fontSize: '14px',
    margin: '200px 0',
    textAlign: 'center',
    color: '#555',
  },
  tableCell: {
    borderBottom: 'none',
  },
  text: {
    fontSize: 11,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'default',
  },
  tableRowAlt: { background: 'rgba(0, 0, 150, 0.025)' },
  tableRow: { background: '#fff' },
}));

export const useStylesTooltip = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.primary.dark,
  },
  tooltip: {
    backgroundColor: theme.palette.primary.dark,
    color: 'rgba(255, 255, 255, 0.87)',
    maxWidth: 350,
    fontSize: theme.typography.pxToRem(16),
    border: '1px solid #dadde9',
    padding: 10,
    textAlign: 'center',
    marginTop: '10px',
  },
}));
