import { Column } from '@material-table/core';
import { Data, CreateFormData, FilterFormData } from './types';
import { green, red } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';

export const columns: Array<Column<Data>> = [
  {
    title: 'ID',
    field: 'programUuid',
    cellStyle: { display: 'none' },
    headerStyle: { display: 'none' },
  },
  {
    title: 'Title',
    field: 'programTitle',
    defaultSort: 'asc',
  },
  {
    title: 'Network',
    field: 'network.networkName',
  },
  {
    title: 'Advertiser',
    field: 'merchant.merchantUrl',
  },
  {
    title: 'Imported',
    field: 'programImported',
    render: (row) =>
      row.programImported ? (
        <Icon style={{ color: green[400] }}>check_circle</Icon>
      ) : (
        <Icon style={{ color: red[400] }}>remove_circle</Icon>
      ),
  },
  {
    title: 'Disabled',
    field: 'programDisabled',
    render: (row) =>
      row.programDisabled ? (
        <Icon style={{ color: green[400] }}>check_circle</Icon>
      ) : (
        <Icon style={{ color: red[400] }}>remove_circle</Icon>
      ),
  },
];

export const createDefaultValues: CreateFormData = {
  programTitle: '',
  programDescription: '',
  programTerms: '',
  programContactPerson: '',
  programContactEmail: '',
  programCategoryKey: null,
  programCountryCode: null,
  programCurrencyCode: null,
  programNetworkUuid: null,
  programMerchantUuid: null,
  programCashbackAllowed: false,
  programCouponSiteAllowed: false,
  programSocialAllowed: false,
  programPpcAllowed: false,
};

export const filterDefaultValues: FilterFormData = {
  programNetworkUuid: null,
  programMerchantUuid: null,
  programCategoryKey: null,
  programDisabled: false,
  programImported: false,
};
