import { useState } from 'react';
import Datepicker from 'components/DatepickerV3';
import { Grid, Button, Paper, makeStyles, TextField } from '@material-ui/core';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { Autocomplete } from '@material-ui/lab';
import { Account, Currency, ViewModeType, FiltersType } from './types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

type PropsType = {
  accounts: Account[];
  currencies: Currency[];
  handleFiltersSubmit: (filters: FiltersType) => void;
  handleViewChange?: (mode: ViewModeType) => void;
  viewMode: ViewModeType;
  isLoading: boolean;
  handleCreateClick: () => void;
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

export default function Filters({
  accounts,
  handleFiltersSubmit,
  currencies,
  viewMode,
  isLoading,
  handleCreateClick,
}: PropsType) {
  const classes = useStyles();
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);
  const [selectedCurrency, setSelectedCurrency] = useState<Currency | null>(null);
  const [filterByPeriodDatetime, setFilterByPeriodDatetime] = useState(false);

  const [helperDate, setHelperDate] = useState({
    from: startOfMonth(new Date()),
    to: endOfMonth(new Date()),
  });
  const [overviewDate, setOverviewDate] = useState({
    from: startOfMonth(new Date()),
    to: endOfMonth(new Date()),
  });

  const handleDateChange = (start: Date, end: Date) => {
    if (viewMode === 'helper') {
      setHelperDate({
        from: start,
        to: end,
      });
    } else {
      setOverviewDate({
        from: start,
        to: end,
      });
    }
  };

  const handleSearchClick = () => {
    const filters: FiltersType = {
      accountUuid: selectedAccount?.accountUuid ?? '',
      currencyCode: selectedCurrency?.currencyCode ?? '',
      helperFromDate: format(helperDate.from, 'yyyy-MM-dd'),
      helperToDate: format(helperDate.to, 'yyyy-MM-dd'),
      overviewFromDate: format(overviewDate.from, 'yyyy-MM-dd'),
      overviewToDate: format(overviewDate.to, 'yyyy-MM-dd'),
      filterByPeriodDatetime: filterByPeriodDatetime,
    };
    handleFiltersSubmit(filters);
  };

  return (
    <Paper variant="outlined" className={classes.root}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={3}>
          <Autocomplete
            id="account-select"
            options={accounts}
            getOptionLabel={(option) => option.accountName}
            onChange={(_, value) => setSelectedAccount(value)}
            renderInput={(params) => {
              return <TextField {...params} label="Account" variant="outlined" margin="dense" />;
            }}
          />
        </Grid>
        <Grid item xs={viewMode === 'helper' ? 3 : 2}>
          <Autocomplete
            id="currency-select"
            options={currencies}
            getOptionLabel={(option) => option.currencyCode}
            onChange={(_, value) => setSelectedCurrency(value)}
            renderInput={(params) => {
              return <TextField {...params} label="Currency" variant="outlined" margin="dense" />;
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <div style={{ border: '1px solid rgba(0, 0, 0, 0.2)', padding: '0px', borderRadius: '5px' }}>
            {viewMode === 'helper' ? (
              <Datepicker change={handleDateChange} startDate={helperDate.from} endDate={helperDate.to} />
            ) : (
              <Datepicker change={handleDateChange} startDate={overviewDate.from} endDate={overviewDate.to} />
            )}
          </div>
        </Grid>
        {viewMode !== 'helper' && (
          <Grid item xs={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={filterByPeriodDatetime}
                  onChange={(e) => setFilterByPeriodDatetime(e.target.checked)}
                  name="filterByPeriodDatetime"
                  color="primary"
                />
              }
              label="Filter by period"
            />
          </Grid>
        )}
        <Grid item xs={1}>
          <Button disabled={isLoading} onClick={handleSearchClick} variant="contained" color="primary" fullWidth>
            {isLoading ? 'Searching...' : 'Search'}
          </Button>
        </Grid>
        <Grid item xs={1}>
          <Button onClick={handleCreateClick} variant="contained" color="primary" fullWidth>
            Create
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}
