import React from 'react';
import { GraphDataType, GroupByType } from '../../types';
import BarChart from './BarChartContainer';
import LineChart from './LineChartContainer';
import LoadingScreen from './Loading';

type Props = {
  data: GraphDataType;
  isLoading: boolean;
  group: GroupByType;
};

export default function ChartContainer({ data, isLoading, group }: Props) {
  if (!['date', 'week', 'month', 'year', 'advertiser'].includes(group)) return null;
  if (isLoading) return <LoadingScreen />;
  if (data.lines.length) return <LineChart data={data.lines} />;
  if (data.bars.length) return <BarChart data={data.bars} />;
  return null;
}
