import React from 'react';
import { UpdateFormPropsType as PropsType, UpdateFormData as FormData } from './types';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import {
  DialogContent,
  Grid,
  TextField,
  Paper,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Divider,
  Chip,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useForm, Controller } from 'react-hook-form';
import { makeCall } from 'utils/utils';
import Switch from 'components/EnhancedSwitch';
import EnhancedTextField from 'components/EnhancedTextField';
// import CommissionRulesTable from 'components/CommissionRuleTable';
import Clipboard from 'components/ClipboardV2';
import CommissionRulesTable from 'pages/CommissionRule/Table';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
      marginBottom: theme.spacing(3),
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
      color: '#fff',
    },
    chipsRoot: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginTop: theme.spacing(2),

      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Update({ open, handleClose, refresh, countries, currencies, record }: PropsType) {
  const classes = useStyles();

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
  } = useForm<FormData>();

  React.useEffect(() => {
    if (record) {
      const selectedCountry = countries.filter((row) => row.countryCode === record.trackerCountryCode);
      const selectedCurrency = currencies.filter((row) => row.currencyCode === record.trackerCurrencyCode);

      reset({
        trackerName: record.trackerName,
        trackerUrlMatch: record.trackerUrlMatch,
        trackerUrlAlias: record.trackerUrlAlias,
        trackerTagDeeplink: record.trackerTagDeeplink,
        trackerTagChannelId: record.trackerTagChannelId,
        trackerTagAccountId: record.trackerTagAccountId,
        trackerTagCampaignId: record.trackerTagCampaignId,
        trackerTagTrackingUrl: record.trackerTagTrackingUrl,
        trackerTagUrlId: record.trackerTagUrlId,
        trackerTagCustom1: record.trackerTagCustom1,
        trackerTagCustom2: record.trackerTagCustom2,
        trackerTagCustom3: record.trackerTagCustom3,
        trackerTagCustom4: record.trackerTagCustom4,
        trackerTagCustom5: record.trackerTagCustom5,
        trackerFixed: record.trackerFixed,
        trackerPercentage: record.trackerPercentage,
        trackerSplitDeeplinkPercentage: record.trackerSplitDeeplinkPercentage,
        trackerOmitDeeplink: record.trackerOmitDeeplink,
        trackerDeeplinkPathOnly: record.trackerDeeplinkPathOnly,
        trackerUrlAliasExact: record.trackerUrlAliasExact,
        trackerStatus: record.trackerStatus,
        trackerPriority: record.trackerPriority,
        trackerDisabled: record.trackerDisabled,
        trackerIsDealStatus: record.trackerIsDealStatus,
        trackerCountryCode: selectedCountry.length ? selectedCountry[0] : null,
        trackerCurrencyCode: selectedCurrency.length ? selectedCurrency[0] : null,
      });
    }
  }, [record, reset, countries, currencies]);

  const onSubmit = async (data: FormData) => {
    if (record && record.trackerImported) {
      const payload = {
        trackerDisabled: data.trackerDisabled,
        trackerOmitDeeplink: data.trackerOmitDeeplink,
        trackerPriority: data.trackerPriority,
        trackerSplitDeeplinkPercentage: data.trackerSplitDeeplinkPercentage,
        trackerUrlAlias: data.trackerUrlAlias,
        trackerUrlAliasExact: data.trackerUrlAliasExact,
        trackerIsDealStatus: data.trackerIsDealStatus,
        trackerDeeplinkPathOnly: data.trackerDeeplinkPathOnly,
      };
      await makeCall(`trackers/settings/${record?.trackerUuid}`, 'PUT', payload);
    } else {
      const payload = {
        ...data,
        trackerCountryCode: data.trackerCountryCode?.countryCode ?? '',
        trackerCurrencyCode: data.trackerCurrencyCode?.currencyCode ?? '',
      };
      await makeCall(`trackers/${record?.trackerUuid}`, 'PUT', payload);
    }
    handleClose();
    refresh();
  };

  return (
    <>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Update Tracker
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
              <Grid item xs={9}>
                <Paper variant="outlined" style={{ padding: '20px' }}>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <EnhancedTextField
                        control={control}
                        fieldName="trackerName"
                        label="Tracker name"
                        hasError={errors.trackerName ? true : false}
                        errorTxt="Tracker name is required!"
                        disabled={record?.trackerImported}
                        autoFocus
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <EnhancedTextField
                        control={control}
                        fieldName="trackerUrlMatch"
                        label="URL match"
                        hasError={errors.trackerUrlMatch ? true : false}
                        errorTxt="URL match is required!"
                        disabled={record?.trackerImported}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <EnhancedTextField
                        control={control}
                        fieldName="trackerUrlAlias"
                        label="URL alias"
                        required={false}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <EnhancedTextField
                        control={control}
                        fieldName="trackerTagDeeplink"
                        label="Tag deeplink"
                        required={false}
                        disabled={record?.trackerImported}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <EnhancedTextField
                        control={control}
                        fieldName="trackerTagChannelId"
                        label="Tag channel id"
                        required={false}
                        disabled={record?.trackerImported}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <EnhancedTextField
                        control={control}
                        fieldName="trackerTagAccountId"
                        label="Tag account id"
                        required={false}
                        disabled={record?.trackerImported}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <EnhancedTextField
                        control={control}
                        fieldName="trackerTagCampaignId"
                        label="Tag campaign id"
                        required={false}
                        disabled={record?.trackerImported}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <EnhancedTextField
                        control={control}
                        fieldName="trackerTagTrackingUrl"
                        label="Tag tracking URL"
                        required={false}
                        disabled={record?.trackerImported}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <EnhancedTextField
                        control={control}
                        fieldName="trackerTagUrlId"
                        label="Tag URL id"
                        required={false}
                        disabled={record?.trackerImported}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <EnhancedTextField
                        control={control}
                        fieldName="trackerTagCustom1"
                        label="Custom tag 1"
                        required={false}
                        disabled={record?.trackerImported}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <EnhancedTextField
                        control={control}
                        fieldName="trackerTagCustom2"
                        label="Custom tag 2"
                        required={false}
                        disabled={record?.trackerImported}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <EnhancedTextField
                        control={control}
                        fieldName="trackerTagCustom3"
                        label="Custom tag 3"
                        required={false}
                        disabled={record?.trackerImported}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <EnhancedTextField
                        control={control}
                        fieldName="trackerTagCustom4"
                        label="Custom tag 4"
                        required={false}
                        disabled={record?.trackerImported}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <EnhancedTextField
                        control={control}
                        fieldName="trackerTagCustom5"
                        label="Custom tag 5"
                        required={false}
                        disabled={record?.trackerImported}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <EnhancedTextField
                        control={control}
                        fieldName="trackerFixed"
                        label="Fixed"
                        hasError={errors.trackerFixed ? true : false}
                        errorTxt="Fixed is required!"
                        disabled={record?.trackerImported}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <EnhancedTextField
                        control={control}
                        fieldName="trackerPercentage"
                        label="Percentage"
                        hasError={errors.trackerPercentage ? true : false}
                        errorTxt="Percentage is required!"
                        disabled={record?.trackerImported}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <EnhancedTextField
                        control={control}
                        fieldName="trackerSplitDeeplinkPercentage"
                        label="Deeplink percentage"
                        hasError={errors.trackerSplitDeeplinkPercentage ? true : false}
                        errorTxt="Deeplink percentage is required!"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="trackerCountryCode"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            id="country-select"
                            disabled={record?.trackerImported}
                            options={countries}
                            onChange={(_, data) => field.onChange(data)}
                            getOptionLabel={(option) => option.countryName}
                            fullWidth
                            renderInput={(params) => (
                              <TextField
                                error={errors.trackerCountryCode ? true : false}
                                helperText={errors.trackerCountryCode ? 'Country is required!' : null}
                                {...params}
                                label="Country"
                                margin="dense"
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="trackerCurrencyCode"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            disabled={record?.trackerImported}
                            id="currency-select"
                            options={currencies}
                            onChange={(_, data) => field.onChange(data)}
                            getOptionLabel={(option) => option.currencyCode}
                            fullWidth
                            renderInput={(params) => (
                              <TextField
                                error={errors.trackerCurrencyCode ? true : false}
                                helperText={errors.trackerCurrencyCode ? 'Currency is required!' : null}
                                {...params}
                                label="Currency"
                                margin="dense"
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Switch
                        isChecked={!!record?.trackerOmitDeeplink}
                        control={control}
                        fieldName="trackerOmitDeeplink"
                        label="Omit deeplink"
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Switch
                        isChecked={!!record?.trackerDeeplinkPathOnly}
                        control={control}
                        fieldName="trackerDeeplinkPathOnly"
                        label="Deeplink path"
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Switch
                        isChecked={!!record?.trackerUrlAliasExact}
                        control={control}
                        fieldName="trackerUrlAliasExact"
                        label="URL alias exact"
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Switch
                        isChecked={!!record?.trackerStatus}
                        control={control}
                        fieldName="trackerStatus"
                        label="Status"
                        disabled={record?.trackerImported}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Switch
                        isChecked={!!record?.trackerPriority}
                        control={control}
                        fieldName="trackerPriority"
                        label="Priority"
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Switch
                        isChecked={!!record?.trackerDisabled}
                        control={control}
                        fieldName="trackerDisabled"
                        label="Disabled"
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Switch
                        isChecked={!!record?.trackerIsDealStatus}
                        control={control}
                        fieldName="trackerIsDealStatus"
                        label="Deal status"
                      />
                    </Grid>
                    <Grid item xs={10}></Grid>
                    <Grid item xs={2}>
                      <Button disabled={isSubmitting} type="submit" color="primary" variant="contained" fullWidth>
                        {isSubmitting ? 'Updating...' : 'Update'}
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper variant="outlined" style={{ padding: '20px' }}>
                  <Typography variant="h6" color="primary">
                    Tracker information
                  </Typography>
                  <Divider />
                  <div className={classes.chipsRoot}>
                    <Clipboard id={record?.trackerUuid ?? ''} />
                    <Chip
                      variant="outlined"
                      label={
                        <Typography variant="subtitle2" color="primary">
                          Account name: {record?.account?.accountName ?? '-'}
                        </Typography>
                      }
                      color="primary"
                    />
                    <Chip
                      variant="outlined"
                      label={
                        <Typography variant="subtitle2" color="primary">
                          Media URL: {record?.media?.mediaUrl ?? '-'}
                        </Typography>
                      }
                      color="primary"
                    />
                    <Chip
                      variant="outlined"
                      label={
                        <Typography variant="subtitle2" color="primary">
                          Merchant URL: {record?.merchant.merchantUrl ?? '-'}
                        </Typography>
                      }
                      color="primary"
                    />
                    <Chip
                      variant="outlined"
                      label={
                        <Typography variant="subtitle2" color="primary">
                          Network name: {record?.network.networkName ?? '-'}
                        </Typography>
                      }
                      color="primary"
                    />
                    <Chip
                      variant="outlined"
                      label={
                        <Typography variant="subtitle2" color="primary">
                          Program name: {record?.program.programTitle ?? '-'}
                        </Typography>
                      }
                      color="primary"
                    />
                    <Chip
                      variant="outlined"
                      label={
                        <Typography variant="subtitle2" color="primary">
                          API identifier: {record?.trackerApiIdentifier ?? '-'}
                        </Typography>
                      }
                      color="primary"
                    />
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </form>
          {record && (
            <Grid container style={{ marginTop: '20px' }}>
              <Grid xs={12} item>
                <CommissionRulesTable
                  shouldCreateDisabled={false}
                  searchOptions={{ commissionRuleTrackerUuid: record.trackerUuid }}
                  options={{
                    program: { programTitle: record.program.programTitle, programUuid: record.program.programUuid },
                    merchant: {
                      merchantUrl: record.merchant.merchantUrl,
                      merchantUuid: record.merchant.merchantUuid,
                    },
                    tracker: {
                      trackerName: record.trackerName,
                      trackerUuid: record.trackerUuid,
                    },
                    media: {
                      mediaUrl: record.media.mediaUrl,
                      mediaUuid: record.media.mediaUuid,
                    },
                    account: {
                      accountName: record.account.accountName,
                      accountUuid: record.account.accountUuid,
                    },
                  }}
                />
              </Grid>
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
