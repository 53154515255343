import { useState, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Divider,
  Typography,
  MenuItem,
} from '@material-ui/core';
import { makeCall } from 'utils/utils';
import EnhancedTextField from 'components/EnhancedTextField';
import EnhancedSwitch from 'components/EnhancedSwitch';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CreateFormData as FormData, CreateFormPropsType as PropsType, Tracker, Network } from './types';
import { createDefaultValues as defaultValues } from './initialData';
import { DateTimePicker } from '@material-ui/pickers';

export function Create({
  open,
  handleClose,
  refresh,
  countries,
  advertisers,
  networks,
  admins,
  currencies,
}: PropsType) {
  const [trackers, setTrackers] = useState<Tracker[]>([]);
  const [isTrackerDisabled, setIsTrackerDisabled] = useState(true);
  const networkValue = useRef<Network | null | undefined>(null);

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
    setValue,
    getValues,
  } = useForm<FormData>({ defaultValues });

  const resetForm = () => {
    networkValue.current = null;
    setIsTrackerDisabled(true);
    setTrackers([]);
    reset(defaultValues);
    handleClose();
  };

  const onSubmit = async (data: FormData) => {
    let expireDate = '';
    let startDate = '';
    if (data.dealExpireDatetime) {
      expireDate = new Date(
        data.dealExpireDatetime.getTime() - data.dealExpireDatetime.getTimezoneOffset() * 60000
      ).toISOString();
    }
    if (data.dealStartDatetime) {
      startDate = new Date(
        data.dealStartDatetime.getTime() - data.dealStartDatetime.getTimezoneOffset() * 60000
      ).toISOString();
    }
    const payload = {
      ...data,
      dealCountryCode: data.dealCountryCode?.countryCode,
      dealDiscountCurrencyCode: data.dealDiscountCurrencyCode?.currencyCode ?? '',
      dealAdvertiserUuid: data.dealAdvertiserUuid?.merchantUuid,
      dealManagerAdminUuid: data.dealManagerAdminUuid?.adminUuid,
      dealGlobalTrackerUuid: data.dealGlobalTrackerUuid?.trackerUuid ?? '',
      dealStartDatetime: startDate,
      dealExpireDatetime: expireDate,
    };
    const response = await makeCall('deals', 'POST', payload);
    resetForm();
    refresh(response?.data.data);
  };

  const handleSelection = async () => {
    setValue('dealGlobalTrackerUuid', null);
    setTrackers([]);
    setIsTrackerDisabled(true);
    const advertiser = getValues('dealAdvertiserUuid');
    const network = networkValue.current;
    if (network && advertiser) {
      const payload = {
        trackerAccountUuid: '',
        trackerMediaUuid: '',
        trackerNetworkUuid: network.networkUuid,
        trackerMerchantUuid: advertiser.merchantUuid,
        trackerStatus: true,
        trackerDisabled: false,
      };
      const response = await makeCall('trackers', 'GET', payload);
      setTrackers(response?.data.data);
      setIsTrackerDisabled(false);
    }
  };
  return (
    <>
      <Dialog
        open={open}
        disableBackdropClick={true}
        onEscapeKeyDown={resetForm}
        onClose={resetForm}
        aria-labelledby="create-form"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="create-form">Create Deal</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="dealName"
                  label="Deal name"
                  hasError={errors.dealName ? true : false}
                  errorTxt="Deal name is required!"
                  autoFocus
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="dealTargetUrl"
                  label="Target URL"
                  hasError={errors.dealTargetUrl ? true : false}
                  errorTxt="Valid URL is required!"
                  isURL
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="dealDiscountType"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      label="Discount type"
                      error={errors.dealDiscountType ? true : false}
                      helperText={errors.dealDiscountType ? 'Discount type is required!' : null}
                      margin="dense"
                      fullWidth
                    >
                      <MenuItem value="percentage">Percentage</MenuItem>
                      <MenuItem value="fixed">Fixed</MenuItem>
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <EnhancedTextField
                  control={control}
                  fieldName="dealDiscountValue"
                  label="Discount value"
                  hasError={errors.dealDiscountValue ? true : false}
                  errorTxt="Discount value is required!"
                />
              </Grid>
              <Grid item xs={4}>
                <EnhancedTextField
                  control={control}
                  fieldName="dealDiscountText"
                  label="Discount text"
                  required={false}
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField control={control} fieldName="dealDescription" label="Description" required={false} />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField control={control} fieldName="dealCouponCode" label="Coupon code" required={false} />
              </Grid>
              <Grid item xs={12}>
                <EnhancedTextField
                  control={control}
                  fieldName="dealTermsConditions"
                  label="Terms & Conditions"
                  required={false}
                  multiline
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="dealManagerAdminUuid"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="admin-select"
                      options={admins}
                      onChange={(_, data) => field.onChange(data)}
                      getOptionLabel={(option) => option.adminFirstname}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          error={errors.dealManagerAdminUuid ? true : false}
                          helperText={errors.dealManagerAdminUuid ? 'Admin manager is required!' : null}
                          {...params}
                          label="Admin manager"
                          margin="dense"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="dealDiscountCurrencyCode"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="currency-select"
                      options={currencies}
                      onChange={(_, data) => field.onChange(data)}
                      getOptionLabel={(option) => option.currencyCode}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          error={errors.dealDiscountCurrencyCode ? true : false}
                          helperText={errors.dealDiscountCurrencyCode ? 'Currency is required!' : null}
                          {...params}
                          label="Discount currency"
                          margin="dense"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="dealCountryCode"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="country-select"
                      options={countries}
                      onChange={(_, data) => field.onChange(data)}
                      getOptionLabel={(option) => option.countryName}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          error={errors.dealCountryCode ? true : false}
                          helperText={errors.dealCountryCode ? 'Country is required!' : null}
                          {...params}
                          label="Country"
                          margin="dense"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="dealAdvertiserUuid"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="advertiser-select"
                      options={advertisers}
                      onChange={(_, data) => {
                        field.onChange(data);
                        handleSelection();
                      }}
                      getOptionLabel={(option) => option.merchantName}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          error={errors.dealAdvertiserUuid ? true : false}
                          helperText={errors.dealAdvertiserUuid ? 'Advertiser is required!' : null}
                          {...params}
                          label="Advertiser"
                          margin="dense"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="dealStartDatetime"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <DateTimePicker
                      {...field}
                      label="Deal start on"
                      inputVariant="standard"
                      fullWidth
                      error={errors.dealStartDatetime ? true : false}
                      helperText={errors.dealStartDatetime ? 'Deal start date is required!' : null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="dealExpireDatetime"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <DateTimePicker
                      {...field}
                      label="Deal expires on"
                      inputVariant="standard"
                      fullWidth
                      error={errors.dealExpireDatetime ? true : false}
                      helperText={errors.dealExpireDatetime ? 'Deal expire date is required!' : null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={10}></Grid>
              <Grid item xs={2}>
                <EnhancedSwitch control={control} isChecked={false} fieldName="dealActiveStatus" label="Status" />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography color="primary" variant="subtitle2">
                  If this is a global deal, select a global tracker, Pleas select Advertiser and Network to enable
                  Tracker selection.
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  id="network-select"
                  options={networks}
                  onChange={(_, data) => {
                    networkValue.current = data;
                    handleSelection();
                  }}
                  getOptionLabel={(option) => option.networkName}
                  fullWidth
                  renderInput={(params) => <TextField {...params} label="Networks" margin="dense" />}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="dealGlobalTrackerUuid"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      disabled={isTrackerDisabled}
                      id="tracker-select"
                      options={trackers}
                      onChange={(_, data) => field.onChange(data)}
                      getOptionLabel={(option) => option.trackerName}
                      fullWidth
                      renderInput={(params) => <TextField {...params} label="Global Tracker" margin="dense" />}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={resetForm} color="primary">
              Cancel
            </Button>
            <Button disabled={isSubmitting} type="submit" color="primary">
              {isSubmitting ? 'Creating...' : 'Create'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
