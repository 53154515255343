import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  TextField,
  Switch,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Grid,
  Typography,
  Icon,
} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeCall } from 'utils/utils';
import { CreateFormData as FormData, CreateFormPropsType as PropsType } from './types';
import { createDefaultValues as defaultValues } from './initialData';

type APIKeyResponse = {
  apiKeyUuid: string;
  apiKeyPublicKey: string;
  apiKeySecretKey: string;
};

export function Create({ open, handleClose, refresh }: PropsType) {
  const [apiKeyResponse, setApiKeyResponse] = useState<APIKeyResponse | null>(null);
  const [message, setMessage] = useState('');
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
  } = useForm<FormData>({ defaultValues });

  const resetForm = () => {
    reset(defaultValues);
    setApiKeyResponse(null);

    handleClose();
  };

  const onSubmit = async (data: FormData) => {
    const payload = {
      ...data,
    };
    const response = await makeCall('apikeys', 'POST', payload);
    setApiKeyResponse(response?.data.data);
    reset(defaultValues);
    refresh();
  };

  const handleCopyClick = (type: string) => {
    if (type === 'public') {
      navigator.clipboard.writeText(apiKeyResponse?.apiKeyPublicKey ?? '');
      setMessage('Public Key copied to clipboard successfully!');
    }
    if (type === 'secret') {
      navigator.clipboard.writeText(apiKeyResponse?.apiKeySecretKey ?? '');
      setMessage('Secret Key copied to clipboard successfully!');
    }
  };
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={Boolean(message)}
        autoHideDuration={3000}
        onClose={() => setMessage('')}
      >
        <Alert variant="filled" severity="success">
          {message}
        </Alert>
      </Snackbar>
      <Dialog
        open={open}
        disableBackdropClick={true}
        onEscapeKeyDown={resetForm}
        onClose={resetForm}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">Create API key</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            {apiKeyResponse ? (
              <Grid container style={{ marginBottom: 20 }}>
                <Grid item xs={12}>
                  <Alert severity="success">
                    <AlertTitle>API key created successfully!</AlertTitle>
                    <Typography>Please copy secret key, you cannot retrieve it once dialog is closed.</Typography>
                    <Typography style={{ margin: '10px 0' }}>
                      Public Key: {apiKeyResponse.apiKeyPublicKey}{' '}
                      <Icon
                        onClick={() => handleCopyClick('public')}
                        style={{ cursor: 'pointer', transform: 'translate(6px, 5px)' }}
                        color="primary"
                      >
                        assignment
                      </Icon>
                    </Typography>
                    <Typography>
                      Secret Key: {apiKeyResponse.apiKeySecretKey}{' '}
                      <Icon
                        onClick={() => handleCopyClick('secret')}
                        style={{ cursor: 'pointer', transform: 'translate(6px, 5px)' }}
                        color="primary"
                      >
                        assignment
                      </Icon>
                    </Typography>
                  </Alert>
                </Grid>
              </Grid>
            ) : null}
            <Controller
              name="apiKeyScope"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">API key scope</InputLabel>
                  <Select labelId="demo-simple-select-label" {...field}>
                    <MenuItem value="connect">Connect</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
            <Controller
              name="apiKeyName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors.apiKeyName ? true : false}
                  helperText={errors.apiKeyName ? 'API key name is required!' : null}
                  autoFocus
                  margin="dense"
                  label="API key name"
                  fullWidth
                  style={{ marginTop: 20 }}
                />
              )}
            />
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors.apiKeyDescription ? true : false}
                  helperText={errors.apiKeyDescription ? 'API key description is required!' : null}
                  margin="dense"
                  label="API key description"
                  fullWidth
                  style={{ marginTop: 20 }}
                />
              )}
              name="apiKeyDescription"
              control={control}
              rules={{ required: true }}
            />
            <Controller
              name="apiKeyActiveStatus"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <FormControlLabel
                  style={{ marginTop: 20 }}
                  control={<Switch defaultChecked {...field} color="primary" />}
                  label="Active"
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={resetForm} color="primary">
              Cancel
            </Button>
            <Button disabled={isSubmitting} type="submit" color="primary">
              {isSubmitting ? 'Creating...' : 'Create'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
