import { useForm } from 'react-hook-form';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { makeCall } from 'utils/utils';
import EnhancedTextField from 'components/EnhancedTextField';
import {BaseFormPropType as PropTypes} from 'types/global'
import {CreateFormData as FormData} from './types';
import {createDefaultValues as defaultValues} from './initialData'

export default function Create({open, handleClose, refresh}: PropTypes) {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
  } = useForm<FormData>({ defaultValues });

  const resetForm = () => {
    reset(defaultValues);
    handleClose();
  };

  const onSubmit = async(data: FormData) => {
    const payload = {
      ...data
    }
    await makeCall('merchants', 'POST', payload);
    resetForm();
    refresh();
  }

  return (
    <>
      <Dialog
        open={open}
        disableBackdropClick={true}
        onEscapeKeyDown={resetForm}
        onClose={resetForm}
        aria-labelledby="create-form"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="create-form">Create Advertiser</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                <EnhancedTextField
                  control={control}
                  fieldName="merchantName"
                  label="Advertiser name" 
                  hasError={errors.merchantName ? true : false}
                  errorTxt="Advertiser name is required!"
                  autoFocus
                />
                </Grid>
                <Grid item xs={12}>
                <EnhancedTextField
                  control={control}
                  fieldName="merchantUrl"
                  label="Advertiser Url"
                  hasError={errors.merchantUrl ? true : false}
                  errorTxt="Advertiser Url is required!"
                />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
            <Button onClick={resetForm} color="primary">
              Cancel
            </Button>
            <Button disabled={isSubmitting} type="submit" color="primary">
              {isSubmitting ? 'Creating...' : 'Create'}
            </Button>
          </DialogActions>
        </form>
        </Dialog> 
    </>
  )
}
