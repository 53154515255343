import { Column } from '@material-table/core';
import { Data } from './types';

export const columns: Array<Column<Data>> = [
  { title: 'ID', field: 'accountTypeUuid', cellStyle: { display: 'none' }, headerStyle: { display: 'none' } },
  { title: 'Name', field: 'accountTypeName' },
];

export const createDefaultValues = {
  accountTypeName: '',
};
