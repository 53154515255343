import { useState } from 'react';
import { Grid, Button, Paper, makeStyles, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { filterSelectOptions } from './initialData';
import { FilterSelectType } from './types';
import { Account } from 'types/global';

type Props = {
  handleFilterSubmit: (data: any) => void;
  isLoading: boolean;
  accounts: Account[];
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

export function Filters({ handleFilterSubmit, isLoading, accounts }: Props) {
  const classes = useStyles();
  const [logoValue, setLogoValue] = useState<FilterSelectType | undefined>({ label: 'All', value: null });
  const [accountValue, setAccountValue] = useState<Account | undefined | null>();

  const handleSearchClick = () => {
    const payload: {
      hasLogo?: boolean;
      accountUuid?: string;
    } = {};
    if (typeof logoValue?.value === 'boolean') {
      payload.hasLogo = logoValue?.value;
    }
    if (accountValue) {
      payload.accountUuid = accountValue.accountUuid;
    }

    handleFilterSubmit(payload);
  };
  return (
    <Paper variant="outlined" className={classes.root}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={5}>
          <Autocomplete
            id="account-select"
            options={accounts}
            getOptionLabel={(option) => option.accountName}
            onChange={(_, value) => setAccountValue(value)}
            value={accountValue}
            renderInput={(params) => {
              return <TextField {...params} label="Account" variant="outlined" margin="dense" />;
            }}
          />
        </Grid>
        <Grid item xs={5}>
          <Autocomplete
            id="logo-select"
            options={filterSelectOptions}
            getOptionLabel={(option) => option.label}
            onChange={(_, value) => setLogoValue(value)}
            disableClearable={true}
            value={logoValue}
            renderInput={(params) => {
              return <TextField {...params} label="Has Logo" variant="outlined" margin="dense" />;
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Button variant="contained" color="primary" fullWidth onClick={handleSearchClick} disabled={isLoading}>
            Search
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}
