import Autocomplete from '@material-ui/lab/Autocomplete';
import { Grid, Paper, TextField, Button } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { FilterFormData as FormData, FilterFormProps as PropsType } from './types';
import { filterDefaultValues as defaultValues } from './initialData';

export default function Filters({ accounts, medias, handleFiltersSubmit }: PropsType) {
  const { handleSubmit, control } = useForm<FormData>({
    defaultValues,
  });

  const onSubmit = (data: FormData) => {
    const payload: any = {};
    if (data.trackerMediaExcludeAccountUuid) {
      payload.trackerMediaExcludeAccountUuid = data.trackerMediaExcludeAccountUuid.accountUuid;
    }
    if (data.trackerMediaExcludeMediaUuid) {
      payload.trackerMediaExcludeMediaUuid = data.trackerMediaExcludeMediaUuid.mediaUuid;
    }
    handleFiltersSubmit(payload);
  };

  return (
    <Paper variant="outlined" style={{ padding: '10px 20px' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3} alignItems="center" justify="center">
          <Grid item xs={5}>
            <Controller
              name="trackerMediaExcludeAccountUuid"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  id="account-select"
                  options={accounts}
                  onChange={(_, data) => field.onChange(data)}
                  getOptionLabel={(option) => option.accountName}
                  fullWidth
                  renderInput={(params) => <TextField {...params} label="Account" margin="dense" variant="outlined" />}
                />
              )}
            />
          </Grid>
          <Grid item xs={5}>
            <Controller
              name="trackerMediaExcludeMediaUuid"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  id="media-select"
                  options={medias}
                  onChange={(_, data) => field.onChange(data)}
                  getOptionLabel={(option) => option.mediaName}
                  fullWidth
                  renderInput={(params) => <TextField {...params} label="Media" margin="dense" variant="outlined" />}
                />
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <Button variant="contained" fullWidth type="submit" color="primary">
              Search
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}
