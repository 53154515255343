import { Grid, Card, CardContent, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(5),
  },
  cardInnerBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'default',
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  title: {
    fontSize: 10,
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    marginBottom: theme.spacing(1),
    fontWeight: 400,
  },
  value: {
    fontSize: 18,
    fontWeight: 500,
    textAlign: 'center',
  },
}));

type Props = {
  title: string;
  value: string;
  isLoading: boolean;
};

export default function CardWrapper({ title, value, isLoading }: Props) {
  const classes = useStyles();
  return (
    <Grid item xs={2}>
      <Card variant="outlined">
        <div className={classes.cardInnerBox}>
          <CardContent>
            <div style={{ textAlign: 'center' }}>
              <Typography variant="h3" className={classes.title}>
                {title}
              </Typography>
            </div>
          </CardContent>
          {isLoading ? (
            <Skeleton variant="rect" width={100} animation="wave" />
          ) : (
            <>
              <Typography variant="h3" className={classes.value}>
                {value}
              </Typography>
            </>
          )}
        </div>
      </Card>
    </Grid>
  );
}
