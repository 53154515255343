import React from 'react';
import { Route } from 'react-router-dom';

export function getRoutes(schema: any) {
  const routes: any = [];

  schema.forEach((row: any) => {
    if (row.to) {
      routes.push(<Route key={row.listKey} exact path={row.to} component={row.component} />);
    }
    if (row.nested.length) {
      routes.push(getRoutes(row.nested));
    }
  });
  return routes;
}
