import { Controller, Control } from 'react-hook-form';
import { Switch, FormControlLabel } from '@material-ui/core';

type PropsType = {
  control: Control<any>;
  required?: boolean;
  fieldName: string;
  label: string;
  isChecked?: boolean;
  disabled?: boolean;
};

export default function EnhancedSwitch({ control, disabled = false, fieldName, label, isChecked = true }: PropsType) {
  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          control={<Switch defaultChecked={isChecked} {...field} disabled={disabled} color="primary" />}
          label={label}
        />
      )}
    />
  );
}
