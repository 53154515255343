import { useState, useEffect } from 'react';
import { makeCall } from 'utils/utils';
import WithMotion from 'components/withMotion';
import { Data, Record, Currency, Account } from './types';
import { Grid, makeStyles } from '@material-ui/core';
import { columns } from './initialData';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import Update from './Update';
import Create from './Create';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(6),
  },
  toolbarWrapper: {
    '& .MuiToolbar-gutters': {
      paddingLeft: '16px',
      paddingRight: 0,
    },
    '& .MuiTypography-h6': {
      color: theme.palette.primary.main,
    },
  },
}));

function Container() {
  const classes = useStyles();
  const [isCreate, setIsCreate] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<Data[]>([]);
  const [record, setRecord] = useState<Record | null>(null);
  const [loginToken, setLoginToken] = useState('');
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [accounts, setAccounts] = useState<Account[]>([]);

  useEffect(() => {
    const getData = async () => {
      const currenciesResponse = await makeCall('currencies', 'GET');
      const accountsResponse = await makeCall('accounts', 'GET');
      setCurrencies(currenciesResponse?.data.data);
      setAccounts(accountsResponse?.data.data);
    };
    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      const response = await makeCall('users', 'GET');
      setIsLoading(false);
      setData(response?.data.data);
      setShouldFetch(false);
    };
    if (shouldFetch) {
      getData();
    }
  }, [shouldFetch]);

  useEffect(() => {
    if (loginToken) {
      window.open(`${process.env.REACT_APP_MANAGED_URL}/${loginToken}`, '_blank');
      setLoginToken('');
    }
  }, [loginToken]);

  const getRecord = async (clickedRow: Data) => {
    const response = await makeCall(`users/${clickedRow.userUuid}`, 'GET');
    setRecord(response?.data.data);
    setIsUpdate(true);
  };

  const getUserToken = async (clickedRow: Data) => {
    const response = await makeCall(`users/${clickedRow.userUuid}/jwt`, 'GET');
    setLoginToken(response?.data.data.jwt);
  };

  return (
    <>
      <Create
        open={isCreate}
        accounts={accounts}
        currencies={currencies}
        handleClose={() => setIsCreate(!isCreate)}
        refresh={() => {
          setIsLoading(true);
          setShouldFetch(true);
        }}
      />
      <Update
        record={record}
        currencies={currencies}
        open={isUpdate}
        handleClose={() => setIsUpdate(!isUpdate)}
        refresh={() => {
          setIsLoading(true);
          setShouldFetch(true);
        }}
      />
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <MaterialTable
            title="Users"
            components={{
              Toolbar: (props) => (
                <div className={classes.toolbarWrapper}>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={{
              search: true,
              pageSize: 10,
              actionsColumnIndex: 6,
              actionsCellStyle: { color: '#666' },
            }}
            actions={[
              {
                icon: 'add',
                tooltip: 'Create User',
                isFreeAction: true,
                onClick: () => setIsCreate(!isCreate),
              },
              {
                icon: 'edit',
                tooltip: 'Update user',
                isFreeAction: false,
                onClick: (event, rowData: any) => getRecord(rowData),
              },
              {
                icon: 'login',
                tooltip: 'Login as User',
                isFreeAction: false,
                onClick: (event, rowData) => getUserToken(rowData),
              },
            ]}
            isLoading={isLoading}
            columns={columns}
            data={data}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default WithMotion(Container);
