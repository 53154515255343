import { Column } from '@material-table/core';
import { Data, CurrencyRateData } from './types';

export const columns: Array<Column<Data>> = [
  { title: 'Code', field: 'currencyCode' },
  { title: 'Decimal places', field: 'currencyDecimalPlace' },
  { title: 'Decimal separator', field: 'currencyDecimalSeparator' },
  { title: 'Left symbol', field: 'currencyLeft' },
  { title: 'Right symbol', field: 'currencyRight' },
  { title: 'Thousand separator', field: 'currencyThousandSeparator' },
];

export const currencyRatesColumns: Array<Column<CurrencyRateData>> = [
  { title: 'ID', field: 'currencyRateId' },
  { title: 'From', field: 'currencyRateFrom' },
  { title: 'To', field: 'currencyRateTo' },
  { title: 'Value', field: 'currencyRateValue' },
];

export const createDefaultValues = {
  currencyCode: '',
  currencyLeft: '',
  currencyRight: '',
  currencyDecimalPlace: 0,
  currencyDecimalSeparator: '',
  currencyThousandSeparator: '',
};
