import { Column } from '@material-table/core';
import { MediaTypesData, Data, CreateFormData } from './types';
import { green, red } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';

export const MediaTypesColumns: Array<Column<MediaTypesData>> = [
  { title: 'ID', field: 'mediaTypeUuid' },
  { title: 'Key', field: 'mediaTypeKey' },
  { title: 'Name', field: 'mediaTypeName' },
];

export const columns: Array<Column<Data>> = [
  { title: 'ID', field: 'mediaUuid', cellStyle: { display: 'none' }, headerStyle: { display: 'none' } },
  { title: 'Name', field: 'mediaName' },
  { title: 'URL', field: 'mediaUrl' },
  { title: 'Type', field: 'mediaType.mediaTypeName' },
  { title: 'Account', field: 'account.accountName' },
  { title: 'Country', field: 'country.countryName' },
  {
    title: 'Favicon',
    field: 'mediaFavicon',
    render: (rowData) => <img src={rowData.mediaFavicon} className="img" alt="" />,
  },
  {
    title: 'Discover Mode',
    field: 'mediaDiscoverMode',
    render: (row) =>
      row.mediaDiscoverMode ? (
        <Icon style={{ color: green[400] }}>check_circle</Icon>
      ) : (
        <Icon style={{ color: red[400] }}>remove_circle</Icon>
      ),
  },
];

export const createDefaultValues: CreateFormData = {
  mediaName: '',
  mediaDescription: '',
  mediaUrl: '',
  mediaTypeUuid: null,
  mediaCountryCode: null,
  mediaAccountUuid: null,
  mediaDiscoverMode: false,
};
