import { Column } from '@material-table/core';
import { Data } from './types';
import { green, red } from '@material-ui/core/colors';

import Icon from '@material-ui/core/Icon';

export const columns: Array<Column<Data>> = [
  { title: 'ID', field: 'userUuid', cellStyle: { display: 'none' }, headerStyle: { display: 'none' } },
  { title: 'First name', field: 'userFirstname' },
  { title: 'Last name', field: 'userLastname' },
  { title: 'Email', field: 'userEmail' },
  { title: 'Account', field: 'account.accountName' },
  {
    title: 'Active',
    field: 'userActive',
    render: (row) =>
      row.userActive ? (
        <Icon style={{ color: green[400] }}>check_circle</Icon>
      ) : (
        <Icon style={{ color: red[400] }}>remove_circle</Icon>
      ),
  },
];

export const createDefaultValues = {
  userAccountUuid: null,
  userFirstname: '',
  userLastname: '',
  userEmail: '',
  userPhone: '',
  userPassword: '',
  userActive: true,
  userCurrencyCode: null,
};
