import React from 'react';
import { Typography, Chip, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      marginTop: '30px',
    },
    heading: {
      fontSize: 16,
      color: '#93949f',
      margin: theme.spacing(),
    },
    chip: {
      margin: theme.spacing(),
    },
    chipRoot: {
      borderRadius: 4,
      color: '#fff',
      fontWeight: 500,
      backgroundColor: '#93949f',
    },
    chipDeleteIcon: {
      color: '#fff',
    },
  })
);

type PropsType = {
  filters: any;
  handleFilterClick: (filterId: string) => void;
};

export default function Filters({ filters, handleFilterClick }: PropsType) {
  const classes = useStyles();
  if (
    !filters.mediaUuid &&
    !filters.accountUuid &&
    !filters.date &&
    !filters.targetDomain &&
    !filters.targetUrl &&
    !filters.pageUrl
  ) {
    return null;
  }
  return (
    <div className={classes.root}>
      <Typography variant="subtitle1" className={classes.heading}>
        Filter by:
      </Typography>
      {filters.mediaUuid ? (
        <Chip
          classes={{
            root: classes.chipRoot,
            deleteIcon: classes.chipDeleteIcon,
          }}
          className={classes.chip}
          label={`Media: ${filters.mediaUuid}`}
          onDelete={() => handleFilterClick('mediaUuid')}
        />
      ) : null}
      {filters.accountUuid ? (
        <Chip
          classes={{
            root: classes.chipRoot,
            deleteIcon: classes.chipDeleteIcon,
          }}
          className={classes.chip}
          label={`Account: ${filters.accountUuid}`}
          onDelete={() => handleFilterClick('accountUuid')}
        />
      ) : null}
      {filters.date ? (
        <Chip
          classes={{
            root: classes.chipRoot,
            deleteIcon: classes.chipDeleteIcon,
          }}
          className={classes.chip}
          label={`Date: ${filters.date}`}
          onDelete={() => handleFilterClick('date')}
        />
      ) : null}
      {filters.targetDomain ? (
        <Chip
          classes={{
            root: classes.chipRoot,
            deleteIcon: classes.chipDeleteIcon,
          }}
          className={classes.chip}
          label={`Domain: ${filters.targetDomain}`}
          onDelete={() => handleFilterClick('targetDomain')}
        />
      ) : null}
      {filters.targetUrl ? (
        <Chip
          classes={{
            root: classes.chipRoot,
            deleteIcon: classes.chipDeleteIcon,
          }}
          className={classes.chip}
          label={`Target: ${filters.targetUrl}`}
          onDelete={() => handleFilterClick('targetUrl')}
        />
      ) : null}
      {filters.pageUrl ? (
        <Chip
          classes={{
            root: classes.chipRoot,
            deleteIcon: classes.chipDeleteIcon,
          }}
          className={classes.chip}
          label={`Page Url: ${filters.pageUrl}`}
          onDelete={() => handleFilterClick('pageUrl')}
        />
      ) : null}
    </div>
  );
}
