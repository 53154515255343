import { FetchFuncParams } from './types';
import { makeCall } from 'utils/utils';

export function formatAccounts(data: any) {
  const newData = [{ accountName: 'All', accountUuid: 'All' }];
  data.forEach((row: any) => {
    newData.push({
      accountName: row.accountName,
      accountUuid: row.accountUuid,
    });
  });
  return newData;
}

export function formatChannels(data: any) {
  const newData = [{ channelName: 'All', channelUuid: 'All' }];
  data.forEach((row: any) => {
    newData.push({
      channelName: row.mediaName,
      channelUuid: row.mediaUuid,
    });
  });
  return newData;
}

export const fetchFunc = async ({ url, type = 'GET', payload = null }: FetchFuncParams) => {
  const response = await makeCall(url, type, payload);
  return response?.data.data;
};
