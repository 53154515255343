import { Column } from '@material-table/core';
import { Data } from './types';
import { green, red } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';

export const columns: Array<Column<Data>> = [
  { title: 'ID', field: 'adminUuid', cellStyle: { display: 'none' }, headerStyle: { display: 'none' } },
  { title: 'First name', field: 'adminFirstname' },
  { title: 'Last name', field: 'adminLastname' },
  { title: 'Email', field: 'adminEmail' },
  {
    title: 'Active',
    field: 'adminStatus',
    render: (row) =>
      row.adminStatus ? (
        <Icon style={{ color: green[400] }}>check_circle</Icon>
      ) : (
        <Icon style={{ color: red[400] }}>remove_circle</Icon>
      ),
  },
];

export const createDefaultValues = {
  adminFirstname: '',
  adminLastname: '',
  adminEmail: '',
  adminPass: '',
  adminStatus: true,
};
