import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  TextField,
  Switch,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { makeCall } from 'utils/utils';
import { CreateFormData as FormData, CreateFormPropsType as PropsType } from './types';
import { createDefaultValues as defaultValues } from './initialData';

export default function Create({ open, handleClose, refresh }: PropsType) {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
  } = useForm<FormData>({ defaultValues });

  const resetForm = () => {
    reset(defaultValues);
    handleClose();
  };

  const onSubmit = async (data: FormData) => {
    const payload = {
      ...data,
    };
    await makeCall('admins', 'POST', payload);
    reset(defaultValues);
    handleClose();
    refresh();
  };
  return (
    <Dialog
      open={open}
      disableBackdropClick={true}
      onEscapeKeyDown={resetForm}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Create Admin</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={errors.adminFirstname ? true : false}
                helperText={errors.adminFirstname ? 'Admin first name is required!' : null}
                autoFocus
                margin="dense"
                label="First Name"
                fullWidth
              />
            )}
            name="adminFirstname"
            control={control}
            rules={{ required: true }}
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={errors.adminLastname ? true : false}
                helperText={errors.adminLastname ? 'Admin last name is required!' : null}
                margin="dense"
                label="Last Name"
                fullWidth
              />
            )}
            name="adminLastname"
            control={control}
            rules={{ required: true }}
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={errors.adminEmail ? true : false}
                helperText={errors.adminEmail ? 'Valid email is required!' : null}
                margin="dense"
                label="Email Address"
                fullWidth
              />
            )}
            name="adminEmail"
            control={control}
            rules={{
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Valid email is required!',
              },
            }}
          />
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                error={errors.adminPass ? true : false}
                helperText={errors.adminPass ? 'Password is required and must be 8 characters long!' : null}
                margin="dense"
                label="Password"
                fullWidth
              />
            )}
            name="adminPass"
            control={control}
            rules={{ required: true, minLength: 8 }}
          />
          <Controller
            name="adminStatus"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <FormControlLabel control={<Switch defaultChecked {...field} color="primary" />} label="Active" />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={resetForm} color="primary">
            Cancel
          </Button>
          <Button disabled={isSubmitting} type="submit" color="primary">
            {isSubmitting ? 'Creating...' : 'Create'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
