import { Column } from '@material-table/core';
import { Data } from './types';
import { green, red } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';

export const columns: Array<Column<Data>> = [
  { title: 'Code', field: 'countryCode' },
  { title: 'Name', field: 'countryName' },
  {
    title: 'Active',
    field: 'countryStatus',
    render: (row) =>
      row.countryStatus ? (
        <Icon style={{ color: green[400] }}>check_circle</Icon>
      ) : (
        <Icon style={{ color: red[400] }}>remove_circle</Icon>
      ),
  },
];

export const createDefaultValues = {
  countryCode: '',
  countryName: '',
  countryStatus: false,
};
