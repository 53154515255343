import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Icon } from '@material-ui/core/';
import { Data } from './types';
import { discoverTabs } from './initialData';

type PropsType = {
  handleGroupChange: (group: keyof Data) => void;
  group: keyof Data;
};

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    maxWidth: '100%',
    // marginTop: '22px',
    // marginBottom: '22px',
    minWidth: '100%',
  },
  tabsRoot: {
    borderBottom: '2px solid #e8e8e8',
  },
  tab: {
    minWidth: 10,
  },

  subTab: {
    width: 8,
    fontSize: '12px',
  },
});

export default function TabsContainer({ group, handleGroupChange }: PropsType) {
  const classes = useStyles();
  const handleChange = (event: React.ChangeEvent<{}>, newValue: keyof Data) => {
    handleGroupChange(newValue);
  };
  return (
    <div className={classes.root}>
      <Tabs
        value={group}
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
        aria-label="group-tabs"
        className={classes.tabsRoot}
      >
        {discoverTabs.map((row) => (
          <Tab label={row.label} icon={<Icon>{row.icon}</Icon>} className={classes.tab} value={row.key} />
        ))}
        {/* <Tab label="Medias" icon={<Icon>date_range</Icon>} className={classes.tab} value="medias" />
          <Tab label="Date" icon={<Icon>date_range</Icon>} className={classes.tab} value="date" /> */}
      </Tabs>
    </div>
  );
}
