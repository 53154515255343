import { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { makeCall } from 'utils/utils';
import { tableContainerStyles as useStyles } from 'styles/common';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import { Media, ConnectionChannel, ModalType } from 'types/global';
import EnhancedSnackbar from 'components/EnhancedSnackbar';
import { columns } from './initialData';
import Dialog from './dialog';

type PropsType = {
  apiKey: string;
  connectionUuid: string | undefined;
};

export default function ContainerTable({ apiKey, connectionUuid }: PropsType) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<ConnectionChannel[]>([]);
  const [medias, setMedias] = useState<Media[]>([]);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [record, setRecord] = useState<ConnectionChannel | null>(null);
  const [modalType, setModalType] = useState<ModalType>(null);

  useEffect(() => {
    if (shouldRefresh && connectionUuid) {
      makeCall(`${apiKey}/connectionChannels?connectionChannelConnectionUuid=${connectionUuid}`, 'GET').then((res) => {
        setData(res?.data.data);
        setShouldRefresh(false);
        setIsLoading(false);
      });
    }
  }, [shouldRefresh, apiKey, connectionUuid]);

  const getRecord = async (rowData: ConnectionChannel) => {
    const response = await makeCall(`${apiKey}/connectionChannels/${rowData.connectionChannelUuid}`, 'GET');
    const mediaResponse = await makeCall(
      `medias?mediaAccountUuid=${response?.data.data.connectionChannelAccountUuid}`,
      'GET'
    );
    setRecord(response?.data.data);
    setMedias(mediaResponse?.data.data);
    setModalType('update');
  };

  const refresh = () => {
    const message = `Connection channel updated successfully!`;
    setToastMessage(message);
    setToastOpen(true);
    setIsLoading(true);
    setShouldRefresh(true);
  };

  return (
    <>
      <Dialog
        open={modalType === 'update'}
        medias={medias}
        record={record}
        apiKey={apiKey}
        handleClose={() => setModalType(null)}
        refresh={refresh}
      />
      <EnhancedSnackbar open={toastOpen} message={toastMessage} onClose={() => setToastOpen(false)} />
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <MaterialTable
            title="Connection channels"
            components={{
              Toolbar: (props) => (
                <div className={classes.toolbarWrapper}>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={{
              search: true,
              pageSize: 5,
              actionsColumnIndex: 9,
              actionsCellStyle: { color: '#666' },
            }}
            actions={[
              {
                icon: 'edit',
                tooltip: `Update connection channel`,
                isFreeAction: false,
                onClick: (event, rowData: any) => getRecord(rowData),
              },
            ]}
            isLoading={isLoading}
            columns={columns}
            data={data}
          />
        </Grid>
      </Grid>
    </>
  );
}
