import { makeStyles } from '@material-ui/core';

export const tableContainerStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(6),
  },
  toolbarWrapper: {
    '& .MuiToolbar-gutters': {
      paddingLeft: '16px',
      paddingRight: 0,
    },
    '& .MuiTypography-h6': {
      color: theme.palette.primary.main,
    },
  },
}));
