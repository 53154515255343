import { FetchFuncParams } from './types';
import { makeCall } from 'utils/utils';
import { orderBy } from 'lodash';
import { InsightsDataType } from './types';

export const fetchFunc = async ({ url, type = 'GET', payload = null }: FetchFuncParams) => {
  const response = await makeCall(url, type, payload);
  return response?.data.data;
};

export function sortData(data: InsightsDataType[], sortBy: string, direction: 'asc' | 'desc') {
  const sortedData = orderBy(data, sortBy, direction);

  return sortedData;
}

export function getDateOfWeek(w: any, y: any) {
  var d = 1 + (w - 0) * 7; // 1st of January + 7 days for each week

  return new Date(y, 0, d);
}
