import React, { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useForm, Controller } from 'react-hook-form';
import { makeCall } from 'utils/utils';
import Switch from 'components/EnhancedSwitch';
import EnhancedTextField from 'components/EnhancedTextField';
import { createDefaultValues as defaultValues } from './initial';
import { CreateFormData as FormData, CreateFormPropsType as PropsType, Media } from './types';

export default function Create({
  open,
  handleClose,
  refresh,
  countries,
  currencies,
  programs,
  accounts,
  isProgramSelected,
}: PropsType) {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
    setValue,
  } = useForm<FormData>({ defaultValues });

  const resetForm = () => {
    reset(defaultValues);
    handleClose();
  };

  useEffect(() => {
    if (isProgramSelected) {
      reset({
        ...defaultValues,
        trackerProgramUuid: programs[0],
      });
    }
  }, [isProgramSelected, reset, programs]);

  const [localMedias, setLocalMedias] = React.useState<Media[]>([]);

  const onSubmit = async (data: FormData) => {
    const payload = {
      ...data,
      trackerProgramUuid: data.trackerProgramUuid?.programUuid ?? '',
      trackerAccountUuid: data.trackerAccountUuid?.accountUuid ?? '',
      trackerMediaUuid: data.trackerMediaUuid?.mediaUuid ?? '',
      trackerCountryCode: data.trackerCountryCode?.countryCode ?? '',
      trackerCurrencyCode: data.trackerCurrencyCode?.currencyCode ?? '',
    };
    await makeCall('trackers', 'POST', payload);
    reset(defaultValues);
    handleClose();
    refresh();
  };

  const handleAccountChange = async (data: any) => {
    setValue('trackerMediaUuid', null);
    setLocalMedias([]);
    const response = await makeCall(`medias?mediaAccountUuid=${data.accountUuid}`, 'GET');
    setLocalMedias(response?.data.data);
  };

  return (
    <>
      <Dialog
        open={open}
        disableBackdropClick={true}
        onEscapeKeyDown={resetForm}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">Create Tracker</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <EnhancedTextField
                  control={control}
                  fieldName="trackerName"
                  label="Tracker name"
                  hasError={errors.trackerName ? true : false}
                  errorTxt="Tracker name is required!"
                  autoFocus
                />
              </Grid>
              <Grid item xs={4}>
                <EnhancedTextField
                  control={control}
                  fieldName="trackerUrlMatch"
                  label="URL match"
                  hasError={errors.trackerUrlMatch ? true : false}
                  errorTxt="URL match is required!"
                />
              </Grid>
              <Grid item xs={4}>
                <EnhancedTextField control={control} fieldName="trackerUrlAlias" label="URL alias" required={false} />
              </Grid>
              <Grid item xs={4}>
                <EnhancedTextField
                  control={control}
                  fieldName="trackerTagDeeplink"
                  label="Tag deeplink"
                  required={false}
                />
              </Grid>
              <Grid item xs={4}>
                <EnhancedTextField
                  control={control}
                  fieldName="trackerTagChannelId"
                  label="Tag channel id"
                  required={false}
                />
              </Grid>
              <Grid item xs={4}>
                <EnhancedTextField
                  control={control}
                  fieldName="trackerTagAccountId"
                  label="Tag account id"
                  required={false}
                />
              </Grid>
              <Grid item xs={4}>
                <EnhancedTextField
                  control={control}
                  fieldName="trackerTagCampaignId"
                  label="Tag campaign id"
                  required={false}
                />
              </Grid>
              <Grid item xs={4}>
                <EnhancedTextField
                  control={control}
                  fieldName="trackerTagTrackingUrl"
                  label="Tag tracking URL"
                  required={false}
                />
              </Grid>
              <Grid item xs={4}>
                <EnhancedTextField control={control} fieldName="trackerTagUrlId" label="Tag URL id" required={false} />
              </Grid>
              <Grid item xs={4}>
                <EnhancedTextField
                  control={control}
                  fieldName="trackerTagCustom1"
                  label="Custom tag 1"
                  required={false}
                />
              </Grid>
              <Grid item xs={4}>
                <EnhancedTextField
                  control={control}
                  fieldName="trackerTagCustom2"
                  label="Custom tag 2"
                  required={false}
                />
              </Grid>
              <Grid item xs={4}>
                <EnhancedTextField
                  control={control}
                  fieldName="trackerTagCustom3"
                  label="Custom tag 3"
                  required={false}
                />
              </Grid>
              <Grid item xs={4}>
                <EnhancedTextField
                  control={control}
                  fieldName="trackerTagCustom4"
                  label="Custom tag 4"
                  required={false}
                />
              </Grid>
              <Grid item xs={4}>
                <EnhancedTextField
                  control={control}
                  fieldName="trackerTagCustom5"
                  label="Custom tag 5"
                  required={false}
                />
              </Grid>

              <Grid item xs={4}>
                <EnhancedTextField
                  control={control}
                  fieldName="trackerFixed"
                  label="Fixed"
                  hasError={errors.trackerFixed ? true : false}
                  errorTxt="Fixed is required!"
                />
              </Grid>
              <Grid item xs={4}>
                <EnhancedTextField
                  control={control}
                  fieldName="trackerPercentage"
                  label="Percentage"
                  hasError={errors.trackerPercentage ? true : false}
                  errorTxt="Percentage is required!"
                />
              </Grid>
              <Grid item xs={4}>
                <EnhancedTextField
                  control={control}
                  fieldName="trackerSplitDeeplinkPercentage"
                  label="Deeplink percentage"
                  hasError={errors.trackerSplitDeeplinkPercentage ? true : false}
                  errorTxt="Deeplink percentage is required!"
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="trackerProgramUuid"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="program-select"
                      options={programs}
                      onChange={(_, data) => field.onChange(data)}
                      getOptionLabel={(option) => option.programTitle}
                      fullWidth
                      disabled={isProgramSelected}
                      renderInput={(params) => (
                        <TextField
                          error={errors.trackerProgramUuid ? true : false}
                          helperText={errors.trackerProgramUuid ? 'Program is required!' : null}
                          {...params}
                          label="Program"
                          margin="dense"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="trackerAccountUuid"
                  control={control}
                  // rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="account-select"
                      options={accounts}
                      onChange={(_, data) => {
                        field.onChange(data);
                        handleAccountChange(data);
                      }}
                      getOptionLabel={(option) => option.accountName}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          error={errors.trackerAccountUuid ? true : false}
                          helperText={errors.trackerAccountUuid ? 'Account is required!' : null}
                          {...params}
                          label="Account"
                          margin="dense"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="trackerMediaUuid"
                  control={control}
                  // rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="media-select"
                      options={localMedias}
                      onChange={(_, data) => field.onChange(data)}
                      getOptionLabel={(option) => option.mediaUrl}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          error={errors.trackerMediaUuid ? true : false}
                          helperText={errors.trackerMediaUuid ? 'Media is required!' : null}
                          {...params}
                          label="Media"
                          margin="dense"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="trackerCountryCode"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="country-select"
                      options={countries}
                      onChange={(_, data) => field.onChange(data)}
                      getOptionLabel={(option) => option.countryName}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          error={errors.trackerCountryCode ? true : false}
                          helperText={errors.trackerCountryCode ? 'Country is required!' : null}
                          {...params}
                          label="Country"
                          margin="dense"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="trackerCurrencyCode"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="currency-select"
                      options={currencies}
                      onChange={(_, data) => field.onChange(data)}
                      getOptionLabel={(option) => option.currencyCode}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          error={errors.trackerCurrencyCode ? true : false}
                          helperText={errors.trackerCurrencyCode ? 'Currency is required!' : null}
                          {...params}
                          label="Currency"
                          margin="dense"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <Switch isChecked={false} control={control} fieldName="trackerOmitDeeplink" label="Omit deeplink" />
              </Grid>
              <Grid item xs={2}>
                <Switch isChecked={false} control={control} fieldName="trackerDeeplinkPathOnly" label="Deeplink Path" />
              </Grid>
              <Grid item xs={2}>
                <Switch isChecked={false} control={control} fieldName="trackerUrlAliasExact" label="URL alias exact" />
              </Grid>
              <Grid item xs={2}>
                <Switch isChecked={false} control={control} fieldName="trackerIsDealStatus" label="Deal status" />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={resetForm} color="primary">
              Cancel
            </Button>
            <Button disabled={isSubmitting} type="submit" color="primary">
              {isSubmitting ? 'Creating...' : 'Create'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
