export const v2Endpoints = [
  'dashboards',
  'dashboards',
  'dashboards',
  'insights',
  'users',
  'finance',
  'apikeys',
  'deals',
];
