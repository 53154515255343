import { useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Divider,
  Chip,
  Typography,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { makeCall } from 'utils/utils';
import { UpdateFormData as FormData, UpdateFormPropsType as PropsType } from './types';
import EnhancedTextField from 'components/EnhancedTextField';
import EnhancedSwitch from 'components/EnhancedSwitch';
import Clipboard from 'components/ClipboardV2';
import moment from 'moment';
import { texts } from './initialData';

export default function Update({ open, handleClose, refresh, record }: PropsType) {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
  } = useForm<FormData>();

  useEffect(() => {
    if (record) {
      reset({
        connectionMinutesBetweenImport: record.connectionMinutesBetweenImport,
        connectionStatus: record.connectionStatus,
      });
    }
  }, [record, reset]);

  const onSubmit = async (data: FormData) => {
    await makeCall(`${texts.apiKey}/connections/${record?.connectionUuid}`, 'PUT', data);
    close();
    refresh();
  };

  const close = () => {
    handleClose();
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        disableBackdropClick={true}
        onClose={close}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Update {texts.title} Connection</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Clipboard id={record?.connectionUuid ?? ''} />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Chip
                  variant="outlined"
                  label={
                    <Typography variant="subtitle2" color="primary">
                      Account: {record?.account.accountName ?? '-'}
                    </Typography>
                  }
                  color="primary"
                  style={{ marginRight: '10px' }}
                />
                <Chip
                  variant="outlined"
                  label={
                    <Typography variant="subtitle2" color="primary">
                      Website ID: {record?.connectionWebsiteId ?? '-'}
                    </Typography>
                  }
                  color="primary"
                  style={{ marginRight: '10px' }}
                />
                <Chip
                  variant="outlined"
                  label={
                    <Typography variant="subtitle2" color="primary">
                      Last import: {moment(record?.connectionLastImportDatetime).format('DD MMM, HH:mm')}
                    </Typography>
                  }
                  color="primary"
                  style={{ marginRight: '10px' }}
                />
              </Grid>
            </Grid>
            <Divider style={{ margin: '20px 0' }} />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <EnhancedTextField
                  control={control}
                  fieldName="connectionMinutesBetweenImport"
                  label="Minutes between import"
                  hasError={errors.connectionMinutesBetweenImport ? true : false}
                  errorTxt="Minutes between import is required!"
                />
              </Grid>
              <Grid item xs={4}>
                <EnhancedSwitch
                  isChecked={!!record?.connectionStatus}
                  control={control}
                  fieldName="connectionStatus"
                  label="Status"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={close} color="primary">
              Cancel
            </Button>
            <Button disabled={isSubmitting} type="submit" color="primary">
              {isSubmitting ? 'Updating...' : 'Update'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
