import { useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Divider,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { makeCall } from 'utils/utils';
import { UpdateFormPropsType as PropsType, UpdateFormData as FormData } from './types';
import EnhancedTextField from 'components/EnhancedTextField';
import EnhancedSwitch from 'components/EnhancedSwitch';
import Clipboard from 'components/ClipboardV2';

export default function Update({ open, refresh, handleClose, record }: PropsType) {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
  } = useForm<FormData>();

  useEffect(() => {
    if (record) {
      reset({
        networkName: record.networkName,
        networkLegalName: record.networkLegalName,
        networkTrackingUrl: record.networkTrackingUrl,
        networkTagDeeplink: record.networkTagDeeplink,
        networkUrlDeeplinkTags: record.networkUrlDeeplinkTags,
        networkTagClickId: record.networkTagClickId,
        networkTagChannelUrl: record.networkTagChannelUrl,
        networkTagChannelId: record.networkTagChannelId,
        networkTagAccountId: record.networkTagAccountId,
        networkTagCampaignId: record.networkTagCampaignId,
        networkTagUrlId: record.networkTagUrlId,
        networkTagTrackingUrl: record.networkTagTrackingUrl,
        networlTagUrlShortCode: record.networlTagUrlShortCode,
        networkTagCustom1: record.networkTagCustom1,
        networkTagCustom2: record.networkTagCustom2,
        networkTagCustom3: record.networkTagCustom3,
        networkTagCustom4: record.networkTagCustom4,
        networkTagCustom5: record.networkTagCustom5,
        networkDeeplinkPathOnly: record.networkDeeplinkPathOnly,
        networkUrlEncodeDeeplink: record.networkUrlEncodeDeeplink,
        networkUrlAllowEmptyTagsStatus: record.networkUrlAllowEmptyTagsStatus,
      });
    }
  }, [record, reset]);

  const onSubmit = async (data: FormData) => {
    const payload = {
      ...data,
    };
    await makeCall(`networks/${record?.networkUuid}`, 'PUT', payload);
    close();
    refresh();
  };

  const close = () => {
    handleClose();
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        disableBackdropClick={true}
        onClose={close}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Update Network</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Clipboard id={record?.networkUuid ?? ''} />
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body2">Import: {record?.networkCallbackKey ? 'Yes' : 'No'}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">Callback Key: {record?.networkCallbackKey ?? '-'}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">Callback URL: {record?.networkCallbackUrl ?? '-'}</Typography>
              </Grid>
            </Grid>
            <Divider style={{ margin: '10px 0' }} />
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="networkName"
                  label="Name"
                  hasError={errors.networkName ? true : false}
                  errorTxt="Name is required!"
                  autoFocus
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="networkLegalName"
                  label="Legal Name"
                  hasError={errors.networkLegalName ? true : false}
                  errorTxt="Legal Name is required!"
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="networkTrackingUrl"
                  label="Tracking URL"
                  hasError={errors.networkTrackingUrl ? true : false}
                  errorTxt="Tracking URL is required!"
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="networkTagDeeplink"
                  label="Tag deeplink"
                  required={false}
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="networkUrlDeeplinkTags"
                  label="Url deeplink static tags"
                  required={false}
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="networkTagClickId"
                  label="Global click id tag"
                  required={false}
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="networkTagChannelUrl"
                  label="Global channel url tag"
                  required={false}
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="networkTagChannelId"
                  label="Tag channel id"
                  required={false}
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="networkTagAccountId"
                  label="Tag account id"
                  required={false}
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="networkTagCampaignId"
                  label="Tag campaign id"
                  required={false}
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField control={control} fieldName="networkTagUrlId" label="Tag url id" required={false} />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="networkTagTrackingUrl"
                  label="Tag tracking url"
                  required={false}
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="networlTagUrlShortCode"
                  label="Global url short code"
                  hasError={errors.networlTagUrlShortCode ? true : false}
                  errorTxt="Global url short code is required!"
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="networkTagCustom1"
                  label="Custom Tag 1"
                  required={false}
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="networkTagCustom2"
                  label="Custom Tag 2"
                  required={false}
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="networkTagCustom3"
                  label="Custom Tag 3"
                  required={false}
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="networkTagCustom4"
                  label="Custom Tag 4"
                  required={false}
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField
                  control={control}
                  fieldName="networkTagCustom5"
                  label="Custom Tag 5"
                  required={false}
                />
              </Grid>
              <Grid item xs={3}>
                <EnhancedSwitch
                  control={control}
                  isChecked={!!record?.networkDeeplinkPathOnly}
                  fieldName="networkDeeplinkPathOnly"
                  label="Deeplink Path Only"
                  required={false}
                />
              </Grid>
              <Grid item xs={3}>
                <EnhancedSwitch
                  control={control}
                  isChecked={!!record?.networkUrlEncodeDeeplink}
                  fieldName="networkUrlEncodeDeeplink"
                  label="URL Encode Deeplink"
                  required={false}
                />
              </Grid>
              <Grid item xs={3}>
                <EnhancedSwitch
                  control={control}
                  isChecked={!!record?.networkUrlAllowEmptyTagsStatus}
                  fieldName="networkUrlAllowEmptyTagsStatus"
                  label="Network Allow Empty Tags"
                  required={false}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={close} color="primary">
              Cancel
            </Button>
            <Button disabled={isSubmitting} type="submit" color="primary">
              {isSubmitting ? 'Updating...' : 'Update'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
