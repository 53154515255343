import { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { makeCall } from 'utils/utils';
import { tableContainerStyles as useStyles } from 'styles/common';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import WithMotion from 'components/withMotion';
import { ModalType, DataType, Country, MerchantType, Network, Record, AdminType, Currency } from './types';
import { columns } from './initialData';
import { Filters } from './Filters';
import { Create } from './Create';
import { Update } from './Update';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

function Container() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<DataType[]>([]);
  const [modalType, setModalType] = useState<ModalType>(null);
  const [record, setRecord] = useState<Record | null>(null);
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [advertisers, setAdvertisers] = useState<MerchantType[]>([]);
  const [countries, setCountries] = useState<Country[]>([]);
  const [networks, setNetworks] = useState<Network[]>([]);
  const [admins, setAdmins] = useState<AdminType[]>([]);
  const [currencies, setCurrencies] = useState<Currency[]>([]);

  useEffect(() => {
    makeCall('merchants', 'GET').then((res) => {
      setAdvertisers(res?.data.data);
    });
    makeCall('countries?countryStatus=true', 'GET').then((res) => {
      setCountries(res?.data.data);
    });
    makeCall('currencies', 'GET').then((res) => {
      setCurrencies(res?.data.data);
    });
    makeCall('networks', 'GET').then((res) => {
      setNetworks(res?.data.data);
    });
    makeCall('admins', 'GET').then((res) => {
      setAdmins(res?.data.data);
    });
  }, []);

  useEffect(() => {
    const getData = async () => {
      const response = await makeCall('deals', 'GET');
      setData(response?.data.data);
      setIsLoading(false);
      setShouldRefresh(false);
    };
    if (shouldRefresh) {
      getData();
    }
  }, [shouldRefresh]);

  const getRecord = async (clickedRow: DataType) => {
    const response = await makeCall(`deals/${clickedRow.deal.dealUuid}`, 'GET');
    setRecord(response?.data.data);
    setModalType('update');
  };

  const handleFilterSubmit = async (payload: any) => {
    setIsLoading(true);
    const response = await makeCall('deals', 'GET', payload);
    setData(response?.data.data);
    setIsLoading(false);
  };

  const handleRefresh = async (id: string) => {
    const response = await makeCall(`deals/${id}`, 'GET');
    setRecord(response?.data.data);
    setModalType('update');
    setShouldRefresh(true);
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Create
        open={modalType === 'create'}
        handleClose={() => setModalType(null)}
        refresh={handleRefresh}
        countries={countries}
        advertisers={advertisers}
        networks={networks}
        admins={admins}
        currencies={currencies}
      />
      <Update
        open={modalType === 'update'}
        handleClose={() => setModalType(null)}
        refresh={() => setShouldRefresh(true)}
        countries={countries}
        networks={networks}
        record={record}
        admins={admins}
        currencies={currencies}
      />
      <Filters
        handleFilterSubmit={handleFilterSubmit}
        isLoading={isLoading}
        countries={countries}
        advertisers={advertisers}
      />
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <MaterialTable
            title="Deals"
            components={{
              Toolbar: (props) => (
                <div className={classes.toolbarWrapper}>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={{
              search: true,
              pageSize: 10,
              actionsColumnIndex: 9,
              actionsCellStyle: { color: '#666' },
            }}
            actions={[
              {
                icon: 'add',
                tooltip: 'Create Deal',
                isFreeAction: true,
                onClick: () => setModalType('create'),
              },
              {
                icon: 'edit',
                tooltip: 'Update Deal',
                isFreeAction: false,
                onClick: (event, rowData: any) => getRecord(rowData),
              },
            ]}
            isLoading={isLoading}
            columns={columns}
            data={data}
          />
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
}

export default WithMotion(Container);
