import Dashboard from 'pages/Dashboard';

import Advertisers from 'pages/Merchants';

import Deals from 'pages/Deals';

import Admins from 'pages/Identities/Admins';
import ApiKeys from 'pages/Identities/ApiKeys';
import Clients from 'pages/Identities/Clients';
import Accounts from 'pages/Identities/Accounts';
import Users from 'pages/Identities/Users';
import Medias from 'pages/Identities/Medias';

import Networks from 'pages/Networks/Networks';
import PartnerAds from 'pages/Networks/Connections/PartnerAds';
import DigitalAdvisor from 'pages/Networks/Connections/DigitalAdvisor';
import AdService from 'pages/Networks/Connections/AdService';
import AdTraction from 'pages/Networks/Connections/AdTraction';
import Tradedoubler from 'pages/Networks/Connections/Tradedoubler';
import Tradetracker from 'pages/Networks/Connections/Tradetracker';
import Heylink from 'pages/Networks/Connections/Heylink';
import Awin from 'pages/Networks/Connections/Awin';
import CommissionJunction from 'pages/Networks/Connections/CommissionJunction';
import Daisycon from 'pages/Networks/Connections/Daisycon';

import Programs from 'pages/Programs/Programs';
import Trackers from 'pages/Programs/Trackers';
import TrackerMediaExclude from 'pages/Programs/TrackersMediaExclude';
import CommissionRules from 'pages/CommissionRule/index';

import NetworkPayment from 'pages/Finance/NetworkPayment';
import PaymentNotes from 'pages/Finance/PaymentNotes';
import PaymentNotesHelper from 'pages/Finance/PaymentNotes/Helper';
import FinanceStatusReport from 'pages/Finance/statusReport';

import Insights from 'pages/Reports/Insights';
import Discovers from 'pages/Reports/Discovers';

import Callbacks from 'pages/Logs/Callbacks';

import LinkGenerator from 'pages/Tools/LinkGenerator';

import Currencies from 'pages/Settings/Currency/Currencies';
import CurrencyRates from 'pages/Settings/Currency/Currencies/CurrencyRate';
import Countries from 'pages/Settings/Countries';
import AccountTypes from 'pages/Settings/AccountTypes';
import MediaTypes from 'pages/Identities/Medias/MediaType';

import Logout from 'pages/Logout';

const adminNavItems = [
  {
    exact: true,
    label: 'Dashboard',
    to: '/dashboard',
    icon: 'dashboard',
    nested: [],
    listKey: 'dashboard-1',
    component: Dashboard,
  },
  {
    label: 'Advertisers',
    to: '/advertisers',
    icon: 'store',
    listKey: 'advertisers-1',
    component: Advertisers,
    nested: [],
  },
  {
    exact: true,
    label: 'Deals',
    to: '/deals',
    icon: 'local_offer',
    nested: [],
    listKey: 'deals-1',
    component: Deals,
  },
  {
    label: 'Identities',
    to: '',
    icon: 'business',
    listKey: 'identities-1',
    nested: [
      {
        label: 'Admins',
        to: '/identities/admins',
        nested: [],
        listKey: 'admins-1',
        component: Admins,
      },
      {
        label: 'API Keys',
        to: '/identities/api-keys',
        nested: [],
        listKey: 'identities-7',
        component: ApiKeys,
      },
      {
        label: 'Clients',
        to: '/identities/clients',
        nested: [],
        listKey: 'identities-2',
        component: Clients,
      },
      {
        label: 'Accounts',
        to: '/identities/accounts',
        nested: [],
        listKey: 'identities-3',
        component: Accounts,
      },
      {
        label: 'Users',
        to: '/identities/users',
        nested: [],
        listKey: 'identities-5',
        component: Users,
      },
      {
        label: 'Medias',
        to: '/identities/medias',
        nested: [],
        listKey: 'identities-6',
        component: Medias,
      },
    ],
  },
  {
    label: 'Networks',
    to: '',
    icon: 'group_work',
    nested: [
      {
        label: 'Networks',
        to: '/networks/networks',
        nested: [],
        listKey: 'networks-2',
        component: Networks,
      },
      {
        label: 'Connections',
        to: '',
        listKey: 'connections-1',
        nested: [
          {
            label: 'AdService',
            to: '/networks/connections/adservice',
            nested: [],
            listKey: 'connections-adservice',
            component: AdService,
          },
          {
            label: 'AdTraction',
            to: '/networks/connections/adtraction',
            nested: [],
            listKey: 'connections-3',
            component: AdTraction,
          },
          {
            label: 'Awin',
            to: '/networks/connections/awin',
            nested: [],
            listKey: 'connections-awin',
            component: Awin,
          },
          {
            label: 'Commission Junction',
            to: '/networks/connections/commissionJunction',
            nested: [],
            listKey: 'connections-5',
            component: CommissionJunction,
          },
          {
            label: 'Daisycon',
            to: '/networks/connections/daisycon',
            nested: [],
            listKey: 'connections-daisycon',
            component: Daisycon,
          },
          {
            label: 'Digital Advisor',
            to: '/networks/connections/digitaladvisor',
            nested: [],
            listKey: 'connections-7',
            component: DigitalAdvisor,
          },
          {
            label: 'Heylink',
            to: '/networks/connections/heylink',
            nested: [],
            listKey: 'connections-heylink',
            component: Heylink,
          },
          {
            label: 'PartnerAds',
            to: '/networks/connections/partnerads',
            nested: [],
            listKey: 'connections-8',
            component: PartnerAds,
          },
          {
            label: 'Tradedoubler',
            to: '/networks/connections/tradedoubler',
            nested: [],
            listKey: 'connections-9',
            component: Tradedoubler,
          },
          {
            label: 'Tradetracker',
            to: '/networks/connections/tradetracker',
            nested: [],
            listKey: 'connections-10',
            component: Tradetracker,
          },
        ],
      },
    ],
    listKey: 'networks-1',
  },
  {
    label: 'Program',
    to: '',
    icon: 'description',
    listKey: 'programs-1',
    nested: [
      {
        label: 'Programs',
        to: '/programs/programs',
        nested: [],
        listKey: 'programs-2',
        component: Programs,
      },
      {
        label: 'Trackers',
        to: '/programs/trackers',
        nested: [],
        listKey: 'programs-3',
        component: Trackers,
      },
      {
        label: 'Trackers Media Exclude',
        to: '/programs/trackerMediaExclude',
        nested: [],
        listKey: 'programs-4',
        component: TrackerMediaExclude,
      },
      {
        label: 'Commission Rules',
        to: '/programs/commission-rules',
        nested: [],
        listKey: 'programs-6',
        component: CommissionRules,
      },
    ],
  },
  {
    label: 'Finance',
    to: '',
    icon: 'money',
    listKey: 'finance-1',
    nested: [
      {
        label: 'Network payment notes',
        to: '/finance/network-payments',
        nested: [],
        listKey: 'finance-2',
        component: NetworkPayment,
      },
      {
        label: 'Payment notes',
        to: '',
        nested: [
          {
            label: 'Payment Notes',
            to: '/finance/payment-notes/payment-notes',
            nested: [],
            listKey: 'finance-notes-1',
            component: PaymentNotes,
          },
          {
            label: 'Helper',
            to: '/finance/payment-notes/payment-notes-helper',
            nested: [],
            listKey: 'finance-notes-2',
            component: PaymentNotesHelper,
          },
        ],
        listKey: 'finance-3',
      },
      {
        label: 'Reports',
        to: '',
        icon: '',
        listKey: 'finance-4',
        nested: [
          {
            label: 'Finance status report',
            to: '/finance/report/status',
            nested: '',
            listKey: 'finance-5',
            component: FinanceStatusReport,
          },
        ],
      },
    ],
  },
  {
    label: 'Reports',
    to: '',
    icon: 'assessment',
    listKey: 'reports-1',
    nested: [
      {
        label: 'Insights',
        to: '/reports/insights',
        nested: [],
        listKey: 'reports-4',
        component: Insights,
      },
      {
        label: 'Discovers',
        to: '/reports/discovers',
        nested: [],
        listKey: 'reports-discovers',
        component: Discovers,
      },
    ],
  },

  {
    label: 'Logs',
    to: '',
    icon: 'sync',
    listKey: 'logs-1',
    nested: [
      {
        label: 'Callbacks',
        to: '/logs/callbacks',
        nested: [],
        listKey: 'callbacks-1',
        component: Callbacks,
      },
    ],
  },

  {
    label: 'Tools',
    to: '',
    icon: 'build',
    listKey: 'tools-1',
    nested: [
      {
        label: 'Link Generator',
        to: '/tools/link-generator',
        nested: [],
        listKey: 'tools-link-generator',
        component: LinkGenerator,
      },
    ],
  },
  {
    label: 'Settings',
    to: '',
    icon: 'settings',
    listKey: 'settings-1',
    nested: [
      {
        label: 'Currency',
        to: '',
        listKey: 'settings-2',
        nested: [
          {
            label: 'Currencies',
            to: '/settings/currency/currencies',
            nested: [],
            listKey: 'settings-3',
            component: Currencies,
          },
          {
            label: 'Currency rates',
            to: '/settings/currency/currency-rates',
            nested: [],
            listKey: 'settings-4',
            component: CurrencyRates,
          },
        ],
      },
      {
        label: 'Countries',
        to: '/settings/countries',
        nested: [],
        listKey: 'settings-5',
        component: Countries,
      },
      {
        label: 'Account types',
        to: '/settings/account-types',
        nested: [],
        listKey: 'settings-6',
        component: AccountTypes,
      },
      {
        label: 'Media Types',
        to: '/settings/media-types',
        nested: [],
        listKey: 'settings-7',
        component: MediaTypes,
      },
    ],
  },
  {
    label: 'Log out',
    to: '/logout',
    icon: 'power_settings_new',
    nested: [],
    listKey: 'logout-1',
    component: Logout,
  },
];

const studentNavItems = [
  {
    label: 'Advertisers',
    to: '/advertisers',
    icon: 'store',
    listKey: 'advertisers-1',
    component: Advertisers,
    nested: [],
  },
  {
    label: 'Log out',
    to: '/logout',
    icon: 'power_settings_new',
    nested: [],
    listKey: 'logout-1',
    component: Logout,
  },
];

export { adminNavItems, studentNavItems };
