import { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { makeCall } from 'utils/utils';
import { tableContainerStyles as useStyles } from 'styles/common';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import { Data, Record } from '../types';
import { columns } from './initialData';
import { ModalType } from 'types/global';
import EnhancedSnackbar from 'components/EnhancedSnackbar';
import Update from './Update';
import Create from './Create';

type PropsType = {
  shouldCreateDisabled: boolean;
  searchOptions: any;
  options?: any;
};

export default function TableContainer({ shouldCreateDisabled, searchOptions, options }: PropsType) {
  const classes = useStyles();
  const [data, setData] = useState<Data[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [record, setRecord] = useState<Record>(null);
  const [modalType, setModalType] = useState<ModalType>(null);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  useEffect(() => {
    if (shouldRefresh) {
      makeCall('commissionRules', 'GET', searchOptions).then((res) => setData(res?.data.data));
      setIsLoading(false);
      setShouldRefresh(false);
    }
  }, [shouldRefresh, searchOptions]);

  const getRecord = async (clickedRow: Data) => {
    const response = await makeCall(`commissionRules/${clickedRow.commissionRuleUuid}`, 'GET');
    setRecord(response?.data.data);
    setModalType('update');
  };

  const refresh = (key: string) => {
    const message = `Commission rule ${key} successfully!`;
    setToastMessage(message);
    setToastOpen(true);
    setIsLoading(true);
    setShouldRefresh(true);
  };

  return (
    <>
      {!shouldCreateDisabled && (
        <Create
          open={modalType === 'create'}
          handleClose={() => setModalType(null)}
          refresh={() => refresh('created')}
          options={options}
        />
      )}
      <Update
        open={modalType === 'update'}
        handleClose={() => setModalType(null)}
        refresh={() => refresh('updated')}
        record={record}
      />
      <EnhancedSnackbar open={toastOpen} message={toastMessage} onClose={() => setToastOpen(false)} />
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <MaterialTable
            title="Commission rules"
            components={{
              Toolbar: (props) => (
                <div className={classes.toolbarWrapper}>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={{
              search: true,
              pageSize: 10,
              actionsColumnIndex: 9,
              actionsCellStyle: { color: '#666' },
            }}
            actions={[
              {
                icon: 'add',
                tooltip: `Create commission rule`,
                isFreeAction: true,
                onClick: () => setModalType('create'),
                disabled: shouldCreateDisabled,
              },
              {
                icon: 'edit',
                tooltip: `Update commission rule`,
                isFreeAction: false,
                onClick: (event, rowData: any) => getRecord(rowData),
              },
            ]}
            columns={columns}
            isLoading={isLoading}
            data={data}
          />
        </Grid>
      </Grid>
    </>
  );
}
