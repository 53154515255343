import React, { useEffect, useState } from 'react';
import { makeCall } from 'utils/utils';
import { Grid } from '@material-ui/core';
import { tableContainerStyles as useStyles } from 'styles/common';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import EnhancedSnackbar from 'components/EnhancedSnackbar';
import { columns } from 'pages/Programs/Trackers/initial';
import { Data, Record } from 'pages/Programs/Trackers/types';
import { ModalType, Country, Currency, Account } from 'types/global';
import Create from 'pages/Programs/Trackers/Create';
import Update from 'pages/Programs/Trackers/Update';

type Props = {
  programUuid: string;
  programTitle: string;
  countries: Country[];
  currencies: Currency[];
  accounts: Account[];
};

export default function TrackerTable({ programUuid, programTitle, countries, currencies, accounts }: Props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<Data[]>([]);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [modalType, setModalType] = useState<ModalType>(null);
  const [record, setRecord] = useState<Record | null>(null);

  useEffect(() => {
    async function getData() {
      const response = await makeCall('trackers', 'GET', { trackerProgramUuid: programUuid });
      setData(response?.data.data);
      setIsLoading(false);
      setShouldRefresh(false);
    }
    if (programUuid && shouldRefresh) {
      getData();
    }
  }, [shouldRefresh, programUuid]);

  const getRecord = async (clickedRow: Data) => {
    const response = await makeCall(`trackers/${clickedRow.trackerUuid}`, 'GET');
    setRecord(response?.data.data);
    setModalType('update');
  };

  const refresh = (key: string) => {
    const message = `Tracker ${key} successfully!`;
    setToastMessage(message);
    setToastOpen(true);
    setIsLoading(true);
    setModalType(null);
    setShouldRefresh(true);
  };

  return (
    <>
      <Create
        open={modalType === 'create'}
        handleClose={() => setModalType(null)}
        refresh={() => refresh('created')}
        countries={countries}
        accounts={accounts}
        currencies={currencies}
        isProgramSelected={true}
        programs={[{ programTitle, programUuid }]}
      />
      <Update
        open={modalType === 'update'}
        handleClose={() => setModalType(null)}
        refresh={() => refresh('updated')}
        countries={countries}
        currencies={currencies}
        record={record}
      />
      <EnhancedSnackbar open={toastOpen} message={toastMessage} onClose={() => setToastOpen(false)} />
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <MaterialTable
            title="Program trackers"
            components={{
              Toolbar: (props) => (
                <div className={classes.toolbarWrapper}>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={{
              search: true,
              pageSize: 10,
              actionsColumnIndex: 1,
              actionsCellStyle: { color: '#666' },
            }}
            actions={[
              {
                icon: 'add',
                tooltip: 'Create Tracker',
                isFreeAction: true,
                onClick: () => setModalType('create'),
              },
              {
                icon: 'edit',
                tooltip: 'Update Tracker',
                isFreeAction: false,
                onClick: (event, rowData: any) => getRecord(rowData),
              },
            ]}
            isLoading={isLoading}
            columns={columns}
            data={data}
          />
        </Grid>
      </Grid>
    </>
  );
}
