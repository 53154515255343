import { Column } from '@material-table/core';
import { DataType, CreateFormData, FilterSelectType } from './types';

export const columns: Array<Column<DataType>> = [
  {
    title: 'ID',
    field: 'merchantUuid',
    cellStyle: { display: 'none' },
    headerStyle: { display: 'none' },
  },
  { title: 'Name', field: 'merchantName', defaultSort: 'asc' },
  { title: 'Url', field: 'merchantUrl' },
  {
    title: 'Favicon',
    field: 'merchantFavicon',
    render: (rowData) => <img src={rowData.merchantFavicon} className="img" alt="" />,
  },
];

export const createDefaultValues: CreateFormData = {
  merchantName: '',
  merchantUrl: '',
};

export const filterSelectOptions: FilterSelectType[] = [
  {
    label: 'All',
    value: null,
  },
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];
