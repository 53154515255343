import { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { makeCall, generateFile } from 'utils/utils';
import { tableContainerStyles as useStyles } from 'styles/common';
import WithMotion from 'components/withMotion';
import { OverviewData, HelperData, Account, Currency, ViewModeType, OverviewRecord, FiltersType } from './types';
import Filters from './Filters';
import { helperColumns, overviewColumns } from './initialData';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import View from './View';
import Create from './Create';
import Update from './Update';

function Container() {
  const classes = useStyles();
  const [overviewData, setOverviewData] = useState<OverviewData[]>([]);
  const [isLoadingOverview, setIsLoadingOverview] = useState(false);
  const [isLoadingHelper, setIsLoadingHelper] = useState(false);
  const [helperData, setHelperData] = useState<HelperData[]>([]);
  const [viewMode, setViewMode] = useState<ViewModeType>('overview');
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [isCreate, setIsCreate] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [defaultValues, setDefaultValues] = useState<{}>({
    paymentNotePeriodStartDate: '',
    paymentNotePeriodEndDate: '',
    paymentNoteCurrencyCode: null,
    paymentNoteAccountUuid: null,
  });
  const [record, setRecord] = useState<OverviewRecord | null>(null);
  const [filters, setFilters] = useState<FiltersType>({
    accountUuid: '',
    currencyCode: '',
    helperFromDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
    helperToDate: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
    overviewFromDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
    overviewToDate: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
    filterByPeriodDatetime: false,
  });

  useEffect(() => {
    const getData = () => {
      makeCall('accounts', 'GET').then((res) => setAccounts(res?.data.data));
      makeCall('currencies', 'GET').then((res) => setCurrencies(res?.data.data));
    };
    getData();
  }, []);

  const getOverviewData = async (filters: FiltersType) => {
    if (viewMode === 'helper') {
      setViewMode('overview');
    }
    setIsLoadingOverview(true);
    const payload = {
      accountUuid: filters.accountUuid,
      fromDate: filters.overviewFromDate,
      toDate: filters.overviewToDate,
      currencyCode: filters.currencyCode,
      filterByPeriodDatetime: filters.filterByPeriodDatetime,
      limit: 1000,
      offset: 0,
    };
    setFilters(filters);
    const response = await makeCall('finance/paymentnotes', 'GET', payload);
    setOverviewData(response?.data.data);
    setIsLoadingOverview(false);
  };

  const getHelperData = async (filters: FiltersType) => {
    setIsLoadingHelper(true);
    const payload = {
      accountUuid: filters.accountUuid,
      currencyCode: filters.currencyCode,
      minDate: filters.helperFromDate,
      maxDate: filters.helperToDate,
    };
    setFilters(filters);
    const response = await makeCall('finance/paymentnotes/helper', 'GET', payload);
    setHelperData(response?.data.data);
    setIsLoadingHelper(false);
  };

  const getRecord = async (clickedRowUuid: string, type: string = 'view') => {
    const response = await makeCall(`finance/paymentnotes/${clickedRowUuid}`, 'GET');
    setRecord(response?.data.data);
    if (type === 'view') {
      setIsView(true);
    } else {
      setIsUpdate(true);
    }
  };

  const refreshView = (id: string) => {
    getRecord(id);
  };

  const handleViewChange = (mode: ViewModeType) => {
    setViewMode(mode);
  };

  const handleFiltersSubmit = (filters: FiltersType) => {
    if (viewMode === 'helper') {
      getHelperData(filters);
    }
    if (viewMode === 'overview') {
      getOverviewData(filters);
    }
  };

  const handleRefresh = (id: string) => {
    getRecord(id, 'edit');
    getOverviewData(filters);
  };

  const createFromHelper = (row: any) => {
    const selectedAccount = accounts.filter((acc) => acc.accountUuid === row.accountUuid)[0];
    const selectedCurrency = currencies.filter((cur) => cur.currencyCode === row.currencyCode)[0];
    const defaultValues = {
      paymentNotePeriodStartDate: row.minDate,
      paymentNotePeriodEndDate: row.maxDate,
      paymentNoteCurrencyCode: selectedCurrency ? selectedCurrency : null,
      paymentNoteAccountUuid: selectedAccount ? selectedAccount : null,
    };

    setDefaultValues(defaultValues);
    setIsCreate(true);
  };

  const handleCreateClick = () => {
    setDefaultValues({
      paymentNotePeriodStartDate: '',
      paymentNotePeriodEndDate: '',
      paymentNoteCurrencyCode: null,
      paymentNoteAccountUuid: null,
    });
    setIsCreate(true);
  };

  const handleDownloadPdf = async (id: string) => {
    const response = await makeCall(`finance/paymentnotes/${id}.pdf`, 'GET', null, null, null, 'blob');
    if (response) {
      generateFile(response.data, `${id}.pdf`);
    }
  };

  const openAsCSV = async () => {
    if (overviewData.length) {
      const payload = {
        accountUuid: filters.accountUuid,
        fromDate: filters.overviewFromDate,
        toDate: filters.overviewToDate,
        currencyCode: filters.currencyCode,
        filterByPeriodDatetime: filters.filterByPeriodDatetime,
        limit: 1000,
        offset: 0,
      };
      const response = await makeCall('finance/paymentnotes', 'GET', payload, null, null, 'text', true);
      if (response) {
        generateFile(response.data, `payment-notes-overview.csv`);
      }
    }
  };

  return (
    <>
      <Update
        open={isUpdate}
        handleClose={() => setIsUpdate(!isUpdate)}
        accounts={accounts}
        record={record}
        refresh={() => getOverviewData(filters)}
        refreshView={getRecord}
      />
      <Create
        open={isCreate}
        handleClose={() => setIsCreate(!isCreate)}
        refresh={handleRefresh}
        accounts={accounts}
        currencies={currencies}
        defaultValues={defaultValues}
      />
      <View
        open={isView}
        handleClose={() => setIsView(!isView)}
        refresh={refreshView}
        record={record}
        handleDownloadClick={handleDownloadPdf}
      />
      <Filters
        accounts={accounts}
        currencies={currencies}
        handleFiltersSubmit={handleFiltersSubmit}
        handleViewChange={handleViewChange}
        viewMode={viewMode}
        isLoading={isLoadingHelper || isLoadingOverview}
        handleCreateClick={handleCreateClick}
      />
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          {viewMode === 'helper' ? (
            <MaterialTable
              title="Payment notes helper"
              components={{
                Toolbar: (props) => (
                  <div className={classes.toolbarWrapper}>
                    <MTableToolbar {...props} />
                  </div>
                ),
              }}
              options={{
                search: true,
                pageSize: 10,
                actionsColumnIndex: 9,
                actionsCellStyle: { color: '#666' },
              }}
              actions={[
                {
                  icon: 'add',
                  tooltip: 'Create',
                  isFreeAction: false,
                  onClick: (event, rowData: any) => createFromHelper(rowData),
                },
              ]}
              isLoading={isLoadingHelper}
              columns={helperColumns}
              data={helperData}
            />
          ) : (
            <MaterialTable
              title="Payment notes overview"
              components={{
                Toolbar: (props) => (
                  <div className={classes.toolbarWrapper}>
                    <MTableToolbar {...props} />
                  </div>
                ),
              }}
              options={{
                search: true,
                pageSize: 10,
                actionsColumnIndex: 9,
                actionsCellStyle: { color: '#666' },
              }}
              actions={[
                {
                  icon: 'download',
                  tooltip: 'Download CSV',
                  isFreeAction: true,
                  onClick: (event, rowData: any) => openAsCSV(),
                },
                {
                  icon: 'visibility',
                  tooltip: 'View',
                  isFreeAction: false,
                  onClick: (event, rowData: any) => getRecord(rowData.paymentNote.paymentNoteUuid),
                },
                (rowData) => ({
                  icon: 'picture_as_pdf',
                  tooltip: 'Download PDF',
                  isFreeAction: false,
                  disabled: rowData?.paymentNote?.paymentNoteStatus !== 'COMPLETED',
                  onClick: (event, rowData: any) => handleDownloadPdf(rowData.paymentNote.paymentNoteUuid),
                }),
                (rowData) => ({
                  icon: 'create',
                  tooltip: 'edit',
                  isFreeAction: false,
                  disabled:
                    (rowData?.paymentNote?.paymentNoteStatus === 'COMPLETED' ||
                      rowData?.paymentNote?.paymentNoteStatus === 'COMMITTED') ??
                    false,
                  onClick: (event, rowData: any) => getRecord(rowData.paymentNote.paymentNoteUuid, 'edit'),
                }),
              ]}
              isLoading={isLoadingOverview}
              columns={overviewColumns}
              data={overviewData}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default WithMotion(Container);
