import { Grid } from '@material-ui/core';
import { FiltersType, DataType } from '../types';
import Card from './Card';

type Props = {
  isLoading: boolean;
  data: DataType | null;
  filters: FiltersType | null;
};

export const formatter = new Intl.NumberFormat('da-DK');

export default function CardsContainer({ isLoading, data, filters }: Props) {
  return (
    <Grid style={{ marginTop: 10 }} container alignItems="center" spacing={3}>
      <Card
        title="Conversions"
        value={formatter.format(data?.totals.counts.totalConversionsCount ?? 0)}
        isLoading={isLoading}
      />
      <Card
        title="Pending no payment received"
        value={`${formatter.format(data?.totals.sums.totalPendingNoPaymentReceived ?? 0)} ${
          filters?.dataSource !== 'count' ? data?.totals.currencyCode ?? '' : ''
        }`}
        isLoading={isLoading}
      />
      <Card
        title="Pending payment received"
        value={`${formatter.format(data?.totals.sums.totalPendingPaymentReceived ?? 0)} ${
          filters?.dataSource !== 'count' ? data?.totals.currencyCode ?? '' : ''
        }`}
        isLoading={isLoading}
      />
      <Card
        title="Total Paid"
        value={`${formatter.format(data?.totals.sums.totalPaid ?? 0)} ${
          filters?.dataSource !== 'count' ? data?.totals.currencyCode ?? '' : ''
        }`}
        isLoading={isLoading}
      />
      <Card
        title="Total Rejected"
        value={`${formatter.format(data?.totals.sums.totalRejected ?? 0)} ${
          filters?.dataSource !== 'count' ? data?.totals.currencyCode ?? '' : ''
        }`}
        isLoading={isLoading}
      />
      <Card
        title="Total Provision"
        value={`${formatter.format(data?.totals.sums.totalProvision ?? 0)} ${
          filters?.dataSource !== 'count' ? data?.totals.currencyCode ?? '' : ''
        }`}
        isLoading={isLoading}
      />
    </Grid>
  );
}
